// tslint:disable: no-non-null-assertion
import * as React from "react";
import List from "components/List";
import { ResourceCollection } from "client/resources";
import { MachinePolicyResource } from "client/resources";
import { repository } from "clientInstance";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { NavigationButton, NavigationButtonType } from "components/Button";
import { RouteComponentProps } from "react-router";
import InfrastructureLayout from "../InfrastructureLayout";
import MachinePolicySummary from "../MachinePolicySummary";
import Chip from "components/Chips/Chip";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import ListTitle from "components/ListTitle/ListTitle";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";

class MachinePolicyList extends List<MachinePolicyResource> {}

interface MachinePolicyLayoutState extends DataBaseComponentState {
    machinePoliciesResponse: ResourceCollection<MachinePolicyResource>;
}

export default class MachinePoliciesLayout extends DataBaseComponent<RouteComponentProps<void>, MachinePolicyLayoutState> {
    constructor(props: RouteComponentProps<void>) {
        super(props);
        this.state = {
            machinePoliciesResponse: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const machinePoliciesResponse = await repository.MachinePolicies.list();
            this.setState({ machinePoliciesResponse });
        });
    }

    render() {
        const addButton = (
            <PermissionCheck permission={Permission.MachinePolicyCreate}>
                <NavigationButton type={NavigationButtonType.Primary} label="Add machine policy" href={`${this.props.match.url}/create`} />
            </PermissionCheck>
        );

        return (
            <InfrastructureLayout {...this.props}>
                <PaperLayout busy={this.state.busy} errors={this.state.errors} title="Machine Policies" sectionControl={addButton}>
                    {this.state.machinePoliciesResponse && (
                        <MachinePolicyList
                            initialData={this.state.machinePoliciesResponse}
                            onRow={(item: MachinePolicyResource) => this.buildMachinePolicyRow(item)}
                            onFilter={this.filter}
                            filterSearchEnabled={true}
                            apiSearchParams={["partialName"]}
                            match={this.props.match}
                            filterHintText="Filter by name..."
                            showPagingInNumberedStyle={false}
                        />
                    )}
                </PaperLayout>
            </InfrastructureLayout>
        );
    }

    private filter(filter: string, resource: MachinePolicyResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }

    private buildMachinePolicyRow(machinePolicy: MachinePolicyResource) {
        return (
            <div>
                <ListTitle>
                    {machinePolicy.Name}
                    {machinePolicy.IsDefault && (
                        <span>
                            {" "}
                            <Chip>default</Chip>
                        </span>
                    )}
                </ListTitle>
                <MachinePolicySummary machinePolicy={machinePolicy} hideDescription={false} conciseView={false} />
            </div>
        );
    }
}
