// tslint:disable: no-non-null-assertion
import * as React from "react";
import * as _ from "lodash";
import { RouteComponentProps } from "react-router";
import routeLinks from "routeLinks";
import { ProjectRouteParams } from "../ProjectLayout";
import { Permission, RunbookResource, ProjectResource, RunbookProcessResource, MachineFilterResource, ProjectConnectivityPolicy, TenantedDeploymentMode } from "client/resources";
import { withRunbookContext, WithRunbookContextInjectedProps } from "./RunbookContext";
import { ExpandableFormSection, MarkdownEditor, Summary, Text, required, StringRadioButtonGroup, RadioButton, Note, SummaryNode, FormSectionHeading } from "components/form";
import { repository } from "clientInstance";
import { FormBaseComponent, FormBaseComponentState } from "components/FormBaseComponent/FormBaseComponent";
import { cloneDeep, isEqual } from "lodash";
import RunbooksFormPaperLayout from "./Layouts/RunbooksFormPaperLayout";
import Markdown from "components/Markdown";
import DeleteRunbook from "./DeleteRunbook";
import InternalRedirect from "components/Navigation/InternalRedirect";
import { OverflowMenuItems } from "components/Menu";
import { isAllowed, PermissionCheckProps } from "components/PermissionCheck/PermissionCheck";
import AddRunbook from "./AddRunbook";
import RunNowButton from "./RunNowButton";
import { withProjectContext, WithProjectContextInjectedProps } from "areas/projects/context/withProjectContext";
import { RoleMultiSelect } from "components/MultiSelect";
import { RoleChip } from "components/Chips";
import ExternalLink from "components/Navigation/ExternalLink";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import RadioButtonGroup from "components/form/RadioButton/RadioButtonGroup";
import { connect } from "react-redux";

// tslint:disable-next-line:no-empty-interface
interface RunbookSettingsLayoutState extends FormBaseComponentState<RunbookResource> {
    project: ProjectResource;
    redirectTo: string;
    canDelete: boolean;
    steps: RunbookProcessResource;
    machineRoles: string[];
}

export interface RunbookSettingsLayoutRouteProps {
    runbookId: string;
}

interface GlobalConnectedProps {
    isMultiTenancyEnabled: boolean;
}

class TenantedDeploymentModeRadioButtonGroup extends RadioButtonGroup<TenantedDeploymentMode> {}

type RunbookSettingsLayoutProps = RouteComponentProps<ProjectRouteParams & RunbookSettingsLayoutRouteProps> & WithRunbookContextInjectedProps & WithProjectContextInjectedProps & GlobalConnectedProps;

class RunbookSettingsLayout extends FormBaseComponent<RunbookSettingsLayoutProps, RunbookSettingsLayoutState, RunbookResource> {
    constructor(props: RunbookSettingsLayoutProps) {
        super(props);
        this.state = {
            model: null!,
            cleanModel: null!,
            project: null!,
            redirectTo: null!,
            canDelete: false,
            steps: null!,
            machineRoles: null!,
        };
    }

    async componentDidMount() {
        await this.reload();
        const machineRoles = await repository.MachineRoles.all();
        this.setState({ machineRoles });
    }

    async componentDidUpdate(nextProps: RunbookSettingsLayoutProps) {
        const currentRunbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        const nextRunbook = nextProps.runbookContext.state && nextProps.runbookContext.state.runbook;
        if (!isEqual(currentRunbook, nextRunbook)) {
            await this.reload();
        }
    }

    async reload() {
        const project = this.props.projectContext.state && this.props.projectContext.state.model;
        if (!project) {
            return;
        }

        const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        if (!runbook) {
            return;
        }

        await this.doBusyTask(async () => {
            const steps = await repository.RunbookProcess.get(runbook.RunbookProcessId);
            this.setState({
                model: runbook,
                steps,
                cleanModel: cloneDeep(runbook),
                project,
            });
        });
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} />;
        }

        const overFlowActions = [];
        if (this.state.project) {
            const CloneDialog = () => <AddRunbook projectId={this.state.project.Id} onProcessCreated={id => this.setState({ redirectTo: routeLinks.project(this.state.project.Slug).operations.runbook(id).root })} cloneId={this.state.model.Id} />;
            overFlowActions.push(OverflowMenuItems.dialogItem("Clone", <CloneDialog />, this.clonePermission()));
            overFlowActions.push(
                OverflowMenuItems.deleteItem(
                    "Delete",
                    "Are you sure you want to delete this Runbook?",
                    this.handleDeleteConfirm,
                    dialogDoBusyTask => <DeleteRunbook doBusyTask={dialogDoBusyTask} runbookName={this.state.model.Name} runbookId={this.state.model.Id} onChange={this.onDeleteChanged} />,
                    this.deletePermission(),
                    !this.state.canDelete
                )
            );
            overFlowActions.push(
                OverflowMenuItems.navItem("View Snapshots", routeLinks.project(this.state.project.Slug).operations.runbook(this.state.model.Id).runbookSnapshots, null!, {
                    permission: Permission.RunbookView,
                    project: this.state.project.Id,
                    projectGroup: this.state.project.ProjectGroupId,
                    wildcard: true,
                })
            );
            overFlowActions.push([
                OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.runbookEventsForProject(this.state.project.Id, this.state.model.Id), null!, {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ]);
        }

        return (
            <RunbooksFormPaperLayout
                busy={this.state.busy}
                errors={this.state.errors}
                title={this.state.model && this.state.model.Name}
                breadcrumbTitle={"Runbooks"}
                breadcrumbPath={routeLinks.project(this.props.match.params.projectSlug).operations.runbooks}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                onSaveClick={this.handleSaveClick}
                savePermission={this.editPermission()}
                saveText=""
                secondaryAction={<RunNowButton isDisabled={!this.state.steps || (this.state.steps && this.state.steps.Steps.length === 0)} />}
                overFlowActions={overFlowActions}
                hideExpandAll={true}
                {...this.props}
            >
                {this.state.model && (
                    <>
                        <ExpandableFormSection
                            errorKey="Name"
                            title="Name"
                            focusOnExpandAll
                            summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for the Runbook")}
                            help="Enter a name for the Runbook"
                        >
                            <Text value={this.state.model.Name} onChange={Name => this.setModelState({ Name })} label="Name" validate={required("Please enter a name")} error={this.getFieldError("Name")} autoFocus={true} />
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            errorKey="Description"
                            title="Description"
                            summary={this.state.model.Description ? Summary.summary(<Markdown markup={this.state.model.Description} />) : Summary.placeholder("No description provided")}
                            help="Enter a description for the Runbook"
                        >
                            <MarkdownEditor value={this.state.model.Description} label="Description" onChange={Description => this.setModelState({ Description })} />
                        </ExpandableFormSection>

                        <FormSectionHeading title="Run Settings" />

                        {(this.props.isMultiTenancyEnabled || this.state.cleanModel.MultiTenancyMode !== TenantedDeploymentMode.Untenanted) &&
                            isAllowed({ permission: Permission.TenantView, tenant: "*", project: this.state.project && this.state.project.Id }) && (
                                <ExpandableFormSection errorKey="tenantedDeploymentMode" title="Multi-tenancy" summary={this.tenantedDeploymentModeSummary()} help="Choose whether this runbook runs against a tenant.">
                                    <TenantedDeploymentModeRadioButtonGroup value={this.state.model.MultiTenancyMode} onChange={tenantedDeploymentMode => this.setModelState({ MultiTenancyMode: tenantedDeploymentMode })}>
                                        <RadioButton value={TenantedDeploymentMode.Untenanted} label="Runbook cannot be run against a tenant" isDefault={true} />
                                        <RadioButton value={TenantedDeploymentMode.TenantedOrUntenanted} label="Runbook can be run with or without a tenant" />
                                        <RadioButton value={TenantedDeploymentMode.Tenanted} label="Runbook must be run against a tenant" />
                                    </TenantedDeploymentModeRadioButtonGroup>
                                    <Note>
                                        <ExternalLink href="ProjectTenantedDeploymentMode">Learn more about tenanted deployment modes</ExternalLink>
                                    </Note>
                                </ExpandableFormSection>
                            )}

                        {/* TODO Runbooks: Component */}
                        <ExpandableFormSection
                            errorKey="skipMachines"
                            title="Deployment Target Status"
                            summary={this.skipMachinesSummary(this.state.model.ConnectivityPolicy)}
                            help="Choose to skip unavailable, or exclude unhealthy targets when running the runbook."
                        >
                            <StringRadioButtonGroup label="Unavailable Deployment targets" value={this.state.model.ConnectivityPolicy.SkipMachineBehavior} onChange={skipMachines => this.handleSkipMachinesChanged(skipMachines)}>
                                <RadioButton value="None" label="Do not skip, and fail" isDefault={true} />
                                <RadioButton value="SkipUnavailableMachines" label="Skip" />
                                <Note>Deployment targets that are unavailable at the start of the run or become unavailable during the run will be skipped and removed from the run.</Note>
                            </StringRadioButtonGroup>
                            {this.state.model.ConnectivityPolicy.SkipMachineBehavior === "SkipUnavailableMachines" && (
                                <div>
                                    <RoleMultiSelect
                                        onChange={skipMachinesRoles => this.setConnectivityPolicyState({ TargetRoles: skipMachinesRoles })}
                                        value={this.state.model.ConnectivityPolicy.TargetRoles}
                                        label="Skip unavailable deployment targets only in selected roles"
                                        items={this.state.machineRoles}
                                    />
                                    <Note>By default, deployment targets will be skipped if they are unavailable in all roles, to limit to certain roles select them here.</Note>
                                </div>
                            )}
                            <StringRadioButtonGroup
                                value={this.state.model.ConnectivityPolicy.ExcludeUnhealthyTargets ? "ExcludeUnhealthy" : "None"}
                                onChange={skipUnhealthyTargets => this.setConnectivityPolicyState({ ExcludeUnhealthyTargets: skipUnhealthyTargets === "ExcludeUnhealthy" })}
                                label="Unhealthy Deployment Targets"
                            >
                                <RadioButton value="None" label="Do not exclude" isDefault={true} />
                                <RadioButton value="ExcludeUnhealthy" label="Exclude" />
                                <Note>Deployment targets that are unhealthy at the start of the run will be skipped and removed from the run.</Note>
                            </StringRadioButtonGroup>
                        </ExpandableFormSection>
                    </>
                )}
            </RunbooksFormPaperLayout>
        );
    }

    private tenantedDeploymentModeSummary(): SummaryNode {
        switch (this.state.model.MultiTenancyMode) {
            case TenantedDeploymentMode.Untenanted:
                return Summary.default("Runbook cannot be run against a tenant");
            case TenantedDeploymentMode.TenantedOrUntenanted:
                return Summary.summary("Runbook can be run with or without a tenant");
            case TenantedDeploymentMode.Tenanted:
                return Summary.summary("Runbook must be run against a tenant");
            default:
                return Summary.placeholder("Please select");
        }
    }

    private setConnectivityPolicyState<K extends keyof ProjectConnectivityPolicy>(connectivityPolicyState: Pick<ProjectConnectivityPolicy, K>) {
        this.setChildState2("model", "ConnectivityPolicy", connectivityPolicyState);
    }

    private handleSkipMachinesChanged = (skipMachines: string) => {
        this.setConnectivityPolicyState({
            SkipMachineBehavior: skipMachines,
            TargetRoles: skipMachines === "None" ? [] : this.state.model.ConnectivityPolicy.TargetRoles,
        });
    };

    //TODO Runbooks: extract and reuse w/ProjectSettings
    private skipMachinesSummary(connectivityPolicy: ProjectConnectivityPolicy): SummaryNode {
        //TODO: convert to enum
        if (connectivityPolicy.SkipMachineBehavior !== "SkipUnavailableMachines") {
            return connectivityPolicy.ExcludeUnhealthyTargets ? Summary.summary("Runbook will exclude unhealthy targets, and fail if there is an unavailable target") : Summary.default("Runbook will fail if a target is unavailable");
        }

        const roles = connectivityPolicy.TargetRoles;
        const summary = [connectivityPolicy.ExcludeUnhealthyTargets ? <span key="skipMachines">Runbook will exclude unhealthy targets, and skip unavailable targets</span> : <span key="skipMachines">Runbook will skip unavailable targets</span>];

        if (roles.length > 0) {
            summary.push(connectivityPolicy.TargetRoles.length > 1 ? <span> in roles</span> : <span> in role</span>);

            roles.forEach(r => {
                summary.push(<RoleChip role={r} key={"role-" + r} />);
            });
        }
        return Summary.summary(React.Children.toArray(summary));
    }

    private onDeleteChanged = (canDelete: boolean) => {
        this.setState({ canDelete });
    };

    private clonePermission(): PermissionCheckProps {
        return {
            permission: Permission.RunbookEdit,
            projectGroup: this.state.project && this.state.project.ProjectGroupId,
            wildcard: true,
        };
    }

    private deletePermission(): PermissionCheckProps {
        return {
            permission: Permission.RunbookEdit,
            project: this.state.project && this.state.project.Id,
            wildcard: true,
        };
    }

    private editPermission(): PermissionCheckProps {
        return {
            permission: Permission.RunbookEdit,
            project: this.state.project && this.state.project.Id,
            wildcard: true,
        };
    }

    private handleDeleteConfirm = async () => {
        await repository.Runbooks.del(this.state.model);
        this.setState({ redirectTo: routeLinks.project(this.props.match.params.projectSlug).operations.runbooks });
        return true;
    };

    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const result = await repository.Runbooks.modify(this.state.model);
            this.setState({
                model: result,
                cleanModel: cloneDeep(result),
            });

            this.props.runbookContext.actions.onRunbookUpdated(result);
        });
    };
}

const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled,
    };
};

const EnhancedRunbookSettingsLayout = connect(mapGlobalStateToProps)(withRunbookContext(withProjectContext(RunbookSettingsLayout)));
export default EnhancedRunbookSettingsLayout;
