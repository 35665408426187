// tslint:disable: no-non-null-assertion
import * as React from "react";
import { ProjectRouteParams } from "areas/projects/components/ProjectLayout/ProjectLayout";
import { RouteComponentProps } from "react-router";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { repository } from "clientInstance";
import { default as LibraryVariableSetResource, VariableSetContentType } from "client/resources/libraryVariableSetResource";
import { ProjectResource } from "client/resources";
import { List as MaterialList } from "material-ui";
import ActionTemplateParameterResourceSummary from "components/ActionTemplateParametersList/ActionTemplateParameterResourceSummary";
import BorderedListItem from "components/BorderedListItem";
import ExpansionButtons from "components/form/Sections/ExpansionButtons";
import VariableSetSectionHeading from "areas/variables/VariableSetSectionHeading/VariableDisplayerSectionHeading";
import { Section } from "components/Section/Section";
import ExternalLink from "components/Navigation/ExternalLink";
import InternalLink from "components/Navigation/InternalLink";
import routeLinks from "../../../../../routeLinks";
import Divider from "components/Divider/Divider";
import { GroupedExpandableProps, default as GroupedExpandable } from "components/GroupedExpandable";
import SectionNote from "components/SectionNote/SectionNote";

interface CommonVariableTemplatesState extends DataBaseComponentState {
    project?: ProjectResource;
    libraryVariableSets?: ReadonlyArray<LibraryVariableSetResource>;
}

type Props = RouteComponentProps<ProjectRouteParams> & GroupedExpandableProps;

class CommonVariableTemplates extends DataBaseComponent<Props, CommonVariableTemplatesState> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = await repository.Projects.get(this.props.match.params.projectSlug);
            const libraryVariableSets = (await repository.LibraryVariableSets.all({ ids: project.IncludedLibraryVariableSetIds, contentType: VariableSetContentType.Variables })).filter(libraryVariable => libraryVariable.Templates.length > 0);

            this.setState({
                project,
                libraryVariableSets,
            });
            this.props.registerAllExpanders(libraryVariableSets.map(v => v.Id));
        });
    }

    render() {
        return (
            <PaperLayout title="Common Variable Templates" busy={this.state.busy} errors={this.state.errors}>
                <SectionNote>
                    Variable templates define which variable values are required for each tenant connected to this project. They are defined in library variable sets and allow tenants to provide a common variable value that is shared across projects
                    and environments. Learn more about <ExternalLink href="MultiTenantVariables">tenant-specific variables</ExternalLink>.
                </SectionNote>
                {this.state.libraryVariableSets && this.state.project && this.state.libraryVariableSets.length > 0 && (
                    <Section>
                        To change the common variable templates required by this project <InternalLink to={routeLinks.project(this.state.project).variables.library}>choose different library variable sets</InternalLink>.
                    </Section>
                )}
                <ExpansionButtons />
                {this.state.libraryVariableSets &&
                    this.state.libraryVariableSets.map((lvs, index) => {
                        return (
                            <div key={lvs.Id}>
                                <Divider fullHeight={true} />
                                <VariableSetSectionHeading
                                    variableSetId={lvs.Id}
                                    variableSetName={lvs.Name}
                                    isExpanded={this.props.expanders[lvs.Id]!}
                                    onExpandedChanged={expanded => this.props.onExpandedChanged(lvs.Id, expanded)}
                                    variableSetTab="variableTemplates"
                                >
                                    <MaterialList>
                                        {lvs.Templates.map((template, templateIndex) => {
                                            return (
                                                <BorderedListItem key={templateIndex} disabled={true}>
                                                    <ActionTemplateParameterResourceSummary parameter={template} />
                                                </BorderedListItem>
                                            );
                                        })}
                                    </MaterialList>
                                </VariableSetSectionHeading>
                            </div>
                        );
                    })}
                <Divider fullHeight={true} />
            </PaperLayout>
        );
    }
}

export default GroupedExpandable("common_variable_templates", CommonVariableTemplates);
