// tslint:disable: no-non-null-assertion
import Logo from "components/Logo";
import * as React from "react";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent/FormBaseComponent";
import { RouteComponentProps } from "react-router";
import { DeploymentStepResource, RunCondition, StartTrigger } from "client/resources/deploymentStepResource";
import { StepDetailsParams } from "./StepDetailsLoader";
import Text from "components/form/Text/Text";
import { ExpandableFormSection, Summary, FormSectionHeading } from "components/form";
import { RoleChip } from "components/Chips";
import { cloneDeep } from "lodash";
import ParseHelper from "utils/ParseHelper/ParseHelper";
import RoleMultiSelect from "components/MultiSelect/RoleMultiSelect";
import { BooleanRadioButtonGroup } from "components/form/RadioButton/RadioButtonGroup";
import RadioButton from "components/form/RadioButton/RadioButton";
import { repository } from "clientInstance";
import { ProjectResource } from "client/resources/projectResource";
import RunTriggerExpander from "./RunTriggerExpander";
import StartTriggerExpander from "./StartTriggerExpander";
import ActionProperties from "client/resources/actionProperties";
import routeLinks from "routeLinks";
import MaxParallelism from "./MaxParallelism";
import InternalRedirect from "components/Navigation/InternalRedirect/InternalRedirect";
import StepName from "./StepName";
import { OverflowMenuItems } from "components/Menu";
import { withProjectContext, WithProjectContextInjectedProps } from "../../context";
import { withProcessContext, WithProcessContextInjectedProps } from "../../context";
import { IProcessResource, processPermission } from "client/resources";
import { nextStepReloadPath } from "./DeploymentProcessRoute";
import { WithActionScopeInjectedProps } from "components/Actions/withActionScope";
import { ActionScope } from "components/Actions/pluginRegistry";
import { StepRolling } from "components/Images/Images/DeploymentProcess/StepRolling";
const styles = require("./style.less");

interface ParentStepDetailsModel {
    name: string;
    props: ActionProperties;
    condition: RunCondition;
    startTrigger: StartTrigger;
}

interface ParentStepDetailsState extends OptionalFormBaseComponentState<ParentStepDetailsModel> {
    redirectTo?: string;
    showWindowSize: boolean;
}

interface ParentStepDetailsProps extends RouteComponentProps<StepDetailsParams> {
    stepNumber: string;
    step: DeploymentStepResource;
    availableRoles: string[];
    project: ProjectResource;
    isFirstStep: boolean;
}

type ContextInjectedProps = WithProjectContextInjectedProps & WithProcessContextInjectedProps;
type ParentStepDetailsActions = {
    getSteps: (id: string) => Promise<IProcessResource>;
    modifySteps: (steps: IProcessResource) => Promise<IProcessResource>;
};

type Props = ParentStepDetailsProps & ContextInjectedProps;

class ParentStepDetails extends FormBaseComponent<Props & ParentStepDetailsActions, ParentStepDetailsState, ParentStepDetailsModel> {
    constructor(props: Props & ParentStepDetailsActions) {
        super(props);

        const step = this.props.step;
        const model = {
            name: step.Name,
            props: step.Properties,
            condition: step.Condition,
            startTrigger: step.StartTrigger,
        };
        this.state = {
            model,
            cleanModel: cloneDeep(model),
            showWindowSize: model.props["Octopus.Action.MaxParallelism"] ? model.props["Octopus.Action.MaxParallelism"].toString().length > 0 : false,
        };
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} />;
        }

        const processEditPermission = { permission: processPermission(this.props.processContext.state.process), project: this.props.projectContext.state.model.Id, wildcard: true };
        const actions = [OverflowMenuItems.deleteItemDefault("parent step", () => this.deleteStep(this.props.step), processEditPermission)];

        return (
            <FormPaperLayout
                title={<StepName name={this.state.model?.name!} number={this.props.stepNumber} stepType="Parent Step" />}
                titleLogo={
                    <div className={styles.parentStepIcon}>
                        <StepRolling width={"3.1rem"} height={"3.1rem"} />
                    </div>
                }
                busy={this.state.busy}
                errors={this.state.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                savePermission={processEditPermission}
                onSaveClick={this.handleSaveClick}
                overFlowActions={actions}
                saveText="Step details updated"
                fullWidth={true}
                flatStyle={true}
                hideHelpIcon={true}
            >
                <ExpandableFormSection
                    isExpandedByDefault={!this.state.model?.name}
                    errorKey="Name"
                    title="Step Name"
                    focusOnExpandAll
                    summary={this.state.model!.name ? Summary.summary(this.state.model!.name) : Summary.placeholder("Please enter a name for your step")}
                    help="A short, memorable, unique name for this step."
                >
                    <Text value={this.state.model!.name} onChange={name => this.setModelState({ name })} label="Step name" autoFocus={true} />
                </ExpandableFormSection>

                <ExpandableFormSection isExpandedByDefault={!this.state.model!.name} errorKey="Octopus.Action.TargetRoles" title="Execution Plan" summary={this.executionPlanSummary()} help="Where should this step run?">
                    <RoleMultiSelect
                        label="Runs on targets in roles"
                        onChange={roles => this.setProperties({ ["Octopus.Action.TargetRoles"]: ParseHelper.encodeCSV(roles) })}
                        value={ParseHelper.parseCSV(this.state.model!.props["Octopus.Action.TargetRoles"] as string)}
                        validate={roles => (roles.length === 0 ? "Please enter one or more roles" : "")}
                        error={this.getFieldError("Octopus.Action.TargetRoles")}
                        items={this.props.availableRoles}
                    />
                    <BooleanRadioButtonGroup
                        onChange={v => {
                            this.setState({ showWindowSize: v });
                            this.setProperties({ ["Octopus.Action.MaxParallelism"]: v ? "1" : "" });
                        }}
                        value={this.state.showWindowSize}
                    >
                        <RadioButton value={false} label="Deploy to all deployment targets in parallel." />
                        <RadioButton value={true} label="Configure a rolling deployment" />
                    </BooleanRadioButtonGroup>
                    {this.state.showWindowSize && this.renderWindowSize()}
                </ExpandableFormSection>

                <FormSectionHeading title="Conditions" />
                <RunTriggerExpander
                    isFirstStep={this.props.isFirstStep}
                    condition={this.state.model!.condition}
                    onConditionChange={condition => this.setModelState({ condition })}
                    variableExpression={this.state.model!.props["Octopus.Action.ConditionVariableExpression"] as string}
                    onVariableExpressionChange={x => this.setProperties({ ["Octopus.Action.ConditionVariableExpression"]: x })}
                    projectId={this.props.projectContext.state.model.Id}
                    variableExpressionError={this.getFieldError("ConditionVariableExpression")}
                />

                {!this.props.isFirstStep && <StartTriggerExpander startTrigger={this.state.model!.startTrigger} onChange={startTrigger => this.setModelState({ startTrigger })} />}
            </FormPaperLayout>
        );
    }

    private async deleteStep(step: DeploymentStepResource) {
        await this.doBusyTask(async () => {
            const stepIndex = this.props.processContext.state.process.Steps.findIndex(s => s.Id === step.Id);
            this.props.processContext.state.process.Steps.splice(stepIndex, 1);

            const result = await this.props.processContext.actions.saveSteps(this.props.processContext.state.process);
            if (result) {
                this.props.processContext.actions.onStepsChange(result);
                const redirectTo = routeLinks.project(this.props.projectContext.state.model).process.root;
                this.setState({ redirectTo });
            }
        });
        return true;
    }

    private renderWindowSize() {
        const maxParallelism = this.state.model!.props["Octopus.Action.MaxParallelism"] as string;
        return <MaxParallelism projectId={this.props.projectContext.state.model.Id} value={maxParallelism} onChange={x => this.setProperties({ ["Octopus.Action.MaxParallelism"]: x })} />;
    }

    private setProperties(properties: Partial<ActionProperties>) {
        this.setState(state => {
            return {
                model: {
                    ...state.model,
                    props: {
                        ...state.model!.props,
                        ...properties,
                    },
                },
            };
        });
    }

    private executionPlanSummary() {
        const summary = [<span>This step will run</span>];

        const roles = ParseHelper.parseCSV(this.state.model!.props["Octopus.Action.TargetRoles"] as string);
        const parallelism = this.state.model!.props["Octopus.Action.MaxParallelism"];
        if (roles.length > 0) {
            summary.push(roles.length > 1 ? <span> in roles</span> : <span> in role</span>);
            roles.forEach(r => {
                summary.push(<RoleChip role={r} key={"role-" + r} />);
            });
        }

        if (parallelism) {
            // tslint:disable-next-line
            summary.push(<span> as <strong>rolling step</strong> that will run on <strong>{parallelism}</strong> target{parallelism !== "1" ? "s" : ""} at a time</span>);
        } else {
            // tslint:disable-next-line
            summary.push(<span> with all targets deployed in <strong>parallel</strong></span>);
        }

        return Summary.summary(React.Children.toArray(summary));
    }

    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            let stepsCollection = await this.props.getSteps(this.props.processContext.state.process.Id);
            const originalStep = stepsCollection.Steps.find(s => s.Id === this.props.match.params.stepId);

            originalStep!.Name = this.state.model!.name;
            originalStep!.Properties = { ...originalStep!.Properties, ...this.state.model!.props };
            originalStep!.Condition = this.state.model!.condition;
            originalStep!.StartTrigger = this.state.model!.startTrigger;

            stepsCollection = await this.props.modifySteps(stepsCollection);
            this.props.processContext.actions.onStepsChange(stepsCollection);
            this.reloadThePage();
        });
    };

    private reloadThePage() {
        const currentPath = this.props.location.pathname;
        const reloadKey = this.props.match.params.reloadKey;
        const path = nextStepReloadPath(currentPath, reloadKey);

        this.setState({ redirectTo: path });
    }
}

const ParentStepDetailsWithContext = withProjectContext(withProcessContext(ParentStepDetails));

type RunbooksParentStepDetailsProps = ParentStepDetailsProps;
const RunbooksParentStepDetails: React.FC<RunbooksParentStepDetailsProps> = props => {
    return <ParentStepDetailsWithContext modifySteps={repository.RunbookProcess.modify.bind(repository.RunbookProcess)} getSteps={repository.RunbookProcess.get.bind(repository.RunbookProcess)} {...props} />;
};

type DeploymentProcessParentStepDetailsProps = ParentStepDetailsProps;
const DeploymentProcessParentStepDetails: React.FC<DeploymentProcessParentStepDetailsProps> = props => {
    return <ParentStepDetailsWithContext modifySteps={repository.DeploymentProcesses.modify.bind(repository.DeploymentProcesses)} getSteps={repository.DeploymentProcesses.get.bind(repository.DeploymentProcesses)} {...props} />;
};

type ScopedParentStepDeetailsProps = ParentStepDetailsProps & WithActionScopeInjectedProps;
const ScopedParentStepDeetails: React.FC<ScopedParentStepDeetailsProps> = props => {
    return (
        <>
            {props.scope === ActionScope.Deployments && <DeploymentProcessParentStepDetails {...props} />}
            {props.scope === ActionScope.Runbooks && <RunbooksParentStepDetails {...props} />}
        </>
    );
};

export default ScopedParentStepDeetails;
