// tslint:disable: no-any

import { DeploymentResource, EnvironmentResource, MachineResource } from "../resources/index";
import BasicRepository from "./basicRepository";
import Client from "../client";
import { TaskResource, ResourceCollection, MachineConnectionStatus, DeploymentTargetResource } from "../resources";

export type ListMachinesArgs = {
    skip?: number;
    take?: number;
    partialName?: string;
    roles?: string;
    isDisabled?: boolean;
    healthStatuses?: string;
    commStyles?: string;
    tenantIds?: string;
    tenantTags?: string;
    environmentIds?: string;
    shellNames?: string;
};

class MachineRepository extends BasicRepository<DeploymentTargetResource, DeploymentTargetResource> {
    constructor(client: Client) {
        super("Machines", client);
    }
    list(args?: ListMachinesArgs): Promise<ResourceCollection<DeploymentTargetResource>> {
        return this.client.get(this.client.getLink("Machines"), args);
    }
    listByEnvironment(environment: EnvironmentResource) {
        return this.client.get(environment.Links["Machines"]);
    }
    discover(host: string, port: number, type: any, proxyId: string): Promise<DeploymentTargetResource> {
        return this.client.get<DeploymentTargetResource>(this.client.getLink("DiscoverMachine"), { host, port, type, proxyId });
    }
    getConnectionStatus(machine: MachineResource): Promise<MachineConnectionStatus> {
        return this.client.get<MachineConnectionStatus>(machine.Links["Connection"]);
    }
    getTasks(machine: DeploymentTargetResource, options: any): Promise<ResourceCollection<TaskResource<any>>> {
        return this.client.get<ResourceCollection<TaskResource<any>>>(machine.Links["TasksTemplate"], options);
    }
    hosted() {
        const allArgs = { id: "hosted" };
        return this.client.get(this.client.getLink("Machines"), allArgs);
    }
    listByDeployment(deployment: DeploymentResource) {
        return this.client.get(this.client.getLink("Machines"), { deploymentId: deployment.Id, id: "all" });
    }
}

export default MachineRepository;
