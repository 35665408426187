// tslint:disable: no-any

import { KeyValuePair } from "components/EditList/KeyValueEditList";

export interface NginxLocation {
    path: string;
    directives: string;
    headers: string;
    reverseProxy: boolean;
    reverseProxyUrl: string;
    reverseProxyHeaders: string;
    reverseProxyDirectives: string;
}

const nginxLocationsFromString = (locationsString: string): NginxLocation[] => {
    return JSON.parse(locationsString);
};

const nginxLocationsToString = (locations: NginxLocation[]): string => {
    return JSON.stringify(locations);
};

const keyValuesToString = (keyValues: any) => {
    return JSON.stringify(keyValuesToHash(keyValues));
};

function keyValuesToHash(values: KeyValuePair[]) {
    return values.reduce((idx: any, item) => {
        idx[item.key] = item.value;
        return idx;
    }, {});
}

interface KeyValue {
    key: string;
    value: string | null;
}

function keyValuesFromString(value: string): KeyValue[] {
    if (value === null || value === undefined) {
        return [];
    }

    try {
        const source: Record<string, string> = JSON.parse(value || "{}");
        const initial: KeyValue[] = [];
        return Object.keys(source).reduce((arr, key) => {
            arr.push({ key, value: source[key] });
            return arr;
        }, initial);
    } catch (e) {
        return [];
    }
}

function keyValuesFromArrayString(stringValue: string): KeyValuePair[] {
    if (stringValue === null || stringValue === undefined) {
        return [];
    }

    try {
        const source = JSON.parse(stringValue || "[]");
        return source.reduce((arr: any, object: any) => {
            for (const [key, value] of Object.entries(object)) {
                arr.push({ key, value });
            }
            return arr;
        }, []);
    } catch (e) {
        return [];
    }
}

export { nginxLocationsFromString, nginxLocationsToString, keyValuesToString, keyValuesFromString, keyValuesFromArrayString };
