// tslint:disable: no-non-null-assertion
import { ResourceWithId } from "../resources/resource";
import { client } from "clientInstance";

export function uploadLogo<T extends ResourceWithId>(resource: T, logo: File) {
    const fd = new FormData();
    fd.append("fileToUpload", logo);
    return client.post(resource.Links["Logo"], fd);
}

export async function saveLogo<T extends ResourceWithId>(resource: T, file: File | undefined, reset: boolean) {
    // Important: when using saveLogo
    // We upload the logo first so that when we do the model save we get back a new url for logo
    if (file) {
        return uploadLogo(resource, file);
    } else if (reset) {
        return uploadLogo(resource, null!);
    }
}
