import { Permission } from "client/resources/permission";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { repository } from "clientInstance";

export async function getFeedName(id: string) {
    // QRF's tactical fix on Release/2020.[1,2] to avoid package feed variable not found errors.
    if (id.includes("#{")) {
        return id;
    }

    const feedName = isAllowed({ permission: Permission.FeedView }) ? (await repository.Feeds.get(id)).Name : `(Feed unknown: ${Permission.FeedView} permission required)`;
    return feedName;
}
