// tslint:disable: no-any

import * as React from "react";
import { DataBaseComponent } from "components/DataBaseComponent/DataBaseComponent";
import { MachineChip } from "components/Chips/index";
import { ActionToggleInfo } from "../deploymentStepsWorker";
import { CreateDeploymentBaseResource } from "client/resources/deploymentResource";
import { Callout, CalloutType } from "components/Callout/Callout";
import { IProcessResource, isRunbookProcessResource } from "client/resources/deploymentProcessResource";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import { Section } from "components/Section/Section";
import ActionSummary from "../ActionSummary";
import * as _ from "lodash";
import { MachineModelHealthStatus, isReleaseResource } from "client/resources";
const styles = require("../style.less");

interface ActionPreviewProps {
    deploymentInfo: CreateDeploymentBaseResource;
    stepActionIdsToSkip: string[];
    actions: ActionToggleInfo[];
    process: IProcessResource;
}

export default class ActionPreview extends DataBaseComponent<ActionPreviewProps, never> {
    constructor(props: ActionPreviewProps) {
        super(props);
    }

    /**
     * Prevent rerendering as we click around the UI. This function targets the
     * few properties that the render() function actually uses. If there are no
     * changes in those properties, don't rerender.
     *
     * This low level checking is done in response to
     * https://github.com/OctopusDeploy/Issues/issues/4193
     */
    shouldComponentUpdate(nextProps: ActionPreviewProps) {
        return (
            this.props.actions !== nextProps.actions ||
            this.props.process !== nextProps.process ||
            !_.isEqual(this.props.stepActionIdsToSkip, nextProps.stepActionIdsToSkip) ||
            !_.isEqual(this.props.deploymentInfo.ExcludedMachineIds, nextProps.deploymentInfo.ExcludedMachineIds) ||
            !_.isEqual(this.props.deploymentInfo.SpecificMachineIds, nextProps.deploymentInfo.SpecificMachineIds)
        );
    }

    render() {
        const isRunbooks = isRunbookProcessResource(this.props.process);
        return (
            <div className={styles.stepPreview}>
                <Section sectionHeader={isRunbooks ? "Runbook steps preview" : "Deployment steps preview"}>
                    {this.props.actions &&
                        this.props.actions.length > 0 &&
                        this.props.actions.map((s, index) => (
                            <div key={index} className={styles.step}>
                                <div className={styles.actionSummary}>
                                    <div className={styles.logo}>{this.props.process && <ActionSummary action={s} process={this.props.process} stepActionIdsToSkip={this.props.stepActionIdsToSkip} />}</div>
                                </div>
                                {this.props.stepActionIdsToSkip && !this.props.stepActionIdsToSkip.some(id => id === s.details.ActionId) && (
                                    <div>
                                        <PermissionCheck permission={Permission.MachineView} wildcard={true}>
                                            <div className={styles.machines}>
                                                {s.details.Machines &&
                                                    s.details.Machines.map((m: any) => {
                                                        const excludedMachineIds = this.props.deploymentInfo.ExcludedMachineIds ? this.props.deploymentInfo.ExcludedMachineIds : [];
                                                        const includedMachineIds = this.props.deploymentInfo.SpecificMachineIds ? this.props.deploymentInfo.SpecificMachineIds : [];
                                                        let isExcluded = false;
                                                        let isUnhealthy = false;
                                                        if (excludedMachineIds.length > 0 && excludedMachineIds.some(e => e === m.Id)) {
                                                            isExcluded = true;
                                                        }
                                                        if (includedMachineIds.length > 0 && !includedMachineIds.some(e => e === m.Id)) {
                                                            isExcluded = true;
                                                        }
                                                        if (m.HealthStatus === MachineModelHealthStatus.Unhealthy) {
                                                            isUnhealthy = true;
                                                        }

                                                        return <MachineChip key={m.Id} machineName={m.Name} isDisable={m.isUnavailable} isExcluded={isExcluded} isUnhealthy={isUnhealthy} />;
                                                    })}
                                            </div>
                                            {s.details.HasNoApplicableMachines && (
                                                <Callout title="Warning" type={CalloutType.Warning}>
                                                    There are no enabled deployment targets in the roles specified by this step, in this environment.
                                                </Callout>
                                            )}
                                        </PermissionCheck>
                                    </div>
                                )}
                            </div>
                        ))}
                </Section>
            </div>
        );
    }
}
