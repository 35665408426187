// tslint:disable: no-any

import * as React from "react";
import PaperLayout, { PaperLayoutProps } from "components/PaperLayout";
import SidebarLayout from "components/SidebarLayout/SidebarLayout";

export interface TabbedPaperLayoutProps extends PaperLayoutProps {
    tabNavigation?: React.ComponentType<any>;
    hideTabNavigation?: boolean;
    sidebar?: React.ReactNode;
}

export const TabbedPaperLayout: React.FC<TabbedPaperLayoutProps> = props => {
    const { tabNavigation: TabNavigationComponent, hideTabNavigation = false, children, ...rest } = props;
    return (
        <PaperLayout {...rest}>
            {TabNavigationComponent && !hideTabNavigation && <TabNavigationComponent />}
            <SidebarLayout sideBar={props.sidebar} hideTopDivider={true}>
                {children}
            </SidebarLayout>
        </PaperLayout>
    );
};

TabbedPaperLayout.defaultProps = {
    fullWidth: true,
};

export default TabbedPaperLayout;
