// tslint:disable: no-non-null-assertion
import * as React from "react";
import { flatten } from "lodash";
import { AuthenticationProviderElement, IdentityMetadataResource } from "client/authentication/authenticationProviderElement";
import BaseComponent from "../../../../components/BaseComponent";
import { ClaimsBasedIdentity } from "client/resources/identityResource";
import IdentityType from "client/resources/identityType";
import { ProviderIdentities, ProviderWithUserSettings } from "./ProviderIdentities";

interface ProviderIdentitiesProps {
    userIdentities: ClaimsBasedIdentity[];
    enabledAuthenticationProviders: AuthenticationProviderElement[];
    enabledProvidersMetadata?: IdentityMetadataResource[];
    canCurrentUserEditIdentitiesForUser: boolean;
    isServiceAccount: boolean;
    onChange(changedIdentities: ClaimsBasedIdentity[]): void;
}

export class ProviderGroups extends BaseComponent<ProviderIdentitiesProps, {}> {
    constructor(props: ProviderIdentitiesProps) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div>
                {" "}
                {this.props.enabledAuthenticationProviders &&
                    this.buildIdentitiesByProvider().map(p => (
                        <ProviderIdentities
                            key={p.providerName}
                            provider={p}
                            enabledToSupportAdding={(this.props.enabledProvidersMetadata && this.props.enabledProvidersMetadata.some(ep => ep.IdentityProviderName === p.providerName))!}
                            canCurrentUserEditIdentitiesForUser={this.props.canCurrentUserEditIdentitiesForUser}
                            onChange={changed => this.providerGroupChanged(changed)}
                        />
                    ))}
            </div>
        );
    }

    private buildIdentitiesByProvider() {
        const userIdentities = this.props.userIdentities || [];

        return this.props.enabledAuthenticationProviders
            .filter(ap => IdentityType[ap.IdentityType] === IdentityType.ActiveDirectory || IdentityType[ap.IdentityType] === IdentityType.OAuth)
            .filter(filterOutForServiceAccounts => (this.props.isServiceAccount ? this.isActiveDirectory(filterOutForServiceAccounts.Name) : true))
            .map(p => {
                return {
                    providerName: p.Name,
                    providerDetails: this.props.enabledProvidersMetadata!.find(ep => ep.IdentityProviderName === p.Name)!,
                    identityType: this.determineType(p.Name),
                    identities: userIdentities ? userIdentities.filter(i => i.IdentityProviderName === p.Name) : [],
                };
            })
            .sort((a, b) => a.providerName.localeCompare(b.providerName));
    }

    private providerGroupChanged(update: ProviderWithUserSettings) {
        const identityByProvider = [...this.buildIdentitiesByProvider().filter(p => p.providerName !== update.providerName), update].sort((a, b) => a.providerName.localeCompare(b.providerName));

        this.props.onChange(flatten(identityByProvider.map(ibp => ibp.identities)));
    }

    private determineType = (name: string): IdentityType => {
        /*
        Octopus.Node.Extensibility.Authentication.Extensions.IAuthenticationProvider
        doesn't make use of enum Octopus.Core.Resources.IdentityType
        so just hacking it here till can be solved properly
        */

        if (name.indexOf("Azure") !== -1 || name.indexOf("Google") !== -1) {
            return IdentityType.OAuth;
        } else if (this.isActiveDirectory(name)) {
            return IdentityType.ActiveDirectory;
        } else if (name.indexOf("Guest") !== -1) {
            return IdentityType.Guest;
        } else {
            return IdentityType.UsernamePassword;
        }
    };

    private isActiveDirectory(name: string) {
        return name.indexOf("Directory") !== -1;
    }
}
