// tslint:disable: no-any

import * as React from "react";
const styles = require("./style.less");

export default class CenteredLayout extends React.Component<any, any> {
    render() {
        return <div className={styles.container}>{this.props.children}</div>;
    }
}
