// tslint:disable: no-any

import Client from "../client";
import { ProgressionResource, ProjectResource, RunbookResource, RunbookProgressionResource, RunbooksDashboardItemResource, ResourceCollection } from "../resources";
import { ListArgs } from "./basicRepository";

export type GetTaskRunDashboardItemsListArgs = {
    projectIds?: string[];
    runbookIds?: string[];
    environmentIds?: string[];
    tenantIds?: string[];
    taskIds?: string[];
} & ListArgs;

class ProgressionRepository {
    private client: Client;
    constructor(client: Client) {
        this.client = client;
    }
    getProgression(project: ProjectResource, options?: any): Promise<ProgressionResource> {
        return this.client.get<ProgressionResource>(project.Links["Progression"], options);
    }
    getRunbookProgression(runbook: RunbookResource, options?: any): Promise<RunbookProgressionResource> {
        return this.client.get<RunbookProgressionResource>(runbook.Links["Progression"], options);
    }
    getTaskRunDashboardItemsForRunbook(runbook: RunbookResource, options?: GetTaskRunDashboardItemsListArgs): Promise<ResourceCollection<RunbooksDashboardItemResource>> {
        return this.client.get<ResourceCollection<RunbooksDashboardItemResource>>(runbook.Links["TaskRunDashboardItemsTemplate"], options);
    }
    getTaskRunDashboardItemsForProject(project: ProjectResource, options?: GetTaskRunDashboardItemsListArgs): Promise<ResourceCollection<RunbooksDashboardItemResource>> {
        return this.client.get<ResourceCollection<RunbooksDashboardItemResource>>(project.Links["RunbookTaskRunDashboardItemsTemplate"], options);
    }
}

export default ProgressionRepository;
