// tslint:disable: no-non-null-assertion
// tslint:disable: no-any

import * as React from "react";
import { connect } from "react-redux";
import { defaultContainerKey, onExpanderContainerDestroyed, onExpandErrors, onExpanderSetInitialState, onToggleAll, ExpanderContainer } from "components/form/Sections/reducers/expanders";
import store from "store";
const styles = require("./style.less");
import { Errors } from "components/DataBaseComponent/Errors";
import { isEqual } from "lodash";

export const toggleExpandos = (expanded: boolean, containerKey?: string) => {
    store.dispatch(onToggleAll(containerKey!, expanded));
};

interface ExpansionButtonsProps {
    expandAllOnMount?: boolean;
    errors?: Errors;
    containerKey?: string;
}

interface GlobalDispatchProps {
    onToggleAll(containerKey: string, expanded: boolean): void;
    onExpandErrors(containerKey: string, errorKeys: ReadonlyArray<string>): void;
    onMount(containerKey: string, initialState: boolean): void;
    onDestroy(containerKey: string): void;
}

interface StateProps {
    expanded: ReadonlyArray<boolean>;
}

type Props = ExpansionButtonsProps & StateProps & GlobalDispatchProps;

class ExpansionButtonsInternal extends React.Component<Props, never> {
    constructor(props: Props) {
        super(props);
    }

    componentDidMount() {
        this.props.onMount(this.props.containerKey!, this.props.expandAllOnMount!);
    }

    render() {
        return (
            <div className={styles.linkContainer}>
                {this.expandedValues().some(e => e === false) && (
                    <a role="button" href="#" className={styles.expandLink} onClick={e => this.toggleExpandInt(e, true)}>
                        EXPAND ALL
                    </a>
                )}
                {this.expandedValues().some(e => e === true) && (
                    <a role="button" href="#" className={styles.expandLink} onClick={e => this.toggleExpandInt(e, false)}>
                        COLLAPSE ALL
                    </a>
                )}
            </div>
        );
    }

    componentWillReceiveProps(nextProps: ExpansionButtonsProps) {
        if (this.props.containerKey && nextProps.containerKey !== this.props.containerKey) {
            this.props.onDestroy(this.props.containerKey);
        }

        if (this.props.containerKey && (nextProps.containerKey !== this.props.containerKey || nextProps.expandAllOnMount !== this.props.expandAllOnMount)) {
            this.props.onMount(this.props.containerKey, !!nextProps.expandAllOnMount);
        }

        this.openExpandersWithErrors(nextProps);
    }

    componentWillUnmount() {
        this.props.onDestroy(this.props.containerKey!);
    }

    public toggleExpand = (isExpanded: boolean) => {
        this.props.onToggleAll(this.props.containerKey!, isExpanded);
    };

    private openExpandersWithErrors(nextProps: ExpansionButtonsProps) {
        // ref comparison seems to be okay here.
        if (nextProps.errors && !isEqual(nextProps.errors, this.props.errors)) {
            this.props.onExpandErrors(this.props.containerKey!, Object.keys(nextProps.errors.fieldErrors));
        }
    }

    private expandedValues() {
        return this.props.expanded;
    }

    private toggleExpandInt = (e: any, isExpanded: boolean) => {
        e.preventDefault();
        this.toggleExpand(isExpanded);
    };
}

(ExpansionButtonsInternal as any).defaultProps = {
    expandAllOnMount: false,
};

const mapGlobalStateToProps = (state: GlobalState, ownProps: any) => {
    const containerKey = ownProps.containerKey || defaultContainerKey;
    const container: ExpanderContainer | null | undefined = state.expanders && state.expanders.hasOwnProperty(containerKey) ? state.expanders[containerKey] : null;
    const initialState: boolean = container?.initialState ?? false;
    const storedValues: { [errorKey: string]: boolean | undefined | null } = container ? container.expanderValues : {};
    const expanded = Object.keys(storedValues).map(getValueForKey);

    return { expanded };

    function getValueForKey(errorKey: string) {
        const value = storedValues[errorKey];
        return value === undefined || value === null ? initialState : value;
    }
};

const mapGlobalActionDispatchersToProps = (dispatch: any) => {
    return {
        onToggleAll: (containerKey: string, expanded: boolean) => {
            toggleExpandos(expanded, containerKey);
        },
        onExpandErrors: (containerKey: string, errorKeys: ReadonlyArray<string>) => {
            dispatch(onExpandErrors(containerKey, errorKeys));
        },
        onDestroy: (containerKey: string) => {
            dispatch(onExpanderContainerDestroyed(containerKey));
        },
        onMount: (containerKey: string, initialState: boolean) => {
            dispatch(onExpanderSetInitialState(containerKey, initialState));
        },
    };
};

const ExpansionButtons = connect<StateProps, GlobalDispatchProps, ExpansionButtonsProps>(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(ExpansionButtonsInternal);

export default ExpansionButtons;
