// tslint:disable: no-non-null-assertion
import * as React from "react";
import Tasks from "areas/tasks/components/Tasks/Tasks";
import Logger from "client/logger";
import { useRouteMatch, useHistory, useLocation } from "react-router";
import { withProjectContext, useProjectContext } from "areas/projects/context";

const ProjectTasks: React.FC = () => {
    const match = useRouteMatch();
    const projectContext = useProjectContext();
    const location = useLocation();
    const history = useHistory();
    const project = projectContext.state && projectContext.state.model;

    if (!project) {
        Logger.error(`Failed to find project from context. This should not happen.`);
        return null;
    }

    return <Tasks history={history} location={location} match={match!} restrictToProjectId={project.Id} hideScriptConsoleAction={true} />;
};

const EnhancedProjectTasks = withProjectContext(ProjectTasks);
export default EnhancedProjectTasks;
