// tslint:disable: no-non-null-assertion
import * as React from "react";
import { repository } from "clientInstance";
import { ActionEditProps } from "components/Actions/pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import Note from "components/form/Note/Note";
import { VariableLookupText } from "components/form/VariableLookupText";
import { SensitiveValue } from "client/resources/propertyValueResource";
import CertificateVariableSelect from "components/form/CertificateSelect/CertificateVariableSelect";
import ProjectResource from "client/resources/projectResource";
import { BoundSensitive } from "components/form/Sensitive/Sensitive";
import ActionProperties from "client/resources/actionProperties";
import ExternalLink from "components/Navigation/ExternalLink";

export interface JavaKeystoreProperties {
    "Java.Certificate.Variable": string;
    "Java.Certificate.KeystoreFilename": string;
    "Java.Certificate.KeystoreAlias": string;
    "Java.Certificate.Password": SensitiveValue | string;
}

export interface JavaKeystoreActionEditProps<T = ActionProperties> extends ActionEditProps<T> {
    keystoreFilenameMessage: JSX.Element;
}

export interface JavaKeystoreActionEditState {
    project: ProjectResource;
}

export default class JavaKeystoreComponent extends BaseComponent<JavaKeystoreActionEditProps<JavaKeystoreProperties>, JavaKeystoreActionEditState> {
    constructor(props: JavaKeystoreActionEditProps<JavaKeystoreProperties>) {
        super(props);
        this.state = {
            project: null!,
        };
    }

    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            const project = await repository.Projects.get(this.props.projectId!);
            this.setState({
                project,
            });
        });
    }

    render() {
        const properties = this.props.properties;
        return (
            <div>
                {this.props.projectId ? (
                    <CertificateVariableSelect
                        projectId={this.props.projectId}
                        doBusyTask={this.props.doBusyTask}
                        value={this.props.properties["Java.Certificate.Variable"]}
                        onChange={val => this.props.setProperties({ ["Java.Certificate.Variable"]: val })}
                        allowClear={true}
                        error={this.props.getFieldError("Java.Certificate.Variable")}
                    />
                ) : (
                    <VariableLookupText
                        label="Certificate variable"
                        localNames={this.props.localNames}
                        value={this.props.properties["Java.Certificate.Variable"]}
                        onChange={val => this.props.setProperties({ ["Java.Certificate.Variable"]: val })}
                        error={this.props.getFieldError("Java.Certificate.Variable")}
                    />
                )}
                <Note>
                    A project variable that refers to a certificate.{" "}
                    <span>
                        <ExternalLink href="CertificatesDocumentation">Learn more</ExternalLink>.
                    </span>
                </Note>
                <VariableLookupText
                    localNames={this.props.localNames}
                    value={properties["Java.Certificate.KeystoreFilename"]}
                    onChange={x => this.props.setProperties({ ["Java.Certificate.KeystoreFilename"]: x })}
                    label="Keystore filename"
                    error={this.props.getFieldError("Java.Certificate.KeystoreFilename")}
                />
                <Note>{this.props.keystoreFilenameMessage}</Note>
                <BoundSensitive
                    variableLookup={{
                        localNames: this.props.localNames,
                    }}
                    resetValue={""}
                    value={properties["Java.Certificate.Password"]}
                    onChange={x => this.props.setProperties({ ["Java.Certificate.Password"]: x })}
                    label="Private key password"
                />
                <Note>
                    You can set an optional password which will be assigned to the private key. If this field is left blank, the default password of <em>changeit</em> will be used.
                </Note>
                <VariableLookupText localNames={this.props.localNames} value={properties["Java.Certificate.KeystoreAlias"]} onChange={x => this.props.setProperties({ ["Java.Certificate.KeystoreAlias"]: x })} label="Keystore alias" />
                <Note>
                    The optional alias to assign the private key to. If left blank, the default alias of <em>Octopus</em> will be used
                </Note>
            </div>
        );
    }
}
