// tslint:disable: no-non-null-assertion
// tslint:disable: no-any

import * as React from "react";
import { Permission } from "client/resources/permission";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { gettingStartedLoader, GettingStartedStatus } from "components/GettingStarted/gettingStartedLoader";
const styles = require("./GettingStartedFooter.less");
import { GettingStartedDialog } from "components/GettingStarted/GettingStartedDialog";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { withRouter, RouteComponentProps } from "react-router";
import routeLinks from "../../routeLinks";
import { resolveStringPathWithSpaceId } from "../Navigation/resolvePathWithSpaceId";
import { GettingStartedDetails } from "./GettingStartedDetails";
import ToolTip from "components/ToolTip/ToolTip";
import ExternalLink from "components/Navigation/ExternalLink";
import { OnboardingState, onboardingStateUpdated } from "./reducers/onboardingArea";
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import cn from "classnames";
import classNames = require("classnames");
import { drawerBreakpointWidth } from "components/Drawer/DrawerWrapperLayout";
import { isDrawerOpen } from "components/Drawer/reducers";
import { OnboardingSuccess } from "components/Images/Images/OnboardingSuccess/OnboardingSuccess";
import IconButton from "components/IconButton";
import { Icon } from "components/IconButton/IconButton";
import Environment from "environment";

interface ProgressSection {
    index: number;
    percent: number;
    enabled: boolean;
}

interface GettingStartedFooterState {
    showBar: boolean;
    expand: boolean;
    canDismiss: boolean;
    progressSections: ProgressSection[];
    showDetails: boolean;
    status: GettingStartedStatus | null;
    highlightSection: string | null;
    spacer: number;
    isDashboard: boolean;
    width: number;
}

interface GlobalConnectedProps {
    showFooterOnDashboard?: boolean;
    isDrawerOpen?: boolean;
    isHelpSidebarEnabled?: boolean;
}

interface GlobalDispatchProps {
    onOnboardingStateUpdated?(onboardingState: OnboardingState): void;
}

type GettingStartedFooterProps = RouteComponentProps<{ spaceId: string }> & GlobalConnectedProps & GlobalDispatchProps;

class GettingStartedFooterInternal extends BaseComponent<GettingStartedFooterProps, GettingStartedFooterState> {
    unsubscribe: (() => void) | null;
    divEl: HTMLElement;

    constructor(props: GettingStartedFooterProps) {
        super(props);

        this.state = {
            showBar: true,
            expand: true,
            canDismiss: false,
            progressSections: [],
            showDetails: false,
            status: null,
            highlightSection: null,
            spacer: 0,
            isDashboard: this.isDashboard(this.props.location.pathname, this.props.match.params.spaceId),
            width: 0,
        };
    }

    async componentDidMount() {
        const hasEnvironmentCreatePermission = isAllowed({ permission: Permission.EnvironmentCreate });
        const hasEitherMachineCreateOrAccountCreatePermission = isAllowed({ permission: Permission.MachineCreate }) || isAllowed({ permission: Permission.AccountCreate });
        const hasEitherBuiltInFeedPushOrFeedEditPermission = isAllowed({ permission: Permission.BuiltInFeedPush }) || isAllowed({ permission: Permission.FeedEdit });
        const hasProjectCreatePermission = isAllowed({ permission: Permission.ProjectCreate });
        const hasProjectEditPermission = isAllowed({ permission: Permission.ProjectEdit });
        const hasReleaseCreatePermission = isAllowed({ permission: Permission.ReleaseCreate });
        const hasDeploymentCreatePermission = isAllowed({ permission: Permission.DeploymentCreate });

        const hasAllRequiredPermissions =
            hasEnvironmentCreatePermission &&
            hasEitherMachineCreateOrAccountCreatePermission &&
            hasEitherBuiltInFeedPushOrFeedEditPermission &&
            hasProjectCreatePermission &&
            hasProjectEditPermission &&
            hasReleaseCreatePermission &&
            hasDeploymentCreatePermission;

        if (!hasAllRequiredPermissions) {
            this.setState({
                showBar: false,
            });
            return;
        }

        const tasksStatus = await gettingStartedLoader.loadStatus();

        if (!this.unmounted) {
            if (tasksStatus.tasks.Deploy!.DeployedRelease.IsComplete) {
                this.setState({
                    showBar: false,
                });
                return;
            }

            this.setState({
                expand: this.isBarExpanded(),
            });

            if (this.divEl) {
                this.setState({
                    spacer: this.divEl.getBoundingClientRect().height,
                });
            }

            this.unsubscribe = gettingStartedLoader.subscribe(status => this.refresh(status));
            this.refresh(tasksStatus);
        }

        this.calculateWidth();
        window.addEventListener("resize", this.calculateWidth);
    }

    componentWillUnmount() {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
        window.removeEventListener("resize", this.calculateWidth);
    }

    componentWillReceiveProps(nextProps: GettingStartedFooterProps) {
        this.setState({
            isDashboard: this.isDashboard(nextProps.location.pathname, nextProps.match.params.spaceId),
        });
    }

    componentDidUpdate() {
        if (!this.divEl) {
            return;
        }

        const newHeight = this.divEl.getBoundingClientRect().height;
        if (this.state.spacer === newHeight) {
            return;
        }

        this.setState({
            spacer: newHeight,
        });
    }

    calculateWidth = () => {
        const width = window.innerWidth;
        this.setState({ width });
    };

    render() {
        if ((this.state.isDashboard && !this.props.showFooterOnDashboard) || !this.state.showBar || !this.state.progressSections) {
            return null;
        }

        const isDrawerMediaWidthOrAbove = this.state.width >= drawerBreakpointWidth;
        const drawOffsetStyle = isDrawerMediaWidthOrAbove && this.props.isDrawerOpen && this.props.isHelpSidebarEnabled ? styles.drawerOffset : null;
        const footerActionStyles = isDrawerMediaWidthOrAbove && this.props.isDrawerOpen && this.props.isHelpSidebarEnabled ? classNames(styles.gettingStartedFooterActions, styles.drawerOffsetForFooter) : styles.gettingStartedFooterActions;
        return (
            <div>
                {this.state.showDetails && (
                    <GettingStartedDialog onNavigating={() => this.setState({ showDetails: false })} highlightSection={this.state.highlightSection} open={this.state.showDetails} onClose={() => this.setState({ showDetails: false })} />
                )}
                <div ref={this.setDivRef} className={classNames(styles.gettingStartedFooter, drawOffsetStyle)}>
                    {Environment.isInDevelopmentMode && <IconButton onClick={() => this.dismiss()} icon={Icon.Cancel} className="pull-right" />}
                    {!(this.state.canDismiss || this.state.expand) ? (
                        <div className={styles.gettingStartedMinimize}>
                            <div className={footerActionStyles}>
                                <ToolTip content={"Show my progress"}>
                                    <a className={cn(styles.gettingStartedButton, styles.intensePulse)} onClick={() => this.toggle()}>
                                        <em className="fa fa-list-ol" aria-hidden="true" />
                                    </a>
                                </ToolTip>
                            </div>
                        </div>
                    ) : (
                        <div className={footerActionStyles}>
                            <ToolTip content={"Hide"}>
                                <a className={cn(styles.gettingStartedButton, styles.lightPulse)} onClick={() => this.toggle()}>
                                    <em className="fa fa-minus" aria-hidden="true" />
                                </a>
                            </ToolTip>
                        </div>
                    )}
                    {this.state.canDismiss && (
                        <div className={styles.gettingStartedMinimize}>
                            <div className={footerActionStyles}>
                                <ToolTip content={"Close"}>
                                    <a className={cn(styles.gettingStartedButton, styles.intensePulse)} onClick={() => this.dismiss()}>
                                        <em className="fa fa-times" aria-hidden="true" />
                                    </a>
                                </ToolTip>
                            </div>
                        </div>
                    )}

                    {this.state.canDismiss && (
                        <div className={styles.firstSuccessfulDeploymentContainer}>
                            <div className={styles.firstSuccessfulDeployment}>
                                <div className={styles.successContent}>
                                    <h3>
                                        <strong>Congratulations, you've successfully completed your first deployment!</strong>
                                    </h3>
                                    <p>
                                        <ExternalLink href="OnboardingFeedbackForm">Complete our short feedback form</ExternalLink> on your deployment experience <i>(under 1 minute)</i> and we'll mail you a pack of laptop stickers as a thank you.
                                    </p>
                                    <p>Happy Deployments!</p>
                                </div>
                            </div>
                            <div className={styles.successImage}>
                                <OnboardingSuccess />
                            </div>
                        </div>
                    )}
                    {!this.state.canDismiss && this.state.expand && (
                        <div className={styles.gettingStartedContent}>
                            <GettingStartedDetails onlyShowCurrentSection={true} showGettingStartedDialog={() => this.showGettingStartedDialog()} />
                        </div>
                    )}
                </div>
                <div style={{ paddingTop: `${this.state.spacer}px` }} />
            </div>
        );
    }

    private showGettingStartedDialog() {
        this.setState({ showDetails: true });
    }

    private setDivRef = (el: HTMLDivElement) => {
        this.divEl = el;
    };

    private dismiss() {
        this.setState({ showBar: false });
        if (this.unsubscribe) {
            this.unsubscribe();
            this.unsubscribe = null;
        }
    }

    private isDashboard(path: string, spaceId: string) {
        return resolveStringPathWithSpaceId(routeLinks.root, spaceId) === path || resolveStringPathWithSpaceId(routeLinks.dashboard.root, spaceId) === path;
    }

    private toggle() {
        localStorage.setItem("gettingStartedProgressBarExpanded", (!this.state.expand).toString());
        this.setState({
            expand: !this.state.expand,
        });
    }

    private isBarExpanded() {
        if (localStorage.getItem("gettingStartedProgressBarExpanded") !== null) {
            return localStorage.getItem("gettingStartedProgressBarExpanded") === "true" ? true : false;
        }
        return true;
    }

    private refresh(status: GettingStartedStatus) {
        const sections: ProgressSection[] = [
            {
                index: 1,
                percent: status.tasks.Infrastructure!.Percent,
                enabled: status.tasks.Infrastructure!.IsEnabled,
            },
            {
                index: 2,
                percent: status.tasks.Package!.Percent,
                enabled: status.tasks.Package!.IsEnabled,
            },
            {
                index: 3,
                percent: status.tasks.Project!.Percent,
                enabled: status.tasks.Project!.IsEnabled,
            },
            {
                index: 4,
                percent: status.tasks.Deploy!.Percent,
                enabled: status.tasks.Deploy!.IsEnabled,
            },
        ];

        if (status.tasks.Deploy!.DeployedRelease.IsComplete) {
            this.setState({
                canDismiss: true,
            });
        }

        this.setState({
            status,
            progressSections: sections,
        });
    }
}

const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    const currentOnboardingState = !state.onboardingArea.config
        ? {}
        : {
              showFooterOnDashboard: state.onboardingArea.config.showFooterOnDashboard,
              isDrawerOpen: isDrawerOpen(state),
              isHelpSidebarEnabled: state.configurationArea.features.isHelpSidebarEnabled,
          };
    return currentOnboardingState;
};

const mapGlobalActionDispatchersToProps = (dispatch: Dispatch<Action<any>>) => {
    return {
        onOnboardingStateUpdated: (onboardingState: OnboardingState) => {
            dispatch(
                onboardingStateUpdated({
                    showFooterOnDashboard: onboardingState.showFooterOnDashboard,
                })
            );
        },
    };
};

const GettingStartedFooterConnect = connect<{}, GlobalDispatchProps, GettingStartedFooterProps>(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(GettingStartedFooterInternal);

export default withRouter(GettingStartedFooterConnect);
