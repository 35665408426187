// tslint:disable: no-non-null-assertion
// tslint:disable: no-any

import * as React from "react";
import { IProcessResource, TenantedDeploymentMode, WorkerPoolResource, TagSetResource, RunbookSnapshotResource, processPermission } from "client/resources";
import { client, repository } from "clientInstance";
import SpecialVariables from "client/specialVariables";
import DeploymentPart from "../DeploymentProcess/DeploymentPart";
import { DeploymentActionResource } from "client/resources/deploymentActionResource";
import Roles from "components/Actions/Roles";
import pluginRegistry, { ActionScope } from "components/Actions/pluginRegistry";
import { NavigationButton, NavigationButtonType } from "components/Button/NavigationButton";
import { ScriptModule } from "../DeploymentProcess/SideBar";
import { ProjectResource } from "client/resources/projectResource";
import ActionList from "components/ActionList/ActionList";
import StepSorter from "../DeploymentProcess/DeploymentPartSorter";
import OverflowMenu, { OverflowMenuItems, MenuItem } from "components/Menu/OverflowMenu";
import { DeploymentStepResource } from "client/resources/deploymentStepResource";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import LibraryVariableSetResource, { VariableSetContentType } from "client/resources/libraryVariableSetResource";
import { EnvironmentResource } from "client/resources/environmentResource";
import { ResourcesById } from "client/repositories/basicRepository";
import { keyBy, flatten, isEqual } from "lodash";
import * as tenantTagsets from "components/tenantTagsets";
import { RouteComponentProps } from "react-router";
import routeLinks from "routeLinks";
import Permission from "client/resources/permission";
import PermissionCheck, { isAllowed } from "components/PermissionCheck/PermissionCheck";
import getActionLogoUrl from "../getActionLogoUrl";
import Callout, { CalloutType } from "components/Callout";
import InternalLink from "components/Navigation/InternalLink";
import { UnstructuredFormSection, Select, Checkbox } from "components/form";
import DeploymentPartContextMenu from "../DeploymentProcess/DeploymentPartContextMenu";
import FilterSearchBox from "components/FilterSearchBox";
import { NoResults } from "components/NoResults/NoResults";
import Logger from "client/logger";
import AdvancedFilterLayout from "components/AdvancedFilterLayout/AdvancedFilterLayout";
import { withProjectContext, WithProjectContextInjectedProps, ActionContextProvider } from "../../context";
import { FilterNamedResource, ProcessContextProvider, ProcessContextProps } from "../../context";
import ActionTemplateSearchResource from "client/resources/actionTemplateSearchResource";
import { ProjectRouteParams } from "../ProjectLayout";
import { WithRunbookContextInjectedProps, withRunbookContext } from "./RunbookContext";
import { RunbookProcessEditorFilter } from "../DeploymentProcess/DeploymentPartContextMenu";
import { RunbooksPaperLayout } from "./Layouts";
import RunbookOnboarding from "./RunbookOnboarding";
import RunNowButton from "./RunNowButton";
import PaperLayout from "components/PaperLayout";
import { addSampleStepsToProcess, deleteAllSteps } from "../DeploymentProcess/DeploymentProcessOverview";
import { DeleteStepsCallout } from "../DeploymentProcess/DeleteStepsCallout";
import PublishButton from "./PublishButton";
import { FormTitleForRunbook } from "./LastPublishedChip";
import SidebarLayout from "components/SidebarLayout/SidebarLayout";
import { RunbookProcessOverviewSidebar } from "./RunbookProcessOverviewSidebar";
import { StepRolling } from "components/Images/Images/DeploymentProcess/StepRolling";
import Chip from "components/Chips/Chip";
import { connect } from "react-redux";
import configurationSelectors from "areas/configuration/reducers/selectors";

const deploymentPartStyles = require("../DeploymentProcess/DeploymentPart.less");

interface GlobalConnectedProps {
    isActionContainersEnabled?: boolean;
}

interface RunbookProcessLayoutState extends DataBaseComponentState {
    project: ProjectResource;
    redirectTo?: string;
    open: boolean;
    isLookupDataLoaded: boolean;
    isSaving: boolean;
    selectedParentStepId: string;
    includedScriptModules: ScriptModule[];
    environmentsById?: ResourcesById<EnvironmentResource>;
    tagSets: TagSetResource[];
    workerPoolsById: ResourcesById<WorkerPoolResource>;
    hasLoaded: boolean;
    publishedRunbookSnapshot: RunbookSnapshotResource;
}

type RunbookProcessLayoutProps = RouteComponentProps<ProjectRouteParams> & WithRunbookContextInjectedProps & WithProjectContextInjectedProps & GlobalConnectedProps;

class FilterLayout extends AdvancedFilterLayout<RunbookProcessEditorFilter> {}

class RunbookProcessLayout extends DataBaseComponent<RunbookProcessLayoutProps, RunbookProcessLayoutState> {
    constructor(props: RunbookProcessLayoutProps) {
        super(props);
        this.state = {
            redirectTo: null!,
            project: null!,
            open: false,
            isLookupDataLoaded: false,
            isSaving: false,
            selectedParentStepId: null!,
            includedScriptModules: [],
            tagSets: null!,
            workerPoolsById: null!,
            hasLoaded: false,
            publishedRunbookSnapshot: null!,
        };
    }

    async componentDidMount() {
        await this.reload();
    }

    async componentDidUpdate(nextProps: RunbookProcessLayoutProps) {
        const currentRunbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        const nextRunbook = nextProps.runbookContext.state && nextProps.runbookContext.state.runbook;
        if (!isEqual(currentRunbook, nextRunbook)) {
            await this.reload();
        }
    }

    async reload() {
        let project = this.props.projectContext.state && this.props.projectContext.state.model;
        if (!project) {
            return;
        }

        const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        if (!runbook) {
            return;
        }

        await this.doBusyTask(async () => {
            //We are explicitly typing Promise.all here because of some bugs in TS - https://github.com/microsoft/TypeScript/issues/34925
            //We can infer the types onces https://github.com/microsoft/TypeScript/pull/33707 lands
            const [scriptModules, environmentsById, tagSets, workerPools, publishedRunbookSnapshot] = await Promise.all([
                isAllowed({ permission: Permission.LibraryVariableSetView, environment: "*", tenant: "*" })
                    ? repository.LibraryVariableSets.all({
                          contentType: VariableSetContentType.ScriptModule,
                      })
                    : ([] as LibraryVariableSetResource[]),
                repository.Environments.allById(),
                tenantTagsets.getAll(),
                repository.WorkerPools.all(),
                runbook.PublishedRunbookSnapshotId ? repository.RunbookSnapshots.get(runbook.PublishedRunbookSnapshotId) : Promise.resolve<RunbookSnapshotResource>(null!),
            ]);

            //Refresh project from context
            project = await this.props.projectContext.actions.refreshAndGetModel();

            const workerPoolsById = keyBy(workerPools, wp => wp.Id);
            const includedScriptModules = scriptModules.filter(sm => project.IncludedLibraryVariableSetIds.includes(sm.Id));

            this.setState({
                environmentsById,
                project,
                includedScriptModules,
                tagSets,
                workerPoolsById,
                isLookupDataLoaded: true,
                hasLoaded: true,
                publishedRunbookSnapshot,
            });
        });
    }

    render() {
        const runbook = this.props.runbookContext.state.runbook;
        if (!runbook) {
            return <PaperLayout title="Process" breadcrumbTitle={"Runbooks"} breadcrumbPath={routeLinks.project(this.props.match.params.projectSlug).operations.runbooks} busy={true} fullWidth={true} {...this.props} />;
        }
        return (
            <ActionContextProvider doBusyTask={this.doBusyTask}>
                {actionContext => (
                    <ProcessContextProvider scope={ActionScope.Runbooks} id={runbook.RunbookProcessId} doBusyTask={this.doBusyTask}>
                        {processContext => {
                            const { state: stepsState, actions: stepsActions } = processContext;
                            const hasLoaded: boolean = this.state.isLookupDataLoaded && !!processContext.state.process;

                            const actions: Array<React.ReactElement<any>> = [];
                            if (hasLoaded) {
                                const hasSteps = stepsState.process && stepsState.process.Steps && stepsState.process.Steps.length > 0;
                                if (hasSteps) {
                                    actions.push(<PublishButton doBusyTask={this.doBusyTask} />);
                                }
                                actions.push(this.addStepButton(processContext));
                                actions.push(<RunNowButton isDisabled={!hasSteps} />);

                                const overflowMenuItems: Array<MenuItem | MenuItem[]> = [];
                                if (!stepsState.process.Steps || stepsState.process.Steps.length === 0) {
                                    overflowMenuItems.push(
                                        OverflowMenuItems.item(
                                            "Load a sample process",
                                            async () => {
                                                const deploymentProcessUpdated = await addSampleStepsToProcess(this.doBusyTask, stepsState.process);
                                                processContext.actions.onStepsChange(deploymentProcessUpdated);
                                            },
                                            {
                                                permission: processPermission(processContext.state.process),
                                                project: this.props.projectContext.state.model.Id,
                                                wildcard: true,
                                            }
                                        )
                                    );
                                }
                                overflowMenuItems.push(
                                    OverflowMenuItems.downloadItem(
                                        "Download as JSON",
                                        `${this.state.project.Slug}-${runbook.Name}-process.json`,
                                        client.resolveLinkTemplate("RunbookProcesses", {
                                            id: stepsState.process.Id,
                                        })
                                    )
                                );
                                overflowMenuItems.push(this.getReorderOverflowAction(processContext));
                                if (stepsState.process.Steps && stepsState.process.Steps.length > 0) {
                                    overflowMenuItems.push(
                                        OverflowMenuItems.deleteItem(
                                            "Delete all steps",
                                            "Are you sure you want to delete all steps from this process?",
                                            async () => {
                                                const deploymentProcessUpdated = await deleteAllSteps(this.doBusyTask, stepsState.process);
                                                processContext.actions.onStepsChange(deploymentProcessUpdated);
                                                return true;
                                            },
                                            () => <DeleteStepsCallout />,
                                            {
                                                permission: processPermission(processContext.state.process),
                                                project: this.props.projectContext.state.model.Id,
                                                wildcard: true,
                                            },
                                            false
                                        )
                                    );
                                }
                                overflowMenuItems.push([
                                    OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([stepsState.process.Id]), null!, {
                                        permission: Permission.EventView,
                                        wildcard: true,
                                    }),
                                ]);
                                actions.push(<OverflowMenu menuItems={overflowMenuItems} />);
                            }
                            const layoutTitle = <FormTitleForRunbook runbookName={runbook.Name} />;
                            return (
                                <RunbooksPaperLayout
                                    title={layoutTitle}
                                    breadcrumbTitle={"Runbooks"}
                                    breadcrumbPath={routeLinks.project(this.props.match.params.projectSlug).operations.runbooks}
                                    sectionControl={<ActionList actions={actions} />}
                                    busy={this.state.busy}
                                    errors={this.state.errors}
                                >
                                    {hasLoaded && stepsState.process.Steps.length === 0 && <RunbookOnboarding />}
                                    {hasLoaded && stepsState.process.Steps.length > 0 && (
                                        <FilterLayout
                                            filter={stepsState.filter}
                                            filterSections={[
                                                {
                                                    render: (
                                                        <>
                                                            <PermissionCheck permission={Permission.EnvironmentView} wildcard={true}>
                                                                <Select
                                                                    value={stepsState.filter.environment?.Id}
                                                                    onChange={environmentId => {
                                                                        let environment: FilterNamedResource;
                                                                        if (environmentId) {
                                                                            const resource = this.state.environmentsById![environmentId];
                                                                            environment = {
                                                                                Id: resource.Id,
                                                                                Name: resource.Name,
                                                                            };
                                                                        }
                                                                        stepsActions.onFilterChange(t => ({ ...t, environment }));
                                                                    }}
                                                                    items={Object.values(this.state.environmentsById || {}).map(e => ({ value: e.Id, text: e.Name }))}
                                                                    allowClear={true}
                                                                    allowFilter={true}
                                                                    fieldName="environment"
                                                                />
                                                            </PermissionCheck>
                                                            <Checkbox
                                                                label="Include unscoped steps"
                                                                value={stepsState.filter.includeUnscoped!}
                                                                onChange={includeUnscoped => {
                                                                    stepsActions.onFilterChange(prev => ({ ...prev, includeUnscoped }));
                                                                }}
                                                            />
                                                        </>
                                                    ),
                                                },
                                            ]}
                                            onFilterReset={filter => stepsActions.onFilterChange(prev => filter)}
                                            defaultFilter={processContext.getEmptyFilter()}
                                            initiallyShowFilter={processContext.isFiltering}
                                            additionalHeaderFilters={[
                                                <FilterSearchBox hintText="Filter by name..." value={stepsState.filter.filterKeyword} onChange={filterKeyword => stepsActions.onFilterChange(prev => ({ ...prev, filterKeyword }))} autoFocus={true} />,
                                            ]}
                                            renderContent={() => (
                                                <SidebarLayout
                                                    sideBar={<RunbookProcessOverviewSidebar projectId={this.props.projectContext.state.model.Id} onDataChanged={() => this.reload()} includedScriptModules={this.state.includedScriptModules} />}
                                                >
                                                    {this.getInvalidConfigurationCallouts(processContext)}
                                                    <div className={deploymentPartStyles.stepList}>
                                                        {processContext.filteredSteps.steps.length > 0 ? (
                                                            processContext.filteredSteps.steps
                                                                .filter(x => x.filtered)
                                                                .map(({ step: filteredStep, index }) => {
                                                                    const step = this.findStepByName(stepsState.process, filteredStep.Name);
                                                                    if (!step) {
                                                                        Logger.log(`Failed to find step with name ${filteredStep.Name}`);
                                                                        return null;
                                                                    }
                                                                    return step.Actions.length === 1
                                                                        ? this.buildAction(processContext, ActionScope.Runbooks, actionContext.state.actionTemplates, step, step.Actions[0], index)
                                                                        : this.buildParentStep(processContext, ActionScope.Runbooks, actionContext.state.actionTemplates, step, index);
                                                                })
                                                        ) : (
                                                            <NoResults />
                                                        )}
                                                    </div>
                                                </SidebarLayout>
                                            )}
                                        />
                                    )}
                                </RunbooksPaperLayout>
                            );
                        }}
                    </ProcessContextProvider>
                )}
            </ActionContextProvider>
        );
    }

    private findStepByName(process: IProcessResource, name: string) {
        // We lookup by .Name due to cloned steps not having an ID initially.
        return process.Steps.find(x => x.Name === name);
    }

    private getInvalidAutomaticReleaseCreationConfigurationCallout(processContext: ProcessContextProps) {
        if (this.state.project.AutoCreateRelease) {
            if (this.state.project.ReleaseCreationStrategy == null || this.state.project.ReleaseCreationStrategy.ReleaseCreationPackage == null) {
                return (
                    <div>
                        This project is configured to use Automatic Release Creation, but the step is missing. Please adjust the <InternalLink to={routeLinks.project(this.state.project.Slug).triggers}>Automatic Release Creation</InternalLink>{" "}
                        configuration.
                    </div>
                );
            } else {
                const action = flatten(processContext.state.process.Steps.map(step => step.Actions)).filter(a => a.Name === this.state.project.ReleaseCreationStrategy.ReleaseCreationPackage.DeploymentAction);
                if (action && action.length > 0 && action[0].IsDisabled) {
                    return (
                        <div>
                            Step <InternalLink to={this.calculateDetailsUrl(processContext, action[0].Id)!}>{action[0].Name}</InternalLink> is currently used for Automatic Release Creation, but it has been disabled. Please re-enable the step, or
                            adjust the <InternalLink to={routeLinks.project(this.state.project.Slug).triggers}>Automatic Release Creation</InternalLink> configuration.
                        </div>
                    );
                }
            }
        }
        return null;
    }

    private getInvalidVersioningConfigurationCallout(processContext: ProcessContextProps) {
        if (this.state.project.VersioningStrategy.DonorPackage) {
            const action = flatten(processContext.state.process.Steps.map(step => step.Actions)).filter(a => a.Name === this.state.project.VersioningStrategy.DonorPackage!.DeploymentAction);
            if (action && action.length > 0 && action[0].IsDisabled) {
                return (
                    <div>
                        Step <InternalLink to={this.calculateDetailsUrl(processContext, action[0].Id)!}>{action[0].Name}</InternalLink> is currently used for release versioning, but it has been disabled.
                        <br />
                        Please re-enable the step or adjust the <InternalLink to={routeLinks.project(this.state.project.Slug).settings}>release versioning</InternalLink> configuration.
                    </div>
                );
            }
        }
        return null;
    }

    private getInvalidConfigurationCallouts(processContext: ProcessContextProps) {
        if (this.state.isLookupDataLoaded && !this.state.isSaving) {
            const arcCallout = this.getInvalidAutomaticReleaseCreationConfigurationCallout(processContext);
            const versioningCallout = this.getInvalidVersioningConfigurationCallout(processContext);
            if (arcCallout || versioningCallout) {
                return (
                    <UnstructuredFormSection stretchContent={true}>
                        <Callout type={CalloutType.Warning} title="Invalid Configuration">
                            {arcCallout}
                            {versioningCallout}
                        </Callout>
                    </UnstructuredFormSection>
                );
            }
        }
        return null;
    }

    private buildParentStep(processContext: ProcessContextProps, scope: ActionScope, actionTemplates: ActionTemplateSearchResource[], step: DeploymentStepResource, stepIndex: number) {
        const showWindowSize = step.Properties[SpecialVariables.Action.MaxParallelism] ? step.Properties[SpecialVariables.Action.MaxParallelism]!.toString().length > 0 : false;
        const parentStepLabel = showWindowSize ? (
            <span>Rolling deployment</span>
        ) : (
            <span>
                Multi-step deployment across
                <br />
                deployment targets
            </span>
        );
        return (
            <div key={step.Id} className={deploymentPartStyles.group}>
                <DeploymentPartContextMenu
                    scope={ActionScope.Runbooks}
                    keywordSearch={processContext.state.filter && processContext.state.filter.filterKeyword}
                    step={step}
                    stepIndex={stepIndex}
                    isParentGroup={true}
                    render={renderProps => {
                        return (
                            <DeploymentPart actionType={parentStepLabel} icon={<StepRolling />} {...renderProps}>
                                {step.Properties[SpecialVariables.Action.MaxParallelism] ? <span>Rolling deployment</span> : <span>Multi-step deployment</span>}
                                &nbsp;across deployment targets in&nbsp;
                                <Roles rolesAsCSV={step.Properties[SpecialVariables.Action.TargetRoles] as string} />
                            </DeploymentPart>
                        );
                    }}
                />
                {step.Actions.map((action, index) => this.buildAction(processContext, scope, actionTemplates, step, action, index + 1, stepIndex))}
            </div>
        );
    }

    private buildAction(processContext: ProcessContextProps, scope: ActionScope, actionTemplates: ActionTemplateSearchResource[], step: DeploymentStepResource, action: DeploymentActionResource, actionIndex: number, stepIndex?: number) {
        const isChildAction = !!stepIndex;
        let actionTypeName = action.ActionType;
        const actionTemplate = actionTemplates.find(x => x.Type === action.ActionType);
        if (actionTemplate) {
            actionTypeName = actionTemplate.Name;
        }

        const environmentsUserCanAccess = (environments: string[]) => environments.filter(e => Object.keys(this.state.environmentsById!).includes(e));
        const feedViewPermissionGranted = isAllowed({ permission: Permission.FeedView, project: processContext.state.process.ProjectId, wildcard: true });

        return (
            <DeploymentPartContextMenu
                scope={ActionScope.Runbooks}
                key={action.Id}
                step={step}
                action={action}
                stepIndex={stepIndex}
                actionIndex={actionIndex}
                isChildAction={isChildAction}
                keywordSearch={processContext.state.filter && processContext.state.filter.filterKeyword}
                render={renderProps => {
                    return (
                        <DeploymentPart
                            actionType={actionTypeName}
                            logoUrl={getActionLogoUrl(action)}
                            environments={environmentsUserCanAccess(action.Environments).map(id => this.state.environmentsById![id])}
                            excludedEnvironments={environmentsUserCanAccess(action.ExcludedEnvironments).map(id => this.state.environmentsById![id])}
                            tags={this.getTags(action)}
                            {...renderProps}
                        >
                            <div className={deploymentPartStyles.summaryContainer}>
                                {pluginRegistry
                                    .getAction(action.ActionType, scope)
                                    .summary(
                                        action.Properties,
                                        isChildAction ? null! : (step.Properties[SpecialVariables.Action.TargetRoles!] as string),
                                        action.Packages,
                                        action.WorkerPoolId ? this.state.workerPoolsById[action.WorkerPoolId] : null!,
                                        action.WorkerPoolVariable
                                    )}
                                {this.props.isActionContainersEnabled && action.Container.Image && action.Container.FeedId && action.Properties["Octopus.Action.RunOnServer"] === "true" ? (
                                    <span style={{ paddingLeft: "0.1em" }}>
                                        <Chip description={feedViewPermissionGranted ? `Container Image: ${action.Container.Image}` : undefined}>Runs in a container</Chip>
                                    </span>
                                ) : (
                                    ""
                                )}
                            </div>
                        </DeploymentPart>
                    );
                }}
            />
        );
    }

    private refreshData = async () => {
        await this.reload();
    };

    private getTags(action: DeploymentActionResource): string[] {
        if (this.props.runbookContext.state.runbook!.MultiTenancyMode === TenantedDeploymentMode.Untenanted) {
            return [];
        }
        return action.TenantTags;
    }

    private addStepButton(processContext: ProcessContextProps) {
        const processId = processContext.state.process.Id;
        const runbookId = this.props.runbookContext.state.runbook?.Id!;

        return (
            <PermissionCheck permission={processPermission(processContext.state.process)} project={this.state.project.Id} wildcard={true}>
                <NavigationButton
                    type={NavigationButtonType.Primary}
                    label="Add Step"
                    href={
                        routeLinks
                            .project(this.props.match.params.projectSlug)
                            .operations.runbook(runbookId)
                            .runbookProcess.runbookProcess(processId).steps.stepTemplates
                    }
                />
            </PermissionCheck>
        );
    }

    private getReorderOverflowAction(processContext: ProcessContextProps) {
        const {
            actions: { onStepsChange },
            state: {
                process: { ProjectId: projectId, Id: processId },
            },
        } = processContext;

        return OverflowMenuItems.dialogItem("Reorder steps", <StepSorter scope={ActionScope.Runbooks} title="Reorder Steps" processId={processId} saveDone={this.refreshData} onStepsUpdated={onStepsChange} />, {
            permission: processPermission(processContext.state.process),
            project: projectId,
            wildcard: true,
        });
    }

    private calculateDetailsUrl(processContext: ProcessContextProps, id: string): string | null {
        const {
            state: {
                process: { ProjectId: projectId, Id: processId },
            },
        } = processContext;
        const runbookId = this.props.runbookContext.state.runbook?.Id!;

        return routeLinks
            .project(projectId)
            .operations.runbook(runbookId)
            .runbookProcess.runbookProcess(processId)
            .steps.step(id);
    }
}

const EnhancedRunbookProcessLayout = withRunbookContext(withProjectContext(RunbookProcessLayout));

const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    return {
        isActionContainersEnabled: configurationSelectors.createFeatureEnabledSelector(t => t.isActionContainersEnabled)(state),
    };
};

export default connect(mapGlobalStateToProps)(EnhancedRunbookProcessLayout);
