// tslint:disable: no-any

import * as React from "react";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import { repository } from "clientInstance";
import ExpansionButtons from "components/form/Sections/ExpansionButtons";
import { sortBy, cloneDeep, uniq, take } from "lodash";
import SimpleExpander from "components/SimpleExpander";
import {
    ActionTemplateParameterResource,
    TenantProjectVariable,
    AccountResource,
    ControlType,
    Permission,
    TenantResource,
    TenantVariableResource,
    TenantMissingVariableResource,
    TenantLibraryVariable,
    AccountType,
    EnvironmentResource,
    CertificateResource,
} from "client/resources";
import Logo from "components/Logo/Logo";
import { EnvironmentChip, ChipIcon } from "components/Chips/index";
import { FormBaseComponentState } from "components/FormBaseComponent/FormBaseComponent";
import cn from "classnames";
import { Callout, CalloutType } from "components/Callout/Callout";
import MissingVariablesIcon from "../MissingVariablesIcon/MissingVariablesIcon";
import OverflowMenu, { OverflowMenuItems } from "components/Menu/OverflowMenu";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { useDispatch } from "react-redux";
import { tenantsActions } from "../tenantsArea";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import { UrlNavigationTabsContainer, TabItem } from "components/Tabs";
import { VariablesTab } from "areas/tenants/TenantsRoutes/tenantRouteLinks";
import routeLinks from "../../../routeLinks";
import ProjectMultiSelect from "../../../components/MultiSelect/ProjectMultiSelect";
import { ProjectResource } from "../../../client/resources/projectResource";
import ActionButton from "../../../components/Button/ActionButton";
import LookupResourceChipComponent from "components/LookupResourceChip";
import ActionTemplateParameterInputFormElement from "../../../components/ActionTemplateParameterInput/ActionTemplateParameterInputRenderer";
import InternalLink from "../../../components/Navigation/InternalLink/InternalLink";
import Lookup from "components/Lookup";
import CardTitle from "components/CardTitle";
import ToolTip from "components/ToolTip";
const styles = require("./style.less");
import SectionNote from "components/SectionNote/SectionNote";
import { NoResults } from "components/NoResults/NoResults";
import FormPage from "components/FormPage/FormPage";
import { useParams } from "react-router";
import DataBaseComponent from "components/DataBaseComponent";
import { memoize } from "lodash";
import { useCallback } from "react";

interface LookupData {
    tenant: TenantResource;
    hasLibraryVariableSetView?: boolean;
    environments: EnvironmentResource[];
    accounts: AccountResource[];
    missingVariables: TenantMissingVariableResource[];
}

interface InitialData {
    lookupData: LookupData;
    variables: TenantVariableResource;
}

const areAnyTemplatesOfType = (predicate: (controlType: string | undefined) => boolean, variables: TenantVariableResource): boolean => {
    return !!Object.keys(variables.ProjectVariables).find(p => !!variables.ProjectVariables[p].Templates.find(t => predicate(t.DisplaySettings["Octopus.ControlType"])));
};

type MissingProjectVariableIndex = { [projectId: string]: { [environmentId: string]: string[] } };
type MissingLibraryVariableIndex = { [libraryVariableSetId: string]: string[] };

interface MissingVariablesIndex {
    projects: MissingProjectVariableIndex;
    libraries: MissingLibraryVariableIndex;
}

const buildMissingVariableIndex = (tenantId: string, variables: TenantMissingVariableResource[]): MissingVariablesIndex => {
    const tenantMissing = variables.find(t => t.TenantId === tenantId);

    const projectVariables: { [projectId: string]: { [environmentId: string]: string[] } } = {};
    const libraryVariables: { [libraryVariableSetId: string]: string[] } = {};

    if (tenantMissing) {
        tenantMissing.MissingVariables.forEach(variable => {
            if (!variable.ProjectId) {
                if (variable.LibraryVariableSetId) {
                    if (!libraryVariables[variable.LibraryVariableSetId]) {
                        libraryVariables[variable.LibraryVariableSetId] = [];
                    }
                    libraryVariables[variable.LibraryVariableSetId].push(variable.VariableTemplateId);
                }
            } else {
                if (!projectVariables[variable.ProjectId]) {
                    projectVariables[variable.ProjectId] = {};
                }

                if (variable.EnvironmentId && !projectVariables[variable.ProjectId][variable.EnvironmentId]) {
                    projectVariables[variable.ProjectId][variable.EnvironmentId] = [];
                } else if (variable.EnvironmentId) {
                    projectVariables[variable.ProjectId][variable.EnvironmentId].push(variable.VariableTemplateId);
                }
            }
        });
    }

    return {
        projects: projectVariables,
        libraries: libraryVariables,
    };
};

const TenantVariablesFormPage = FormPage<InitialData>();
const title = "Tenant Variables";

const TenantVariablesPage: React.FC = () => {
    const { tenantId } = useParams<TenantVariablesParams>();
    const dispatch = useDispatch();
    const onTenantVariablesFetched = useCallback(
        (v: TenantMissingVariableResource) => {
            dispatch(tenantsActions.tenantMissingVariablesFetched(v));
        },
        [dispatch]
    );

    return (
        <TenantVariablesFormPage
            title={title}
            load={async () => {
                const hasLibraryVariableSetView = isAllowed({ permission: Permission.LibraryVariableSetView, environment: "*", tenant: "*" });

                const environments = repository.Environments.all();
                const missingVariables = repository.Tenants.missingVariables({ tenantId }, true);

                const tenant = await repository.Tenants.get(tenantId);
                const variables = await repository.Tenants.getVariables(tenant);

                const accounts = areAnyTemplatesOfType(type => type === ControlType.AzureAccount || type === ControlType.AmazonWebServicesAccount, variables) ? repository.Accounts.all() : Promise.resolve([]);
                notifyTenantVariablesFetched(tenant.Id, await missingVariables, onTenantVariablesFetched);

                const lookupData: LookupData = {
                    tenant,
                    hasLibraryVariableSetView,
                    accounts: await accounts,
                    environments: await environments,
                    missingVariables: await missingVariables,
                };

                return {
                    variables,
                    lookupData,
                };
            }}
            renderWhenLoaded={(data: InitialData) => {
                return <TenantVariablesLayoutInternal {...data} onTenantVariablesFetched={onTenantVariablesFetched} />;
            }}
        />
    );
};

class LinkedEnvironmentSummary extends React.Component<
    {
        linkedEnvironments: string[];
        environments: EnvironmentResource[];
    },
    { showAll: boolean }
> {
    state = { showAll: false };

    render() {
        const { linkedEnvironments, environments } = this.props;
        if (linkedEnvironments.length === 0) {
            return <span>No linked environments</span>;
        }

        const message = `Linked environment${linkedEnvironments.length === 1 ? "" : "s"}`;

        const LookupEnvironmentChip = LookupResourceChipComponent<EnvironmentResource>();
        const linkedEnvironmentResources = environments.filter(x => linkedEnvironments.some(l => l === x.Id));

        if (this.state.showAll) {
            const chips = linkedEnvironmentResources.map(env => (
                <LookupEnvironmentChip lookupCollection={linkedEnvironmentResources} key={env.Id} lookupId={env.Id} type={ChipIcon.Environment} chipRender={item => <EnvironmentChip environmentName={item.Name} />} />
            ));
            return (
                <div>
                    {message}
                    {chips}
                    <ActionButton
                        label={`Show Summary`}
                        onClick={e => {
                            e.stopPropagation();
                            this.setState({ showAll: false });
                        }}
                    />
                </div>
            );
        } else {
            const chips = take(linkedEnvironmentResources, 3).map(env => (
                <LookupEnvironmentChip lookupCollection={linkedEnvironmentResources} key={env.Id} lookupId={env.Id} type={ChipIcon.Environment} chipRender={item => <EnvironmentChip environmentName={item.Name} />} />
            ));

            const left = linkedEnvironments.length - chips.length;
            return (
                <div>
                    {message}
                    {chips}
                    {left > 0 && (
                        <ActionButton
                            label={`${left} More`}
                            onClick={e => {
                                e.stopPropagation();
                                this.setState({ showAll: true });
                            }}
                        />
                    )}
                </div>
            );
        }
    }
}

const libraryVariableTitle = (missingVariables: string[], libraryVariableSet: TenantLibraryVariable) => {
    return (
        <div className={styles.cardTitle}>
            <div className={styles.libraryVariableSetContainer}>
                <CardTitle>{libraryVariableSet.LibraryVariableSetName}</CardTitle>
                <div className={styles.libraryWarning}>
                    <MissingVariablesIcon show={!!missingVariables} />
                </div>
            </div>
            <OverflowMenu menuItems={[OverflowMenuItems.navItem("Edit Library Template", routeLinks.library.variableSet(libraryVariableSet.LibraryVariableSetId), undefined, { permission: Permission.VariableView, wildcard: true })]} />
        </div>
    );
};

export interface TenantVariablesParams {
    tenantId: string;
}

interface TenantVariablesLayoutProps {
    lookupData: LookupData;
    variables: TenantVariableResource;
    onTenantVariablesFetched(tenantMissingVariables: TenantMissingVariableResource): void;
}

type Model = TenantVariableResource;
interface TenantVariablesFormState extends FormBaseComponentState<Model> {
    projectIds: string[];
    missingVariables: TenantMissingVariableResource[];
    accounts: AccountResource[];
    certificates?: CertificateResource[];
}

function notifyTenantVariablesFetched(tenantId: string, missingVariables: TenantMissingVariableResource[], onTenantVariablesFetched: (tenantMissingVariables: TenantMissingVariableResource) => void) {
    const found = missingVariables.find(x => x.TenantId === tenantId);

    if (found) {
        onTenantVariablesFetched(found);
    }
}

class TenantVariablesLayoutInternal extends DataBaseComponent<TenantVariablesLayoutProps, TenantVariablesFormState> {
    private buildMissingVariableIndex = memoize(buildMissingVariableIndex);
    private memoizedRepositoryCertificatesList = memoize(() => repository.Certificates.listForTenant(this.props.lookupData.tenant.Id));

    constructor(props: TenantVariablesLayoutProps) {
        super(props);
        const initialModel = this.props.variables;
        const initialMissingVariables = this.props.lookupData.missingVariables;
        const initialAccounts = this.props.lookupData.accounts;

        this.state = {
            projectIds: [],
            model: initialModel,
            cleanModel: cloneDeep(initialModel),
            missingVariables: initialMissingVariables,
            accounts: initialAccounts,
        };
    }

    handleSaveClick = async () => {
        const tenant = this.props.lookupData.tenant;
        await this.doBusyTask(async () => {
            const variables = await repository.Tenants.setVariables(tenant, this.state.model);
            const missingVariables = await repository.Tenants.missingVariables({ tenantId: tenant.Id }, true);
            notifyTenantVariablesFetched(this.props.lookupData.tenant.Id, missingVariables, this.props.onTenantVariablesFetched);

            this.setState({
                model: variables,
                cleanModel: cloneDeep(variables),
                missingVariables,
            });
        });
    };

    projectVariableTitle = (projectVariables: TenantProjectVariable, missingVariables: { [p: string]: string[] }, linkedEnvironments: string[]) => {
        return (
            <div className={styles.cardTitle}>
                <div className={styles.projectLogoContainer}>
                    <Logo url={projectVariables.Links.Logo} size="2rem" />
                </div>
                <div className={styles.projectName}>
                    <div className={styles.projectNameAndWarningIconContainer}>
                        <ToolTip content={"Project: " + projectVariables.ProjectName}>
                            <CardTitle>{projectVariables.ProjectName}</CardTitle>
                        </ToolTip>
                        {missingVariables && (
                            <div className={styles.projectCardWarning}>
                                <MissingVariablesIcon show={!!missingVariables} />
                            </div>
                        )}
                    </div>
                    <div className={cn(styles.helpText, "visible-md")}>
                        {linkedEnvironments.length} linked environment{linkedEnvironments.length === 1 ? "" : "s"}
                    </div>
                </div>
                <div style={{ flex: "1 1 20rem" }} className={cn(styles.helpText, "hide-md")}>
                    <LinkedEnvironmentSummary linkedEnvironments={linkedEnvironments} environments={this.props.lookupData.environments} />
                </div>
                <OverflowMenu menuItems={[OverflowMenuItems.navItem("Edit Project Template", routeLinks.project(projectVariables.ProjectId).variables.projectTemplates, undefined, { permission: Permission.VariableView, wildcard: true })]} />
            </div>
        );
    };

    renderAllProjectVariables = (missingVariableIndex: MissingVariablesIndex) => {
        const projectVariables = this.state.model.ProjectVariables;

        const projects: TenantProjectVariable[] = sortBy(
            Object.keys(projectVariables).map(projectId => projectVariables[projectId]),
            project => project.ProjectName
        );

        if (projects.length === 0) {
            return (
                <div>
                    <SectionNote>
                        Project variables allow tenants to have different values for each connected projects linked environments. Learn more about <ExternalLink href="MultiTenantVariables">tenant-specific variables</ExternalLink>.
                    </SectionNote>
                    <NoResults />
                </div>
            );
        }

        return (
            <div>
                {Object.keys(missingVariableIndex.projects).length !== 0 && this.renderMissingVariablesMessage()}
                <SectionNote>
                    Project variables allow tenants to have different values for each connected projects linked environments. Learn more about <ExternalLink href="MultiTenantVariables">tenant-specific variables</ExternalLink>.
                </SectionNote>
                <div style={{ width: "15.6rem", paddingLeft: "1rem" }}>
                    <ProjectMultiSelect
                        items={Object.keys(projectVariables).map(p => {
                            const projectVariable = projectVariables[p];
                            return ({
                                Name: projectVariable.ProjectName,
                                Id: projectVariable.ProjectId,
                            } as any) as ProjectResource;
                        })}
                        value={this.state.projectIds}
                        label="Filter by project"
                        onChange={projectIds => this.setState(prevState => ({ ...prevState, projectIds }))}
                    />
                </div>
                <ExpansionButtons />
                {projects.filter(p => this.state.projectIds.length === 0 || this.state.projectIds.indexOf(p.ProjectId) !== -1).map(v => this.renderProjectVariable(v, missingVariableIndex))}
            </div>
        );
    };

    renderMissingVariablesMessage() {
        return (
            <Callout type={CalloutType.Warning} title="Provide missing variable values">
                If required variable values are not set it could cause the deployment to fail.
            </Callout>
        );
    }

    renderAllLibraryVariables = (missingVariableIndex: MissingVariablesIndex) => {
        const variables = sortBy(
            Object.keys(this.state.model.LibraryVariables).map(libraryVariableSetId => this.state.model.LibraryVariables[libraryVariableSetId]),
            libraryVariableSet => libraryVariableSet.LibraryVariableSetName
        ).filter(libraryVariable => libraryVariable.Templates.length > 0);

        if (variables.length === 0) {
            return (
                <div>
                    <SectionNote>
                        Common variables are values that remain constant across connected projects and linked environments for this tenant eg. tenant alias and contact details. Learn more about{" "}
                        <ExternalLink href="MultiTenantVariables">tenant-specific variables</ExternalLink>.
                    </SectionNote>
                    <NoResults />
                </div>
            );
        }

        return (
            <div>
                {Object.keys(missingVariableIndex.libraries).length !== 0 && this.renderMissingVariablesMessage()}
                <SectionNote>
                    Common variables are values that remain constant across connected projects and linked environments for this tenant eg. tenant alias and contact details. Learn more about{" "}
                    <ExternalLink href="MultiTenantVariables">tenant-specific variables</ExternalLink>.
                </SectionNote>
                <ExpansionButtons />
                {variables.map(v => this.renderLibraryVariables(v, missingVariableIndex))}
            </div>
        );
    };

    renderLibraryVariables = (libraryVariableSet: TenantLibraryVariable, missingVariableIndex: MissingVariablesIndex) => {
        const missingVariables = missingVariableIndex.libraries[libraryVariableSet.LibraryVariableSetId];
        const libraryVariables = libraryVariableSet.Templates.map(t => t.Name);

        return (
            <SimpleExpander title={libraryVariableTitle(missingVariables, libraryVariableSet)} errorKey={libraryVariableSet.LibraryVariableSetId} key={libraryVariableSet.LibraryVariableSetId}>
                <div>{libraryVariableSet.Templates.map(template => this.renderLibraryVariable(template, libraryVariableSet.LibraryVariableSetId, libraryVariables, missingVariableIndex))}</div>
            </SimpleExpander>
        );
    };

    renderProjectVariable = (projectVariables: TenantProjectVariable, missingVariableIndex: MissingVariablesIndex) => {
        const linkedEnvironments = this.props.lookupData.tenant.ProjectEnvironments[projectVariables.ProjectId];
        const missingVariables = missingVariableIndex.projects[projectVariables.ProjectId];
        const tenantId = this.props.lookupData.tenant.Id;

        return (
            <SimpleExpander title={this.projectVariableTitle(projectVariables, missingVariables, linkedEnvironments)} errorKey={projectVariables.ProjectId} key={projectVariables.ProjectId}>
                <div>
                    {projectVariables.Templates.length === 0 ? (
                        <SectionNote>There are no variable templates for this project.</SectionNote>
                    ) : linkedEnvironments.length === 0 ? (
                        <Callout title="No environments have been selected for this project" type={CalloutType.Warning}>
                            To add environments to this project go to the <InternalLink to={routeLinks.tenant(tenantId).overview}>overview page</InternalLink>.
                        </Callout>
                    ) : (
                        linkedEnvironments.map(environmentId => this.renderProjectEnvironmentVariable(projectVariables, environmentId, missingVariableIndex))
                    )}
                </div>
            </SimpleExpander>
        );
    };

    renderProjectEnvironmentVariable = (projectVariables: TenantProjectVariable, environmentId: string, missingVariableIndex: MissingVariablesIndex) => {
        const variableTemplates = Object.keys(projectVariables.Templates).map(templateId => (projectVariables.Templates as any)[templateId]) as ActionTemplateParameterResource[];

        const templateNames = uniq(variableTemplates.map(p => p.Name));

        // check we have an environment, `state.environments` may not contain it due to the users permissions
        return (
            <Lookup
                lookupCollection={this.props.lookupData.environments}
                lookupId={environmentId}
                getIdFromElement={(element: EnvironmentResource) => element.Id}
                render={(item: EnvironmentResource) => (
                    <SimpleExpander
                        title={
                            <div className={styles.cardTitle}>
                                <span className={styles.projectEnvironmentVariableTitle}>{`${item.Name} variables`}</span>
                            </div>
                        }
                        errorKey={item.Id}
                        key={item.Id}
                        isExpandedByDefault={true}
                    >
                        <div>{variableTemplates.map(template => this.renderVariable(template, projectVariables.ProjectId, environmentId, templateNames, missingVariableIndex))}</div>
                    </SimpleExpander>
                )}
            />
        );
    };

    renderLibraryVariable = (variableTemplate: ActionTemplateParameterResource, libraryVariableSetId: string, otherBindableVariables: string[], missingVariableIndex: MissingVariablesIndex) => {
        const missingVariables = (missingVariableIndex.libraries[libraryVariableSetId] || []).indexOf(variableTemplate.Id) !== -1;

        return (
            <div className={styles.variableContainer} key={variableTemplate.Id}>
                <ActionTemplateParameterInputFormElement
                    parameter={variableTemplate}
                    sourceItems={{
                        accounts: {
                            items: this.state.accounts,
                            type: [AccountType.AzureServicePrincipal, AccountType.AzureSubscription],
                            onRequestRefresh: this.refreshAccounts,
                        },
                        certificates: {
                            items: this.getCertificates,
                            onRequestRefresh: this.refreshCertificates,
                        },
                        tenantId: this.props.lookupData.tenant.Id,
                    }}
                    localNames={otherBindableVariables}
                    doBusyTask={this.doBusyTask}
                    value={this.state.model.LibraryVariables[libraryVariableSetId].Variables[variableTemplate.Id]}
                    warning={missingVariables ? "Value required for deployment" : undefined}
                    onChange={newVal => this.handleLibraryVariableChanged(libraryVariableSetId, variableTemplate.Id, newVal)}
                />
            </div>
        );
    };

    handleLibraryVariableChanged = (libraryVariableSetId: string, displayPropsId: string, value: any) => {
        this.setState(state => ({
            model: {
                ...state.model,
                LibraryVariables: {
                    ...state.model.LibraryVariables,
                    [libraryVariableSetId]: {
                        ...state.model.LibraryVariables[libraryVariableSetId],
                        Variables: {
                            ...state.model.LibraryVariables[libraryVariableSetId].Variables,
                            [displayPropsId]: value,
                        },
                    },
                },
            },
        }));
    };

    renderVariable = (variableTemplate: ActionTemplateParameterResource, projectId: string, environmentId: string, otherBindableVariables: string[], missingVariableIndex: MissingVariablesIndex) => {
        const missingVariables = ((missingVariableIndex.projects[projectId] || {})[environmentId] || []).indexOf(variableTemplate.Id) !== -1;

        return (
            <div className={styles.variableContainer} key={variableTemplate.Id}>
                <ActionTemplateParameterInputFormElement
                    parameter={variableTemplate}
                    sourceItems={{
                        accounts: {
                            items: this.state.accounts,
                            type: [AccountType.AzureServicePrincipal, AccountType.AzureSubscription],
                            onRequestRefresh: this.refreshAccounts,
                        },
                        certificates: {
                            items: this.getCertificates,
                            onRequestRefresh: this.refreshCertificates,
                        },
                        tenantId: this.props.lookupData.tenant.Id,
                    }}
                    localNames={otherBindableVariables}
                    doBusyTask={this.doBusyTask}
                    value={this.state.model.ProjectVariables[projectId].Variables[environmentId][variableTemplate.Id]}
                    warning={missingVariables ? "Value required for deployment" : undefined}
                    onChange={newVal => this.handleProjectVariableChanged(projectId, environmentId, variableTemplate.Id, newVal)}
                />
            </div>
        );
    };

    handleProjectVariableChanged = (projectId: string, environmentId: string, displayPropsId: string, value: any) => {
        this.setState(state => ({
            model: {
                ...state.model,
                ProjectVariables: {
                    ...state.model.ProjectVariables,
                    [projectId]: {
                        ...state.model.ProjectVariables[projectId],
                        Variables: {
                            ...state.model.ProjectVariables[projectId].Variables,
                            [environmentId]: {
                                ...state.model.ProjectVariables[projectId].Variables[environmentId],
                                [displayPropsId]: value,
                            },
                        },
                    },
                },
            },
        }));
    };

    render() {
        const { tenant } = this.props.lookupData;
        const missingVariableIndex = this.buildMissingVariableIndex(tenant.Id, this.state.missingVariables);

        return (
            <FormPaperLayout
                hideExpandAll={true}
                title="Tenant Variables"
                saveText="Tenant details updated"
                model={this.state.model}
                busy={this.state.busy}
                errors={this.state.errors}
                cleanModel={this.state.cleanModel}
                savePermission={{ permission: Permission.VariableEdit, tenant: this.props.lookupData.tenant.Id, wildcard: true }}
                onSaveClick={this.handleSaveClick}
            >
                {tenant && (
                    <UrlNavigationTabsContainer defaultValue={VariablesTab.ProjectVariables}>
                        {this.props.lookupData.hasLibraryVariableSetView && (
                            <TabItem label="Common Variables" warning={Object.keys(missingVariableIndex.libraries).length > 0 ? `Some of the required variable values have not been set` : undefined} value={VariablesTab.CommonVariables}>
                                {this.renderAllLibraryVariables(missingVariableIndex)}
                            </TabItem>
                        )}{" "}
                        <TabItem label={"Project Variables"} warning={Object.keys(missingVariableIndex.projects).length > 0 ? `Some of the required variable values have not been set` : undefined} value={VariablesTab.ProjectVariables}>
                            {this.renderAllProjectVariables(missingVariableIndex)}
                        </TabItem>
                    </UrlNavigationTabsContainer>
                )}
            </FormPaperLayout>
        );
    }

    private refreshAccounts = () => {
        return this.doBusyTask(async () => {
            this.setState({ accounts: await repository.Accounts.all() });
        });
    };

    private getCertificates = async () => {
        let certificates = this.state.certificates;
        if (!certificates) {
            certificates = await this.memoizedRepositoryCertificatesList();
            this.setState({ certificates });
        }

        return certificates;
    };

    private refreshCertificates = () => {
        return this.doBusyTask(async () => {
            this.setState({ certificates: await repository.Certificates.listForTenant(this.props.lookupData.tenant.Id) });
        });
    };
}
export default TenantVariablesPage;
