// tslint:disable: no-any

import * as React from "react";
import { Switch, RouteComponentProps, withRouter } from "react-router-dom";
import { ChannelsRoute } from "../Channels";
import StepsRoute from "../DeploymentProcess/DeploymentProcessRoute";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import { DeploymentsOverview } from "../DeploymentsOverview";
import { ReleasesRoute } from "../Releases";
import { ActionScope } from "components/Actions/pluginRegistry";
import { deploymentLinks } from "./deploymentRouteLinks";

type DeploymentsRouteProps = { path: string } & RouteComponentProps<any>;

export class DeploymentsRoute extends React.Component<DeploymentsRouteProps, any> {
    render() {
        const links = deploymentLinks(this.props.path);
        const match = this.props.match;

        return (
            <ReloadableRoute path={links.root}>
                <Switch>
                    <StepsRoute path={`${match.path}/process`} scope={ActionScope.Deployments} />
                    <ReloadableRoute path={`${match.path}/channels`} component={ChannelsRoute} />
                    <ReloadableRoute path={`${match.path}/releases`} component={ReleasesRoute} />
                    <ReloadableRoute path={`${match.path}`} component={DeploymentsOverview} />
                </Switch>
            </ReloadableRoute>
        );
    }
}

const EnhancedDeploymentsRoute = withRouter(DeploymentsRoute);

export default EnhancedDeploymentsRoute;
