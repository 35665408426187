// tslint:disable: no-any

import * as React from "react";
const StickyNode = require("react-stickynode");

// This is a monkey patch to fix this issue: https://github.com/yahoo/react-stickynode/issues/46
// We should go back and submit a PR to that repo when we have more time
// This is required for the variable editor headers
StickyNode.prototype.getTargetHeight = (target: any) => {
    return (target && target.offsetHeight + target.getBoundingClientRect().top) || 0;
};

// We have the following as a hack for IE11 which needs the extra help to calculate the initial position,
// this isn't perfect and there still remains some jankyness for IE11 however, constantly recalculating
// using setTimeout to update the position has proven to be very expensive and can result in some pretty
// bad performance issues as things keep getting scheduled.
const originalUpdateInitialDimension = StickyNode.prototype.updateInitialDimension;
function updateInitialDimension(this: any, options: any) {
    originalUpdateInitialDimension.call(this, options);
    let position: any = null;

    window.setTimeout(() => positionCheckingUpdateInitialDimension.call(this), 0);

    function positionCheckingUpdateInitialDimension(this: any) {
        const oldPosition = position;
        const stickyElement = this.refs.inner;
        const outerElement = this.refs.outer;
        if (stickyElement || outerElement) {
            position = stickyElement.getBoundingClientRect();
            if (
                oldPosition === null ||
                oldPosition.bottom !== position.bottom ||
                oldPosition.top !== position.top ||
                oldPosition.left !== position.left ||
                oldPosition.right !== position.right ||
                oldPosition.height !== position.height ||
                oldPosition.width !== position.width
            ) {
                originalUpdateInitialDimension.call(this, options);
                this.update();
            }
        }
    }
}

StickyNode.prototype.updateInitialDimension = updateInitialDimension;

export enum StickyStatus {
    STATUS_ORIGINAL = 0,
    STATUS_RELEASED = 1,
    STATUS_FIXED = 2,
}

interface StickyState {
    status: StickyStatus;
}

interface StickyProps {
    top?: string | number;
    bottomBoundary?: string | number;
    innerZ?: number;
    onStateChange?(state: StickyState): void;
}

const Sticky: React.SFC<StickyProps> = (props: StickyProps) => {
    return <StickyNode {...props} />;
};

export default Sticky;
