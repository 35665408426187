// tslint:disable: no-non-null-assertion
import * as React from "react";
import RadioButtonImpl from "material-ui/RadioButton";
import { withTheme } from "components/Theme";
const styles = require("./style.less");
import { ThirdPartyIcon, ThirdPartyIconType } from "components/Icon";

interface RadioButtonProps {
    className?: string;
    // tslint:disable-next-line: no-any
    value?: any;
    label?: React.ReactNode;
    isDefault?: boolean;
    disabled?: boolean;
    autoFocus?: boolean;
}

interface DefaultRadioButtonProps extends RadioButtonProps {
    checked: boolean;
}

export class RadioButton extends React.Component<RadioButtonProps & DefaultRadioButtonProps, {}> {
    static defaultProps: DefaultRadioButtonProps = {
        checked: false,
        isDefault: false,
    };
    radioButtonImpl: RadioButtonImpl | null;

    componentDidMount() {
        if (this.props.autoFocus) {
            // this digs into the RadioButton implementation to set focus
            // tslint:disable-next-line: no-any
            const internalSwitch = this.radioButtonImpl!.refs.enhancedSwitch as any;
            internalSwitch.refs.checkbox.focus();
            internalSwitch.tabPressed = true;
            internalSwitch.handleFocus();
        }
    }

    render() {
        return withTheme(theme => {
            const { isDefault, label, disabled, ...otherProps } = this.props;
            const newlabel = isDefault ? <span className={styles.isDefault}>{label}</span> : label;

            return (
                <RadioButtonImpl
                    disabled={disabled}
                    ref={ref => (this.radioButtonImpl = ref)}
                    label={newlabel}
                    labelStyle={this.props.disabled ? { color: theme.secondaryText } : { color: theme.primaryText }}
                    checkedIcon={<ThirdPartyIcon iconType={ThirdPartyIconType.RadioButtonChecked} style={{ fill: theme.focus }} />}
                    iconStyle={{ marginRight: "0.5rem", fill: theme.primaryText }}
                    aria-checked={this.props.checked}
                    {...otherProps}
                />
            );
        });
    }
}

export default RadioButton;
