// tslint:disable: no-any

import BasicRepository from "./basicRepository";
import Client from "../client";

class RetentionPolicyRepository extends BasicRepository<any, any> {
    constructor(client: Client) {
        super("RetentionPolicies", client);
    }
}

export default RetentionPolicyRepository;
