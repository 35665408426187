// tslint:disable: no-non-null-assertion
// tslint:disable: no-any

import * as React from "react";
import SortableList from "components/SortableList/SortableList";
import { repository } from "clientInstance";
import { IProcessResource } from "client/resources/deploymentProcessResource";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { noOp } from "utils/noOp";
import { ActionScope } from "components/Actions/pluginRegistry";

type StepSorterActionsProps = {
    getProcess: (id: string) => Promise<IProcessResource>;
    modifySteps: (steps: IProcessResource) => Promise<IProcessResource>;
};

interface StepSorterProps {
    title: string;
    processId: string;
    stepId?: string;
    saveDone?(): void;
    onStepsUpdated(steps: IProcessResource): void;
}

interface StepSorterState extends DataBaseComponentState {
    steps: IProcessResource;
}

class StepSorter extends DataBaseComponent<StepSorterProps & StepSorterActionsProps, StepSorterState> {
    constructor(props: StepSorterProps & StepSorterActionsProps) {
        super(props);

        this.state = {
            steps: null!,
        };
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const steps = await this.props.getProcess(this.props.processId);
            this.setState({ steps });
        });
    }

    async save() {
        return this.doBusyTask(async () => {
            const result = await this.props.modifySteps(this.state.steps);
            this.props.onStepsUpdated(result);
            this.props.saveDone ? this.props.saveDone() : noOp();
        });
    }

    render() {
        const steps = this.state.steps;

        const body = steps && <SortableList onOrderChanged={this.props.stepId ? this.actionsOrderChanged : this.stepsOrderChanged} items={this.props.stepId ? this.getStep(steps.Steps, this.props.stepId).Actions : steps.Steps} />;

        return (
            <SaveDialogLayout title={this.props.title} busy={this.state.busy} errors={this.state.errors} onSaveClick={() => this.save()}>
                {body}
            </SaveDialogLayout>
        );
    }

    private getStep(steps: any, stepId: string) {
        return steps.filter((s: any) => s.Id === stepId)[0];
    }

    private stepsOrderChanged = (Steps: any[]) => {
        this.setChildState1("steps", { Steps });
    };

    private actionsOrderChanged = (actions: any[]) => {
        this.setState(state => {
            const steps = [...state.steps.Steps];
            const index = steps.findIndex(s => s.Id === this.props.stepId);
            steps[index] = { ...steps[index], Actions: actions };
            return {
                steps: {
                    ...state.steps,
                    Steps: steps,
                },
            };
        });
    };
}

type RunbookStepSorterProps = StepSorterProps;
export const RunbookStepSorter: React.FC<RunbookStepSorterProps> = props => {
    return <StepSorter {...props} modifySteps={repository.RunbookProcess.modify.bind(repository.RunbookProcess)} getProcess={repository.RunbookProcess.get.bind(repository.RunbookProcess)} />;
};

type DeploymentProcessStepSorterProps = StepSorterProps;
export const DeploymentProcessStepSorter: React.FC<DeploymentProcessStepSorterProps> = props => {
    return <StepSorter {...props} modifySteps={repository.DeploymentProcesses.modify.bind(repository.DeploymentProcesses)} getProcess={repository.DeploymentProcesses.get.bind(repository.DeploymentProcesses)} />;
};

type ScopedStepSorterProps = StepSorterProps & { scope: ActionScope };
export const ScopedStepSorter: React.FC<ScopedStepSorterProps> = ({ scope, ...rest }) => {
    const Sorter = scope === ActionScope.Deployments ? DeploymentProcessStepSorter : RunbookStepSorter;
    return <Sorter {...rest} />;
};

export default ScopedStepSorter;
