// tslint:disable: no-non-null-assertion
import { WorkerPoolType } from "client/resources/workerPoolsSupportedTypesResouce";
import { WorkerPoolResource, Permission } from "client/resources";
import { FormBaseComponentState, FormBaseComponent } from "components/FormBaseComponent/FormBaseComponent";
import Markdown from "components/Markdown";
import React = require("react");
import StringHelper from "utils/StringHelper";
import { OverflowMenuItems } from "components/Menu";
import routeLinks from "routeLinks";
import InfrastructureLayout from "../../InfrastructureLayout";
import FormPaperLayout from "components/FormPaperLayout";
import InternalRedirect from "components/Navigation/InternalRedirect";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import { Text, ExpandableFormSection, Summary, required, Checkbox, MarkdownEditor } from "components/form";
import { repository } from "clientInstance";
import { PermissionCheck } from "components/PermissionCheck";
import { NavigationButton } from "components/Button";
import { cloneDeep } from "lodash";

interface WorkerPoolEditProps<TWorkerPoolResource extends WorkerPoolResource> {
    create?: boolean;
    workerPool: TWorkerPoolResource;
}

interface WorkerPoolEditState<TModel extends WorkerPoolResource> extends FormBaseComponentState<TModel> {
    deleted: boolean;
    newId: string;
}

abstract class WorkerPoolEditBase<TWorkerPoolResource extends WorkerPoolResource, TState extends WorkerPoolEditState<TWorkerPoolResource>, TProps extends WorkerPoolEditProps<TWorkerPoolResource>> extends FormBaseComponent<
    WorkerPoolEditProps<TWorkerPoolResource>,
    TState,
    TWorkerPoolResource
> {
    abstract customExpandableFormSections(): JSX.Element[];

    descriptionSummary() {
        return this.state.model && this.state.model.Description ? Summary.summary(<Markdown markup={this.state.model.Description} />) : Summary.placeholder("No description provided");
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            this.setState({
                model: this.props.workerPool,
                cleanModel: cloneDeep(this.props.workerPool),
            });
        });
    }

    render() {
        const title = this.props.create ? "Create worker pool" : this.state.model ? this.state.model.Name : StringHelper.ellipsis;

        const overFlowActions = [];
        if (!this.props.create && !!this.state.model) {
            overFlowActions.push(
                OverflowMenuItems.deleteItem(
                    "Delete",
                    "Are you sure you want to delete this worker pool?",
                    this.handleDeleteConfirm,
                    <div>
                        <p>Deleting this worker pool is permanent, there is no going back.</p>
                        <p>Do you wish to continue?</p>
                    </div>,
                    { permission: Permission.WorkerEdit }
                ),
                [
                    OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([this.state.model.Id]), null!, {
                        permission: Permission.EventView,
                        wildcard: true,
                    }),
                ]
            );
        }

        const saveText: string = this.state.newId ? "Worker pool created" : "Worker pool details updated";

        return (
            <InfrastructureLayout {...this.props}>
                <FormPaperLayout
                    title={title}
                    breadcrumbTitle={"Worker pools"}
                    breadcrumbPath={routeLinks.infrastructure.workerPools.root}
                    busy={this.state.busy}
                    errors={this.state.errors}
                    model={this.state.model}
                    cleanModel={this.state.cleanModel}
                    savePermission={{ permission: Permission.WorkerEdit }}
                    onSaveClick={this.handleSaveClick}
                    saveText={saveText}
                    expandAllOnMount={this.props.create}
                    overFlowActions={overFlowActions}
                    secondaryAction={this.addWorkerButton()}
                >
                    {this.state.deleted && <InternalRedirect to={routeLinks.infrastructure.workerPools.root} />}
                    {this.state.newId && <InternalRedirect to={routeLinks.infrastructure.workerPool(this.state.newId)} />}
                    {this.state.model && (
                        <TransitionAnimation>
                            <ExpandableFormSection
                                errorKey="Name"
                                title="Name"
                                focusOnExpandAll
                                summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your worker pool")}
                                help="A short, memorable, unique name for this worker pool. Example: Development."
                            >
                                <Text value={this.state.model.Name} onChange={Name => this.setModelState({ Name })} label="Name" validate={required("Please enter a worker pool name")} autoFocus={true} />
                            </ExpandableFormSection>

                            <ExpandableFormSection
                                errorKey="IsDefault"
                                title="Default Worker Pool"
                                summary={this.state.model.IsDefault ? Summary.summary("Yes") : Summary.default("No")}
                                help={"Set this worker pool to be used as the default worker pool"}
                            >
                                <Checkbox value={this.state.model.IsDefault} onChange={IsDefault => this.setModelState({ IsDefault })} label="Default" />
                            </ExpandableFormSection>

                            <ExpandableFormSection errorKey="Description" title="Description" summary={this.descriptionSummary()} help="Enter a description for your worker pool.">
                                <MarkdownEditor value={this.state.model.Description} label="Worker pool description" onChange={Description => this.setModelState({ Description })} />
                            </ExpandableFormSection>

                            {this.customExpandableFormSections()}
                        </TransitionAnimation>
                    )}
                </FormPaperLayout>
            </InfrastructureLayout>
        );
    }

    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const isNew = this.state.model.Id == null;
            const result = (await repository.WorkerPools.save(this.state.model)) as TWorkerPoolResource;
            this.setState({
                model: result,
                cleanModel: cloneDeep(result),
                newId: isNew ? result.Id : null!,
            });
        });
    };

    private handleDeleteConfirm = async () => {
        const result = await repository.WorkerPools.del(this.state.model);
        this.setState(state => {
            return {
                model: null,
                cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                deleted: true,
            };
        });
        return true;
    };

    private addWorkerButton() {
        return this.state.model && this.state.model.CanAddWorkers ? (
            <PermissionCheck permission={Permission.WorkerEdit}>
                <NavigationButton href={routeLinks.infrastructure.workerMachines.new(this.state.model ? this.state.model.Id : null!)} label="Add worker" />
            </PermissionCheck>
        ) : null;
    }
}

export { WorkerPoolEditProps, WorkerPoolEditState };

export default WorkerPoolEditBase;
