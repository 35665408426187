// tslint:disable: no-non-null-assertion
import { StaticWorkerPoolResource } from "client/resources";
import WorkerPoolEditBase, { WorkerPoolEditProps, WorkerPoolEditState } from "./WorkerPoolEditBase";

interface StaticWorkerPoolEditProps extends WorkerPoolEditProps<StaticWorkerPoolResource> {}

interface StaticWorkerPoolEditState extends WorkerPoolEditState<StaticWorkerPoolResource> {}

class StaticWorkerPoolEdit extends WorkerPoolEditBase<StaticWorkerPoolResource, StaticWorkerPoolEditState, StaticWorkerPoolEditProps> {
    constructor(props: StaticWorkerPoolEditProps) {
        super(props);
        this.state = {
            model: null!,
            cleanModel: null!,
            deleted: false,
            newId: null!,
        };
    }

    customExpandableFormSections(): JSX.Element[] {
        return [];
    }
}

export default StaticWorkerPoolEdit;
