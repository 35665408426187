// tslint:disable: no-non-null-assertion
// tslint:disable: no-any

import * as React from "react";
import BaseComponent from "components/BaseComponent";
import Logo from "components/Logo";
import InternalLink from "components/Navigation/InternalLink";
import cn from "classnames";
import { OverflowMenu } from "components/Menu";
import ToolTip from "components/ToolTip";
import { LocationDescriptor } from "history";
import { ThirdPartyIcon, ThirdPartyIconType } from "components/Icon";
import { CommonDeploymentPartProps } from "./DeploymentPart";
import { ToolTipPosition } from "../../../../components/ToolTip";
import MediaQuery from "react-responsive";

const styles = require("./DeploymentPartForSidebar.less");

interface DeploymentPartForSidebarProps extends CommonDeploymentPartProps {
    className?: string;
    isChildAction?: boolean;
    isCurrentAction?: boolean;
    disableActionsDueToGroupEditing?: boolean;
}

export const toolTipBreakpoint = "1279px";

class DeploymentPartForSidebar extends BaseComponent<DeploymentPartForSidebarProps, any> {
    render() {
        const disableOverflowMenu = this.props.isPlaceholder || this.props.isCurrentAction || this.props.disableActionsDueToGroupEditing;

        return withStepLink(
            <>
                {this.props.isRunInParallelWithLast && (
                    <MediaQuery minWidth={toolTipBreakpoint}>
                        {(matches: boolean) => {
                            const toolTipPosition = matches ? ToolTipPosition.Left : undefined;
                            return (
                                <div className={styles.stepWrapper}>
                                    <ToolTip content="Runs in parallel" position={toolTipPosition}>
                                        <div className={styles.parallelContainer}>
                                            <div className={styles.parallelIcon}>
                                                <div className={styles.content}>&nbsp;</div>
                                            </div>
                                        </div>
                                    </ToolTip>
                                    {this.renderLogoOrIcon(toolTipPosition!)}
                                </div>
                            );
                        }}
                    </MediaQuery>
                )}
                {!this.props.isRunInParallelWithLast && (
                    <MediaQuery minWidth={toolTipBreakpoint}>
                        {(matches: boolean) => {
                            const toolTipPosition = matches ? ToolTipPosition.Left : undefined;
                            return this.renderLogoOrIcon(toolTipPosition!);
                        }}
                    </MediaQuery>
                )}
                {withDisabledTooltipWrapper(
                    <div className={styles.stepContainer}>
                        <div className={styles.stepNumber}>{this.props.index}</div>
                        <div className={styles.stepName}>{this.props.name}</div>
                    </div>,
                    this.props.isDisabled!
                )}
                {disableOverflowMenu ? (
                    <ToolTip
                        content={
                            this.props.disableActionsDueToGroupEditing ? (
                                <span>
                                    Menu options unavailable while
                                    <br />
                                    viewing a parent or its child steps.
                                </span>
                            ) : (
                                <span>
                                    Menu options unavailable while
                                    <br />
                                    viewing a step.
                                </span>
                            )
                        }
                    >
                        <div className={styles.contextualActionsDisabledFakeMenuIcon}>
                            <ThirdPartyIcon iconType={ThirdPartyIconType.MoreVert} focusable={"false"} />
                        </div>
                    </ToolTip>
                ) : (
                    <div className={styles.contextualActions}>
                        <OverflowMenu key={`${this.props.name}-overflow`} menuKey={`${this.props.name}-overflow`} menuItems={this.props.menuItems!} className={styles.overflowMenuButton} />
                    </div>
                )}
            </>,
            this.props.isPlaceholder!,
            this.props.isDisabled!,
            this.props.isCurrentAction!,
            this.props.detailsUrl!,
            this.props.isParentGroup!
        );
    }

    private renderLogoOrIcon(toolTipPosition: ToolTipPosition) {
        return (
            <ToolTip content={this.props.actionType} position={toolTipPosition}>
                <div className={styles.stepLogo}>{this.props.logoUrl ? <Logo url={this.props.logoUrl} isDisabled={this.props.isDisabled} size={"2rem"} className={styles.logo} /> : this.props.icon}</div>
            </ToolTip>
        );
    }
}

function withDisabledTooltipWrapper(children: React.ReactNode, isDisabled: boolean) {
    if (isDisabled) {
        return (
            <ToolTip content="Disabled" style={{ width: "100%" }}>
                {children}
            </ToolTip>
        );
    }
    return children;
}

function withStepLink(children: React.ReactNode, isPlaceholder: boolean, isDisabled: boolean, isCurrentAction: boolean, detailsUrl: LocationDescriptor, isParentGroup: boolean) {
    return isPlaceholder ? (
        <div className={cn(styles.listItem, isCurrentAction ? styles.isActive : null, isParentGroup ? styles.isParentGroup : null)}>
            <div className={styles.listItemLine} />
            {children}
        </div>
    ) : (
        <InternalLink className={cn(styles.listItem, isCurrentAction ? styles.isActive : null, isDisabled ? styles.isDisabled : null, isParentGroup ? styles.isParentGroup : null)} to={detailsUrl}>
            <div className={styles.listItemLine} />
            {children}
        </InternalLink>
    );
}

export default DeploymentPartForSidebar;
