// tslint:disable: no-non-null-assertion
import * as React from "react";
import { repository } from "clientInstance";
import { LibraryLayout } from "../LibraryLayout/LibraryLayout";
import { Permission } from "client/resources";
import PermissionCheck, { isAllowed } from "components/PermissionCheck/PermissionCheck";
import PaperLayout from "components/PaperLayout";
import { OpenDeleteDialogButton } from "components/Button";
import InternalLink from "components/Navigation/InternalLink";
import { ResourceCollection } from "client/resources";
import PagingDataTable from "components/PagingDataTable";
import ByteSizeFormatter from "utils/ByteSizeFormatter";
import { RouteComponentProps } from "react-router";
import SidebarLayout from "components/SidebarLayout/SidebarLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import routeLinks from "../../../../routeLinks";
const styles = require("./style.less");
import { PackageVersionResource } from "../../../../client/resources/packageResource";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import FeedResource from "client/resources/feedResource";
import ToolTip from "components/ToolTip";
import DateFormatter from "utils/DateFormatter";
import Callout, { CalloutType } from "components/Callout";
import { Text } from "components/form";

class PackageItemDataTable extends PagingDataTable<PackageVersionResource> {}

interface PackageVersionsListState extends DataBaseComponentState {
    packagesResponse?: ResourceCollection<PackageVersionResource>;
    packagesToDelete: string[];
    feed?: FeedResource;
    isSure: boolean;
    confirmationInputValue: string;
}

export class PackageVersionsList extends DataBaseComponent<RouteComponentProps<{ packageId: string }>, PackageVersionsListState> {
    private packageId: string;
    private isFeedAdmin = isAllowed({ permission: Permission.BuiltInFeedAdminister, project: "*" });

    constructor(props: RouteComponentProps<{ packageId: string }>) {
        super(props);
        this.packageId = this.props.match.params.packageId;
        this.state = {
            packagesToDelete: [],
            isSure: false,
            confirmationInputValue: "",
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            await this.load();
        });
    }

    async load() {
        const feed = await repository.Feeds.getBuiltIn();
        const packagesResponse = await repository.Feeds.searchPackageVersions(feed, this.packageId, { take: 30 });
        this.setState({ packagesResponse, feed });
    }

    async delete() {
        await this.doBusyTask(async () => {
            await repository.Packages.deleteMany(this.state.packagesToDelete);
            await this.load();
        });
        return true;
    }

    render() {
        if (this.state.packagesResponse && this.state.packagesResponse.Items.length === 0) {
            return <InternalRedirect to={routeLinks.library.builtInRepository.root} />;
        }

        const sideBar = <p>If any projects depend on these packages, they will not be able to be deployed should you delete required versions from the repository.</p>;

        const table =
            this.state.packagesResponse && this.state.packagesResponse.Items.length > 0 ? (
                <PackageItemDataTable
                    initialData={this.state.packagesResponse}
                    onRow={this.buildRow}
                    apiSearchParams={["filter"]}
                    filterSearchEnabled={true}
                    onFilter={this.filter}
                    headerColumns={["Version", "Published", "Size"]}
                    onEmpty={this.handleOnEmpty}
                    rowColumnClassName={styles.packageVersionCell}
                    onItemsChecked={this.isFeedAdmin ? (packagesToDelete: string[]) => this.setState({ packagesToDelete }) : null!}
                />
            ) : null;

        return (
            <LibraryLayout {...this.props}>
                <PaperLayout title={this.packageId} breadcrumbTitle={"Packages"} breadcrumbPath={routeLinks.library.builtInRepository.root} sectionControl={this.deleteSelectedButton()} busy={this.state.busy} errors={this.state.errors}>
                    <SidebarLayout sideBar={sideBar}>{table}</SidebarLayout>
                </PaperLayout>
            </LibraryLayout>
        );
    }

    private filter(filter: string, resource: PackageVersionResource) {
        return (
            !filter ||
            filter.length === 0 ||
            (resource.PackageId ? resource.PackageId.toLowerCase().includes(filter.toLowerCase()) : false) ||
            (resource.Title ? resource.Title.toLowerCase().includes(filter.toLowerCase()) : false) ||
            (resource.Version ? resource.Version.toLowerCase().includes(filter.toLowerCase()) : false)
        );
    }

    private handleOnEmpty = () => {
        return <div>No packages found</div>;
    };

    private deleteSelectedButton = () => {
        const multipleSelected = this.state.packagesToDelete.length > 1;
        const selectedPackageCount = this.state.packagesToDelete.length;

        const message = `Are you sure you want to delete ${multipleSelected ? `these ${selectedPackageCount}` : "this"} package${multipleSelected ? "s" : ""}?`;
        const isSure = this.state.isSure;
        return (
            <PermissionCheck permission={Permission.BuiltInFeedAdminister} project="*">
                <OpenDeleteDialogButton
                    label="Delete Selected"
                    disabled={!this.state.packagesResponse || selectedPackageCount === 0}
                    disableDeleteButton={!isSure}
                    dialogTitle={message}
                    onDeleteClick={() => this.delete()}
                    dialogOnClose={() => this.setState({ confirmationInputValue: "", isSure: false })}
                >
                    <Callout title="This is a destructive action" type={CalloutType.Danger}>
                        This action <strong>cannot</strong> be undone. This will permanently delete files from disk and break releases relying on {multipleSelected ? "these" : "this"} package{multipleSelected ? "s" : ""}.
                    </Callout>
                    <div>
                        <p>
                            Please type in <strong>Delete</strong> to confirm.
                        </p>
                        <Text value={this.state.confirmationInputValue} onChange={this.confirmationOnChange} />
                    </div>
                </OpenDeleteDialogButton>
            </PermissionCheck>
        );
    };

    private confirmationOnChange = (value: string) => {
        let isSure = false;
        if (value === "Delete") {
            isSure = true;
        }
        this.setState({ confirmationInputValue: value, isSure });
    };

    private buildRow = (pkg: PackageVersionResource) => {
        return [
            <div className={styles.row}>
                <PermissionCheck permission={Permission.BuiltInFeedDownload} project="*">
                    <a href={pkg.Links["Raw"]}>
                        <em className="fa fa-download" />
                    </a>
                    &nbsp;
                </PermissionCheck>
                <InternalLink to={routeLinks.library.builtInRepository.package(encodeURIComponent(pkg.Id))}>{pkg.Version}</InternalLink>
            </div>,
            <ToolTip content={DateFormatter.dateToLongFormat(pkg.Published)!}>{DateFormatter.dateToShortFormat(pkg.Published)}</ToolTip>,
            ByteSizeFormatter(pkg.SizeBytes!),
        ].filter(c => !!c);
    };
}

export default PackageVersionsList;
