// tslint:disable: no-non-null-assertion
import * as React from "react";
import IconButton, { Icon } from "components/IconButton/IconButton";
import { Moment } from "moment";
const styles = require("./style.less");
import { Text } from "components/form";
import { ActionButton, ActionButtonType } from "components/Button";
import ParseHelper from "utils/ParseHelper/ParseHelper";
import DebounceValue from "components/DebounceValue/DebounceValue";
import CancelInvalidUpdate from "areas/projects/components/Releases/Deployments/NowOrLater/TimePicker/CancelInvalidInput";

interface TimePickerProps {
    time: Moment;
    onTimeChanged(time: Moment): void;
}

interface TimePickerState {
    hour: string;
    minute: string;
}

const error = "\t"; // this displays error state in controls but no error text is visible
const valid = "";

const CancelInvalidText = CancelInvalidUpdate(Text);
const TimeText = DebounceValue(CancelInvalidText);

export default class TimePicker extends React.Component<TimePickerProps, TimePickerState> {
    constructor(props: TimePickerProps) {
        super(props);
        this.state = {
            hour: props.time.format("h"),
            minute: props.time.format("mm"),
        };
    }

    componentWillReceiveProps(nextProps: TimePickerProps) {
        this.setState({
            hour: nextProps.time.format("h"),
            minute: nextProps.time.format("mm"),
        });
    }

    render() {
        // Use debouncedText so that if current time is 8 you can type 10 without the intermediate 1 being invalid (too early)
        return (
            <div className={styles.timePicker}>
                <div className={styles.digit}>
                    <IconButton icon={Icon.ArrowUp} onClick={this.incrementHours} tabIndex={-1} />
                    <TimeText label="" type="text" value={this.state.hour} onChange={this.handleHoursChanged} cancelableValidate={this.validateHour} />
                    <IconButton icon={Icon.ArrowDown} onClick={this.decrementHours} tabIndex={-1} />
                </div>
                <div className={styles.digit}>
                    <IconButton icon={Icon.ArrowUp} onClick={this.incrementMinutes} tabIndex={-1} />
                    <TimeText label="" type="text" value={this.state.minute} onChange={this.handleMinutesChanged} cancelableValidate={this.validateMinute} />
                    <IconButton icon={Icon.ArrowDown} onClick={this.decrementMinutes} tabIndex={-1} />
                </div>
                <div>
                    <ActionButton type={ActionButtonType.Ternary} label={this.props.time.format("A")} onClick={this.handleAmPmToggled} />
                </div>
            </div>
        );
    }

    private validateHour = (val: string) => {
        if (val.length > 2) {
            // reject the change, show an error based on the current value since we won't update
            return { error: this.validateHourString(this.state.hour), rejectChange: true };
        }
        return { error: this.validateHourString(val), rejectChange: false };
    };

    private validateMinute = (val: string) => {
        if (val.length > 2) {
            // reject the change, show an error based on the current value since we won't update
            return { error: this.validateMinuteString(this.state.minute), rejectChange: true };
        }
        return { error: this.validateMinuteString(val), rejectChange: false };
    };

    // Note that we intentially don't mess with the date portion of the time
    private incrementHours = () => {
        this.props.onTimeChanged(this.props.time.clone().add(1, "hours"));
    };

    private decrementHours = () => {
        this.props.onTimeChanged(this.props.time.clone().add(-1, "hours"));
    };

    private incrementMinutes = () => {
        this.props.onTimeChanged(this.props.time.clone().add(15, "minutes"));
    };

    private decrementMinutes = () => {
        this.props.onTimeChanged(this.props.time.clone().add(-15, "minutes"));
    };

    private validateHourString = (value: string): string => {
        const hour = ParseHelper.safeParseInt(value, null!);
        return this.isHour(hour) ? valid : error;
    };

    private validateMinuteString = (value: string): string => {
        if (value.length !== 2) {
            return error;
        }
        const minute = ParseHelper.safeParseInt(value, null!);
        return this.isMinute(minute) ? valid : error;
    };

    private handleHoursChanged = (value: string) => {
        this.setState({ hour: value });
        let parsed = ParseHelper.safeParseInt(value, null!);
        if (this.isHour(parsed)) {
            if (this.props.time.format("A") === "PM" && parsed !== 12) {
                parsed = parsed + 12;
            }
            if (this.props.time.format("A") === "AM" && parsed === 12) {
                parsed = 0;
            }
            this.props.onTimeChanged(this.props.time.clone().hours(parsed));
        }
    };

    private handleMinutesChanged = (value: string) => {
        this.setState({ minute: value });
        const parsed = ParseHelper.safeParseInt(value, null!);
        if (this.isMinute(parsed)) {
            this.props.onTimeChanged(this.props.time.clone().minutes(parsed));
        }
    };

    private isHour = (hour?: number): boolean => {
        return hour !== null && hour! > 0 && hour! <= 12;
    };

    private isMinute = (minute?: number): boolean => {
        return minute !== null && minute! >= 0 && minute! <= 59;
    };

    private handleAmPmToggled = () => {
        const hours = this.props.time.hours();
        const toggled = hours >= 12 ? hours - 12 : hours + 12;
        this.props.onTimeChanged(this.props.time.clone().hours(toggled));
    };
}
