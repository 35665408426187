// tslint:disable: no-non-null-assertion
// tslint:disable: no-any

import { ProjectRouteParams } from "areas/projects/components/ProjectLayout/ProjectLayout";
import ActionToggle from "areas/projects/components/Releases/Deployments/ActionToggle";
import EnvironmentAndTenantSelector from "areas/projects/components/Releases/Deployments/EnvironmentAndTenantSelector/EnvironmentAndTenantSelector";
import PackageDeploymentOptions from "areas/projects/components/Releases/Deployments/PackageDeploymentOptions";
import PendingInterruptions from "areas/projects/components/Releases/Deployments/PendingInterruptions";
import {
    ChannelResource,
    CreateDeploymentResource,
    DashboardResource,
    DeploymentPreviewResource,
    DeploymentPromotionTarget,
    DeploymentPromotionTenant,
    DeploymentResource,
    DeploymentTemplateResource,
    EnvironmentResource,
    GuidedFailureMode,
    IExecutionResource,
    OctopusError,
    Permission,
    ProjectResource,
    ReleaseResource,
    TaskResource,
    TenantedDeploymentMode,
    TenantResource,
} from "client/resources";
import Form, { FormElement } from "client/resources/form";
import { repository } from "clientInstance";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import { Callout, CalloutType } from "components/Callout/Callout";
import { createErrorsFromOctopusError, Errors } from "components/DataBaseComponent/Errors";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent/FormBaseComponent";
import matchErrorsToFieldNames from "components/FormBaseComponent/matchErrorsToFieldNames";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import ExternalLink from "components/Navigation/ExternalLink";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import InternalRedirect from "components/Navigation/InternalRedirect/InternalRedirect";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import * as _ from "lodash";
import { Dictionary } from "lodash";
import { Moment } from "moment";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import routeLinks from "routeLinks";
import RequestRaceConditioner from "utils/RequestRaceConditioner";
import { DeploymentModelType } from "../../Runbooks/RunbookRunNowLayout";
import { DeploymentCreateGoal } from "../ReleasesRoutes/releaseRouteLinks";
import CurrentVersionMap from "./currentVersionMap";
import { DeploymentRequestModel } from "./deploymentRequestModel";
import FailureMode from "./FailureMode";
import { default as NowOrLater, NowOrLaterEnum } from "./NowOrLater/NowOrLater";
import PackageDownloadOptions from "./PackageDownloadOptions";
import { loadPendingInterruptions } from "./pendingInterruptionUtil";
import DeploymentPreview, { DeploymentMachineInfo, DeploymentType } from "./Preview";
import PromptVariables from "./PromptVariables";
import { WithProjectContextInjectedProps, withProjectContext } from "areas/projects/context/withProjectContext";

type DeploymentCreateRouteParams = {
    previousId?: string;
    goal?: DeploymentCreateGoal;
    releaseVersion?: string;
    tenantIds?: string;
    tags?: string;
} & ProjectRouteParams;

type DeploymentCreateProps = RouteComponentProps<DeploymentCreateRouteParams> & WithProjectContextInjectedProps;

export type PromotionsMap = { [id: string]: DeploymentPromotionTarget | DeploymentPromotionTenant };

interface AvailableDeploymentsApiResults {
    allowDeployment: boolean;
    previews: Map<string, DeploymentPreviewResource>;
    promptVariablesForm: Form;
    deployments: DeploymentRequestModel[];
    pendingInterruptions: Array<TaskResource<any>> | null;
}

// tslint:disable-next-line:no-empty-interface
interface DeploymentModel {}

interface DeploymentCreateState extends OptionalFormBaseComponentState<DeploymentModel> {
    previousDeployment: DeploymentResource;
    nowOrLater: NowOrLaterEnum;
    forcePackageDownload: boolean;
    forcePackageRedeployment: boolean;
    guidedFailureMode: GuidedFailureMode;
    actionIdsToSkip: string[];
    deployments: DeploymentRequestModel[];
    queueTime: Moment;
    queueTimeExpiry: Moment;
    selectedEnvironmentIds: string[];
    selectedTenantIds: string[];
    redirectPath?: string;
    promptVariablesForm: Form;
    promotionsMap: PromotionsMap;
    template: DeploymentTemplateResource;
    missingTenantVariables: Dictionary<string[]>;
    allEnvironments: EnvironmentResource[];
    allTenants: TenantResource[];
    pendingInterruptions: Array<TaskResource<any>>;
    goal: string;
    releaseVersion: string;
    previousDeploymentId: string;
    project: ProjectResource;
    release: ReleaseResource;
    channel: ChannelResource;
    currentVersionMap: CurrentVersionMap;
    dashboard: DashboardResource;
    previews: Map<string, DeploymentPreviewResource>;
    previousDeploymentBeingRetried?: DeploymentResource;
}

const MaximumInterruptionsToLoad = 10;

export const AuditTrailLink: React.SFC<{ link: string }> = ({ link, children }) => {
    return <PermissionCheck permission={Permission.EventView} wildcard={true} render={() => <InternalLink to={link}>{children}</InternalLink>} />;
};

export const ModifiedProperty: React.SFC<{ itemLink: React.ComponentType<any>; description: string }> = ({ itemLink: ItemLink, description }) => (
    <strong>
        {description} (<ItemLink />)
    </strong>
);

export const CsvSeparated: React.SFC<any> = props => {
    const children = React.Children.toArray(props.children).reduce((prev, current, index) => {
        return [...prev, index !== 0 ? ", " : null, current];
    }, []);
    return <React.Fragment children={children} />;
};

class DeploymentCreate extends FormBaseComponent<DeploymentCreateProps, DeploymentCreateState, DeploymentModel> {
    private buildDeploymentInfoRaceConditioner = new RequestRaceConditioner();

    constructor(props: DeploymentCreateProps) {
        super(props);
        const goal = this.props.match.params.goal!;
        const releaseVersion = this.props.match.params.releaseVersion!;
        const previousDeploymentId = goal === DeploymentCreateGoal.TryAgain ? this.props.match.params.previousId! : null!;

        this.state = {
            previousDeployment: null!,
            missingTenantVariables: {},
            nowOrLater: NowOrLaterEnum.Now,
            forcePackageDownload: false,
            forcePackageRedeployment: false,
            guidedFailureMode: GuidedFailureMode.EnvironmentDefault,
            actionIdsToSkip: [],
            deployments: [],
            queueTime: null!,
            queueTimeExpiry: null!,
            selectedEnvironmentIds: [],
            selectedTenantIds: [],
            promptVariablesForm: null!,
            promotionsMap: null!,
            template: null!,
            allEnvironments: [],
            allTenants: [],
            pendingInterruptions: [],
            goal,
            releaseVersion,
            previousDeploymentId,
            dashboard: null!,
            project: null!,
            release: null!,
            channel: null!,
            currentVersionMap: null!,
            previews: new Map<string, DeploymentPreviewResource>(),
            previousDeploymentBeingRetried: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const allEnvsPromise = this.loadAllEnvironments();
            const previousDeployment = this.state.previousDeploymentId ? await repository.Deployments.get(this.state.previousDeploymentId) : null!;

            const project = this.props.projectContext.state.model;
            const allTenantsPromise = this.loadAllTenants(project);
            const missingTenantVariablesPromise = this.loadMissingTenantVariables(project);
            const release = await repository.Projects.getReleaseByVersion(project, this.state.releaseVersion);
            const channelPromise = repository.Channels.get(release.ChannelId);
            const dashboard = await repository.Dashboards.getDashboard({ projectId: project.Id, showAll: true });
            const currentVersionMap = new CurrentVersionMap(dashboard);

            const template = await repository.Releases.getDeploymentTemplate(release);

            const isRetry = previousDeployment && this.state.goal === DeploymentCreateGoal.TryAgain;
            const guidedFailureMode = isRetry ? (previousDeployment.UseGuidedFailure ? GuidedFailureMode.On : GuidedFailureMode.Off) : project.DefaultGuidedFailureMode;
            const actionIdsToSkip = isRetry && previousDeployment.SkipActions.length > 0 ? previousDeployment.SkipActions : [];
            const forcePackageDownload = isRetry ? previousDeployment.ForcePackageDownload : this.state.forcePackageDownload;

            this.setState({
                template,
                promotionsMap: this.buildPromotionsMap(template),
                previousDeployment,
                guidedFailureMode,
                actionIdsToSkip,
                missingTenantVariables: await missingTenantVariablesPromise,
                allEnvironments: await allEnvsPromise,
                allTenants: await allTenantsPromise,
                project,
                release,
                channel: await channelPromise,
                dashboard,
                currentVersionMap,
                forcePackageDownload,
                previousDeploymentBeingRetried: isRetry ? previousDeployment : null!,
            });
        });
    }

    render() {
        if (this.state.redirectPath) {
            return <InternalRedirect to={this.state.redirectPath} push={true} />;
        }

        // If the user attempts to create deployments and one or more fail, the primary-action becomes "Deploy Unsuccessful"
        // This allows retrying only the failed attempts
        let onSaveLabel: string;
        let onSaveClick: () => Promise<any>;
        let secondaryButton: React.ReactNode;

        if (!this.hasFailedAttempts()) {
            onSaveLabel = "Deploy";
            onSaveClick = () => this.deploy();
            secondaryButton = null;
        } else {
            onSaveLabel = "Retry Unsuccessful";
            onSaveClick = () => this.deploy(true);
            secondaryButton = <ActionButton label={"Deploy All"} type={ActionButtonType.Secondary} onClick={() => this.deploy(false)} />;
        }

        const isSaveEnabled = !this.state.busy && this.canDeploy(this.state.selectedEnvironmentIds, this.state.selectedTenantIds);
        return (
            <FormPaperLayout
                busy={this.state.busy}
                errors={this.state.errors}
                title={`Deploy release ${this.state.release ? this.state.release.Version : ""}`}
                breadcrumbTitle={`Release ${this.state.release ? this.state.release.Version : ""}`}
                breadcrumbPath={routeLinks.project(this.props.match.params.projectSlug).deployments.release(this.state.release).root}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                onSaveClick={onSaveClick}
                saveButtonLabel={onSaveLabel}
                savePermission={{ permission: Permission.DeploymentCreate, environment: "*", tenant: "*", project: this.state.project && this.state.project.Id }}
                saveText=""
                forceDisableFormSaveButton={!isSaveEnabled}
                disableDirtyFormChecking={true}
                secondaryAction={secondaryButton}
            >
                {this.deploymentConfigurationForm()}
            </FormPaperLayout>
        );
    }

    private modifiedPropertiesAsCsvElement(template: DeploymentTemplateResource): JSX.Element {
        const DeploymentAuditTrailLink = () => <AuditTrailLink link={routeLinks.configuration.deploymentProcessEventsForProject(this.state.project.Id)}>audit trail</AuditTrailLink>;
        const VariableSetAuditTrailLink = () => <AuditTrailLink link={routeLinks.configuration.variableSetEventsForProject(this.state.project.Id)}>audit trail</AuditTrailLink>;
        const LibraryVariableSetAuditTrailLink = () => <AuditTrailLink link={routeLinks.configuration.libraryVariableSetEventsRegardingAny(this.state.project.IncludedLibraryVariableSetIds)}>audit trail</AuditTrailLink>;

        return (
            <CsvSeparated>
                {template.IsDeploymentProcessModified && <ModifiedProperty description="Deployment Process modified" itemLink={DeploymentAuditTrailLink} />}
                {template.IsVariableSetModified && <ModifiedProperty description="Variable Set modified" itemLink={VariableSetAuditTrailLink} />}
                {template.IsLibraryVariableSetModified && <ModifiedProperty description="Library Variable Set modified" itemLink={LibraryVariableSetAuditTrailLink} />}
            </CsvSeparated>
        );
    }

    private deploymentConfigurationForm() {
        const template = this.state.template;
        const project = this.state.project;
        const tenantsWithMissingVariables = (this.state.missingTenantVariables[this.state.selectedEnvironmentIds[0]] || []).filter(tenantId => this.state.selectedTenantIds.indexOf(tenantId) !== -1);
        const selectedEnvironmentsWithMissingDynamicInfrastructure =
            project && project.ProjectConnectivityPolicy && project.ProjectConnectivityPolicy.AllowDeploymentsToNoTargets === false
                ? []
                : this.state.allEnvironments.filter(e => e.AllowDynamicInfrastructure === false && this.state.selectedEnvironmentIds.indexOf(e.Id) > -1);

        return (
            <div>
                {this.state && this.state.release && (
                    <div>
                        {template && (!template.PromoteTo || template.PromoteTo.length === 0) && (
                            <Callout title="Note" type={CalloutType.Warning}>
                                Before you can deploy this release, you need to <InternalLink to={routeLinks.infrastructure.environments.root}>add an environment</InternalLink> to deploy it to.
                            </Callout>
                        )}
                        {selectedEnvironmentsWithMissingDynamicInfrastructure.length > 0 && (
                            <Callout title="Dynamic Infrastructure Note" type={CalloutType.Information}>
                                This project allows deployments to be created when there are no deployment targets, but the following environments do not allow dynamic targets to be created. Please note that this may cause an error during deployment
                                if you're using <ExternalLink href="EnvironmentDynamicInfrastructure">Dynamic Infrastructure</ExternalLink>.<div>You can opt into dynamic infrastructure for a given environment from the link(s) below:</div>
                                <div>
                                    {selectedEnvironmentsWithMissingDynamicInfrastructure.map(env => (
                                        <span>
                                            <InternalLink key={env.Id} to={routeLinks.infrastructure.environment(env)} openInSelf={false}>
                                                {env.Name}
                                            </InternalLink>
                                            &nbsp;
                                        </span>
                                    ))}
                                </div>
                            </Callout>
                        )}
                        {template && (template.IsDeploymentProcessModified || template.IsVariableSetModified || template.IsLibraryVariableSetModified) && (
                            <Callout title="Something has changed since this snapshot was taken." type={CalloutType.Warning}>
                                {this.modifiedPropertiesAsCsvElement(template)}: For consistency, this deployment will use a snapshot of the variables and deployment process that was taken when the release was created, which does not include the
                                latest changes that have been made to the project.
                                {template.IsDeploymentProcessModified && <span> A changed process can only be incorporated by creating a new release (this one may be renamed if desired).</span>}
                                {(template.IsVariableSetModified || template.IsLibraryVariableSetModified) && (
                                    <span>
                                        {" "}
                                        Variables can be updated via the&nbsp;
                                        <InternalLink to={routeLinks.project(this.state.project.Slug).release(this.state.release).root}>release page</InternalLink>.
                                    </span>
                                )}
                            </Callout>
                        )}
                        <PendingInterruptions pendingInterruptions={this.state.pendingInterruptions} />
                        {template && (
                            <EnvironmentAndTenantSelector
                                project={this.state.project}
                                template={template}
                                channel={this.state.channel}
                                previousDeployment={this.state.previousDeployment}
                                tenantedDeploymentMode={this.state.project.TenantedDeploymentMode}
                                onSelectionUpdated={this.onSelectionUpdated}
                                tenantsWithMissingVariables={tenantsWithMissingVariables}
                                onDoingBusyTask={this.doBusyTask}
                                release={this.state.release}
                                dashboard={this.state.dashboard}
                                allTenants={this.state.allTenants}
                                allEnvironments={this.state.allEnvironments}
                                goal={this.props.match.params.goal}
                                previousId={this.props.match.params.previousId}
                                tenantIds={this.props.match.params.tenantIds}
                                tags={this.props.match.params.tags}
                                search={this.props.location.search}
                            />
                        )}

                        {this.state.promptVariablesForm && this.state.promptVariablesForm.Elements.length > 0 && (
                            <PromptVariables
                                form={this.state.promptVariablesForm}
                                onParameterChanged={variable => {
                                    const promptVariablesForm = { ...this.state.promptVariablesForm };
                                    promptVariablesForm.Values[variable.VariableName] = variable.Value;
                                    this.setState({ promptVariablesForm });
                                }}
                            />
                        )}

                        <NowOrLater onScheduleDatesSet={this.onDeploymentScheduleChanged} modelType={DeploymentModelType.Deployment} />

                        <ActionToggle
                            selectedEnvironmentIds={this.state.selectedEnvironmentIds}
                            previews={Array.from(this.state.previews.values())}
                            release={this.state.release}
                            actionIds={this.state.actionIdsToSkip}
                            onActionIdsChanged={this.onActionIdsToSkipChanged}
                        />

                        <FailureMode guidedFailureMode={this.state.guidedFailureMode} onModeChanged={guidedFailureMode => this.setState({ guidedFailureMode })} modelType={DeploymentModelType.Deployment} />

                        <PackageDownloadOptions forcePackageDownload={this.state.forcePackageDownload} onOptionChanged={this.onPackageDownloadOptionChanged} />

                        {this.state.project.DefaultToSkipIfAlreadyInstalled && <PackageDeploymentOptions forcePackageRedeployment={this.state.forcePackageRedeployment} onChange={this.onPackageReDeploymentOptionChanged} />}

                        {this.state.deployments.length > 0 && (
                            <DeploymentPreview
                                release={this.state.release}
                                getDeploymentPreview={this.getDeploymentPreview}
                                deployments={this.state.deployments}
                                stepActionIdsToSkip={this.state.actionIdsToSkip}
                                tenantedDeploymentMode={this.state.project.TenantedDeploymentMode}
                                promptVariableForm={this.state.promptVariablesForm}
                                onExcludeSpecificMachinesSelected={this.onExcludeSpecificMachinesSelected}
                                onIncludeSpecificMachinesSelected={this.onIncludeSpecificMachinesSelected}
                                onAllTargetsSelected={this.onAllTargetsSelected}
                                tenantsWithMissingVariables={tenantsWithMissingVariables}
                                onDoingBusyTask={this.doBusyTask}
                                allEnvironments={this.state.allEnvironments}
                                allTenants={this.state.allTenants}
                                modelType={DeploymentModelType.Deployment}
                            />
                        )}
                    </div>
                )}
            </div>
        );
    }

    private getDeploymentPreview = (environmentId: string, tenantId: string) => {
        return this.state.previews.get(`${environmentId || ""}${tenantId || ""}`);
    };

    private canDeploy(selectedEnvironmentIds: string[], resultantTenants: string[]): boolean {
        if (!this.state.project) {
            return false;
        }

        const environmentSelected = selectedEnvironmentIds && selectedEnvironmentIds.length > 0;
        const tenantSelected = resultantTenants && resultantTenants.length > 0;
        switch (this.state.project.TenantedDeploymentMode) {
            case TenantedDeploymentMode.TenantedOrUntenanted:
                return environmentSelected || tenantSelected;
            case TenantedDeploymentMode.Untenanted:
                return environmentSelected && !tenantSelected;
            case TenantedDeploymentMode.Tenanted:
                return environmentSelected && tenantSelected;
            default:
                throw new Error("TenantedDeploymentMode not recognized");
        }
    }

    private buildPromotionsMap(template: DeploymentTemplateResource) {
        const promotionsMap: PromotionsMap = {};

        _.each(template.PromoteTo, environmentPromotion => {
            promotionsMap[environmentPromotion.Id] = environmentPromotion;
        });

        _.each(template.TenantPromotions, tenantPromotion => {
            promotionsMap[tenantPromotion.Id] = tenantPromotion;
        });
        return promotionsMap;
    }

    private async loadMissingTenantVariables(project: ProjectResource): Promise<Dictionary<string[]>> {
        if (project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted) {
            return Promise.resolve({});
        }
        const missingTenantVariables = await repository.Tenants.missingVariables({ projectId: project.Id }, true);
        const missingVariables: Dictionary<string[]> = {};
        missingTenantVariables.forEach(t => {
            t.MissingVariables.forEach(mv => {
                const newVals = missingVariables[mv.EnvironmentId!] || [];
                newVals.push(t.TenantId);
                missingVariables[mv.EnvironmentId!] = _.uniq(newVals);
            });
        });
        return missingVariables;
    }

    private async deploy(retry = false) {
        await this.doBusyTask(async () => {
            const deploymentPromises = [];
            const deployments = _.cloneDeep(this.state.deployments);
            const errors: Errors[] = [];

            await this.setUseGuidedFailure(deployments);

            for (const record of deployments) {
                // If retrying, only process previously failed
                if (retry) {
                    if (!record.response || !this.isError(record.response)) {
                        continue;
                    }
                }

                record.request.FormValues = this.state.promptVariablesForm ? this.state.promptVariablesForm.Values : null;

                deploymentPromises.push(
                    repository.Deployments.create(record.request as any)
                        .then(deployment => {
                            record.response = deployment;
                        })
                        .catch(ex => {
                            const error = createErrorsFromOctopusError(ex);
                            error.fieldErrors = matchErrorsToFieldNames(ex, this.state.model);
                            errors.push(error);
                            record.response = ex;
                        })
                );
            }

            await Promise.all(deploymentPromises);
            if (deployments.length === 1 && (deployments[0].response as DeploymentResource).TaskId) {
                // If creating a single deployment was successful, navigate to the task details for that deployment
                const redirectPath = routeLinks
                    .project(this.state.project)
                    .release(this.state.release)
                    .deployments.specific(deployments[0].response as DeploymentResource);
                this.setState({ redirectPath });
            } else if (_.every(deployments, result => !!(result.response as DeploymentResource).TaskId)) {
                // If creating multiple deployments were all successful, navigate to the task list page filtered
                // to show the created deployment tasks
                const taskIds = _.map(deployments, result => (result.response as DeploymentResource).TaskId);
                this.setState({ redirectPath: routeLinks.tasks.filtered({ ids: taskIds, spaces: [repository.spaceId!], includeSystem: false }) });
            } else {
                // Otherwise there was at least one error when creating the deployment/s

                if (errors.length === 1) {
                    // If there was a single error then the error details at the top of the page
                    this.setState({ errors: errors[0] });
                } else {
                    // If there were multiple errors, show a generic message at the top of the page
                    // The individual error details will be shown in the deployments section
                    this.setError(`${errors.length} errors occurred while attempting to create the deployments.  See the Deployments section below for the error details.`);
                }

                this.setState({ deployments });
            }
        });
    }

    private createDeployments(environmentIds: string[], tenantIds: string[], promptVariablesForm: Form) {
        const results = [];

        if (environmentIds.length === 0) {
            return [];
        }

        if (tenantIds.length > 0) {
            for (const tenantId of tenantIds) {
                results.push(this.createDeploymentRequest(environmentIds[0], tenantId, promptVariablesForm));
            }
        } else {
            if (this.state.project && this.state.project.TenantedDeploymentMode !== TenantedDeploymentMode.Tenanted) {
                for (const environmentId of environmentIds) {
                    results.push(this.createDeploymentRequest(environmentId, null!, promptVariablesForm));
                }
            }
        }

        return results;
    }

    private async loadDeploymentPreviews(environmentIds: string[], tenantIds: string[]) {
        const map = new Map<string, DeploymentPreviewResource>();
        let keys: string[] = [];
        let values: DeploymentPreviewResource[] = [];

        // If tenants have been selected then we use the tenant-environment deployment-previews
        if (tenantIds && tenantIds.length > 0) {
            [keys, values] = await this.getTenantEnvironmentPreviews(environmentIds, tenantIds);
        } else {
            const promises = environmentIds
                .filter(environmentId => this.state.promotionsMap[environmentId])
                .map(environmentId => {
                    keys.push(environmentId);
                    return repository.Releases.getDeploymentPreview(this.state.promotionsMap[environmentId]);
                });

            values = await Promise.all(promises);
        }

        for (let index = 0; index < keys.length; index++) {
            map.set(keys[index], values[index]);
        }

        return map;
    }

    // Returns promises for deployment-previews for the combination of selected tenants and environments
    private async getTenantEnvironmentPreviews(environmentIds: string[], tenantIds: string[]): Promise<[string[], DeploymentPreviewResource[]]> {
        const keys: string[] = [];
        const requestedDeploymentPreviews = _.flatten(
            tenantIds.map(tenantId => {
                const dpt = this.state.promotionsMap[tenantId] as DeploymentPromotionTenant;

                return dpt.PromoteTo.filter(tenantEnvironmentPromotion => environmentIds.includes(tenantEnvironmentPromotion.Id)).map(tenantEnvironmentPromotion => {
                    keys.push(tenantEnvironmentPromotion.Id + tenantId);
                    return { TenantId: tenantId, EnvironmentId: tenantEnvironmentPromotion.Id };
                });
            })
        );

        const request = { DeploymentPreviews: requestedDeploymentPreviews };
        const values = await repository.Releases.deploymentPreviews(this.state.release, request);

        return [keys, values];
    }

    private loadFormDetails(previews: Map<string, DeploymentPreviewResource>) {
        const form: Form = { Elements: [], Values: {} };

        previews.forEach((preview: DeploymentPreviewResource) => {
            if (!preview || !preview.Form) {
                return;
            }

            if (preview.Form.Values) {
                _.each(preview.Form.Values, (v, k) => {
                    form.Values[k] = v;
                });
            }

            if (preview.Form.Elements) {
                preview.Form.Elements.forEach(c => {
                    if (
                        !form.Elements.find((e: FormElement) => {
                            return e.Name === c.Name;
                        })
                    ) {
                        form.Elements.push(c);
                    }
                });
            }
        });

        return form;
    }

    private createDeploymentRequest(environmentId: string, tenantId: string, promptVariablesForm: Form): DeploymentRequestModel {
        const isRetryingInThisScope = this.state.previousDeploymentBeingRetried && this.state.previousDeploymentBeingRetried.EnvironmentId === environmentId && this.state.previousDeploymentBeingRetried.TenantId === tenantId;
        const specificMachineIds = isRetryingInThisScope && this.state.previousDeploymentBeingRetried!.SpecificMachineIds.length > 0 ? this.state.previousDeploymentBeingRetried!.SpecificMachineIds : [];
        const excludeMachineIds = isRetryingInThisScope && this.state.previousDeploymentBeingRetried!.ExcludedMachineIds.length > 0 ? this.state.previousDeploymentBeingRetried!.ExcludedMachineIds : [];

        const request: CreateDeploymentResource = {
            ReleaseId: this.state.release.Id,
            EnvironmentId: environmentId,
            TenantId: tenantId,
            SkipActions: this.state.actionIdsToSkip,
            QueueTime: this.state.queueTime,
            QueueTimeExpiry: this.state.queueTimeExpiry,
            FormValues: promptVariablesForm ? promptVariablesForm.Values : null,
            ForcePackageDownload: this.state.forcePackageDownload,
            UseGuidedFailure: false,
            SpecificMachineIds: specificMachineIds,
            ExcludedMachineIds: excludeMachineIds,
            ForcePackageRedeployment: this.state.forcePackageRedeployment,
        };

        return {
            tenantId,
            environmentId,
            request,
            currentVersion: this.state.currentVersionMap.getCurrentRelease(environmentId, tenantId)!,
        };
    }

    private async getAvailableDeploymentsFromApi(environmentIds: string[], tenantIds: string[], tenantTagsUsed: boolean): Promise<AvailableDeploymentsApiResults> {
        const previews = await this.loadDeploymentPreviews(environmentIds, tenantIds);
        const promptVariablesForm = this.loadFormDetails(previews);

        // If the selected tenant-tags did not match any tenants, then we want to ensure checkCanDeploy is false and that
        // there are no deployments created
        if (tenantTagsUsed && tenantIds.length === 0) {
            return {
                previews,
                allowDeployment: false,
                promptVariablesForm,
                deployments: [],
                pendingInterruptions: null,
            };
        }

        const deployments = this.createDeployments(environmentIds, tenantIds, promptVariablesForm);

        let pendingInterruptions: Array<TaskResource<any>> = [];
        // We only load interruptions if the number of deployments is low, see https://github.com/OctopusDeploy/Issues/issues/4415
        if (deployments.length < MaximumInterruptionsToLoad) {
            pendingInterruptions = await loadPendingInterruptions(
                this.state.project.Id,
                deployments.map(d => {
                    return { EnvironmentId: d.environmentId, TenantId: d.tenantId };
                })
            );
        }

        return {
            previews,
            allowDeployment: true,
            promptVariablesForm,
            deployments,
            pendingInterruptions,
        };
    }

    private onSelectionUpdated = async (environmentIds: string[], tenantIds: string[], tenantTagsUsed: boolean) => {
        await this.doBusyTask(async () => {
            await this.buildDeploymentInfoRaceConditioner.avoidStaleResponsesForRequest(this.getAvailableDeploymentsFromApi(environmentIds, tenantIds, tenantTagsUsed), apiResults => {
                if (!apiResults.allowDeployment) {
                    this.setState({
                        selectedEnvironmentIds: environmentIds,
                        selectedTenantIds: tenantIds,
                        deployments: [],
                        promptVariablesForm: apiResults.promptVariablesForm,
                    });
                } else {
                    this.setState({
                        previews: apiResults.previews,
                        selectedEnvironmentIds: environmentIds,
                        selectedTenantIds: tenantIds,
                        deployments: apiResults.deployments,
                        promptVariablesForm: apiResults.promptVariablesForm,
                        pendingInterruptions: apiResults.pendingInterruptions!,
                        actionIdsToSkip: environmentIds.length === 0 ? [] : this.state.actionIdsToSkip,
                    });
                }
            });
        });
    };

    private async setUseGuidedFailure(deploymentRequests: DeploymentRequestModel[]) {
        const mode = this.state.guidedFailureMode;
        if (deploymentRequests.length > 0) {
            if (mode === GuidedFailureMode.EnvironmentDefault) {
                const deploymentsByEnvironment = _.groupBy(deploymentRequests, x => x.environmentId);
                const environmentIds = _.chain(deploymentRequests)
                    .map(x => x.environmentId)
                    .uniq()
                    .value();

                for (const environmentId of environmentIds) {
                    const environment = await repository.Environments.get(environmentId);
                    for (const deployment of deploymentsByEnvironment[environmentId]) {
                        deployment.request.UseGuidedFailure = environment.UseGuidedFailure;
                    }
                }
            } else {
                for (const deployment of deploymentRequests) {
                    deployment.request.UseGuidedFailure = mode === GuidedFailureMode.On;
                }
            }
        }
    }

    private onPackageDownloadOptionChanged = (forcePackageDownload: boolean) => {
        const deployments = _.cloneDeep(this.state.deployments);
        deployments.forEach(deployment => (deployment.request.ForcePackageDownload = forcePackageDownload));
        this.setState({ deployments, forcePackageDownload });
    };

    private onPackageReDeploymentOptionChanged = (forcePackageRedeployment: boolean) => {
        const deployments = _.cloneDeep(this.state.deployments);
        deployments.forEach(deployment => (deployment.request.ForcePackageRedeployment = forcePackageRedeployment));
        this.setState({ deployments, forcePackageRedeployment });
    };

    private onDeploymentScheduleChanged = (queueTime: Moment, queueTimeExpiry: Moment) => {
        const deployments = _.cloneDeep(this.state.deployments);
        deployments.forEach(deployment => {
            deployment.request.QueueTime = queueTime;
            deployment.request.QueueTimeExpiry = queueTimeExpiry;
        });
        this.setState({ deployments, queueTime, queueTimeExpiry });
    };

    private onActionIdsToSkipChanged = (excludedActionIdsToSkip: string[]) => {
        const deployments = _.cloneDeep(this.state.deployments);
        deployments.forEach(deployment => (deployment.request.SkipActions = excludedActionIdsToSkip));
        this.setState({ deployments, actionIdsToSkip: excludedActionIdsToSkip });
    };

    private onExcludeSpecificMachinesSelected = (machineInfo: DeploymentMachineInfo) => {
        this.setTargetMachineIds(machineInfo.deploymentType, machineInfo.id, machineInfo.machineIds, []);
    };

    private async loadAllEnvironments() {
        return repository.Environments.all();
    }

    private async loadAllTenants(project: ProjectResource) {
        if (project && (project.TenantedDeploymentMode === TenantedDeploymentMode.Tenanted || project.TenantedDeploymentMode === TenantedDeploymentMode.TenantedOrUntenanted)) {
            return repository.Tenants.all();
        }
        return [];
    }

    private onIncludeSpecificMachinesSelected = (machineInfo: DeploymentMachineInfo) => {
        this.setTargetMachineIds(machineInfo.deploymentType, machineInfo.id, [], machineInfo.machineIds);
    };

    private onAllTargetsSelected = (machineInfo: DeploymentMachineInfo) => {
        this.setTargetMachineIds(machineInfo.deploymentType, machineInfo.id, [], []);
    };

    private setTargetMachineIds = (deploymentType: DeploymentType, targetId: string, excludedMachineIds: string[], specificMachineIds: string[]) => {
        const deployments = _.cloneDeep(this.state.deployments);
        const deployment = deploymentType === DeploymentType.Tenant ? deployments.find(x => x.tenantId === targetId) : deployments.find(x => x.environmentId === targetId);

        deployment!.request.ExcludedMachineIds = excludedMachineIds;
        deployment!.request.SpecificMachineIds = specificMachineIds;
        this.setState({ deployments });
    };

    private hasFailedAttempts(): boolean {
        return _.find(this.state.deployments, deployment => deployment.response && this.isError(deployment.response)) !== undefined;
    }

    private isError(response: IExecutionResource | OctopusError): response is OctopusError {
        return (response as OctopusError).ErrorMessage !== undefined;
    }
}

export default withProjectContext(DeploymentCreate);
