import * as React from "react";
import cn from "classnames";

const styles = require("./CustomDialogFrames.less");

export type Frame = React.ComponentType<{}>;

export interface DialogFrameProp {
    frame?: Frame;
}

export const FrameWrapper: React.SFC<BaseFrameProps> = React.forwardRef(({ children, className }, ref) => <div ref={ref} className={cn(className, styles.wrapper, styles.frame, styles.padded)} children={children} />);

interface BaseFrameProps {
    className?: string;
    ref?: React.Ref<HTMLDivElement>;
}

export const DialogFrame: React.SFC<BaseFrameProps> = React.forwardRef(({ children, className }, ref) => {
    return (
        <div ref={ref} className={cn(styles.frame, styles.shadow, className)}>
            {children}
        </div>
    );
});

export const FullScreenDialogFrame: React.SFC<BaseFrameProps> = React.forwardRef(({ children, className }, ref) => {
    return (
        <DialogFrame ref={ref} className={cn(styles.fullscreen, styles.commonFrame, styles.opaque, styles.shadow, className)}>
            {children}
        </DialogFrame>
    );
});

export const LargeDialogFrame: React.SFC<BaseFrameProps> = React.forwardRef(({ children, className }, ref) => {
    return (
        <DialogFrame ref={ref} className={cn(styles.huge, styles.commonFrame, styles.opaque, styles.shadow, className)}>
            {children}
        </DialogFrame>
    );
});

export const MediumDialogFrame: React.SFC<BaseFrameProps> = React.forwardRef(({ children, className }, ref) => (
    <FrameWrapper>
        <div ref={ref} className={cn(styles.medium, styles.commonFrame, styles.opaque, styles.shadow, className)}>
            {children}
        </div>
    </FrameWrapper>
));

export const SmallDialogFrame: React.SFC<BaseFrameProps> = React.forwardRef(({ children, className }, ref) => <MediumDialogFrame ref={ref} className={cn(styles.small, className)} children={children} />);
