// tslint:disable: no-non-null-assertion
import * as React from "react";
import InfrastructureLayout from "../InfrastructureLayout/InfrastructureLayout";
import { RouteComponentProps } from "react-router";
import { repository } from "clientInstance";
import { AccountResource, AccountType, AzureServicePrincipalAccountResource, AzureSubscriptionAccountResource, SshKeyPairAccountResource, UsernamePasswordAccountResource, AmazonWebServicesAccountResource } from "client/resources/accountResource";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import PaperLayout from "components/PaperLayout/PaperLayout";
import URI from "urijs";
import SshKeyPairAccountEdit from "./SshKeyPairAccountEdit";
import UsernamePasswordAccountEdit from "./UsernamePasswordAccountEdit";
import TokenAccountEdit from "./TokenAccountEdit";
import AzureAccountEdit from "./AzureAccountEdit";
import { AccountUsageResource } from "client/resources/accountUsageResource";
import AmazonWebServicesAccountEdit from "./AmazonWebServicesAccountEdit";
import { TokenAccountResource } from "../../../../client/resources";

interface AccountEditRealState extends DataBaseComponentState {
    account: AccountResource;
    accountType: AccountType;
}

export default class AccountEdit extends DataBaseComponent<RouteComponentProps<{ accountId: string }>, AccountEditRealState> {
    constructor(props: RouteComponentProps<{ accountId: string }>) {
        super(props);
        this.state = {
            accountType: null!,
            account: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const accountId = this.props.match && this.props.match.params ? this.props.match.params.accountId : null;
            if (accountId) {
                const account = await repository.Accounts.get(accountId);
                this.setState({ accountType: account.AccountType, account });
            } else {
                const query = URI(this.props.location.search);
                const accountType = query.search(true).accountType;
                const usage = emptyAccountUsage;
                if (!accountType) {
                    throw new Error("Invalid path");
                }
                this.setState({ accountType });
            }
        });
    }

    render() {
        if (this.state.accountType) {
            switch (this.state.accountType) {
                case AccountType.SshKeyPair:
                    return <SshKeyPairAccountEdit account={this.state.account as SshKeyPairAccountResource} />;
                case AccountType.Token:
                    return <TokenAccountEdit account={this.state.account as TokenAccountResource} />;
                case AccountType.UsernamePassword:
                    return <UsernamePasswordAccountEdit account={this.state.account as UsernamePasswordAccountResource} />;
                case AccountType.AzureSubscription:
                case AccountType.AzureServicePrincipal:
                    return <AzureAccountEdit account={this.state.account as AzureServicePrincipalAccountResource | AzureSubscriptionAccountResource} />;
                case AccountType.AmazonWebServicesAccount:
                    return <AmazonWebServicesAccountEdit account={this.state.account as AmazonWebServicesAccountResource} />;
            }
        }

        return (
            <InfrastructureLayout {...this.props}>
                <PaperLayout title="Accounts" busy={this.state.busy} errors={this.state.errors} />
            </InfrastructureLayout>
        );
    }
}

const emptyAccountUsage: AccountUsageResource = {
    Targets: [],
    DeploymentProcesses: [],
    Releases: [],
    LibraryVariableSets: [],
    ProjectVariableSets: [],
    RunbookProcesses: [],
    RunbookSnapshots: [],
};
