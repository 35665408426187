import * as React from "react";
import ActionButton, { ActionButtonType, IconPosition } from "components/Button/ActionButton";
import { DialogLayout, DialogLayoutDispatchProps } from "components/DialogLayout/DialogLayout";
import { DialogLayoutConnect } from "components/Dialog/DialogLayoutConnect";
import { formatUrl } from "components/Navigation/ExternalLink/ExternalLink";
import { withProps } from "recompose";
import { useRequiredContext } from "hooks";
import { OctopusThemeContext } from "components/Theme";

interface LicenseLimitReachedDialogLayoutInternalProps {
    limitType: string;
    onActionClick?: () => void;
}

const LicenseLimitReachedDialogLayoutInternal: React.FC<LicenseLimitReachedDialogLayoutInternalProps & DialogLayoutDispatchProps> = props => {
    const themeContext = useRequiredContext(OctopusThemeContext, "OctopusThemeContext");

    const notifyActionClick = React.useCallback(() => {
        if (props.onActionClick) {
            props.onActionClick();
        }
    }, [props.onActionClick]);

    // if you take the icon property:
    //      ` icon={<i className="fa fa-external-link" style={{ color: whiteConstant }} />}`
    // and apply it inside the action that launches this dialog
    // then it works, not sure why it works differently on this ActionButton
    const loadMoreInfo = (
        <ActionButton
            key={"unlock-more-" + props.limitType}
            label={"Unlock More " + props.limitType}
            icon={<i className="fa fa-external-link" style={{ color: themeContext.whiteConstant }} />}
            iconPosition={IconPosition.Right}
            type={ActionButtonType.Primary}
            onClick={() => {
                window.open(formatUrl("Upgrade" + props.limitType), "_blank");
                notifyActionClick();
            }}
        />
    );

    const cancel = <ActionButton key="Cancel" label="Cancel" onClick={() => close()} />;

    return (
        <DialogLayout title={`You've hit your ${props.limitType} limit...`} actions={[cancel, loadMoreInfo]} busy={null} errors={null} closeDialog={() => close()} additionalActions={[]}>
            <div>Want to add more? You'll need to upgrade your licence. Click to see your upgrade options and get a quote.</div>
        </DialogLayout>
    );

    function close() {
        props.close();
        notifyActionClick();
    }
};

interface LimitHitDialogProps {
    onActionClick?: () => void;
}

const SpaceLimitReachedDialogLayoutInternal = withProps({ limitType: "Spaces" })(LicenseLimitReachedDialogLayoutInternal);
const WorkerLimitReachedDialogLayoutInternal = withProps({ limitType: "Workers" })(LicenseLimitReachedDialogLayoutInternal);

export const SpaceLimitReachedDialogLayout = DialogLayoutConnect.to<LimitHitDialogProps>(SpaceLimitReachedDialogLayoutInternal);
export const WorkerLimitReachedDialogLayout = DialogLayoutConnect.to<LimitHitDialogProps>(WorkerLimitReachedDialogLayoutInternal);
