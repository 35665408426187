// tslint:disable: no-non-null-assertion
import * as React from "react";
import { Text } from "components/form";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { repository } from "clientInstance";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";

interface ChangeTaskCapProps {
    nodeId: string;

    onSaveDone(): Promise<void>;
}

interface ChangeTaskCapState extends DataBaseComponentState {
    loaded: boolean;
    name?: string;
    taskCap?: number;
}

export class ChangeTaskCap extends DataBaseComponent<ChangeTaskCapProps, ChangeTaskCapState> {
    constructor(props: ChangeTaskCapProps) {
        super(props);
        this.state = { loaded: false };
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const node = await repository.OctopusServerNodes.get(this.props.nodeId);
            this.setState({ taskCap: node.MaxConcurrentTasks, name: node.Name, loaded: true });
        });
    }

    async save() {
        return this.doBusyTask(async () => {
            const node = await repository.OctopusServerNodes.get(this.props.nodeId);
            node.MaxConcurrentTasks = this.state.taskCap!;
            await repository.OctopusServerNodes.modify(node);
            await this.props.onSaveDone();
        });
    }

    render() {
        const body = this.state.loaded && (
            <Text label="Task cap" hintText="Number of tasks this node can execute at once." value={this.state.taskCap!.toString()} onChange={v => this.setState({ taskCap: parseInt(v, 10) })} autoFocus={true} min={0} type="number" />
        );

        return (
            <SaveDialogLayout title={this.state.loaded ? `Change Task Cap for ${this.state.name}` : "Change Task Cap"} busy={this.state.busy} errors={this.state.errors} onSaveClick={() => this.save()}>
                {body}
            </SaveDialogLayout>
        );
    }
}
