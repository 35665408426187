// tslint:disable: no-non-null-assertion
// tslint:disable: no-any

import BaseComponent from "../BaseComponent";
import { OctopusError } from "client/resources";
import RefreshLoop from "utils/RefreshLoop/refresh-loop";
import { PromiseCancelledError } from "../../utils/PromiseCancelledError";
import { Errors, createErrorsFromOctopusError } from "./Errors";

export interface DataBaseComponentState {
    busy?: Promise<void>;
    errors?: Errors;
}

export type DoBusyTask = (action: () => Promise<any>, clearCurrentErrors?: boolean) => Promise<boolean>;
export type Refresh = () => Promise<void>;

export class DataBaseComponent<Props, State extends DataBaseComponentState> extends BaseComponent<Props, State> {
    private busies: Array<Promise<void>> = [];
    private stopRefreshLoop: () => void | undefined;

    constructor(props: Props) {
        super(props);

        this.provideErrorHandling(this.doBusyTaskInternal);
    }

    componentWillUnmount() {
        if (this.stopRefreshLoop !== undefined) {
            this.stopRefreshLoop();
        }
    }

    public doBusyTask: DoBusyTask = async (action: () => Promise<any>, clearCurrentErrors: boolean = true): Promise<boolean> => {
        return this.doBusyTaskInternal(action, clearCurrentErrors);
    };

    protected getFieldError = (fieldName: string) => {
        if (this.state.errors && this.state.errors.fieldErrors) {
            const found = Object.keys(this.state.errors.fieldErrors).find(k => k.toLowerCase() === fieldName.toLowerCase());
            if (found) {
                return this.state.errors.fieldErrors[found];
            }
            const foundPartialMatch = Object.keys(this.state.errors.fieldErrors).find(k => k.endsWith("." + fieldName));
            if (foundPartialMatch) {
                return this.state.errors.fieldErrors[foundPartialMatch];
            }
        }
        return "";
    };

    protected async startRefreshLoop<K extends keyof State>(getData: () => Promise<Pick<State, K> | null | State>, refreshInterval: number, noBusyIndicator = false): Promise<Refresh> {
        if (this.stopRefreshLoop !== undefined) {
            throw new Error("Can't create more than one loop in a component");
        }

        const loop = new RefreshLoop(async isLoopStillRunning => {
            const refreshData = async () => {
                const innerData = await getData();
                if (isLoopStillRunning()) {
                    this.setState(innerData);
                }
            };

            if (noBusyIndicator) {
                await refreshData();
            } else {
                await this.doBusyTask(async () => refreshData());
            }
        }, refreshInterval);

        this.stopRefreshLoop = loop.stop;
        const data = await getData();
        if (this.unmounted) {
            throw new PromiseCancelledError("Component unmounted before loop could start");
        }
        this.setState(data);

        loop.start();

        return loop.refresh;
    }

    protected setError = (message: string, details: string[] = [], fieldErrors: { [other: string]: string } = {}) => {
        this.setState({
            errors: {
                message,
                details,
                fieldErrors,
            },
        });
    };

    protected mapToOctopusError(err: OctopusError) {
        // we override this in subclasses so don't remove
        return createErrorsFromOctopusError(err);
    }

    private doBusyTaskInternal = async (action: () => Promise<void>, clearCurrentErrors: boolean): Promise<boolean> => {
        let busy: Promise<void>;
        try {
            // Sometimes child components will load some lookup data while a parent component
            // is displaying an error. The child uses the parent's doBusyTask so that the busy
            // indicator and errors display correctly. But we shouldn't clear existing errors
            // from that child load.
            if (clearCurrentErrors) {
                this.setState({
                    errors: null!,
                });
            }

            busy = action();
            this.busies = [busy, ...this.busies];
            const singlePromise = Promise.all(this.busies).then(v => {
                /* */
            }); //the .then gives us Promise<void> instead of Promise<void[]>
            this.setState({ busy: singlePromise });
            await busy;

            return true;
        } catch (e) {
            if (e instanceof OctopusError) {
                const errors = this.mapToOctopusError(e);
                this.setState({ errors });
                return false;
            }
            if (e instanceof PromiseCancelledError) {
                // swallow it, no point bubbling this up any further since we intentionally cancelled the promise
                return false;
            }
            throw e;
        } finally {
            this.busies = this.busies.filter(b => b !== busy);
            // we need to return null here when done
            // because some buttons etc just check for the
            // existance of a busy promise
            this.setState({
                busy:
                    this.busies.length > 0
                        ? Promise.all(this.busies).then(v => {
                              /* */
                          })
                        : null!,
            });
        }
    };
}
