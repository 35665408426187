// tslint:disable: no-non-null-assertion
import * as React from "react";
import * as _ from "lodash";
import pluginRegistry, { ActionEditProps } from "../pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { repository } from "clientInstance";
import Roles from "../Roles";
import { ActionSummaryProps } from "../actionSummaryProps";
import { ActionExecutionLocation } from "../../../client/resources";
import ExpanderSectionHeading from "components/form/Sections/FormSectionHeading";
import FeedResource, { FeedType } from "client/resources/feedResource";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import CommonSummaryHelper from "utils/CommonSummaryHelper/CommonSummaryHelper";
import PackageDownloadOptions from "components/PackageDownloadOptions/PackageDownloadOptions";
import { VariableLookupText } from "components/form/VariableLookupText";
import Note from "components/form/Note/Note";
import Summary from "components/form/Sections/Summary";
import routeLinks from "../../../routeLinks";
import { getFeedName } from "../getFeedName";
import { TargetRoles } from "areas/projects/components/DeploymentProcess/ActionDetails";
import { GetPrimaryPackageReference, InitialisePrimaryPackageReference, SetPrimaryPackageReference } from "../../../client/resources";
import Callout, { CalloutType } from "components/Callout";
import DeferredPackageSelector from "components/PackageSelector/DeferredPackageSelector";
import { getChangesToPackageReference } from "../getChangesToPackageReference";
import { PackageSelectionMode } from "client/resources/packageReference";

interface TransferPackageActionSummaryState {
    feedName: string;
}

class AzureCloudServiceActionSummary extends BaseComponent<ActionSummaryProps, TransferPackageActionSummaryState> {
    constructor(props: ActionSummaryProps) {
        super(props);
        this.state = { feedName: null! };
    }

    async componentDidMount() {
        const pkg = GetPrimaryPackageReference(this.props.packages);
        if (pkg) {
            this.setState({ feedName: await getFeedName(pkg.FeedId) });
        }
    }

    render() {
        const pkg = GetPrimaryPackageReference(this.props.packages);
        return pkg ? (
            <div>
                Transfer package <strong> {pkg.PackageId} </strong>
                from {this.state.feedName ? <strong>{this.state.feedName}</strong> : <em>{pkg.FeedId}</em>}
                {this.props.targetRolesAsCSV && (
                    <span>
                        {" "}
                        to deployment targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} />{" "}
                    </span>
                )}
            </div>
        ) : (
            <Callout type={CalloutType.Warning} title="Misconfigured step">
                Package was not selected or cannot be found. Please review this step and ensure a valid package is selected.
            </Callout>
        );
    }
}

interface TransferProperties {
    "Octopus.Action.Package.TransferPath": string;
}

interface TransferPackageActionEditState {
    feeds: FeedResource[];
}

export class TransferPackageActionEdit extends BaseComponent<ActionEditProps<TransferProperties>, TransferPackageActionEditState> {
    constructor(props: ActionEditProps<TransferProperties>) {
        super(props);

        this.state = {
            feeds: [],
        };
    }

    async componentDidMount() {
        await this.loadFeeds(feeds => this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, feeds)));
    }

    transferPathSummary() {
        const transferPath = this.props.properties["Octopus.Action.Package.TransferPath"];
        if (!transferPath) {
            return Summary.placeholder("The transfer path has not been configured");
        }
        return Summary.summary(
            <span>
                The package will be moved to <strong>{transferPath}</strong> once it is transfered
            </span>
        );
    }

    render() {
        // The package is initialized in componentDidMount, but render gets called before the update is propagated
        if (!this.props.packages || this.props.packages.length === 0) {
            return null;
        }

        const pkg = GetPrimaryPackageReference(this.props.packages);
        const feed = _.find(this.state.feeds, f => f.Id === pkg.FeedId);

        const help =
            this.state.feeds.length > 0 ? (
                <span>
                    This step is used to push the contents of a package to one or more machines which may be sourced from an external feed or the Octopus built-in feed. You can configure the remote machines to deploy to in the{" "}
                    <InternalLink to={routeLinks.infrastructure.root} openInSelf={false}>
                        Infrastructure
                    </InternalLink>{" "}
                    tab.
                </span>
            ) : (
                <span>Choose the package you which to transfer</span>
            );

        return (
            <div>
                <ExpanderSectionHeading title="Package Details" />
                <ExpandableFormSection
                    errorKey="Octopus.Action.Package.PackageId|Octopus.Action.Package.FeedId"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Package"
                    summary={CommonSummaryHelper.deferredPackageSummary(pkg, this.state.feeds)}
                    help={help}
                >
                    <DeferredPackageSelector
                        packageId={pkg.PackageId}
                        feedId={pkg.FeedId}
                        onPackageIdChange={packageId => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))}
                        onFeedIdChange={feedId => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))}
                        packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")}
                        feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")}
                        projectId={this.props.projectId}
                        feeds={this.state.feeds}
                        localNames={this.props.localNames}
                        feedType={[FeedType.Nuget, FeedType.BuiltIn, FeedType.Maven, FeedType.GitHub]}
                        refreshFeeds={this.loadFeeds}
                        parameters={this.props.parameters}
                        packageSelectionMode={pkg.Properties["SelectionMode"]}
                        packageSelectionModeError={this.props.getFieldError("SelectionMode")}
                        onPackageSelectionModeChange={value => this.props.setPackages(SetPrimaryPackageReference(getChangesToPackageReference(value), this.props.packages))}
                        packageParameterName={pkg.Properties["PackageParameterName"]}
                        packageParameterError={this.props.getFieldError("PackageParameterName")}
                        onPackageParameterChange={packageParameter => this.props.setPackages(SetPrimaryPackageReference({ Properties: { ...pkg.Properties, PackageParameterName: packageParameter } }, this.props.packages))}
                    />
                    {pkg.Properties["SelectionMode"] === PackageSelectionMode.Immediate && (
                        <PackageDownloadOptions
                            packageAcquisitionLocation={pkg.AcquisitionLocation}
                            onPackageAcquisitionLocationChanged={acquisitionLocation => this.props.setPackages(SetPrimaryPackageReference({ AcquisitionLocation: acquisitionLocation }, this.props.packages))}
                            feed={feed}
                            projectId={this.props.projectId}
                            localNames={this.props.localNames}
                        />
                    )}
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Octopus.Action.Package.TransferPath"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Transfer Path"
                    summary={this.transferPathSummary()}
                    help="Enter the path the package should be moved to on the remote target."
                >
                    <VariableLookupText
                        localNames={this.props.localNames}
                        value={this.props.properties["Octopus.Action.Package.TransferPath"]}
                        onChange={x => this.props.setProperties({ ["Octopus.Action.Package.TransferPath"]: x })}
                        error={this.props.getFieldError("Octopus.Action.Package.TransferPath")}
                        label="Transfer path"
                    />
                    <Note>The location that the package should be moved to once it is uploaded to the remote target.</Note>
                </ExpandableFormSection>
            </div>
        );
    }

    private loadFeeds = (callback?: (feeds: FeedResource[]) => void) => {
        return this.props.doBusyTask(async () => {
            this.setState({ feeds: await repository.Feeds.all() }, () => callback && callback(this.state.feeds));
        });
    };
}

pluginRegistry.registerActionForAllScopes({
    executionLocation: ActionExecutionLocation.AlwaysOnTarget,
    actionType: "Octopus.TransferPackage",
    summary: (properties, targetRolesAsCSV, packages) => <AzureCloudServiceActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} packages={packages} />,
    edit: TransferPackageActionEdit,
    canHaveChildren: step => true,
    canBeChild: true,
    targetRoleOption: action => TargetRoles.Optional,
    hasPackages: action => true,
});
