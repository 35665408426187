// tslint:disable: no-any

import BasicRepository from "./basicRepository";
import Client from "../client";
import { ScheduledTaskDetailsResource } from "client/resources";

interface DetailsArgs {
    tail: number;
    verbose: boolean;
}

class SchedulerRepository extends BasicRepository<any, any> {
    constructor(client: Client) {
        super("Scheduler", client);
    }

    getDetails(name: string, options?: DetailsArgs) {
        const args = { ...options, name };
        return this.client.get<ScheduledTaskDetailsResource>(this.client.getLink("Scheduler"), args);
    }
}

export default SchedulerRepository;
