// tslint:disable: no-non-null-assertion
import * as React from "react";
import { IProcessResource, isDeploymentProcessResource, isRunbookProcessResource } from "client/resources";
import { ActionScope } from "components/Actions/pluginRegistry";
import { repository } from "clientInstance";
import routeLinks from "routeLinks";

const modifySteps = async (steps: IProcessResource): Promise<IProcessResource> => {
    let result: IProcessResource | undefined;
    if (isDeploymentProcessResource(steps)) {
        result = await repository.DeploymentProcesses.modify(steps);
    } else if (isRunbookProcessResource(steps)) {
        result = await repository.RunbookProcess.modify(steps);
    }

    return result!;
};

const deleteSteps = async (steps: IProcessResource): Promise<IProcessResource> => {
    let result: IProcessResource = null!;
    if (isDeploymentProcessResource(steps)) {
        result = await repository.DeploymentProcesses.del(steps);
    } else if (isRunbookProcessResource(steps)) {
        result = await repository.RunbookProcess.del(steps);
    }

    return result;
};

const getScopedFetchSteps = (scope: ActionScope) => {
    return (id: string): Promise<IProcessResource> => {
        if (scope === ActionScope.Deployments) {
            return repository.DeploymentProcesses.get(id);
        } else if (scope === ActionScope.Runbooks) {
            return repository.RunbookProcess.get(id);
        }

        throw new Error(`Unknown scope ${scope} provided. Can't fetch steps.`);
    };
};

const getProcessOverviewUrl = (resource: IProcessResource) => {
    if (isDeploymentProcessResource(resource)) {
        return routeLinks.project(resource.ProjectId).process.root;
    } else if (isRunbookProcessResource(resource)) {
        return routeLinks
            .project(resource.ProjectId)
            .operations.runbook(resource.RunbookId)
            .runbookProcess.runbookProcess(resource.Id).steps.root;
    }
};

export type ScopeAwareStepActionRenderProps = ScopeAwareStepActions;
type ScopeAwareStepActionsProps = {
    scope: ActionScope;
    children: (props: ScopeAwareStepActionRenderProps) => React.ReactNode;
};

const ScopeAwareStepActions: React.FC<ScopeAwareStepActionsProps> = ({ scope, children }) => {
    const actions = useScopeAwareStepActions(scope);
    return <React.Fragment>{children(actions)}</React.Fragment>;
};

type ScopeAwareStepActions = {
    getSteps: (id: string) => Promise<IProcessResource>;
    modify: (steps: IProcessResource) => Promise<IProcessResource>;
    delete: (steps: IProcessResource) => Promise<IProcessResource>;
    getProcessOverviewUrl: (steps: IProcessResource) => string | undefined;
};

const useScopeAwareStepActions = (scope: ActionScope) => {
    const actions: ScopeAwareStepActions = React.useMemo(
        () => ({
            delete: deleteSteps,
            getSteps: getScopedFetchSteps(scope),
            modify: modifySteps,
            getProcessOverviewUrl,
        }),
        [scope]
    );

    return actions;
};

export default ScopeAwareStepActions;

export { ScopeAwareStepActions, modifySteps, deleteSteps, getScopedFetchSteps };
