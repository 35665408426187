// tslint:disable: no-non-null-assertion
// tslint:disable: no-any

import * as React from "react";
import { UserResource } from "client/resources/userResource";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent/FormBaseComponent";
import { AuthenticationProviderElement, IdentityMetadataResource } from "client/authentication/authenticationProviderElement";
import { ClaimsBasedIdentity } from "client/resources/identityResource";
import { session, repository } from "clientInstance";
import FormPaperLayout from "components/FormPaperLayout";
import { Section } from "components/Section/Section";
import { ProviderGroups } from "areas/configuration/components/Users/ProviderGroups";

interface UserEditModel {
    original: UserResource;
    identities: ClaimsBasedIdentity[];
}

interface UserLoginsState extends OptionalFormBaseComponentState<UserEditModel> {
    user: UserResource;
    enabledAuthenticationProviders: AuthenticationProviderElement[] | null;
    enabledProvidersMetadata: IdentityMetadataResource[] | null;
    canCurrentUserEditIdentitiesForUser?: boolean;
}

export default class UserLogins extends FormBaseComponent<any, UserLoginsState, UserEditModel> {
    constructor(props: any) {
        super(props);

        this.state = {
            user: null!,
            model: null!,
            cleanModel: null!,
            canCurrentUserEditIdentitiesForUser: true,
            enabledAuthenticationProviders: null, //start as null so we can only show no providers once it loads
            enabledProvidersMetadata: null,
        };
    }

    currentUserId(): string {
        return session && session.currentUser ? session.currentUser.Id : null!;
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const user = this.currentUserId() ? await repository.Users.get(this.currentUserId()) : null;

            const configDoc = await repository.UserIdentityMetadata.authenticationConfiguration(user!.Id);
            const metadataDoc = await repository.UserIdentityMetadata.all();

            this.setState({
                user: user!,
                model: this.buildModel(user!),
                cleanModel: this.buildModel(user!),
                enabledAuthenticationProviders: configDoc.AuthenticationProviders || [],
                canCurrentUserEditIdentitiesForUser: configDoc.CanCurrentUserEditIdentitiesForUser,
                enabledProvidersMetadata: metadataDoc.Providers,
            });
        });
    }

    handleAddIdentity(identity: any) {
        this.setState(state => ({
            model: {
                ...state!.model,
                identities: [identity, ...state!.model!.identities],
            },
        }));
        return true;
    }

    render() {
        return (
            <FormPaperLayout title={"My Logins"} busy={this.state.busy} errors={this.state.errors} model={this.state.model} cleanModel={this.state.cleanModel} onSaveClick={this.handleSaveClick}>
                {this.state.model && this.state.enabledAuthenticationProviders!.length > 0 ? (
                    <ProviderGroups
                        userIdentities={this.state.model.identities}
                        enabledAuthenticationProviders={this.state.enabledAuthenticationProviders!}
                        canCurrentUserEditIdentitiesForUser={this.state.canCurrentUserEditIdentitiesForUser!}
                        enabledProvidersMetadata={this.state.enabledProvidersMetadata!}
                        isServiceAccount={this.state.user.IsService}
                        onChange={identities => this.setModelState({ identities })}
                    />
                ) : (
                    this.state.enabledAuthenticationProviders && <Section>There are no providers that support external identities enabled.</Section>
                )}
            </FormPaperLayout>
        );
    }

    buildModel(user: UserResource): UserEditModel {
        return user
            ? {
                  original: user,
                  identities: user.Identities,
              }
            : {
                  original: null!,
                  identities: [],
              };
    }

    handleSaveClick = async () => {
        const user: UserResource = {
            ...this.state.user,
            Identities: this.state.model!.identities,
        };

        await this.doBusyTask(async () => {
            const result = await repository.Users.save(user);

            this.setState({
                cleanModel: this.buildModel(user),
                model: this.buildModel(user),
            });
        });
    };
}
