// tslint:disable: no-non-null-assertion
import { PaletteType } from "@material-ui/core";
import { ThemePaletteType } from "theme";
import Environment from "environment";

export class ThemeStorage {
    static set(theme: ThemePaletteType): void {
        localStorage.setItem(ThemeStorage.key, theme);
        ThemeStorage.fireEventsInThisContext(theme);
    }

    static get(): ThemePaletteType {
        return this.getStoredValue() || this.getPreferredValue() || "light";
    }

    static addEventListener(callback: (theme: ThemePaletteType) => void): void {
        // Having to filter out session storage events and events on keys
        // that we're interested in is a bit tedious so this provides a
        // clean way for consumers to get storage events without having
        // to worry about those implementation details.
        const sub = (event: StorageEvent) => {
            if (event.storageArea === localStorage) {
                if (event.key === ThemeStorage.key) {
                    callback(event.newValue as PaletteType);
                }
            }
        };

        ThemeStorage.subscriptions.set(callback, sub);
        window.addEventListener("storage", sub, true);
    }

    static removeEventListener(callback: (theme: ThemePaletteType) => void) {
        const sub = ThemeStorage.subscriptions.get(callback);
        if (sub) {
            ThemeStorage.subscriptions.delete(callback);
            window.removeEventListener("storage", sub, false);
        }
    }

    private static subscriptions: Map<(theme: ThemePaletteType) => void, (event: StorageEvent) => void> = new Map();

    private static key = "theme";

    private static getStoredValue(): ThemePaletteType {
        return localStorage.getItem(ThemeStorage.key) as PaletteType;
    }

    private static getPreferredValue(): ThemePaletteType {
        if (!window.matchMedia) {
            return null!;
        } else if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
            return "dark";
        } else if (window.matchMedia("(prefers-color-scheme: light)").matches) {
            return "light";
        } else {
            return null!;
        }
    }

    private static fireEventsInThisContext(newTheme: ThemePaletteType): void {
        // Storage events don't get fired in the page that raised them.
        // The events only exist to tell other tabs that something
        // changed. Fire the callbacks manually.
        ThemeStorage.subscriptions.forEach((sub, callback) => {
            callback(newTheme);
        });
    }
}
