// tslint:disable: no-non-null-assertion
import * as React from "react";
import * as PropTypes from "prop-types";
import EventListener from "react-event-listener";

const rowsHeight = 24;

function getStyles(state: EnhancedTextareaState) {
    return {
        root: {
            position: "relative", // because the shadow has position: 'absolute'
        },
        textarea: {
            height: state.height,
            width: "100%",
            resize: "none",
            font: "inherit",
            padding: 0,
            cursor: "inherit",
        },
        shadow: {
            resize: "none",
            // Overflow also needed to here to remove the extra row
            // added to textareas in Firefox.
            overflow: "hidden",
            // Visibility needed to hide the extra text area on ipads
            visibility: "hidden",
            position: "absolute",
            height: "auto",
        },
    };
}

interface EnhancedTextareaProps {
    defaultValue: string;
    disabled: boolean;
    hintText: string;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onHeightChange: (event: UIEvent, newHeight: number) => void;
    rows: number;
    rowsMax: number;
    shadowStyle: React.CSSProperties;
    /**
     * Override the inline-styles of the root element.
     */
    style: React.CSSProperties;
    textareaStyle: React.CSSProperties;
    value: string;
    // tslint:disable-next-line: no-any
    valueLink: any;
}

interface EnhancedTextareaState {
    height: number | null;
}

class EnhancedTextarea extends React.Component<EnhancedTextareaProps, EnhancedTextareaState> {
    static defaultProps: Partial<EnhancedTextareaProps> = {
        rows: 1,
    };

    static contextTypes = {
        muiTheme: PropTypes.object.isRequired,
    };

    state: EnhancedTextareaState = {
        height: null,
    };

    shadowRef: React.RefObject<HTMLTextAreaElement> = React.createRef();
    inputRef: React.RefObject<HTMLTextAreaElement> = React.createRef();

    componentWillMount() {
        this.setState({
            height: this.props.rows * rowsHeight,
        });
    }

    componentDidMount() {
        this.syncHeightWithShadow(this.props.value);
    }

    componentWillReceiveProps(nextProps: EnhancedTextareaProps) {
        if (nextProps.value !== this.props.value || nextProps.rowsMax !== this.props.rowsMax) {
            this.syncHeightWithShadow(nextProps.value, null!, nextProps);
        }
    }

    handleResize = (event: UIEvent) => {
        this.syncHeightWithShadow(this.props.value, event);
    };

    getInputNode() {
        return this.inputRef.current;
    }

    setValue(value: string) {
        const ref = this.getInputNode();
        if (ref) {
            ref.value = value;
        }
        this.syncHeightWithShadow(value);
    }

    syncHeightWithShadow(newValue: string, event?: UIEvent, props?: EnhancedTextareaProps) {
        const shadow = this.shadowRef.current!;
        const displayText = this.props.hintText && (newValue === "" || newValue === undefined || newValue === null) ? this.props.hintText : newValue;

        if (displayText !== undefined) {
            shadow.value = displayText;
        }

        let newHeight = shadow.scrollHeight;

        // Guarding for jsdom, where scrollHeight isn't present.
        // See https://github.com/tmpvar/jsdom/issues/1013
        if (newHeight === undefined) {
            return;
        }

        props = props || this.props;

        if (props.rowsMax >= props.rows) {
            newHeight = Math.min(props.rowsMax * rowsHeight, newHeight);
        }

        newHeight = Math.max(newHeight, rowsHeight);

        if (this.state.height !== newHeight) {
            const input = this.inputRef.current!;
            const cursorPosition = input.selectionStart;
            this.setState(
                {
                    height: newHeight,
                },
                () => {
                    if (input === document.activeElement) {
                        input.setSelectionRange(cursorPosition, cursorPosition);
                    }
                }
            );

            if (props.onHeightChange) {
                props.onHeightChange(event!, newHeight);
            }
        }
    }

    handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (!this.props.hasOwnProperty("value")) {
            this.syncHeightWithShadow(event.target.value);
        }

        if (this.props.hasOwnProperty("valueLink")) {
            this.props.valueLink.requestChange(event.target.value);
        }

        if (this.props.onChange) {
            this.props.onChange(event);
        }
    };

    render() {
        const {
            onChange, // eslint-disable-line no-unused-vars
            onHeightChange, // eslint-disable-line no-unused-vars
            rows, // eslint-disable-line no-unused-vars
            rowsMax, // eslint-disable-line no-unused-vars
            shadowStyle,
            style,
            hintText, // eslint-disable-line no-unused-vars
            textareaStyle,
            valueLink, // eslint-disable-line no-unused-vars
            ...other
        } = this.props;

        const { prepareStyles } = this.context.muiTheme;
        const styles = getStyles(this.state);
        const rootStyles = { ...styles.root, ...style };
        const textareaStyles = { ...styles.textarea, ...textareaStyle };
        const shadowStyles = { ...textareaStyles, ...styles.shadow, ...shadowStyle };
        const overrides: React.TextareaHTMLAttributes<HTMLTextAreaElement> = {};

        if (this.props.hasOwnProperty("valueLink")) {
            overrides.value = this.props.valueLink.value;
        }

        return (
            <div style={prepareStyles(rootStyles)}>
                <EventListener target="window" onResize={this.handleResize} />
                <textarea ref={this.shadowRef} style={prepareStyles(shadowStyles)} tabIndex={-1} rows={this.props.rows} defaultValue={this.props.defaultValue} readOnly={true} value={this.props.value} />
                <textarea {...other} {...overrides} ref={this.inputRef} rows={this.props.rows} style={prepareStyles(textareaStyles)} onChange={this.handleChange} />
            </div>
        );
    }
}

export default EnhancedTextarea;
