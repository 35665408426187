import * as React from "react";
import routeLinks from "routeLinks";
import { Navigation, NavItem } from "components/NavigationSidebarLayout/index";
import Permission from "client/resources/permission";
import { IsAllowedToSeeDeploymentsOverview } from "../ProjectsRoutes/ProjectToOverviewRedirect";
import { RunbookTemporaryCallout } from "../Runbooks/OperationsOverviewLayout";
import { compact } from "lodash";
import { NewFeatureChip } from "components/Chips";

export function ProjectLinks(projectSlug: string, projectId: string, isMultiTenancyEnabled: boolean | undefined) {
    // Note: Use projectSlug from our route (and NOT this.state.project) to avoid the sidebar refreshing unnecessarily.
    const projectLinks = routeLinks.project(projectSlug || "");
    const navigationLinks: Array<NavItem | null> = [];

    const deploymentsSubLinks: Array<NavItem | null> = [];
    deploymentsSubLinks.push(
        Navigation.navItem("Overview", projectLinks.deployments.root, true, {
            permission: Permission.ReleaseView,
            project: projectId,
            tenant: "*",
        })
    );
    deploymentsSubLinks.push(
        Navigation.navItem("Process", projectLinks.deployments.process.root, undefined, {
            permission: Permission.ProcessView,
            project: projectId,
            tenant: "*",
        })
    );
    deploymentsSubLinks.push(
        Navigation.navItem("Channels", projectLinks.deployments.channels, undefined, {
            permission: Permission.ProcessView,
            project: projectId,
            tenant: "*",
        })
    );
    deploymentsSubLinks.push(
        Navigation.navItem("Releases", projectLinks.deployments.releases, undefined, {
            permission: Permission.ReleaseView,
            project: projectId,
            tenant: "*",
        })
    );
    deploymentsSubLinks.push(
        Navigation.navItem("Triggers", projectLinks.triggers, undefined, {
            permission: Permission.TriggerView,
            project: projectId,
        })
    );

    if (IsAllowedToSeeDeploymentsOverview(projectId) && deploymentsSubLinks.length > 0) {
        navigationLinks.push(Navigation.navGroup("Deployments", projectLinks.deployments.root, compact(deploymentsSubLinks), undefined, true)); // Strip any that failed permission checks.
    }

    const operationsSubLinks: Array<NavItem | null> = [];
    operationsSubLinks.push(
        Navigation.navItem("Overview", projectLinks.operations.root, true, {
            permission: Permission.RunbookView,
            project: projectId,
            wildcard: true,
        })
    );
    operationsSubLinks.push(
        Navigation.navItem("Runbooks", projectLinks.operations.runbooks, undefined, {
            permission: Permission.RunbookView,
            project: projectId,
            wildcard: true,
        })
    );
    operationsSubLinks.push(
        Navigation.navItem("Triggers", projectLinks.operations.triggers, undefined, {
            permission: Permission.TriggerView,
            project: projectId,
        })
    );

    navigationLinks.push(
        Navigation.navGroup(
            <div>
                Operations{" "}
                <RunbookTemporaryCallout>
                    <NewFeatureChip />
                </RunbookTemporaryCallout>
            </div>,
            projectLinks.operations.root,
            compact(operationsSubLinks), // Strip any that failed permission checks.
            {
                permission: Permission.RunbookView,
                project: projectId,
                wildcard: true,
            },
            true
        )
    );
    const variableSubLinks: Array<NavItem | null> = [];
    variableSubLinks.push(Navigation.navItem("Project", projectLinks.variables.root, true));
    if (isMultiTenancyEnabled) {
        variableSubLinks.push(Navigation.navItem("Project Templates", projectLinks.variables.projectTemplates));
        variableSubLinks.push(Navigation.navItem("Common Templates", projectLinks.variables.commonTemplates));
    }
    variableSubLinks.push(Navigation.navItem("Library Sets", projectLinks.variables.library));
    variableSubLinks.push(Navigation.navItem("All", projectLinks.variables.all));
    variableSubLinks.push(Navigation.navItem("Preview", projectLinks.variables.preview));

    navigationLinks.push(
        Navigation.navGroup(
            "Variables",
            projectLinks.variables.root,
            compact(variableSubLinks), // Strip any that failed permission checks.
            {
                permission: Permission.VariableView,
                project: projectId,
                wildcard: true,
            }
        )
    );
    navigationLinks.push(
        Navigation.navItem("Tasks", projectLinks.tasks, undefined, {
            permission: Permission.TaskView,
            project: projectId,
            projectGroup: "*",
            tenant: "*",
        })
    );
    navigationLinks.push(
        Navigation.navItem("Settings", projectLinks.settings, undefined, {
            permission: Permission.ProjectView,
            project: projectId,
            projectGroup: "*",
            tenant: "*",
        })
    );

    return compact(navigationLinks);
}
