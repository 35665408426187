// tslint:disable: no-non-null-assertion
import * as React from "react";
import { repository } from "clientInstance";
import { LibraryLayout } from "../LibraryLayout/LibraryLayout";
import PaperLayout from "components/PaperLayout";
import { ResourceCollection, OctopusPackageVersionBuildInformationMappedResource } from "client/resources";
import LatestBuildInformationList from "./LatestBuildInformationList";
import { RouteComponentProps } from "react-router";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";

interface BuildInformationOverviewState extends DataBaseComponentState {
    loaded: boolean;
    initialPackages: ResourceCollection<OctopusPackageVersionBuildInformationMappedResource>;
    totalPackages: number;
}

export class BuildInformationOverview extends DataBaseComponent<RouteComponentProps<{}>, BuildInformationOverviewState> {
    private defaultTake = 50;

    constructor(props: RouteComponentProps<{}>) {
        super(props);
        this.state = {
            loaded: false,
            initialPackages: null!,
            totalPackages: 0,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(() => this.initialLoad());
    }

    async initialLoad() {
        const initialPackages = await this.getInitialBuildInformation("", this.defaultTake);

        this.setState({
            initialPackages,
            totalPackages: initialPackages.TotalResults,
            loaded: true,
        });
    }

    async getInitialBuildInformation(filter: string, take: number) {
        return repository.BuildInformationRepository.list({ filter, latest: true, take });
    }

    render() {
        const loaded = this.state.loaded;

        return (
            <LibraryLayout {...this.props}>
                <PaperLayout title="Build Information Repository" busy={this.state.busy} errors={this.state.errors}>
                    {loaded && <LatestBuildInformationList match={this.props.match} initialPackages={this.state.initialPackages} />}
                </PaperLayout>
            </LibraryLayout>
        );
    }
}

export default BuildInformationOverview;
