import { VariableType } from "client/resources/variableResource";
import { repository } from "../../clientInstance";

interface VariableTypeDetails {
    value: VariableType;
    text: string;
    /**
     * If true, this account type will only be displayed in the UI for EAP builds
     */
    eapOnly: boolean;
}

/**
 * A collection that defines the variable types available to the UI
 */
export const RawVariableTypeDetailsMap: VariableTypeDetails[] = [
    { value: VariableType.AzureAccount, text: "Azure Account", eapOnly: false },
    { value: VariableType.AmazonWebServicesAccount, text: "AWS Account", eapOnly: false },
    { value: VariableType.Certificate, text: "Certificate", eapOnly: false },
    { value: VariableType.WorkerPool, text: "Worker Pool", eapOnly: false },
    { value: VariableType.Sensitive, text: "Sensitive", eapOnly: false },
    { value: VariableType.String, text: "Text", eapOnly: false },
];

/**
 * Returns a list of variable types, filtered to eap only values for production releases,
 * but including the supplied type (so existing variables can be edited).
 * @param {VariableType} force The type to force into the returned list
 * @returns {VariableTypeDetails[]} A filtered list of variable types
 */
export function filteredVariableTypes(force: VariableType) {
    return RawVariableTypeDetailsMap.filter(variable => repository.getServerInformation().isEarlyAccessProgram || !variable.eapOnly || variable.value === force);
}
