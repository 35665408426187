// tslint:disable: no-any

import * as React from "react";
import Tasks from "areas/tasks/components/Tasks/Tasks";
import { RouteComponentProps } from "react-router";
import TaskLayout from "areas/tasks/TaskLayout";

interface TasksLayoutProps extends RouteComponentProps<any> {}

const TasksLayout: React.FC<TasksLayoutProps> = props => {
    return (
        <TaskLayout>
            <Tasks {...props} />
        </TaskLayout>
    );
};

export default TasksLayout;
