import * as React from "react";
import { useProjectContext } from "../../context";
import PaperLayout from "components/PaperLayout";

export type WithProjectDeploymentProcessInjectedProps = {
    processId: string;
};

//This HOC pulls the project ID off the current deployment process context and passes it into the wrapped component
export const withProjectDeploymentProcessId = <T extends WithProjectDeploymentProcessInjectedProps>(Component: React.ComponentType<T & WithProjectDeploymentProcessInjectedProps>) => {
    type ExternalProps = Omit<T, keyof WithProjectDeploymentProcessInjectedProps>;
    const WithProjectDeploymentProcess: React.FC<ExternalProps> = props => {
        const context = useProjectContext();
        if (!context.state.model) {
            return <PaperLayout busy={true} />;
        }
        return <Component processId={context.state.model.DeploymentProcessId} {...(props as T)} />;
    };

    return WithProjectDeploymentProcess;
};

export default withProjectDeploymentProcessId;
