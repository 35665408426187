// tslint:disable: no-non-null-assertion
// tslint:disable: no-any

import * as React from "react";
import RadioButtonGroupInternal from "./RadioButtonGroupInternal";
import FormFieldProps from "../FormFieldProps";
const styles = require("./style.less");
import cn from "classnames";
import InputLabel from "components/form/InputLabel";
import { withBoundField } from "components/form/BoundField/BoundField";

interface RadioButtonGroupProps<TValue> extends FormFieldProps<TValue> {
    horizontal?: boolean;
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    className?: string;
    noMargin?: boolean;
    validate?(value: TValue): string;
    onValidate?(value: string): void;
}

interface RadioButtonGroupState {
    error?: string;
    showExternalError: boolean;
}

class RadioButtonGroup<TValue> extends React.Component<RadioButtonGroupProps<TValue>, RadioButtonGroupState> {
    constructor(props: RadioButtonGroupProps<TValue>) {
        super(props);
        this.state = {
            showExternalError: true,
        };
    }

    componentWillReceiveProps(nextProps: RadioButtonGroupProps<TValue>) {
        const isNewExternalErrorAvailable = nextProps.error !== this.props.error;
        if (isNewExternalErrorAvailable) {
            this.setState({ showExternalError: true });
        }
    }

    handleChange(event: any, value: any) {
        if (this.props.validate) {
            const result = this.props.validate(value);
            this.setState({ error: result });
            if (this.props.onValidate) {
                this.props.onValidate(result);
            }
        }
        this.setState({ showExternalError: false });
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        const { className, value, label, validate, children, error, onChange, onValidate, horizontal, noMargin, ...otherProps } = this.props;

        const err = this.state.error || (this.state.showExternalError && error);

        const classes = [styles.radioButtonGroup, className];
        if (horizontal) {
            classes.push(styles.horizontal);
        }

        return (
            <div className={cn(styles.container, { [styles.topMargin]: !noMargin })}>
                <InputLabel label={label!} />
                <RadioButtonGroupInternal className={cn(classes)} name={name} onChange={(e: any, v: any) => this.handleChange(e, v)} floatingLabelText={label} valueSelected={value} {...otherProps}>
                    {children}
                </RadioButtonGroupInternal>
                {err && <div className={styles.errorText}>{err}</div>}
            </div>
        );
    }
}

export class StringRadioButtonGroup extends RadioButtonGroup<string> {}

export class BooleanRadioButtonGroup extends RadioButtonGroup<boolean> {}

export const BoundStringRadioButtonGroup = withBoundField(StringRadioButtonGroup);

export const BoundRadioButtonGroup = withBoundField(RadioButtonGroup);

export default RadioButtonGroup;
