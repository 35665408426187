import * as React from "react";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import endpointRegistry, { EndpointRegistration, CategorizedEndpointRegistration, CategoryDefinition, EndpointSelectionScope } from "./endpointRegistry";
import cn from "classnames";
import { ActiveItem } from "components/ActiveItem";
import { Section } from "components/Section/Section";
import { getQueryFromWindow } from "utils/UrlHelper";
import { withRegistrations, RegistrationProps } from "./withRegistrations";
import { connect } from "react-redux";
import { EndpointActions as ReduxEndpointActions } from "areas/infrastructure/reducers/endpoints";
const { selectEndpoint } = ReduxEndpointActions;
import { noOp } from "utils/noOp";
import { Dispatch, Action } from "redux";
import PageDivider from "components/PageDivider";
import { orderBy } from "utils/orderBy";
import callAll from "utils/callAll";
import { withProps, compose, withHandlers } from "recompose";
import { withRouter, RouteComponentProps } from "react-router";
import routeLinks from "routeLinks";
import { withNavigation, NavigateRenderProps } from "components/Navigation/Navigate";
import { useRequiredContext } from "hooks";
import { OctopusThemeContext } from "components/Theme";
const styles = require("./EndpointSelector.less");

interface EndpointRegistrationCallback {
    onSelect: (registration: EndpointRegistration) => void;
}

interface CategorizedEndpointRegistrationCallback {
    onSelect: (registration: CategorizedEndpointRegistration) => void;
}

interface ChangeCategoryCallback {
    onChangeCategory: (category: CategoryDefinition) => void;
}

interface EndpointCardGroupProps extends CategorizedEndpointRegistrationCallback {
    endpoints: CategorizedEndpointRegistration[];
    className?: string;
    category: CategoryDefinition;
    scope: EndpointSelectionScope;
}

const EndpointCardGroup: React.SFC<EndpointCardGroupProps> = ({ endpoints = [], className, onSelect, category, scope }) => {
    const ordered = orderBy(endpoints, ["displayOrder", "name"]);

    return (
        <div className={cn(styles.cardGroup, className)}>
            {ordered.map(x => {
                const element = x.renderCard({
                    scope,
                    registration: x,
                    category,
                    getNavigationProps: (navProps = {}) => ({
                        onNavigate: () => callAll(onSelect, navProps.onNavigate)(x),
                    }),
                });
                return React.cloneElement(element, { key: x.name });
            })}
        </div>
    );
};

export const EndpointCardGroupTitle: React.SFC<{ className?: string }> = ({ className, children }) => (
    <div className={cn(styles.groupHeading, className)}>
        <PageDivider>{children}</PageDivider>
    </div>
);

export const EndpointCardGroupHelp: React.SFC<{ className?: string }> = ({ className, children }) => <div className={cn(styles.groupHelp, className)}>{children}</div>;

type ActiveItemButtonProps = { active?: boolean; label: string; onClick?: () => void; style?: object; icon?: {} };

const ActiveItemButton: React.SFC<ActiveItemButtonProps> = ({ onClick, label, active, style }) => {
    const themeContext = useRequiredContext(OctopusThemeContext, "OctopusThemeContext");

    const labelProps = active ? { color: themeContext.whiteConstant } : undefined;
    return <ActionButton type={ActionButtonType.Category} labelProps={labelProps} label={label} onClick={onClick} className={cn({ [styles.activeCategoryButton]: active })} style={style} />;
};

const EndpointActions: React.SFC<{ className?: string }> = ({ children, className }) => <div className={cn(styles.actions, className)}>{children}</div>;

type EndpointRegistrationViewProps = RegistrationProps &
    EndpointRegistrationCallback &
    ChangeCategoryCallback & {
        activeItem?: CategoryDefinition;
        scope: EndpointSelectionScope;
    };

class EndpointRegistrationsView extends React.Component<EndpointRegistrationViewProps & HeadingProps> {
    render() {
        const { registrations, onChangeCategory, activeItem, categories, heading, onSelect } = this.props;

        const actions: React.ReactNode[] = orderBy(
            [
                ...registrations.filter(x => !endpointRegistry.isCategorizedEndpoint(x)).map(x => ({ label: x.name, displayOrder: x.displayOrder, action: () => onSelect(x) })),
                ...Object.values(categories).map(x => ({ label: x.category.category, displayOrder: x.category.displayOrder, action: () => onChangeCategory(x.category) })),
            ],
            ["displayOrder", "label"]
        ).map(x => <ActiveItemButton label={x.label} onClick={x.action} key={x.label} active={activeItem && activeItem.category === x.label} />);

        return (
            <React.Fragment>
                <Section className={styles.headingContainer}>{heading}</Section>
                <EndpointActions children={actions} className={styles.paperActions} />
                <Section bodyClassName={styles.container}>
                    {activeItem && <EndpointCardGroupTitle children={activeItem.title} className={styles.activeItemGroupHeading} />}
                    {activeItem && !!activeItem.help && <EndpointCardGroupHelp children={activeItem.help} />}
                    {activeItem && (
                        <EndpointCardGroup
                            scope={this.props.scope}
                            endpoints={categories[activeItem.category] && categories[activeItem.category].endpoints}
                            onSelect={onSelect}
                            category={categories[activeItem.category] && categories[activeItem.category].category}
                        />
                    )}
                </Section>
            </React.Fragment>
        );
    }
}

interface HeadingProps {
    heading: React.ReactNode;
}

type EndpointSelectorDispatchProps = EndpointRegistrationCallback & ChangeCategoryCallback;
type EndpointSelectorProps = HeadingProps & RegistrationProps & EndpointSelectorDispatchProps & { initial?: CategoryDefinition; scope: EndpointSelectionScope };

export const EndpointSelector: React.SFC<EndpointSelectorProps> = ({ registrations, categories, heading, scope, ...rest }) => {
    return (
        <ActiveItem<CategoryDefinition>
            render={({ onSelect, activeItem }) => (
                <EndpointRegistrationsView
                    scope={scope}
                    activeItem={activeItem}
                    onChangeCategory={category => {
                        if (rest.onChangeCategory) {
                            rest.onChangeCategory(category);
                        }
                        onSelect(category);
                    }}
                    registrations={registrations}
                    categories={categories}
                    heading={heading}
                    onSelect={rest.onSelect}
                />
            )}
            initial={rest.initial}
        />
    );
};

export function withCategoryHistory() {
    return <T extends EndpointSelectorProps>(Component: React.ComponentType<T>) => {
        class WithCategoryHistory extends React.Component<T> {
            render() {
                const categoryFromUrl = getQueryFromWindow("category", value => this.props.categories[value]);
                const overrides = {
                    onChangeCategory: (category: CategoryDefinition) => {
                        if (this.props.onChangeCategory) {
                            this.props.onChangeCategory(category);
                        }
                    },
                    initial: categoryFromUrl ? categoryFromUrl.category : null,
                };

                return <Component {...this.props} {...overrides} />;
            }
        }

        return WithCategoryHistory;
    };
}

function withRegistrationNavigation<T>(navigate: (routeProps: RouteComponentProps<T>, navigation: NavigateRenderProps, registration: EndpointRegistration) => void) {
    return compose(
        withRouter,
        withNavigation,
        withHandlers({
            onSelect: (props: RouteComponentProps<T> & EndpointSelectorDispatchProps & NavigateRenderProps) => (registration: EndpointRegistration) => {
                navigate(props, { navigate: props.navigate, redirect: props.redirect, open: props.open }, registration);
            },
        })
    );
}

interface MachineSettingsNewRouteProps {
    environmentId: string;
}

const withDeploymentTargetNavigation = withRegistrationNavigation<MachineSettingsNewRouteProps>(({ match }, { navigate }, registration) => {
    const environmentId = match.params ? match.params.environmentId : undefined;
    if (endpointRegistry.isMachineRegistration(registration) && registration.discoverable) {
        return navigate(routeLinks.infrastructure.machines.discover(registration.key, environmentId));
    } else {
        return navigate(routeLinks.infrastructure.machines.create({ type: registration.communicationStyle, environment: environmentId }));
    }
});

interface WorkerSettingsNewRouteProps {
    workerPoolId: string;
}

const withWorkerNavigation = withRegistrationNavigation<WorkerSettingsNewRouteProps>(({ match }, { navigate }, registration) => {
    const workerPoolId = match.params ? match.params.workerPoolId : undefined;
    if (endpointRegistry.isMachineRegistration(registration) && registration.discoverable) {
        return navigate(routeLinks.infrastructure.workerMachines.discover(registration.key, workerPoolId));
    } else {
        return navigate(routeLinks.infrastructure.workerMachines.create({ type: registration.communicationStyle, environment: workerPoolId }));
    }
});

function withGlobalState<T extends EndpointRegistrationCallback>(component: React.ComponentType<T>) {
    type WithGlobalStateExternalProps = Omit<T, keyof EndpointRegistrationCallback> & Partial<EndpointRegistrationCallback>;
    const mapGlobalActionDispatchersToProps = (dispatch: Dispatch<Action<{}>>, props: T): EndpointRegistrationCallback => ({
        onSelect: (registration: EndpointRegistration) => {
            props.onSelect ? props.onSelect(registration) : noOp();
            dispatch(selectEndpoint(registration.key));
        },
    });
    const BaseComponent = component as React.ComponentType<EndpointRegistrationCallback>;

    const connectDispatch = connect(null, mapGlobalActionDispatchersToProps);
    return connectDispatch(BaseComponent) as React.ComponentType<WithGlobalStateExternalProps>;
}

const withMachineRegistrations = withRegistrations(
    x => endpointRegistry.categorizeEndpoints(x),
    () => endpointRegistry.getAllMachines()
);
const withKnownEndpointRegistrations = withRegistrations(
    x => endpointRegistry.categorizeEndpoints(x),
    () => endpointRegistry.getAllRegistrations()
);

type EnhancedSelectorProps = HeadingProps & Partial<RegistrationProps>;

const forDeploymentTargets = compose<EndpointSelectorProps, EnhancedSelectorProps>(withDeploymentTargetNavigation, withProps({ scope: EndpointSelectionScope.DeploymentTarget }), withGlobalState, withKnownEndpointRegistrations, withCategoryHistory());

const forWorkers = compose<EndpointSelectorProps, EnhancedSelectorProps>(withWorkerNavigation, withProps({ scope: EndpointSelectionScope.Worker }), withGlobalState, withMachineRegistrations, withCategoryHistory());

const WorkerEndpointSelector = forWorkers(EndpointSelector);
const DeploymentTargetEndpointSelector = forDeploymentTargets(EndpointSelector);

export default EndpointSelector;

export { withMachineRegistrations, withKnownEndpointRegistrations, WorkerEndpointSelector, DeploymentTargetEndpointSelector };
