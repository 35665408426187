// tslint:disable: no-any

import * as React from "react";
import LegacyMuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { MuiThemeProvider, Theme as MuiTheme } from "@material-ui/core/styles";
import { createMuiTheme, createLegacyMuiTheme, ThemePaletteType } from "theme";
import { ThemeStorage } from "./ThemeStorage";
import { OctopusTheme, LightTheme, DarkTheme } from "./Themes";
import { getMuiTheme } from "material-ui/styles";
import { useRequiredContext } from "hooks";

const OctopusThemeContext = React.createContext(LightTheme);

interface OctopusThemeProviderState {
    theme: OctopusTheme;
    legacyMuiTheme: any;
    muiTheme: MuiTheme;
}

class Theme extends React.Component<{}, OctopusThemeProviderState> {
    constructor(props: {}) {
        super(props);

        const themeName = ThemeStorage.get();
        this.state = this.buildState(themeName);
    }

    componentDidMount() {
        ThemeStorage.addEventListener(this.themeChanged);
    }

    componentWillUnmount() {
        ThemeStorage.removeEventListener(this.themeChanged);
    }

    render() {
        return (
            <OctopusThemeContext.Provider value={this.state.theme}>
                <LegacyMuiThemeProvider muiTheme={this.state.legacyMuiTheme}>
                    <MuiThemeProvider theme={this.state.muiTheme}>{this.props.children}</MuiThemeProvider>
                </LegacyMuiThemeProvider>
            </OctopusThemeContext.Provider>
        );
    }

    private themeChanged = (themeName: ThemePaletteType) => {
        this.setState(this.buildState(themeName));
    };

    private buildState = (themeName: ThemePaletteType) => {
        const octopusTheme = this.mapThemeNameToTheme(themeName);
        const legacyMuiTheme = createLegacyMuiTheme(octopusTheme);
        const muiTheme = createMuiTheme(themeName, octopusTheme);

        return {
            theme: octopusTheme,
            legacyMuiTheme,
            muiTheme,
        };
    };

    private mapThemeNameToTheme(themeName: string): OctopusTheme {
        return themeName === "light" ? LightTheme : DarkTheme;
    }
}

function withTheme(render: (theme: OctopusTheme) => React.ReactElement | null | undefined): React.ReactElement {
    return <OctopusThemeContext.Consumer>{render}</OctopusThemeContext.Consumer>;
}

function useOctopusTheme(): OctopusTheme {
    return useRequiredContext(OctopusThemeContext, "OctopusThemeContext");
}

export default Theme;

export { Theme, OctopusThemeContext, OctopusTheme, withTheme, useOctopusTheme };
