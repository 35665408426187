// tslint:disable: no-non-null-assertion
import * as React from "react";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import NavigationSidebarLayout, { Navigation } from "components/NavigationSidebarLayout";
import AreaTitle from "components/AreaTitle";
import { Permission } from "client/resources/permission";
import routeLinks from "routeLinks";

class InfrastructureLayout extends BaseComponent<{}, {}> {
    render() {
        const navLinks = [
            Navigation.navItem("Overview", routeLinks.infrastructure.overview, undefined, { permission: [Permission.EnvironmentView, Permission.MachineView, Permission.WorkerView], wildcard: true })!,
            Navigation.navItem("Deployment Targets", routeLinks.infrastructure.machines.root, undefined, { permission: [Permission.MachineView], wildcard: true })!,
            Navigation.navItem("Environments", routeLinks.infrastructure.environments.root, undefined, { permission: [Permission.EnvironmentView, Permission.MachineView], wildcard: true })!,
            Navigation.navItem("Workers", routeLinks.infrastructure.workerMachines.root, undefined, { permission: [Permission.WorkerView], wildcard: true })!,
            Navigation.navItem("Worker Pools", routeLinks.infrastructure.workerPools.root, undefined)!,
            Navigation.navItem("Machine Policies", routeLinks.infrastructure.machinePolicies.root, undefined, { permission: Permission.MachinePolicyView, wildcard: true })!,
            Navigation.navItem("Machine Proxies", routeLinks.infrastructure.proxies.root, undefined, { permission: Permission.ProxyView, wildcard: true })!,
            Navigation.navItem("Accounts", routeLinks.infrastructure.accounts.root, undefined, { permission: Permission.AccountView, wildcard: true })!,
        ];

        return (
            <main id="maincontent">
                <AreaTitle link={routeLinks.infrastructure.root} title="Infrastructure" />
                <NavigationSidebarLayout navLinks={navLinks!} content={this.props.children} />
            </main>
        );
    }
}

export default InfrastructureLayout;
