// tslint:disable: no-any

import * as React from "react";

import { DataTableBody, DataTableRow, DataTableRowColumn } from "components/DataTable";

const styles = require("./style.less");
import { LifecycleStatus } from "utils/MapProgressionToStatus/MapProgressionToStatus";
import { PhaseResource } from "client/resources";
import PhaseStatusIcon from "components/PhaseStatusIcon/PhaseStatusIcon";

interface PhaseHeaderProps {
    phase: PhaseResource;
    lifecycleStatus: LifecycleStatus;
    isOptional: boolean;
    title: any;
    actionButton: any;
    environmentId?: string;
    [others: string]: any;
}

const PhaseHeader: React.StatelessComponent<PhaseHeaderProps> = props => {
    return (
        <DataTableBody>
            <DataTableRow className={props.className}>
                <DataTableRowColumn>
                    <PhaseStatusIcon phase={props.phase} status={props.lifecycleStatus} environmentId={props.environmentId} />
                    {props.title}
                </DataTableRowColumn>
                <DataTableRowColumn />
                <DataTableRowColumn />
                <DataTableRowColumn className={styles.deploymentRowButton}>
                    {props.actionButton}
                    <div className={styles.deploymentExpander} />
                </DataTableRowColumn>
            </DataTableRow>
        </DataTableBody>
    );
};

PhaseHeader.displayName = "PhaseHeader";
export default PhaseHeader;
