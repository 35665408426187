import * as React from "react";
import PaperLayout from "components/PaperLayout/PaperLayout";
import DataLoader from "components/DataLoader";

interface FormPageProps<TData> {
    title: string;
    load(): Promise<TData>;
    renderWhenLoaded(data: TData): React.ReactNode;
}
// TODO: Consolidate Page and FormPage into a single component. These are doing similar-but-different things.
export default function FormPage<TData>() {
    const DataLoaderInternal = DataLoader<TData>();

    return class LoadInner extends React.Component<FormPageProps<TData>> {
        render() {
            return <DataLoaderInternal load={this.props.load} renderWhenLoaded={this.props.renderWhenLoaded} renderAlternate={({ errors, busy }) => <PaperLayout title={this.props.title} errors={errors} busy={busy} />} />;
        }
    };
}
