// tslint:disable: no-non-null-assertion
import * as React from "react";
import { RouteComponentProps } from "react-router";
import LibraryLayout from "../LibraryLayout/LibraryLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import PaperLayout from "components/PaperLayout";
import { repository } from "clientInstance";
import { CertificateResource, ResourceCollection } from "client/resources";
import CertificateSearch from "components/form/CertificateSearch/CertificateSearch";
import CertificateSidebar from "./CertificateSidebar";
const styles = require("./style.less");
import SidebarLayout from "components/SidebarLayout/SidebarLayout";
import routeLinks from "../../../../routeLinks";

interface CertificatesState extends DataBaseComponentState {
    certificates?: ResourceCollection<CertificateResource>;
}

export default class ArchivedCertificates extends DataBaseComponent<RouteComponentProps<void>, CertificatesState> {
    constructor(props: RouteComponentProps<void>) {
        super(props);
        this.state = {
            certificates: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const certificates = await repository.Certificates.list({ archived: true });
            this.setState({
                certificates,
            });
        });
    }

    render() {
        const content =
            this.state.certificates &&
            (this.state.certificates.TotalResults > 0 ? (
                <CertificateSearch certificates={this.state.certificates} doBusyTask={this.doBusyTask} archived />
            ) : (
                <div className={styles.root}>
                    <span>The certificate archive is empty.</span>
                </div>
            ));

        return (
            <LibraryLayout {...this.props}>
                <PaperLayout title="Archived Certificates" breadcrumbTitle={"Certificates"} breadcrumbPath={routeLinks.library.certificates.root} busy={this.state.busy} errors={this.state.errors}>
                    <SidebarLayout sideBar={<CertificateSidebar />}>{content}</SidebarLayout>
                </PaperLayout>
            </LibraryLayout>
        );
    }
}
