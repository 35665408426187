// tslint:disable: no-non-null-assertion
import { DynamicWorkerPoolResource } from "client/resources";
import WorkerPoolEditBase, { WorkerPoolEditProps, WorkerPoolEditState } from "./WorkerPoolEditBase";
import { cloneDeep } from "lodash";
import { repository } from "clientInstance";
import { DynamicWorkerType } from "client/resources/workerPoolsSupportedTypesResouce";
import { Summary, Select, ExpandableFormSection } from "components/form";
import React = require("react");

interface DynamicWorkerPoolEditProps extends WorkerPoolEditProps<DynamicWorkerPoolResource> {}

interface DynamicWorkerPoolEditState extends WorkerPoolEditState<DynamicWorkerPoolResource> {
    workerType: string;
    workerTypes: DynamicWorkerType[];
}

class DynamicWorkerPoolEdit extends WorkerPoolEditBase<DynamicWorkerPoolResource, DynamicWorkerPoolEditState, DynamicWorkerPoolEditProps> {
    constructor(props: DynamicWorkerPoolEditProps) {
        super(props);
        this.state = {
            model: null!,
            cleanModel: null!,
            deleted: false,
            newId: null!,
            workerTypes: null!,
            workerType: null!,
        };
    }

    customExpandableFormSections(): JSX.Element[] {
        const baseElements: JSX.Element[] = [];
        const items = this.state.workerTypes ? this.state.workerTypes.map(t => ({ value: t.Id, text: t.Id })) : [];

        baseElements.push(
            <ExpandableFormSection key="WorkerType" errorKey="WorkerType" title="Dynamic Worker Type" summary={this.workerTypeSummary()} help="The worker type">
                <Select value={this.state.model.WorkerType} onChange={this.handleWorkerTypeChange} items={items} />
            </ExpandableFormSection>
        );

        return baseElements;
    }

    workerTypeSummary() {
        return this.state.model.WorkerType ? Summary.summary(this.state.model.WorkerType) : Summary.placeholder("No worker type");
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const dynamicWorkerTypesPromise = repository.WorkerPools.getDynamicWorkerTypes();
            const dynamicWorkerTypes = await dynamicWorkerTypesPromise;
            this.setState({
                model: this.props.workerPool,
                cleanModel: cloneDeep(this.props.workerPool),
                workerTypes: dynamicWorkerTypes,
            });
        });
    }

    private handleWorkerTypeChange = (value: string) => {
        const workerTypes = this.state.workerTypes.filter(l => l.Id === value);
        this.setModelState({ WorkerType: workerTypes.length > 0 ? workerTypes[0].Id : null! });
    };
}

export default DynamicWorkerPoolEdit;
