// tslint:disable: no-non-null-assertion
import * as React from "react";
import { ExpandableFormSection, Summary, FormSectionHeading, Note } from "components/form";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import Text from "../../../../components/form/Text/Text";
import { AccountType, AzureServicePrincipalAccountResource, AzureSubscriptionAccountResource, AzureEnvironment } from "client/resources/";
import AccountEditBase, { AccountEditModel } from "./AccountEditBase";
import { SensitiveValue } from "../../../../client/resources/propertyValueResource";
import SensitiveFileUpload from "../../../../components/form/SensitiveFileUpload/SensitiveFileUpload";
import RadioButtonGroup from "../../../../components/form/RadioButton/RadioButtonGroup";
import RadioButton from "../../../../components/form/RadioButton/RadioButton";
import Sensitive, { ObfuscatedPlaceholder } from "../../../../components/form/Sensitive/Sensitive";
import { Callout } from "components/Callout";
import { CalloutType } from "../../../../components/Callout/Callout";
import { client } from "clientInstance";
const styles = require("./style.less");
import { ActionButton } from "components/Button/ActionButton";
import Dialog from "components/Dialog/Dialog";
import { SaveAndTestAzureAccountDialog } from "./SaveAndTestAccountDialog";
import AccountUsage from "areas/infrastructure/components/AccountEdit/AccountUsage";
import Checkbox from "components/form/Checkbox/Checkbox";
import { Select } from "components/form";

interface ServicePrincipleAuth {
    clientId: string;
    tenantId: string;
    password: SensitiveValue;
    resourceManagementBaseUri: string;
    activeDirectoryBaseUri: string;
}

interface ManagementCertAuth {
    certificateBytes?: SensitiveValue; // optional cannot send null to API
    certificateThumbprint: string;
    serviceManagementBaseUri: string;
    serviceManagementEndpointSuffix: string;
}

interface AzureModel extends AccountEditModel {
    subscriptionNumber: string;
    azureEnvironment: string;
    accountType: AzureAccountTypes;
    managementCertAuth?: ManagementCertAuth;
    servicePrincipleAuth?: ServicePrincipleAuth;
}

export interface Item {
    value: string;
    text: string;
    icon?: JSX.Element;
    style?: object;
}

type AzureAccountTypes = AccountType.AzureSubscription | AccountType.AzureServicePrincipal;
type AzureAccountResources = AzureServicePrincipalAccountResource | AzureSubscriptionAccountResource;

class AzureAccountEdit extends AccountEditBase<AzureAccountResources, AzureModel> {
    getPartialModel(account?: AzureAccountResources): Partial<AzureModel> | undefined {
        if (!account) {
            return {
                accountType: AccountType.AzureServicePrincipal,
                managementCertAuth: {
                    serviceManagementBaseUri: "",
                    serviceManagementEndpointSuffix: "",
                    certificateThumbprint: null!,
                },
                servicePrincipleAuth: {
                    password: null!,
                    clientId: null!,
                    tenantId: null!,
                    activeDirectoryBaseUri: null!,
                    resourceManagementBaseUri: null!,
                },
                azureEnvironment: "",
            };
        }

        const model = {
            accountType: account.AccountType as AzureAccountTypes,
            subscriptionNumber: account.SubscriptionNumber,
            azureEnvironment: account.AzureEnvironment,
        };

        if (account.AccountType === AccountType.AzureServicePrincipal) {
            const spAccount = account as AzureServicePrincipalAccountResource;
            const usingAzureEnvironment = !!account.AzureEnvironment || !!spAccount.ActiveDirectoryEndpointBaseUri || !!spAccount.ResourceManagementEndpointBaseUri;
            this.setState({ usingAzureEnvironment });
            return {
                ...model,
                servicePrincipleAuth: {
                    password: spAccount.Password,
                    tenantId: spAccount.TenantId,
                    clientId: spAccount.ClientId,
                    activeDirectoryBaseUri: spAccount.ActiveDirectoryEndpointBaseUri,
                    resourceManagementBaseUri: spAccount.ResourceManagementEndpointBaseUri,
                },
            };
        } else if (account.AccountType === AccountType.AzureSubscription) {
            const mgtCertAccount = account as AzureSubscriptionAccountResource;
            const usingAzureEnvironment = !!account.AzureEnvironment || !!mgtCertAccount.ServiceManagementEndpointBaseUri || !!mgtCertAccount.ServiceManagementEndpointSuffix;
            this.setState({ usingAzureEnvironment });
            return {
                ...model,
                managementCertAuth: {
                    certificateBytes: mgtCertAccount.CertificateBytes,
                    certificateThumbprint: mgtCertAccount.CertificateThumbprint,
                    serviceManagementBaseUri: mgtCertAccount.ServiceManagementEndpointBaseUri,
                    serviceManagementEndpointSuffix: mgtCertAccount.ServiceManagementEndpointSuffix,
                },
            };
        }
    }

    getPartialResource(): (Partial<AzureAccountResources> & { AccountType: AccountType }) | undefined {
        const resource = {
            AccountType: this.state.model!.accountType,
            AzureEnvironment: this.state.model!.azureEnvironment,
            SubscriptionNumber: this.state.model!.subscriptionNumber,
        };

        if (this.state.model!.accountType === AccountType.AzureSubscription) {
            return {
                ...resource,
                CertificateBytes: this.state.model!.managementCertAuth!.certificateBytes,
                ServiceManagementEndpointBaseUri: this.state.model!.managementCertAuth!.serviceManagementBaseUri,
                ServiceManagementEndpointSuffix: this.state.model!.managementCertAuth!.serviceManagementEndpointSuffix,
            };
        } else if (this.state.model!.accountType === AccountType.AzureServicePrincipal) {
            return {
                ...resource,
                Password: this.state.model!.servicePrincipleAuth!.password,
                TenantId: this.state.model!.servicePrincipleAuth!.tenantId,
                ClientId: this.state.model!.servicePrincipleAuth!.clientId,
                ActiveDirectoryEndpointBaseUri: this.state.model!.servicePrincipleAuth!.activeDirectoryBaseUri,
                ResourceManagementEndpointBaseUri: this.state.model!.servicePrincipleAuth!.resourceManagementBaseUri,
            };
        }
    }

    subscriptionSummary() {
        return this.state.model!.subscriptionNumber ? Summary.summary(this.state.model!.subscriptionNumber) : Summary.placeholder("No subscription provided");
    }

    azureEnvironmentSummary() {
        return this.state.model!.azureEnvironment ? Summary.summary(this.state.model!.azureEnvironment) : Summary.placeholder("Using the default AzureCloud environment");
    }

    clientIdSummary() {
        return this.state.model!.servicePrincipleAuth!.clientId ? Summary.summary(this.state.model!.servicePrincipleAuth!.clientId) : Summary.placeholder("No client\\application ID provided");
    }

    tenantIdSummary() {
        return this.state.model!.servicePrincipleAuth!.tenantId ? Summary.summary(this.state.model!.servicePrincipleAuth!.tenantId) : Summary.placeholder("No tenant ID provided");
    }

    passwordSummary() {
        return this.state.model!.servicePrincipleAuth!.password && this.state.model!.servicePrincipleAuth!.password.HasValue ? Summary.summary(ObfuscatedPlaceholder) : Summary.placeholder("No password provided");
    }

    resourceManagementUriSummary() {
        return this.state.model!.servicePrincipleAuth!.resourceManagementBaseUri ? Summary.summary(this.state.model!.servicePrincipleAuth!.resourceManagementBaseUri) : Summary.placeholder("The default endpoint is not being overridden");
    }

    activeDirectoryUriSummary() {
        return this.state.model!.servicePrincipleAuth!.activeDirectoryBaseUri ? Summary.summary(this.state.model!.servicePrincipleAuth!.activeDirectoryBaseUri) : Summary.placeholder("The default endpoint is not being overridden");
    }

    serviceManagementUriSummary() {
        return this.state.model!.managementCertAuth!.serviceManagementBaseUri ? Summary.summary(this.state.model!.managementCertAuth!.serviceManagementBaseUri) : Summary.placeholder("The default base URI is not being overridden");
    }

    serviceManagementEndpointSuffixSummary() {
        return this.state.model!.managementCertAuth!.serviceManagementEndpointSuffix
            ? Summary.summary(this.state.model!.managementCertAuth!.serviceManagementEndpointSuffix)
            : Summary.placeholder("The default endpoint suffix is not being overridden");
    }

    managementCertificateSummary() {
        if (!this.state.model!.managementCertAuth!.certificateBytes) {
            return Summary.placeholder("No certificate provided");
        }

        if (!this.state.model!.managementCertAuth!.certificateBytes.HasValue) {
            return Summary.placeholder("No certificate provided");
        }

        if (!this.state.model!.managementCertAuth!.certificateBytes.NewValue) {
            return Summary.summary(this.state.model!.managementCertAuth!.certificateThumbprint);
        }

        return Summary.summary("New certificate selected to be uploaded");
    }

    azureEnvironmentCheckboxSummary() {
        return this.state.model!.azureEnvironment !== ""
            ? Summary.summary(
                  <span>
                      Selected the <b>{this.state.model!.azureEnvironment}</b> Azure Environment
                  </span>
              )
            : Summary.placeholder(
                  <span>
                      Using the default <b>AzureCloud</b> Environment
                  </span>
              );
    }

    resourceManagementUriLabel() {
        return this.state.model!.azureEnvironment === "" ? "Select an Azure Environment to be able to edit this field" : "Resource Management Endpoint Base Uri";
    }

    authenticationMethodSummary() {
        return this.state.model!.accountType === null
            ? Summary.placeholder("Select the Azure authentication method")
            : this.state.model!.accountType === AccountType.AzureServicePrincipal
            ? Summary.summary(<span>Use a Service Principal</span>)
            : Summary.summary(<span>Use a Management Certificate</span>);
    }

    activeDirectoryURILabel() {
        return this.state.model!.azureEnvironment === "" ? "Select an Azure Environment to be able to edit this field" : "Active Directory Endpoint Base Uri";
    }

    serviceManagementBaseUriLabel() {
        return this.state.model!.azureEnvironment === "" ? "Select an Azure Environment to be able to edit this field" : "Service Management Endpoint Base Uri";
    }

    serviceManagementEndpointSuffixLabel() {
        return this.state.model!.azureEnvironment === "" ? "Select an Azure Environment to be able to edit this field" : "Service Management Endpoint Suffix";
    }

    customSecondaryAction(): React.ReactElement | null {
        return (
            <div>
                <ActionButton onClick={() => this.handleSaveClick(true)} label="Save and Test" />
                {this.state.accountId && (
                    <Dialog open={this.state.showTestDialog}>
                        <SaveAndTestAzureAccountDialog onOkClick={() => this.testDone()} accountId={this.state.accountId} />
                    </Dialog>
                )}
            </div>
        );
    }

    customExpandableFormSections(): React.ReactElement[] {
        const baseElements: React.ReactElement[] = [];

        const sectionHeading = !this.state.accountId ? "Azure Details" : this.state.model!.accountType === AccountType.AzureServicePrincipal ? "Service Principal Details" : "Management Certificate Details";

        baseElements.push(
            <FormSectionHeading title={sectionHeading} key={"header"} />,
            <ExpandableFormSection errorKey="SubscriptionNumber" key="subscriptionID" title="Subscription ID" summary={this.subscriptionSummary()} help="Your Azure subscription ID. This is a GUID in the format xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx.">
                <Text value={this.state.model!.subscriptionNumber} onChange={subscriptionNumber => this.setModelState({ subscriptionNumber })} label="Subscription" error={this.getFieldError("SubscriptionNumber")} />
            </ExpandableFormSection>
        );

        if (!this.state.accountId) {
            baseElements.push(
                <ExpandableFormSection
                    isExpandedByDefault={true}
                    key="AuthenticationMethod"
                    title="Authentication Method"
                    errorKey="accountType"
                    summary={this.authenticationMethodSummary()}
                    help="Select the Azure authentication method to show the relevant form inputs below."
                >
                    <RadioButtonGroup value={this.state.model!.accountType} onChange={x => this.setModelState({ accountType: x as AzureAccountTypes })}>
                        <RadioButton value={AccountType.AzureServicePrincipal} label="Use a Service Principal" isDefault={true} />
                        <RadioButton value={AccountType.AzureSubscription} label="Use a Management Certificate" />
                    </RadioButtonGroup>
                </ExpandableFormSection>
            );
        }

        if (this.state.model!.accountType === AccountType.AzureServicePrincipal) {
            baseElements.push(this.servicePrincipalExpandableFormSections());
        } else if (this.state.model!.accountType === AccountType.AzureSubscription) {
            baseElements.push(this.managementCertificateExpandableFormSections());
        }

        baseElements.push(
            <FormSectionHeading title="Azure Environment" key={"EnvironmentHeader"} />,
            <ExpandableFormSection
                errorKey="AzureEnvironment"
                key={"AzureEnvironment"}
                title="Azure Environment"
                summary={this.azureEnvironmentCheckboxSummary()}
                help={
                    <span>
                        Check this box only if you are using an isolated Azure Environment. <ExternalLink href="AzureEnvironments">Learn more about them here</ExternalLink>.
                    </span>
                }
            >
                <Checkbox label="Configure Isolated Azure Environment connection." value={this.state.usingAzureEnvironment} onChange={v => this.setAzureEnvironmentCheckbox(v)} />
                {this.state.usingAzureEnvironment && (
                    <div>
                        <Select value={this.state.model!.azureEnvironment} onChange={v => this.setAzureEnvironmentFields(v!)} items={this.toItemArray(this.state.azureEnvironmentsList)} label="Azure Environment" allowClear={true} />
                    </div>
                )}
            </ExpandableFormSection>
        );

        if (this.state.model!.accountType === AccountType.AzureServicePrincipal && this.state.usingAzureEnvironment) {
            baseElements.push(this.servicePrincipalExpandableEnvironmentFormSections());
        } else if (this.state.model!.accountType === AccountType.AzureSubscription && this.state.usingAzureEnvironment) {
            baseElements.push(this.managementCertificateExpandableEnvironmentFormSections());
        }

        return baseElements;
    }

    servicePrincipalExpandableFormSections(): React.ReactElement {
        return (
            <div key="SP">
                <ExpandableFormSection errorKey="TenantId" key="tenantid" title="Tenant ID" summary={this.tenantIdSummary()} help={`Your Azure Active Directory Tenant ID. This is a GUID in the format xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx.`}>
                    <Text value={this.state.model!.servicePrincipleAuth!.tenantId} onChange={value => this.setServicePrincipleAuth({ tenantId: value })} label="Active Directory Tenant ID" error={this.getFieldError("TenantId")} />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="ClientId"
                    key="clientId"
                    title="Application ID"
                    summary={this.clientIdSummary()}
                    help={`Your Azure Active Directory Application ID. This is a GUID in the format xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx.
                    This value is known as Application ID in the Azure Portal and the API, and previously referred to as Client ID in the old Azure Portal.`}
                >
                    <Text value={this.state.model!.servicePrincipleAuth!.clientId} onChange={value => this.setServicePrincipleAuth({ clientId: value })} label="Active Directory Appication ID" error={this.getFieldError("ClientId")} />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="password"
                    key="password"
                    title="Application Password / Key"
                    summary={this.passwordSummary()}
                    help={`The password for the Azure Active Directory application. This value is known as Key in the Azure Portal, and Password in the API.`}
                >
                    <Sensitive value={this.state.model!.servicePrincipleAuth!.password} label="Active Directory Password\\Key" onChange={value => this.setServicePrincipleAuth({ password: value })} />
                </ExpandableFormSection>
            </div>
        );
    }

    managementCertificateExpandableFormSections(): JSX.Element {
        return (
            <div key="MC">
                <ExpandableFormSection
                    errorKey="managementCert"
                    key="managementCert"
                    title="Management certificate (.pfx)"
                    summary={this.managementCertificateSummary()}
                    help="Leave blank to let Octopus generate a new certificate or provide a password free .pfx file."
                >
                    <SensitiveFileUpload
                        availablePlaceholder={this.state.model!.managementCertAuth!.certificateThumbprint}
                        label="Management certificate (.pfx)"
                        value={this.state.model!.managementCertAuth!.certificateBytes!}
                        onChange={value => this.setManagementCertAuth({ certificateBytes: value })}
                    />
                    <Callout type={CalloutType.Information} title="Using your certificate">
                        <p>To give permission for Octopus to deploy to your Azure subscription, upload the public key portion (.cer) of the certificate to the Azure portal:</p>
                        <ol className={styles.certInstructionList}>
                            <li>
                                Download the certificate public key:
                                <br />
                                {this.state.model && this.state.account && <ExternalLink href={client.resolve(this.state.account.Links.PublicKey)}>{this.state.model!.managementCertAuth!.certificateThumbprint}</ExternalLink>}
                            </li>
                            <li>
                                Log in to the <ExternalLink href="ManageAzure">Azure management portal</ExternalLink>
                            </li>
                            <li>
                                Click <strong>All services</strong>
                            </li>
                            <li>
                                Click <strong>Subscriptions</strong> from the list, then select the subscription that you want to associate with the certificate
                            </li>
                            <li>
                                Click <strong>Management certificates</strong>
                            </li>
                            <li>
                                Click the <strong>Upload</strong> toolbar button
                            </li>
                            <li>
                                Click <strong>Upload</strong> and wait for your certificate to upload
                            </li>
                        </ol>
                    </Callout>
                </ExpandableFormSection>
            </div>
        );
    }

    servicePrincipalExpandableEnvironmentFormSections(): JSX.Element {
        return (
            <div key="SPEE">
                <ExpandableFormSection
                    errorKey="ADbaseUri"
                    key="ADbaseUri"
                    title="AD Endpoint Base Uri"
                    summary={this.activeDirectoryUriSummary()}
                    help="Set this only if you need to override the default Active Directory Endpoint. In most cases you should leave the pre-populated value as is"
                >
                    <Text
                        value={this.state.model!.servicePrincipleAuth!.activeDirectoryBaseUri}
                        onChange={value => this.setServicePrincipleAuth({ activeDirectoryBaseUri: value })}
                        label={this.activeDirectoryURILabel()}
                        disabled={this.state.model!.azureEnvironment === ""}
                        error={this.getFieldError("ActiveDirectoryEndpointBaseUri")}
                    />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="RMbaseUri"
                    key="RMbaseUri"
                    title="Resource Management Base Uri"
                    summary={this.resourceManagementUriSummary()}
                    help="Set this only if you need to override the default Resource Management Endpoint. In most cases you should leave the pre-populated value as is"
                >
                    <Text
                        value={this.state.model!.servicePrincipleAuth!.resourceManagementBaseUri}
                        onChange={value => this.setServicePrincipleAuth({ resourceManagementBaseUri: value })}
                        label={this.resourceManagementUriLabel()}
                        disabled={this.state.model!.azureEnvironment === ""}
                        error={this.getFieldError("ResourceManagementEndpointBaseUri")}
                    />
                </ExpandableFormSection>
            </div>
        );
    }

    managementCertificateExpandableEnvironmentFormSections(): JSX.Element {
        return (
            <div key="MCEE">
                <ExpandableFormSection
                    errorKey="SMbaseUri"
                    key="SMbaseUri"
                    title="Service Management Base Uri"
                    summary={this.serviceManagementUriSummary()}
                    help="Set this only if you need to override the default Service Management Base URI. In most cases you should leave the pre-populated value as is"
                >
                    <Text
                        value={this.state.model!.managementCertAuth!.serviceManagementBaseUri}
                        onChange={value => this.setManagementCertAuth({ serviceManagementBaseUri: value })}
                        label={this.serviceManagementBaseUriLabel()}
                        disabled={this.state.model!.azureEnvironment === ""}
                        error={this.getFieldError("ServiceManagementEndpointBaseUri")}
                    />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="SMendpointSuffix"
                    key="SMendpointSuffix"
                    title="Storage Endpoint Suffix"
                    summary={this.serviceManagementEndpointSuffixSummary()}
                    help="Set this only if you need to override the default Storage Endpoint Suffix. In most cases you should leave the pre-populated value as is"
                >
                    <Text
                        value={this.state.model!.managementCertAuth!.serviceManagementEndpointSuffix}
                        onChange={value => this.setManagementCertAuth({ serviceManagementEndpointSuffix: value })}
                        label={this.serviceManagementEndpointSuffixLabel()}
                        disabled={this.state.model!.azureEnvironment === ""}
                        error={this.getFieldError("ServiceManagementEndpointSuffix")}
                    />
                </ExpandableFormSection>
            </div>
        );
    }

    setManagementCertAuth<K extends keyof ManagementCertAuth>(state: Pick<ManagementCertAuth, K>) {
        this.setState(prev => ({
            model: {
                ...prev.model,
                managementCertAuth: {
                    ...prev.model!.managementCertAuth,
                    ...(state as object),
                },
            },
        }));
    }

    setServicePrincipleAuth<K extends keyof ServicePrincipleAuth>(state: Pick<ServicePrincipleAuth, K>) {
        this.setState(prev => ({
            model: {
                ...prev.model,
                servicePrincipleAuth: {
                    ...prev.model!.servicePrincipleAuth,

                    ...(state as object),
                },
            },
        }));
    }

    clearAzureEnvironmentFields() {
        this.setModelState({ azureEnvironment: "" });
        this.setManagementCertAuth({ serviceManagementEndpointSuffix: "", serviceManagementBaseUri: "" });
        this.setServicePrincipleAuth({ activeDirectoryBaseUri: "", resourceManagementBaseUri: "" });
    }

    setAzureEnvironmentCheckbox(v: boolean) {
        if (this.state.usingAzureEnvironment === true && v === false) {
            this.clearAzureEnvironmentFields();
        }
        this.setState({ usingAzureEnvironment: v });
    }

    setAzureEnvironmentFields(environmentName: string) {
        if (environmentName === "") {
            this.clearAzureEnvironmentFields();
        } else {
            const environment = this.state.azureEnvironmentsList.find(x => x.Name === environmentName);
            this.setModelState({ azureEnvironment: environmentName });
            if (this.state.model!.accountType === AccountType.AzureServicePrincipal) {
                this.setServicePrincipleAuth({ activeDirectoryBaseUri: environment!.AuthenticationEndpoint });
                this.setServicePrincipleAuth({ resourceManagementBaseUri: environment!.ResourceManagerEndpoint });
            } else {
                this.setManagementCertAuth({ serviceManagementEndpointSuffix: environment!.StorageEndpointSuffix });
                this.setManagementCertAuth({ serviceManagementBaseUri: environment!.ManagementEndpoint });
            }
        }
    }

    toItemArray(azureEnvironments: AzureEnvironment[]): Item[] {
        const items: Item[] = [];
        azureEnvironments.forEach(ae => {
            items.push({ value: ae.Name, text: ae.DisplayName });
        });
        return items;
    }
}

export default AzureAccountEdit;
