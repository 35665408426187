// tslint:disable: no-non-null-assertion
import * as React from "react";
import { useRequiredContext } from "hooks/useRequiredContext";
import { useDoBusyTaskEffect, DoBusyTask } from "components/DataBaseComponent";
import ActionTemplateSearchResource from "client/resources/actionTemplateSearchResource";
import { repository } from "clientInstance";

type ActionContextProps = ReturnType<typeof useActionsSetup>;

const ActionContext = React.createContext<ActionContextProps>(null!);

interface ActionContextState {
    actionTemplates: ActionTemplateSearchResource[];
}

interface ActionContextProviderProps {
    doBusyTask: DoBusyTask;
    children: (props: ActionContextProps) => React.ReactNode;
}

const getStateUpdaters = (setState: React.Dispatch<React.SetStateAction<ActionContextState>>) => {
    return {
        onActionTemplatesUpdated: (actionTemplates: ActionTemplateSearchResource[]) => setState(current => ({ ...current, actionTemplates })),
    };
};

const useActionsSetup = (doBusyTask: DoBusyTask) => {
    const [state, setState] = React.useState<ActionContextState>({
        actionTemplates: [],
    });

    const updaters = getStateUpdaters(setState);

    const refreshActionTemplates = useDoBusyTaskEffect(
        doBusyTask,
        async () => {
            const templates = await repository.ActionTemplates.search();
            updaters.onActionTemplatesUpdated(templates);
        },
        []
    );

    return {
        actions: { ...updaters, refreshActionTemplates },
        state,
        setState,
    };
};

export const ActionContextProvider: React.FC<ActionContextProviderProps> = ({ children, doBusyTask }) => {
    const value = useActionsSetup(doBusyTask);
    return <ActionContext.Provider value={value}>{children(value)}</ActionContext.Provider>;
};

export interface WithActionContextInjectedProps {
    actionContext: ActionContextProps;
}

export const withActionContext = <T extends unknown>(Component: React.ComponentType<T & WithActionContextInjectedProps>) => {
    const WithActionContext: React.FC<T> = props => {
        const context = useActionContext();
        return <Component actionContext={context} {...props} />;
    };

    return WithActionContext;
};

export const useActionContext = () => {
    return useRequiredContext(ActionContext, "Action");
};
