// tslint:disable: no-non-null-assertion
// tslint:disable: no-any

import * as React from "react";
import { TaskStatusIcon } from "areas/projects/components/TaskStatusIcon/TaskStatusIcon";
import DateFormatter from "utils/DateFormatter";
import { TaskResource } from "client/resources";
import cn from "classnames";
const styles = require("./style.less");

interface TaskDetailsProps {
    task: TaskResource<any>;
    stripTopBottomPadding?: boolean;
}

const TaskDetails: React.StatelessComponent<TaskDetailsProps> = (props: TaskDetailsProps) => {
    const task = props.task;

    return (
        <div className={cn(styles.taskDetailsContainer, { [styles.stripTopBottomPadding]: props.stripTopBottomPadding })}>
            <TaskStatusIcon item={task} />
            <div className={styles.details}>
                <span className={styles.description}>{task.Description}</span>
                <span title={DateFormatter.dateToLongFormat(task.StartTime || task.QueueTime)!} className={styles.date}>
                    {DateFormatter.dateToCustomFormat(task.StartTime || task.QueueTime, "MMMM Do YYYY")}
                </span>
            </div>
        </div>
    );
};

TaskDetails.displayName = "TaskDetails";

export default TaskDetails;
