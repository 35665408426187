// tslint:disable: no-non-null-assertion
// tslint:disable: no-any

import InternalLink from "../Navigation/InternalLink";
import * as React from "react";
import { Text, required } from "components/form";

import { BaseComponent } from "components/BaseComponent/BaseComponent";
import FeedResource, { FeedType, isOctopusProjectFeed, getFeedTypeLabel } from "client/resources/feedResource";
import { Callout, CalloutType } from "components/Callout";
import Note from "components/form/Note/Note";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import routeLinks from "../../routeLinks";
import { AutoCompleteOption } from "components/AutoComplete/AutoComplete";
import { repository } from "clientInstance";
import { BoundAutoComplete } from "components/AutoComplete";
import { feedTypeCanSearchEmpty, isContainerImageRegistry } from "../../client/resources/feedResource";
import { BoundSelectWithAddRefresh } from "../form/SelectWithAddRefresh/SelectWithAddRefresh";
import ActionButton, { ActionButtonType } from "../Button";
import * as _ from "lodash";

export enum PackageSelectorType {
    AutoComplete = "AutoComplete",
    FreeText = "FreeText",
}
export interface PackageSelectorProps {
    packageId: string | undefined;
    feedId: string | undefined;
    projectId: string | undefined;
    feeds: FeedResource[];
    localNames: string[] | undefined;
    feedType?: FeedType[];
    autoFocus?: boolean;
    packageIdError?: string;
    feedIdError?: string;
    packageSelectorType?: PackageSelectorType;
    feedSelectLabel?: string;
    packageSelectLabel?: string;
    onPackageIdChange(value: string): void;
    onFeedIdChange(value: string): void;
    refreshFeeds(): Promise<boolean>;
}

interface PackageSelectorState {
    looksLikeVersion: boolean;
}

export const noFeedsWarning = (feedType?: FeedType[], refreshFeeds?: () => Promise<any>) => {
    return (
        <Callout type={CalloutType.Warning} title={`${getFeedTypeLabel(feedType)} Feed required`}>
            Please add an appropriate feed in the{" "}
            <InternalLink to={routeLinks.library.feeds} openInSelf={false}>
                External Feeds
            </InternalLink>{" "}
            area and try again.
            <div>
                <ActionButton type={ActionButtonType.Ternary} label="Refresh" onClick={refreshFeeds} />
            </div>
        </Callout>
    );
};

export const possibleFeeds = (feeds: FeedResource[], feedType?: FeedType[]) => {
    return feeds.filter(
        f =>
            !isOctopusProjectFeed(f.FeedType) && // We will never show the OctopusProject feed when selecting packages
            (!feedType || feedType.length === 0 || feedType.some(feedTypeToCheck => feedTypeToCheck === f.FeedType))
    );
};

export const getDefaultFeedId = (feeds: FeedResource[]): string | undefined => {
    if (feeds.length === 0) {
        return undefined;
    }

    const buildtIn = feeds.find(f => f.FeedType === FeedType.BuiltIn);
    if (buildtIn) {
        return buildtIn.Id;
    }

    return feeds[0].Id;
};

class PackageSelector extends BaseComponent<PackageSelectorProps, PackageSelectorState> {
    constructor(props: PackageSelectorProps) {
        super(props);

        this.state = {
            looksLikeVersion: false,
        };
    }

    componentDidMount() {
        // Default to built-in feed.
        if (!this.props.feedId) {
            const defaultFeedId = getDefaultFeedId(possibleFeeds(this.props.feeds, this.props.feedType));
            if (defaultFeedId) {
                this.props.onFeedIdChange(defaultFeedId);
            }
        }
    }

    checkIfLooksLikeVersion = () => {
        let looksLikeVersion = false;
        const packageId = this.props.packageId;
        if (packageId) {
            if (packageId.match(/\.[0-9]+\.?$/)) {
                looksLikeVersion = true;
            }
        }
        this.setState({ looksLikeVersion });
    };

    render() {
        const feeds = possibleFeeds(this.props.feeds, this.props.feedType);

        if (feeds == null || feeds.length === 0) {
            return noFeedsWarning(this.props.feedType, this.props.refreshFeeds);
        }

        const defaultFeedId = getDefaultFeedId(feeds);
        let selectedFeedId = this.props.feedId;
        if (selectedFeedId === null) {
            selectedFeedId = defaultFeedId!;
        }
        const selectedFeed: FeedResource = this.getSelectedFeed(feeds)!;

        const feedSelectLabel = this.props.feedSelectLabel || "Package feed";

        return (
            <div>
                <BoundSelectWithAddRefresh
                    variableLookup={{
                        localNames: this.props.localNames,
                    }}
                    resetValue={defaultFeedId!}
                    value={selectedFeedId}
                    onChange={this.feedChanged}
                    items={feeds.map(f => ({ value: f.Id, text: f.Name }))}
                    error={this.props.feedIdError}
                    autoFocus={this.props.autoFocus}
                    label={feedSelectLabel}
                    addUrl={`#${routeLinks.library.feeds}`}
                    onRequestRefresh={this.props.refreshFeeds}
                />
                <Note>
                    The feed containing this package. Learn about <ExternalLink href="DynamicPackageFeeds">Dynamically Selecting Packages at Deployment Time</ExternalLink>.
                </Note>
                {this.renderPackageIdSelector(selectedFeed)}
            </div>
        );
    }

    private renderPackageIdSelector(selectedFeed: FeedResource) {
        const label = this.props.packageSelectLabel || "Package ID";

        if (this.props.packageSelectorType && this.props.packageSelectorType === PackageSelectorType.FreeText) {
            return (
                <>
                    <Text label={this.props.packageSelectLabel} value={this.props.packageId!} onChange={this.packageChanged} validate={required("Please enter the ID of the package.")} autoFocus={false} />
                </>
            );
        }

        return (
            <>
                <BoundAutoComplete
                    variableLookup={{
                        localNames: this.props.localNames,
                    }}
                    resetValue=""
                    label={label}
                    value={this.props.packageId || ""}
                    onChange={this.packageChanged}
                    hintText="Enter package ID"
                    allowAnyTextValue={true}
                    getOptions={searchText => this.getPackageOptions(searchText, selectedFeed)}
                    error={this.props.packageIdError}
                    showEmpty={selectedFeed && feedTypeCanSearchEmpty(selectedFeed.FeedType)}
                />
                <Note>
                    Enter the ID of the package.
                    {selectedFeed && selectedFeed.FeedType === FeedType.Maven && (
                        <span>
                            {" "}
                            The format of the package ID is <em>Group:Artifact</em> for example <em>com.google.guava:guava</em> or <em>junit:junit</em>.
                        </span>
                    )}
                </Note>
                {this.state.looksLikeVersion && (
                    <Callout type={CalloutType.Information} title="Package version not required">
                        Hey there! It looks like you might be including the package version number in this field. You shouldn't include the package version number here, just the package ID.
                    </Callout>
                )}
            </>
        );
    }

    private getPackageOptions = async (searchText: string, feed: FeedResource) => {
        if (feed === null) {
            const items: AutoCompleteOption[] = [];
            return {
                items,
                containsAllResults: true,
            };
        }

        const packages = await repository.Feeds.searchPackages(feed, { term: searchText || "", take: 11, skip: 0 });
        return {
            items: packages.Items.slice(0, 10).map(p => ({ Id: p.Id, Name: p.Id })),
            containsAllResults: packages.Items.length !== 11,
        };
    };

    private packageChanged = (packageId: string) => {
        this.props.onPackageIdChange(packageId);
        if (this.props.packageSelectorType && this.props.packageSelectorType !== PackageSelectorType.FreeText) {
            this.checkIfLooksLikeVersion();
        }
    };

    private feedChanged = (feedId: string) => {
        this.props.onFeedIdChange(feedId);
    };

    private getSelectedFeed = (feeds: FeedResource[]) => {
        if (feeds == null || feeds.length === 0) {
            return null;
        }

        if (this.props.feedId) {
            const feed = feeds.find(f => f.Id === this.props.feedId);
            if (feed) {
                return feed;
            }
        }

        return null;
    };
}

export default PackageSelector;
