// tslint:disable: no-non-null-assertion
// tslint:disable: no-any

import * as React from "react";
import { DeploymentStepResource, IProcessResource, isDeploymentProcessResource, isRunbookProcessResource } from "client/resources";
import { useDoBusyTaskEffect, DoBusyTask } from "components/DataBaseComponent";
import { repository } from "clientInstance";
import { useRequiredContext } from "hooks";
import { isEqual } from "lodash";
import { ActionScope } from "components/Actions/pluginRegistry";

export interface FilterNamedResource {
    Id?: string;
    Name?: string;
}

export interface StepsEditorFilter {
    filterKeyword: string;
    environment?: FilterNamedResource | null;
    channel?: FilterNamedResource | null;
    includeUnscoped?: boolean;
}

const notEmpty = <TValue extends unknown>(value: TValue | null | undefined): value is TValue => {
    return value !== null && value !== undefined;
};

type FilteredStep = { step: DeploymentStepResource; index: number; filtered: boolean };
type FilterResult = {
    filterCount: number;
    steps: FilteredStep[];
};

const EMPTY_FILTER_RESULT: FilterResult = { filterCount: 0, steps: [] };

export function getEmptyFilter(): StepsEditorFilter {
    return {
        filterKeyword: "",
        environment: null,
        channel: null,
        includeUnscoped: true,
    };
}

interface ProcessContextState {
    process: IProcessResource;
    filter: StepsEditorFilter;
}

export type ProcessContextProps = ReturnType<typeof useSteps>;
export const ProcessContext = React.createContext<ProcessContextProps | undefined>(undefined);

export const useProcessContext = () => {
    return useRequiredContext(ProcessContext, "Process");
};

export const useOptionalProcessContext = () => {
    return React.useContext(ProcessContext);
};

const useProcessState = (initialFilter: StepsEditorFilter) => {
    return React.useState<ProcessContextState>({ filter: initialFilter, process: null! });
};

const useFilteredSteps = (process: IProcessResource, filter: StepsEditorFilter) => {
    return React.useMemo(() => getFilteredSteps(process, filter), [filter, process]);
};

const getStepContainsKeywordPredicate = (keyword: string = "") => {
    return (step: DeploymentStepResource) => {
        const keywordLower = keyword.toLowerCase();
        return step.Name.toLowerCase().includes(keywordLower) || step.Actions.filter(a => !!a.Name && a.Name.toLowerCase().includes(keywordLower)).length > 0;
    };
};

const getStepEnvironmentPredicate = (environment: FilterNamedResource, includeUnscoped: boolean = true) => {
    return (step: DeploymentStepResource) => {
        return step.Actions.some(
            a =>
                (includeUnscoped && a.Environments.length === 0 && !a.ExcludedEnvironments.some(e => e === environment.Id)) || // unscoped and not excluded
                (a.Environments.some(e => e === environment.Id) && !a.ExcludedEnvironments.some(e => e === environment.Id)) // scoped and not excluded
        );
    };
};

const getStepHasChannelPredicate = (channel: FilterNamedResource, includeUnscoped: boolean = true) => {
    return (step: DeploymentStepResource) => {
        return step.Actions.some(
            a =>
                (includeUnscoped && a.Channels.length === 0) || // unscoped
                a.Channels.some(e => e === channel.Id) // scoped
        );
    };
};

type StepPredicate = (step: DeploymentStepResource) => boolean;

export const getFilteredSteps = (stepCollection: IProcessResource, filter: StepsEditorFilter = { filterKeyword: "" }) => {
    const { channel, environment, includeUnscoped, filterKeyword } = filter;
    const filters: StepPredicate[] = [
        getStepContainsKeywordPredicate(filterKeyword),
        //TODO: Channel not applicable for Runbook so yeah...
        channel ? getStepHasChannelPredicate(channel, includeUnscoped) : null,
        environment ? getStepEnvironmentPredicate(environment, includeUnscoped) : null,
    ].filter(notEmpty);

    return stepCollection && stepCollection.Steps
        ? stepCollection.Steps.reduce((prev, step, index) => {
              const shouldFilter = filters.every(predicate => predicate(step));
              return {
                  filterCount: shouldFilter ? prev.filterCount + 1 : prev.filterCount,
                  steps: [
                      ...prev.steps,
                      {
                          step,
                          index: index + 1,
                          filtered: shouldFilter,
                      },
                  ],
              };
          }, EMPTY_FILTER_RESULT)
        : EMPTY_FILTER_RESULT;
};

const useLoadStepsEffect = (scope: ActionScope, id: string, doBusyTask: DoBusyTask, onLoaded: (process: IProcessResource) => void) => {
    return useDoBusyTaskEffect(
        doBusyTask,
        async () => {
            let result: IProcessResource = null!;
            if (!id) {
                return;
            }

            if (scope === ActionScope.Deployments) {
                result = await repository.DeploymentProcesses.get(id);
            } else if (scope === ActionScope.Runbooks) {
                result = await repository.RunbookProcess.get(id);
            }

            if (onLoaded) {
                onLoaded(result);
            }
        },
        [id, scope]
    );
};

const getStateUpdaters = (setState: React.Dispatch<React.SetStateAction<ProcessContextState>>) => {
    return {
        onFilterChange: (callback: (prev: StepsEditorFilter) => StepsEditorFilter) => setState(current => ({ ...current, filter: callback(current.filter) })),
        onClearFilter: () => setState(current => ({ ...current, filter: getEmptyFilter() })),
        onStepsChange: (process: IProcessResource) => {
            setState(current => {
                return { ...current, process };
            });
        },
    };
};

const saveProcessType = async (steps: IProcessResource): Promise<IProcessResource> => {
    let result: IProcessResource = null!;
    if (isDeploymentProcessResource(steps)) {
        result = await repository.DeploymentProcesses.modify(steps);
    } else if (isRunbookProcessResource(steps)) {
        result = await repository.RunbookProcess.modify(steps);
    }

    return result;
};

const useSteps = (scope: ActionScope, id: string, doBusyTask: DoBusyTask, initialFilter?: StepsEditorFilter) => {
    const [state, setState] = useProcessState(initialFilter!);
    const filteredSteps = useFilteredSteps(state.process, state.filter);
    const refreshSteps = useLoadStepsEffect(scope, id, doBusyTask, process => setState(current => ({ ...current, process })));
    const updaters = getStateUpdaters(setState);

    const saveSteps = async (process: IProcessResource): Promise<any> => {
        const success = await doBusyTask(async () => {
            const result = await saveProcessType(process);
            //If for whatever reason we are modifying another deployment process i.e. cloning a step for example
            //then we really shouldn't try and update the current process for the context.
            if (process && state.process && state.process.Id === process.Id) {
                updaters.onStepsChange(result);
            }
            return true;
        });
        if (!success) {
            // Any failures, revert changes
            const result = await (scope === ActionScope.Deployments ? repository.DeploymentProcesses.get(id) : repository.RunbookProcess.get(id));
            updaters.onStepsChange(result);
            return false;
        }
    };

    return {
        state,
        setState,
        filteredSteps,
        isFiltering: !isEqual(state.filter, getEmptyFilter()),
        getEmptyFilter,
        actions: {
            ...updaters,
            refreshSteps,
            saveSteps,
        },
    };
};

interface ProcessContextProviderProps {
    initialFilter?: StepsEditorFilter;
    id: string;
    doBusyTask: DoBusyTask;
    children: (renderProps: ProcessContextProps) => React.ReactNode;
    scope: ActionScope;
}

export const ProcessContextProvider: React.FC<ProcessContextProviderProps> = ({ children, initialFilter = getEmptyFilter(), doBusyTask, id, scope }) => {
    const value = useSteps(scope, id, doBusyTask, initialFilter);
    return <ProcessContext.Provider value={value}>{children(value)}</ProcessContext.Provider>;
};

export type WithProcessContextInjectedProps = { processContext: ProcessContextProps };

export const withProcessContext = <T extends unknown>(Component: React.ComponentType<T & WithProcessContextInjectedProps>) => {
    const WithProcessContext: React.FC<T> = props => {
        const context = useProcessContext();
        return <Component processContext={context} {...props} />;
    };
    return WithProcessContext;
};
