// tslint:disable: no-any

import * as React from "react";
import cn from "classnames";

const styles = require("./style.less");

export class DataTableFooterRow extends React.Component<any, any> {
    render() {
        return <tr className={cn(this.props.className, styles.dataTableFooterRow)}>{this.props.children}</tr>;
    }
}
