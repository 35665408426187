// tslint:disable: no-non-null-assertion
import { WorkerPoolResource, Permission } from "../../../../client/resources/index";
import { ActionExecutionLocation } from "../../../../client/resources";
import * as React from "react";
import { ExpandableFormSection, Summary, StringRadioButtonGroup, RadioButton, Note, Select, BooleanRadioButtonGroup, UnstructuredFormSection } from "components/form";
import ParseHelper from "utils/ParseHelper/ParseHelper";
import { RunOn, TargetRoles, RunOnDeploymentTarget, RunOnServerOrWorkerPool, ExecutionLocation, generateDefaultActionContainer } from "areas/projects/components/DeploymentProcess/ActionDetails";
import { RoleChip, WorkerPoolChip, EarlyAccessChip } from "components/Chips";
import { RoleMultiSelect } from "components/MultiSelect";
import MaxParallelism from "./MaxParallelism";
const styles = require("./style.less");
import ExternalLink from "../../../../components/Navigation/ExternalLink/ExternalLink";
import { CardFill } from "../../../../components/form/Sections/ExpandableFormSection";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import ActionButton, { ActionButtonType } from "components/Button";
import { ExecuteOctopusServer } from "components/Images/Images/ExecutionLocation/ExecuteOctopusServer";
import { ExecuteOctopusServerRoles } from "components/Images/Images/ExecutionLocation/ExecuteOctopusServerRoles";
import { ExecuteTargets } from "components/Images/Images/ExecutionLocation/ExecuteTargets";
import { ExecuteWorker } from "components/Images/Images/ExecutionLocation/ExecuteWorker";
import WorkerPoolVariableSelect from "components/form/WorkerPoolSelect/WorkerPoolVariableSelect";
import { ExecuteWorkerRoles } from "components/Images/Images/ExecutionLocation/ExecuteWorkerRoles";
import FeedResource from "client/resources/feedResource";

import CommonSummaryHelper from "utils/CommonSummaryHelper";

import { ExecutionContainerImageSelector, StepExecutionOption } from "components/ContainerSelector";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";

import { connect } from "react-redux";
import configurationSelectors from "areas/configuration/reducers/selectors";
import { isRunningOnBuiltInWorker } from "components/ContainerSelector/ExecutionContainerImageSelector";

interface GlobalConnectedProps {
    isActionContainersEnabled?: boolean;
}

interface ExecutionPlanProps extends GlobalConnectedProps {
    projectId: string;
    expandedByDefault: boolean;
    executionLocation: ActionExecutionLocation;
    runOnServerOrWorkerPoolCopy: RunOnServerOrWorkerPool | null;
    runOn: RunOn;
    targetRoleOption: TargetRoles;
    targetRoles: string;
    targetWorkerPool: string | null;
    targetWorkerPoolVariable: string | null;
    disableAddTargetRoles?: boolean;
    isChildStep: boolean;
    maxParallelism: string;
    availableRoles: string[];
    canRunOnWorker: boolean;
    availableWorkerPools: WorkerPoolResource[];
    isBuiltInWorkerEnabled: boolean;
    targetRolesError: string;
    imageNameError: string;
    runsOnServer: boolean;
    feeds: FeedResource[];
    canRunInContainer: boolean;
    loadFeeds: (callback?: (feeds: FeedResource[]) => void) => Promise<boolean>;
    getFieldError(field: string): string;
    onRunOnChanged<T extends RunOn>(runOn: T): void;
    onTargetRolesChanged(roles: string[]): void;
    onTargetWorkerPoolChanged(workerPoolId: string, workerPoolVariable: string | null): void;
    onMaxParallelismChanged(max: string): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}

interface ExecutionPlanState {
    showWindowSize: boolean;
    targetWorkerPool: string;
    targetWorkerPoolVariable: string | null;
    poolSelect: WorkerPoolSelect;
}

enum WorkerPoolSelect {
    Pool = "Pool",
    Variable = "Variable",
}

class ExecutionPlan extends React.Component<ExecutionPlanProps, ExecutionPlanState> {
    phaseEnvironments: string[] = [];
    environmentNameMap = {};

    constructor(props: ExecutionPlanProps) {
        super(props);

        this.state = {
            showWindowSize: props.maxParallelism ? props.maxParallelism.length > 0 : false,
            targetWorkerPool: !(props.runOn instanceof RunOnDeploymentTarget) ? props.targetWorkerPool || this.getDefaultWorkerIdPoolForRunOn(props.runOn)! : null!,
            targetWorkerPoolVariable: props.targetWorkerPoolVariable,
            poolSelect: !!props.targetWorkerPoolVariable ? WorkerPoolSelect.Variable : WorkerPoolSelect.Pool,
        };
    }

    shouldComponentUpdate(nextProps: ExecutionPlanProps, nextState: ExecutionPlanState) {
        return (
            nextProps.runOn !== this.props.runOn ||
            nextProps.targetRoles !== this.props.targetRoles ||
            nextProps.targetRoleOption !== this.props.targetRoleOption ||
            nextProps.maxParallelism !== this.props.maxParallelism ||
            nextProps.targetRolesError !== this.props.targetRolesError ||
            nextProps.imageNameError !== this.props.imageNameError ||
            nextProps.feeds !== this.props.feeds ||
            nextState.showWindowSize !== this.state.showWindowSize ||
            nextState.targetWorkerPool !== this.state.targetWorkerPool ||
            nextState.targetWorkerPoolVariable !== this.state.targetWorkerPoolVariable ||
            nextState.poolSelect !== this.state.poolSelect
        );
    }

    render() {
        return (
            <div>
                <ExpandableFormSection
                    isExpandedByDefault={this.props.expandedByDefault}
                    errorKey="ActionExecutionLocation"
                    title="Execution Location"
                    summary={this.executionLocationSummary()}
                    help="Choose the execution location Octopus should use for this step. "
                    fillCardWidth={CardFill.FillRight}
                >
                    {this.renderRunOnOptions()}
                </ExpandableFormSection>
                {this.renderRolesOptions()}
                {this.props.isActionContainersEnabled ? this.renderContainerImageSelector() : ""}
            </div>
        );
    }

    private executionLocationSummary() {
        const summary = [<span>This step will run</span>];
        if (this.state.targetWorkerPool || this.state.targetWorkerPoolVariable) {
            summary.push(
                <span>
                    {" "}
                    on a <strong>worker</strong> from a worker pool
                </span>
            );
        } else {
            // keeping these as four cases not two and combining the if checks because I am
            // not sure of the priority of Octopus.Action.RunOnServer over props.executionLocation
            if (this.props.executionLocation === ActionExecutionLocation.AlwaysOnServer) {
                summary.push(
                    <span>
                        {" "}
                        on the <strong>Octopus Server</strong>
                    </span>
                );
            } else if (this.props.executionLocation === ActionExecutionLocation.AlwaysOnTarget) {
                summary.push(
                    <span>
                        {" "}
                        on each <strong>deployment target</strong>
                    </span>
                );
            } else if (this.props.runOn.executionLocation === ExecutionLocation.DeploymentTarget) {
                summary.push(
                    <span>
                        {" "}
                        on each <strong>deployment target</strong>
                    </span>
                );
            } else {
                summary.push(
                    <span>
                        {" "}
                        on the <strong>Octopus Server</strong>
                    </span>
                );
            }
        }

        if (this.props.targetRoleOption === TargetRoles.None) {
            summary.push(<p />);
            return Summary.summary(React.Children.toArray(summary));
        }

        if (
            this.props.runOn.executionLocation !== ExecutionLocation.DeploymentTarget &&
            (this.props.runOn.executionLocation === ExecutionLocation.OctopusServerForRoles || this.props.runOn.executionLocation === ExecutionLocation.WorkerPoolForRoles)
        ) {
            summary.push(<span> on behalf of each deployment target</span>);
        }

        return Summary.summary(React.Children.toArray(summary));
    }

    private workerPoolSummary() {
        if (this.state.poolSelect === WorkerPoolSelect.Variable) {
            return Summary.summary(
                <span>
                    The worker pool from the <strong>{this.state.targetWorkerPoolVariable}</strong> variable will be used
                </span>
            );
        }
        const worker = this.props.availableWorkerPools.find(element => element.Id === this.state.targetWorkerPool);
        return worker ? Summary.summary(<WorkerPoolChip workerPoolName={worker.Name} workerPoolType={worker.WorkerPoolType} />) : Summary.placeholder("No pool selected - Let Octopus pick the default pool");
    }

    private workerPoolsAvailable() {
        return this.props.availableWorkerPools.length > 0 && this.props.canRunOnWorker;
    }

    private renderRunOnOptions = () => {
        const imageHeight = "5rem";

        const alwaysOnServer = this.props.executionLocation === ActionExecutionLocation.AlwaysOnServer;
        if (alwaysOnServer && this.props.targetRoleOption !== TargetRoles.Optional) {
            if (this.workerPoolsAvailable()) {
                // Workers
                return (
                    <div>
                        {this.props.targetRoleOption !== TargetRoles.None ? (
                            <div className={styles.col}>
                                {" "}
                                <span>
                                    This step will run once on a <strong>worker</strong> on behalf of each deployment target
                                </span>
                                <ExecuteWorkerRoles height={imageHeight} />
                                <Note>Execute on a worker on behalf of all the deployment targets in selected roles.</Note>
                            </div>
                        ) : (
                            <div className={styles.col}>
                                {" "}
                                <span>
                                    This step will run once on a <strong>worker</strong>
                                </span>
                                <ExecuteWorker height={imageHeight} />
                                <Note>Execute once on a worker.</Note>
                            </div>
                        )}
                    </div>
                );
            } else {
                // Octopus Server
                return (
                    <div>
                        {this.props.targetRoleOption !== TargetRoles.None ? (
                            <div className={styles.col}>
                                {" "}
                                <span>
                                    This step will run on the <strong>Octopus Server</strong> on behalf of each deployment target
                                </span>
                                <ExecuteOctopusServerRoles height={imageHeight} />
                                <Note>Execute on a the Octopus Server on behalf of all the deployment targets in selected roles.</Note>
                            </div>
                        ) : (
                            <div className={styles.col}>
                                {" "}
                                <span>
                                    This step will run on the <strong>Octopus Server</strong>
                                </span>
                                <ExecuteOctopusServer height={imageHeight} />
                                <Note>Execute once on the Octopus Server.</Note>
                            </div>
                        )}
                    </div>
                );
            }
        }

        if (this.props.executionLocation === ActionExecutionLocation.AlwaysOnTarget) {
            return (
                <div className={styles.col}>
                    <span>
                        This step will run on each <strong>deployment target</strong>
                    </span>
                    <ExecuteTargets height={imageHeight} />
                    <Note>Execute on each deployment target with the selected roles.</Note>
                </div>
            );
        }

        return (
            <div className={styles.row}>
                {this.renderExecutionLocationRadioGroup(alwaysOnServer)}

                <div className={styles.images}>
                    {this.props.runOn.executionLocation === ExecutionLocation.DeploymentTarget ? (
                        <TransitionAnimation>
                            <ExecuteTargets height={imageHeight} />
                            <Note>Execute on each deployment target with the selected roles.</Note>
                        </TransitionAnimation>
                    ) : (
                        <>
                            {this.props.runOn.executionLocation === ExecutionLocation.OctopusServer && (
                                <TransitionAnimation>
                                    <ExecuteOctopusServer height={imageHeight} />
                                    <Note>Execute once on the Octopus Server.</Note>
                                </TransitionAnimation>
                            )}
                            {this.props.runOn.executionLocation === ExecutionLocation.OctopusServerForRoles && (
                                <TransitionAnimation>
                                    <ExecuteOctopusServerRoles height={imageHeight} />
                                    <Note>Execute on the Octopus Server on behalf of all the deployment targets in selected roles.</Note>
                                </TransitionAnimation>
                            )}
                            {this.props.runOn.executionLocation === ExecutionLocation.WorkerPool && (
                                <TransitionAnimation>
                                    <ExecuteWorker height={imageHeight} />
                                    <Note>Execute once on a worker.</Note>
                                </TransitionAnimation>
                            )}
                            {this.props.runOn.executionLocation === ExecutionLocation.WorkerPoolForRoles && (
                                <TransitionAnimation>
                                    <ExecuteWorkerRoles height={imageHeight} />
                                    <Note>Execute on a worker on behalf of all the deployment targets in selected roles.</Note>
                                </TransitionAnimation>
                            )}
                        </>
                    )}
                </div>
            </div>
        );
    };

    private renderExecutionLocationRadioGroup(alwaysOnServer: boolean) {
        return (
            <StringRadioButtonGroup value={this.props.runOn.executionLocation} onChange={this.onRunOnSelectChanged}>
                {!this.props.isChildStep && this.workerPoolsAvailable() && <RadioButton value={ExecutionLocation.WorkerPool} label="Run once on a worker" />}
                {!this.props.isChildStep && !this.workerPoolsAvailable() && <RadioButton value={ExecutionLocation.OctopusServer} label="Run on the Octopus Server" />}
                {this.workerPoolsAvailable() ? (
                    <RadioButton value={ExecutionLocation.WorkerPoolForRoles} label="Run on a worker on behalf of each deployment target" />
                ) : (
                    <RadioButton value={ExecutionLocation.OctopusServerForRoles} label="Run on the Octopus Server on behalf of each deployment target" />
                )}
                {!alwaysOnServer && <RadioButton value={ExecutionLocation.DeploymentTarget} label="Run on each deployment target" isDefault={true} />}
            </StringRadioButtonGroup>
        );
    }

    private onRunOnSelectChanged = (runOnType: ExecutionLocation) => {
        if (runOnType === ExecutionLocation.DeploymentTarget) {
            this.props.onRunOnChanged(new RunOnDeploymentTarget());
        } else {
            const newRunOn: RunOnServerOrWorkerPool = this.props.runOnServerOrWorkerPoolCopy
                ? { ...this.props.runOnServerOrWorkerPoolCopy, executionLocation: runOnType }
                : { container: generateDefaultActionContainer(), runningInContainer: false, executionLocation: runOnType };

            this.setWorkerPoolDefault(this.state.poolSelect, newRunOn);

            this.props.onRunOnChanged(newRunOn);
        }
    };

    private renderContainerImageSelector() {
        const runOn = this.props.runOn;

        const feedViewPermissionGranted = isAllowed({ permission: Permission.FeedView, project: this.props.projectId, wildcard: true });

        if (runOn instanceof RunOnDeploymentTarget) {
            return null;
        }

        if (!this.props.canRunInContainer) {
            return null;
        }

        return (
            <>
                <ExpandableFormSection
                    errorKey="Octopus.Action.Container.Image|Octopus.Action.Container.FeedId"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Container Image"
                    summary={CommonSummaryHelper.actionContainerSummary(runOn.container, this.props.feeds, runOn, !feedViewPermissionGranted)}
                    help={`Choose to run directly on ${isRunningOnBuiltInWorker(runOn) ? "the Octopus Server" : "a worker"} or inside a container`}
                    chip={<EarlyAccessChip />}
                >
                    <ExecutionContainerImageSelector
                        projectId={this.props.projectId}
                        runOn={runOn}
                        feeds={this.props.feeds}
                        refreshFeeds={this.props.loadFeeds}
                        onFeedIdChange={feedId => this.onFeedIdChanged(feedId, runOn)}
                        onImageNameChange={imageName => this.onImageNameChanged(imageName, runOn)}
                        resetContainer={updatedRunOn => this.onContainerReset(updatedRunOn)}
                        onStepExecutionOptionChange={option => {
                            if (option === StepExecutionOption.RunInsideContainerOnWorker) {
                                this.props.onRunOnChanged({ ...runOn, runningInContainer: true });
                            } else {
                                this.props.onRunOnChanged({ ...runOn, runningInContainer: false, container: generateDefaultActionContainer() });
                            }
                        }}
                        imageNameError={this.props.imageNameError}
                    />
                </ExpandableFormSection>
            </>
        );
    }

    private onFeedIdChanged = (feedId: string, runOn: RunOnServerOrWorkerPool) => {
        const updatedContainer = { ...runOn.container, FeedId: feedId };
        this.props.onRunOnChanged({ runningInContainer: runOn.runningInContainer, executionLocation: runOn.executionLocation, container: updatedContainer });
    };

    private onImageNameChanged = (imageName: string, runOn: RunOnServerOrWorkerPool) => {
        const updatedContainer = { ...runOn.container, Image: imageName };
        this.props.onRunOnChanged({ runningInContainer: runOn.runningInContainer, executionLocation: runOn.executionLocation, container: updatedContainer });
    };

    private onContainerReset = (runOn: RunOnServerOrWorkerPool) => {
        this.props.onRunOnChanged({ ...runOn });
    };

    private renderRolesOptions = () => {
        if (this.props.targetRoleOption === TargetRoles.None) {
            return null;
        }

        if (this.props.isChildStep && !this.props.runsOnServer) {
            const roles = this.roleList(this.props.targetRoles);
            const pluralized = roles.length === 1 ? "" : "s";
            return (
                <UnstructuredFormSection>
                    This step is part of a rolling step, which runs on deployment targets in the following {`role${pluralized}`} {roles}
                </UnstructuredFormSection>
            );
        }

        const nodes = [];
        if (!(this.props.runOn.executionLocation === ExecutionLocation.DeploymentTarget) && (this.props.runOn.executionLocation === ExecutionLocation.WorkerPool || this.props.runOn.executionLocation === ExecutionLocation.WorkerPoolForRoles)) {
            nodes.push(
                <ExpandableFormSection isExpandedByDefault={this.props.expandedByDefault} summary={this.workerPoolSummary()} title="Worker Pool" errorKey="Octopus.Action.WorkerPoolId" help="Which worker pool should Octopus use for this step?">
                    <StringRadioButtonGroup value={this.state.poolSelect} onChange={this.onPoolSelectChanged}>
                        <RadioButton value={WorkerPoolSelect.Pool} label="Runs on a worker from a specific worker pool" />
                        {this.state.poolSelect === WorkerPoolSelect.Pool && (
                            <Select
                                items={this.props.availableWorkerPools.map(e => {
                                    return { value: e.Id, text: e.Name };
                                })}
                                value={this.state.targetWorkerPool}
                                validate={value => (value == null ? "Please select a worker pool" : "")}
                                label={"Select a pool"}
                                onChange={workerPoolId => this.onTargetWorkerPoolChange(workerPoolId!, null!)}
                            />
                        )}
                        <RadioButton value={WorkerPoolSelect.Variable} label="Runs on a worker from a pool selected via a variable" />
                        {this.state.poolSelect === WorkerPoolSelect.Variable && (
                            <WorkerPoolVariableSelect
                                doBusyTask={this.props.doBusyTask}
                                projectId={this.props.projectId}
                                value={this.state.targetWorkerPoolVariable}
                                onChange={workerPoolVariable => this.onTargetWorkerPoolChange(null!, workerPoolVariable)}
                            />
                        )}
                    </StringRadioButtonGroup>
                </ExpandableFormSection>
            );
        }

        const shouldRunOnOrOnBehalfOfTargets = () => {
            return (
                this.props.runOn.executionLocation === ExecutionLocation.DeploymentTarget || this.props.runOn.executionLocation === ExecutionLocation.OctopusServerForRoles || this.props.runOn.executionLocation === ExecutionLocation.WorkerPoolForRoles
            );
        };

        const shouldRenderTargetRolesAndWindowSizeOptions = () => {
            return !this.props.isChildStep && shouldRunOnOrOnBehalfOfTargets();
        };

        const rolePostfix = this.props.disableAddTargetRoles !== true && " (type to add new)"; // Keep this text short for mobile.
        if (shouldRenderTargetRolesAndWindowSizeOptions()) {
            nodes.push(
                <div>
                    <ExpandableFormSection
                        isExpandedByDefault={this.props.expandedByDefault}
                        summary={this.props.targetRoles ? Summary.summary(this.roleList(this.props.targetRoles)) : Summary.placeholder("No roles selected")}
                        title={this.props.runOn.executionLocation === ExecutionLocation.DeploymentTarget ? "On Targets in Roles" : "On Behalf Of"}
                        errorKey="Octopus.Action.TargetRoles"
                        help={this.props.runOn.executionLocation === ExecutionLocation.DeploymentTarget ? "Run this step on these deployment targets." : "Run this step on behalf of these deployment targets."}
                    >
                        <RoleMultiSelect
                            onChange={this.props.onTargetRolesChanged}
                            value={ParseHelper.parseCSV(this.props.targetRoles)}
                            label={this.props.runOn.executionLocation === ExecutionLocation.DeploymentTarget ? `Runs on targets in roles${rolePostfix}` : `On behalf of target roles${rolePostfix}`}
                            validate={roles => (roles.length === 0 ? "Please enter one or more roles" : "")}
                            error={this.props.targetRolesError}
                            items={this.props.availableRoles}
                            canAdd={this.props.disableAddTargetRoles !== true}
                        />
                        <Note>This step will run on all deployment targets with these roles.</Note>
                        {!this.state.showWindowSize && (
                            <React.Fragment>
                                <ActionButton
                                    label="Configure a rolling deployment"
                                    type={ActionButtonType.Ternary}
                                    onClick={() => {
                                        this.setState({ showWindowSize: true });
                                        this.props.onMaxParallelismChanged("1");
                                    }}
                                />
                            </React.Fragment>
                        )}
                        {this.state.showWindowSize && (
                            <React.Fragment>
                                <ActionButton
                                    label="Deploy to all deployment targets in parallel"
                                    type={ActionButtonType.Ternary}
                                    onClick={() => {
                                        this.setState({ showWindowSize: false });
                                        this.props.onMaxParallelismChanged("");
                                    }}
                                />
                            </React.Fragment>
                        )}
                    </ExpandableFormSection>
                </div>
            );

            if (this.state.showWindowSize) {
                nodes.push(
                    <div>
                        <ExpandableFormSection
                            isExpandedByDefault={this.props.expandedByDefault}
                            summary={Summary.summary(this.rollingDeploymentSummary())}
                            title="Rolling Deployment"
                            errorKey="Octopus.Action.RollingDeployment"
                            help="Select the rolling window Octopus should use for this step. "
                        >
                            <Note>
                                Learn more about <ExternalLink href="rolling-deployments">Rolling Deployments</ExternalLink>
                            </Note>
                            <MaxParallelism key="maxparallelism" projectId={this.props.projectId} value={this.props.maxParallelism} onChange={x => this.props.onMaxParallelismChanged(x)} />
                        </ExpandableFormSection>
                    </div>
                );
            }
        }

        if (this.props.isChildStep && this.props.runsOnServer) {
            const executionLocation = this.state.targetWorkerPool ? (
                <span>
                    {" "}
                    a <strong>worker</strong> from the worker pool
                </span>
            ) : (
                <span>
                    {" "}
                    the <strong>Octopus Server</strong>
                </span>
            );
            const roles = this.roleList(this.props.targetRoles);
            const pluralized = roles.length === 1 ? "" : "s";
            nodes.push(
                <UnstructuredFormSection>
                    This step is part of a rolling step, which runs on {executionLocation} on behalf of each deployment target in the following {`role${pluralized}`} {roles}
                </UnstructuredFormSection>
            );
        }

        return React.Children.toArray(nodes);
    };

    private rollingDeploymentSummary() {
        return this.state.showWindowSize ? (
            <span>
                This is a rolling deployments step that will run on{" "}
                <strong>
                    {this.props.maxParallelism} target{this.props.maxParallelism !== "1" ? "s" : ""}
                </strong>{" "}
                at once.
            </span>
        ) : (
            <span>This step will be run on all deployment targets in parallel.</span>
        );
    }

    private roleList(csv: string) {
        const list = ParseHelper.parseCSV(csv);
        return list.map(r => <RoleChip role={r} key={"role-" + r} />);
    }

    private onPoolSelectChanged = (select: WorkerPoolSelect) => {
        if (!(this.props.runOn instanceof RunOnDeploymentTarget)) {
            this.setWorkerPoolDefault(select, this.props.runOn);
        }
        this.setState({ poolSelect: select });
    };

    private setWorkerPoolDefault(select: WorkerPoolSelect, runOn: RunOnServerOrWorkerPool) {
        if (select === WorkerPoolSelect.Pool) {
            const workerPoolId = this.state.targetWorkerPool || this.getDefaultWorkerIdPoolForRunOn(runOn);
            this.onTargetWorkerPoolChange(workerPoolId!, null!);
        } else {
            this.onTargetWorkerPoolChange(null!, this.state.targetWorkerPoolVariable);
        }
    }

    private getDefaultWorkerIdPoolForRunOn(runOn: RunOnServerOrWorkerPool) {
        if (runOn.executionLocation === ExecutionLocation.WorkerPool || runOn.executionLocation === ExecutionLocation.WorkerPoolForRoles) {
            const defaultPool = this.props.availableWorkerPools ? this.props.availableWorkerPools.filter(p => p.IsDefault).pop() : null!;
            return defaultPool ? defaultPool.Id : null!;
        }
        return null;
    }

    private onTargetWorkerPoolChange = (workerPoolId: string, workerPoolVariable: string | null) => {
        this.setState(
            {
                targetWorkerPool: workerPoolId,
                targetWorkerPoolVariable: workerPoolVariable,
            },
            () => this.props.onTargetWorkerPoolChanged(workerPoolId, workerPoolVariable)
        );
    };
}

const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    return {
        isActionContainersEnabled: configurationSelectors.createFeatureEnabledSelector(t => t.isActionContainersEnabled)(state),
    };
};

export default connect(mapGlobalStateToProps)(ExecutionPlan);
