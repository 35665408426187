import { BaseComponent } from "components/BaseComponent/BaseComponent";
import pluginRegistry, { ActionEditProps } from "components/Actions/pluginRegistry";
import { KubernetesSecretProperties } from "components/Actions/kubernetes/kubernetesProperties";
import * as React from "react";
import StringKeyValueEditList from "components/EditList/KeyValueEditList";
import { default as ExpandableFormSection } from "components/form/Sections/ExpandableFormSection";
import { JsonUtils } from "utils/jsonUtils";
import Summary from "components/form/Sections/Summary";
import { ActionExecutionLocation } from "client/resources";
import { TargetRoles } from "areas/projects/components/DeploymentProcess/ActionDetails";
import { ActionSummaryProps } from "components/Actions/actionSummaryProps";
import { KubernetesSecretComponent } from "components/Actions/kubernetes/kubernetesSecretComponent";
import * as _ from "lodash";
import { ScriptPackageProperties } from "components/Actions/script/ScriptPackageReferenceDialog";
import KubernetesNamespaceFormSection from "./kubernetesNamespaceFormSection";

class KubernetesDeploySecretActionSummary extends BaseComponent<ActionSummaryProps, never> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }

    render() {
        return <div>Deploy a secret resource to Kubernetes</div>;
    }
}

class KubernetesDeploySecretActionEdit extends BaseComponent<ActionEditProps<KubernetesSecretProperties, ScriptPackageProperties>, never> {
    constructor(props: ActionEditProps<KubernetesSecretProperties, ScriptPackageProperties>) {
        super(props);
    }

    render() {
        return (
            <div>
                <KubernetesSecretComponent
                    properties={this.props.properties}
                    packages={this.props.packages}
                    plugin={this.props.plugin}
                    getFieldError={this.props.getFieldError}
                    setProperties={this.props.setProperties}
                    setPackages={this.props.setPackages}
                    doBusyTask={this.props.doBusyTask}
                    busy={this.props.busy}
                    errors={this.props.errors}
                    projectId={this.props.projectId}
                    expandedByDefault={true}
                    importLabels={true}
                />
                <ExpandableFormSection
                    errorKey="Octopus.Action.KubernetesContainers.DeploymentLabels"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Secret Labels"
                    summary={this.labelsSummary()}
                    help={"The labels applied to the secret resource"}
                >
                    <StringKeyValueEditList
                        items={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"]}
                        name="Label"
                        separator=""
                        onChange={val => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentLabels"]: val })}
                        valueLabel="Value"
                        keyLabel="Name"
                        hideBindOnKey={false}
                        projectId={this.props.projectId}
                    />
                </ExpandableFormSection>
                <KubernetesNamespaceFormSection namespace={this.props.properties["Octopus.Action.KubernetesContainers.Namespace"]} onChange={ns => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.Namespace"]: ns })} />
            </div>
        );
    }

    private labelsSummary() {
        const labels = _.toPairs(JsonUtils.tryParse(this.props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {}));

        if (labels.length === 0) {
            return Summary.placeholder("The config map resource labels");
        }

        return Summary.summary(
            <span>
                Add the label{labels.length > 1 && <span>s</span>}{" "}
                {_.chain(labels)
                    .flatMap(pair => [
                        <strong>
                            {pair[0]}: {pair[1]}
                        </strong>,
                        <span>, </span>,
                    ])
                    .slice(0, -1)
                    .value()}
            </span>
        );
    }
}

pluginRegistry.registerActionForAllScopes({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.KubernetesDeploySecret",
    summary: (properties, targetRolesAsCSV) => <KubernetesDeploySecretActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    edit: KubernetesDeploySecretActionEdit,
    canHaveChildren: step => true,
    canBeChild: true,
    targetRoleOption: action => TargetRoles.Required,
    hasPackages: action => false,
});
