// tslint:disable: no-any

import * as React from "react";
const styles = require("./styles.less");

interface PageDividerProps {
    children: any;
    as?: React.ReactType<any>;
}

export const PageDivider = ({ children, as: Component = "p" }: PageDividerProps) => {
    return React.createElement(Component, null, children);
};

export default PageDivider;
