// tslint:disable: no-any

import * as React from "react";
import TextField from "./TextField";
import FormFieldProps from "../FormFieldProps";
import UseLabelStrategy from "components/LabelStrategy/LabelStrategy";
import { normal } from "fontWeights";
import { UniqueSequenceGenerator } from "../../../utils/UniqueSequenceGenerator/UniqueSequenceGenerator";
import { withTheme } from "components/Theme";
const styles = require("./style.less");

export interface TextProps extends FormFieldProps<string> {
    autoFocus?: boolean;
    autoComplete?: string;
    type?: string;
    min?: number;
    max?: number;
    hintText?: string | JSX.Element;
    underlineShow?: boolean;
    id?: string;
    multiLine?: boolean;
    rows?: number;
    rowsMax?: number;
    style?: React.CSSProperties;
    applyMaxWidth?: boolean;
    disabled?: boolean;
    label?: string | JSX.Element;
    error?: string;
    warning?: string | null;
    className?: string;
    showBorder?: boolean;
    monoSpacedFont?: boolean;
    showValueAsTitleAttribute?: boolean;
    name?: string;
    textInputRef?(textInput: TextInput | null): void;
    validate?(value: string): string;
    onValidate?(value: string): void;
    onKeyPress?(keyEvent: any): void;
    onKeyDown?(keyEvent: any): void;
    onFocus?(event: any): void;
    onBlur?(event: any): void;
    onClick?(event: any): void;
}

interface TextState {
    error?: string;
    showExternalError: boolean;
}

export interface Selection {
    start: number;
    end: number;
}

export interface TextInput {
    isFocused(): boolean;
    focus(): void;
    select(): void;
    forceHasValue(): void;
    getSelection(): Selection;
    insertAtCursor(value: string): void;
    setValueAndSelection(selection: Selection, value: string): void;
}

class TextInternal extends React.Component<TextProps, TextState> {
    static sequence: UniqueSequenceGenerator = new UniqueSequenceGenerator();

    static defaultProps: Partial<TextProps> = {
        type: "text",
        autoFocus: false,
        applyMaxWidth: false,
        showBorder: false,
        textInputRef: input => {
            /* Do nothing */
        },
    };

    private textField: TextField;
    private genericName: string;

    constructor(props: TextProps) {
        super(props);
        this.state = {
            showExternalError: true,
        };

        this.genericName = "name" + TextInternal.sequence.next().toString();
    }

    componentWillMount() {
        if (this.props.textInputRef) {
            this.props.textInputRef(this);
        }
    }

    componentWillUnmount() {
        if (this.props.textInputRef) {
            this.props.textInputRef(null);
        }
    }

    componentWillReceiveProps(nextProps: TextProps) {
        const isNewExternalErrorAvailable = nextProps.error !== this.props.error;
        if (isNewExternalErrorAvailable) {
            this.setState({ showExternalError: true });
        }
    }

    getSelection = () => {
        const input = this.textField.getInputNode();
        return { start: input.selectionStart, end: input.selectionEnd };
    };

    setValueAndSelection = (selection: { start: any; end: any }, value: string) => {
        const input = this.textField.getInputNode();
        input.value = value;
        input.selectionStart = selection.start;
        input.selectionEnd = selection.end;
        this.callValidateAndChange(input.value);
    };

    isFocused() {
        return this.textField && this.textField.getInputNode() === document.activeElement;
    }

    select() {
        if (this.textField) {
            this.textField.select();
        }
    }

    focus() {
        if (this.textField) {
            this.textField.focus();
        }
    }

    insertAtCursor(value: string) {
        if (!this.textField) {
            return;
        }
        const input = this.textField.getInputNode();
        if (input.selectionStart || input.selectionStart === 0) {
            const startPos = input.selectionStart;
            const endPos = input.selectionEnd;
            input.value = input.value.substring(0, startPos) + value + input.value.substring(endPos, input.value.length);
            input.selectionStart = startPos + value.length;
            input.selectionEnd = startPos + value.length;
        } else {
            input.value += value;
        }
        this.callValidateAndChange(input.value);
    }

    forceHasValue() {
        if (this.textField) {
            return this.textField.setState({ hasValue: true });
        }
    }

    handleChange = (event: React.SyntheticEvent<any>) => {
        event.preventDefault();
        event.stopPropagation();
        const value = event.currentTarget.value;
        this.callValidateAndChange(value);
    };

    callValidateAndChange = (value: string) => {
        if (this.props.validate) {
            const result = this.props.validate(value);
            this.setState({ error: result });
            if (this.props.onValidate) {
                this.props.onValidate(result);
            }
        }
        this.setState({ showExternalError: false });
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };

    handleKeyPress = (event: any) => {
        if (this.props.onKeyPress) {
            this.props.onKeyPress(event);
        }
    };

    handleKeyDown = (event: any) => {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(event);
        }
    };

    render() {
        return withTheme(theme => {
            const {
                id,
                label,
                validate,
                error,
                onChange,
                onClick,
                onValidate,
                type,
                value,
                min,
                max,
                name,
                hintText,
                underlineShow,
                warning,
                monoSpacedFont,
                rowsMax,
                onKeyPress,
                rows,
                multiLine,
                applyMaxWidth,
                showBorder,
                textInputRef,
                showValueAsTitleAttribute,
                autoComplete,
                ...otherProps
            } = this.props;

            const err = this.state.error || (this.state.showExternalError && error);
            const errorText = err || warning;
            const val = value ? value : "";

            // Transform underlineShow to a style, so that animations work if you toggle underlineShow to true
            const underlineStyle: React.CSSProperties = underlineShow || underlineShow === undefined ? { borderColor: theme.secondaryText, position: "relative", marginRight: "0.5rem" } : { visibility: "hidden" };
            // margin-top caused overflow because material-ui text field uses border-box and margins are not included in calculations in that mode.
            // This is why we've replaced margin with padding.
            // Unfortunately, text-overflow: ellipsis doesn't work in IE11 or edge, unless the input is readonly.
            // We haven't worked around this in any way yet, but some people do some nasty hacks to get it working
            // in these browsers, like: https://frontendtricksandmagic.wordpress.com/2015/03/07/how-to-make-text-overflow-ellipsis-work-for-inputs-in-ie/
            const inputStyle: React.CSSProperties = { marginTop: "0px", textOverflow: "ellipsis", overflow: "hidden" };

            // margin/padding is only required when there is a label
            inputStyle.paddingTop = label && !multiLine ? "18px" : "0px";

            const hintStyle = { color: theme.secondaryText, fontWeight: normal, height: "24px", whiteSpace: "nowrap", width: "90%", overflow: "hidden", textOverflow: "ellipsis" };
            const widthStyle = applyMaxWidth ? { maxWidth: "100%" } : {};
            const borderStyle = showBorder ? { padding: "0 0.5rem 0 0.5rem", border: "0.0625rem solid", borderColor: theme.divider, marginBottom: "0.5rem" } : {};

            // We are not using margin so there is no need to manually position the error text.
            const errorStyle = { bottom: "0px", color: err ? theme.dangerText : theme.alertText };

            return (
                <div className={styles.container}>
                    <TextField
                        className={monoSpacedFont ? [styles.text, styles.monospacedText].join(" ") : styles.text}
                        ref={(textField: TextField) => (this.textField = textField)}
                        id={id}
                        autoComplete={autoComplete ? autoComplete : "new-password"}
                        type={type}
                        hintText={hintText}
                        name={name || this.genericName}
                        hintStyle={hintStyle}
                        value={val}
                        title={showValueAsTitleAttribute ? val : hintText}
                        onClick={onClick}
                        onChange={this.handleChange}
                        errorText={errorText}
                        errorStyle={errorStyle}
                        floatingLabelStyle={{ color: theme.secondaryText, fontWeight: normal }}
                        floatingLabelFocusStyle={errorText ? { color: err ? theme.dangerText : theme.alertText } : { color: theme.focus, fontWeight: normal }}
                        floatingLabelFixed={!!hintText}
                        floatingLabelText={label}
                        underlineStyle={underlineStyle}
                        underlineFocusStyle={{ borderColor: theme.focus, bottom: "10px" }}
                        underlineDisabledStyle={{ borderBottom: "0.0625rem solid rgb(224, 224, 224)" }}
                        min={min}
                        max={max}
                        rows={multiLine ? rows || 3 : rows}
                        rowsMax={rowsMax}
                        multiLine={multiLine}
                        onKeyPress={this.handleKeyPress}
                        onKeyDown={this.handleKeyDown}
                        inputStyle={inputStyle}
                        style={{ ...widthStyle, ...borderStyle }}
                        {...otherProps}
                    />
                </div>
            );
        });
    }
}

const Text = UseLabelStrategy(TextInternal, fieldName => fieldName);
export default Text;
