// tslint:disable: no-non-null-assertion
// tslint:disable: no-any

import * as React from "react";
import SelectField from "./SelectField";
import MenuItem from "material-ui/MenuItem";
import FormFieldProps from "../FormFieldProps";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import UseLabelStrategy from "components/LabelStrategy/LabelStrategy";
import { withBoundField } from "components/form/BoundField/BoundField";
import classnames = require("classnames");
import { withTheme } from "components/Theme";
const styles = require("./style.less");

export interface Item {
    value: string | undefined | null;
    text: string;
    icon?: JSX.Element;
    style?: object;
    disabled?: boolean;
}

export interface OtherSelectProps {
    fixedLabel?: boolean;
    items: Item[];
    hintText?: string;
    allowClear?: boolean;
    allowFilter?: boolean;
    autoFocus?: boolean;
    disabled?: boolean;
    label?: string | JSX.Element;
    error?: string | null;
    warning?: string;
    className?: any;
    empty?: string;
    selectionRenderer?: (value: string, menuItem: any) => any;
    validate?(value: string): string;
    onValidate?(value: string): void;
}

export type SelectProps = OtherSelectProps & FormFieldProps<string | undefined>;

interface SelectState {
    error?: string;
    filter?: string;
    filteredItems: Item[];
    showExternalError: boolean;
}

class SelectInternal extends React.Component<OtherSelectProps & FormFieldProps<string | undefined>, SelectState> {
    disabled: boolean;
    constructor(props: OtherSelectProps & FormFieldProps<string>) {
        super(props);
        this.state = {
            filter: null!,
            filteredItems: null!,
            showExternalError: true,
        };
    }

    componentWillReceiveProps(nextProps: SelectProps) {
        const isNewExternalErrorAvailable = nextProps.error !== this.props.error;
        if (isNewExternalErrorAvailable) {
            this.setState({ showExternalError: true });
        }
    }

    render() {
        return withTheme(theme => {
            const { value, label, fixedLabel, items, error, onChange, onValidate, validate, warning, hintText, allowClear, allowFilter, className, ...otherProps } = this.props;

            const err = this.state.error || (this.state.showExternalError && error);
            const errorText = err || warning;

            // We need to hack the labelStyle to stop MaterialUI from overflowing other controls that may be sitting underneath this.
            const labelStyle = {
                height: "40px",
                lineHeight: "40px",
            };

            return (
                <div className={styles.container}>
                    <SelectField
                        className={classnames(className, this.props.disabled ? styles.isDisabled : styles.select)}
                        value={value}
                        allowClear={allowClear}
                        labelStyle={labelStyle}
                        onChange={this.handleChange}
                        floatingLabelText={label}
                        hintText={hintText}
                        errorText={errorText}
                        selectedMenuItemStyle={{ color: theme.primary }}
                        dropDownMenuProps={{ onClose: this.handleOnClose }}
                        iconStyle={{ fill: theme.secondaryText }}
                        filter={
                            allowFilter && (
                                <div className={styles.filter}>
                                    <FilterSearchBox hintText={"Find..."} autoFocus={true} value={this.state.filter} onChange={this.handleFilterChanged} />
                                </div>
                            )
                        }
                        {...otherProps}
                    >
                        {(this.state.filteredItems || items).length === 0 && this.props.empty && <span className={styles.empty}>{this.props.empty}</span>}
                        {(this.state.filteredItems || items).map((item, index) => {
                            // Needs preventDefault onMouseDown to prevent https://www.chromestatus.com/features/6662647093133312
                            return (
                                <MenuItem
                                    onMouseDown={e => e.preventDefault()}
                                    key={item.value ?? index}
                                    value={item.value}
                                    leftIcon={item.icon}
                                    primaryText={item.text + (item.disabled ? " (disabled)" : "")}
                                    style={item.style}
                                    disabled={item.disabled}
                                />
                            );
                        })}
                    </SelectField>
                </div>
            );
        });
    }

    private handleChange = (_: any, __: any, value: any) => {
        if (this.props.validate) {
            const result = this.props.validate(value);
            this.setState(prev => ({ ...prev, error: result }));
            if (this.props.onValidate) {
                this.props.onValidate(result);
            }
        }

        this.setState(prev => ({
            ...prev,
            filter: null!,
            filteredItems: null!,
            showExternalError: false,
        }));

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };

    private handleFilterChanged = (value: string) => {
        const filteredItems = this.props.items.filter(item => {
            return item.text.toLowerCase().search(value.toLowerCase()) !== -1;
        });

        this.setState(prev => {
            return {
                ...prev,
                filter: value,
                filteredItems,
            };
        });
    };

    private handleOnClose = () => {
        if (this.state.filter) {
            this.setState({
                filter: null!,
                filteredItems: null!,
            });
        }
    };
}

const Select = UseLabelStrategy(SelectInternal, fieldName => `Select ${fieldName}`);
export default Select;
export { SelectInternal };
export const BoundSelect = withBoundField(Select);
