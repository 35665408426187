// tslint:disable: no-non-null-assertion
import * as React from "react";
import routeLinks from "routeLinks";
import InternalRedirect from "components/Navigation/InternalRedirect";
import { useProjectContext } from "areas/projects/context/ProjectContext";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import { ProjectResource } from "client/resources";

export const ProjectToOverviewRedirect: React.FC = (): React.ReactElement => {
    const projectContext = useProjectContext();
    const projectOverviewPath = OverviewRedirectPathForProject(projectContext.state.model);
    return <InternalRedirect to={projectOverviewPath} />;
};

export const OverviewRedirectPathForProject = (project: ProjectResource): string => {
    if (!project) {
        return null!;
    }
    if (IsAllowedToSeeOperationsOverview(project.Id) && !IsAllowedToSeeDeploymentsOverview(project.Id)) {
        return routeLinks.project(project.Slug).operations.root;
    }
    return routeLinks.project(project.Slug).deployments.root;
};

export const IsAllowedToSeeDeploymentsOverview = (projectId: string | undefined | null): boolean => {
    if (!projectId) {
        return false;
    }
    const isAllowedToSeeDeploymentsOverview = isAllowed({
        permission: [Permission.ReleaseView, Permission.ProcessView],
        project: projectId,
        tenant: "*",
    });
    return isAllowedToSeeDeploymentsOverview;
};

export const IsAllowedToSeeOperationsOverview = (projectId: string): boolean => {
    if (!projectId) {
        return false;
    }
    const isAllowedToSeeDeploymentsOverview = isAllowed({
        permission: Permission.RunbookView,
        project: projectId,
        wildcard: true,
    });
    return isAllowedToSeeDeploymentsOverview;
};
