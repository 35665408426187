// tslint:disable: no-any
// tslint:disable: no-non-null-assertion

import { safeDump, safeLoad as jsyaml } from "js-yaml";
import * as _ from "lodash";
import { PackageAcquisitionLocation } from "../../../client/resources/packageAcquisitionLocation";
import FeedResource, { FeedType } from "../../../client/resources/feedResource";
import { ActionEditProps } from "../pluginRegistry";
import { KubernetesConfigMapProperties, KubernetesDeploymentProperties, KubernetesIngressProperties, KubernetesSecretProperties, KubernetesServiceProperties } from "./kubernetesProperties";
import { ScriptPackageProperties } from "../script/ScriptPackageReferenceDialog";
import { JsonUtils } from "../../../utils/jsonUtils";
import { KeyValuePair } from "../../EditList/KeyValueEditList";
import { IngressRule } from "./kubernetesIngressComponent";
import { KeyValueOption } from "../../EditList/ExtendedKeyValueEditList";
import { CombinedVolumeDetails, ContainerDetails, TolerationDetails, ContainerPackageDetails, NodeAffinityDetails, PodAffinityDetails } from "./kubernetesDeployContainersAction";
import { ServicePort } from "./kubernetesServiceComponent";
import { PackageReference } from "../../../client/resources/packageReference";

/**
 * Deployment resources need to add labels to Pod and then select those labels in the deployment.
 * The selection of labels is generated on the server to allow pods to be matched to individual
 * deployments. But for the purpose of exporting the YAML, we use a placeholder label with
 * this value.
 */
const PLACEHOLDER_LABEL_VALUE = "OctopusExport";
/**
 * This is the label key that the exported YAML uses as a selector.
 */
const PLACEHOLDER_LABEL_KEY = "octopusexport";

interface KubernetesToleration {
    key: string;
    operator: string;
    value: string;
    effect: string;
}

interface KubernetesSecretEnvVar {
    name: string;
    valueFrom: {
        secretKeyRef: {
            name: string;
            key: string;
        };
    };
}

interface KubernetesConfigmapEnvVar {
    name: string;
    valueFrom: {
        configMapKeyRef: {
            name: string;
            key: string;
        };
    };
}

interface KubernetesHttpHeaders {
    name: string;
    value: string;
}

interface KubernetesMatchExpression {
    key: string;
    operator: string;
    values: string[];
}

interface KubernetesMatchExpressions {
    matchExpressions: KubernetesMatchExpression[];
}

interface KubernetesPodAffinity {
    topologyKey: string;
    namespaces: string[];
    labelSelector: KubernetesMatchExpressions;
}

interface KubernetesPreferredPodAffinity {
    weight: number;
    podAffinityTerm: KubernetesPodAffinity;
}

interface KubernetesNodeAffinityRequired {
    nodeSelectorTerms: KubernetesMatchExpressions[];
}

interface KubernetesNodeAffinityPreference {
    weight: number;
    preference: KubernetesMatchExpressions;
}

interface KubernetesSysctl {
    name: string;
    value: string;
}

interface KubernetesVolumeItem {
    key: string;
    path: string;
}

interface KubernetesVolume {
    name: string;
    configMap: {
        name: string;
        items: KubernetesVolumeItem[];
    };
    secret: {
        name: string;
        items: KubernetesVolumeItem[];
    };
    hostPath: {
        path: string;
        type: string;
    };
    emptyDir: {
        medium: string;
    };
    persistentVolumeClaim: {
        claimName: string;
    };
}

export function exportConfigMap(props: ActionEditProps<KubernetesConfigMapProperties, ScriptPackageProperties>, labels: boolean) {
    const configMap = {
        apiVersion: "v1",
        kind: "ConfigMap",
        metadata: {
            name: props.properties["Octopus.Action.KubernetesContainers.ConfigMapName"],
            ...(labels &&
                _.keys(JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {})).length !== 0 && {
                    labels: JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {}),
                }),
        },
        data: _.mapValues(JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.ConfigMapValues"], {}), v => _.toString(v)),
    };

    return convertToYAML(configMap);
}

export function importConfigMap(props: ActionEditProps<KubernetesConfigMapProperties, ScriptPackageProperties>, template: string, labelsAndNamespace: boolean) {
    try {
        const yaml = jsyaml(template.split("---\n")[0]);

        if (yaml.kind !== "ConfigMap") {
            throw new Error("Not the correct type");
        }

        props.setProperties({ ["Octopus.Action.KubernetesContainers.ConfigMapName"]: _.toString(_.get(yaml, "metadata.name")) });
        // Secrets in YAML are base64 encoded, so we need to decode the values
        props.setProperties({ ["Octopus.Action.KubernetesContainers.ConfigMapValues"]: JSON.stringify(yaml.data) });

        if (labelsAndNamespace) {
            props.setProperties({
                ["Octopus.Action.KubernetesContainers.DeploymentLabels"]: JSON.stringify(
                    _.assign(
                        _.mapValues(_.get(yaml, "metadata.labels") || {}, v => _.toString(v)),
                        _.mapValues(_.get(yaml, "spec.template.metadata.labels") || {}, v => _.toString(v))
                    )
                ),
            });
            props.setProperties({ ["Octopus.Action.KubernetesContainers.Namespace"]: _.toString(_.get(yaml, "metadata.namespace")) });
        }
    } catch (e) {
        throw { ErrorMessage: "The supplied text was not valid YAML or not a ConfigMap resource" };
    }
}

export function exportSecret(props: ActionEditProps<KubernetesSecretProperties, ScriptPackageProperties>, labels: boolean) {
    const secret = {
        apiVersion: "v1",
        kind: "Secret",
        metadata: {
            name: props.properties["Octopus.Action.KubernetesContainers.SecretName"],
            ...(labels &&
                _.keys(JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {})).length !== 0 && {
                    labels: JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {}),
                }),
            ...(props.properties["Octopus.Action.KubernetesContainers.Namespace"] && {
                namespace: props.properties["Octopus.Action.KubernetesContainers.Namespace"],
            }),
        },
        data: _.mapValues(JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.SecretValues"], {}), (v: string) => btoa(v)),
    };

    return convertToYAML(secret);
}

export function importSecret(props: ActionEditProps<KubernetesSecretProperties, ScriptPackageProperties>, template: string, labelsAndNamespace: boolean) {
    try {
        const yaml = jsyaml(template.split("---\n")[0]);

        if (yaml.kind !== "Secret") {
            throw new Error("Not the correct type");
        }

        props.setProperties({ ["Octopus.Action.KubernetesContainers.SecretName"]: _.toString(_.get(yaml, "metadata.name")) });
        // Secrets in YAML are base64 encoded, so we need to decode the values
        props.setProperties({ ["Octopus.Action.KubernetesContainers.SecretValues"]: JSON.stringify(_.mapValues(yaml.data, (v: string) => atob(v))) });

        if (labelsAndNamespace) {
            props.setProperties({
                ["Octopus.Action.KubernetesContainers.DeploymentLabels"]: JSON.stringify(
                    _.assign(
                        _.mapValues(_.get(yaml, "metadata.labels") || {}, v => _.toString(v)),
                        _.mapValues(_.get(yaml, "spec.template.metadata.labels") || {}, v => _.toString(v))
                    )
                ),
            });
            props.setProperties({ ["Octopus.Action.KubernetesContainers.Namespace"]: _.toString(_.get(yaml, "metadata.namespace")) });
        }
    } catch {
        throw { ErrorMessage: "The supplied text was not valid YAML or not a Secret resource" };
    }
}

export function exportIngress(props: ActionEditProps<KubernetesIngressProperties>, labels: boolean) {
    const ingress = {
        apiVersion: "networking.k8s.io/v1beta1",
        kind: "Ingress",
        metadata: {
            name: props.properties["Octopus.Action.KubernetesContainers.IngressName"],
            ...(labels &&
                _.keys(JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {})).length !== 0 && {
                    labels: JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {}),
                }),
            ...(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.IngressAnnotations"], []).length !== 0 && {
                annotations: _.fromPairs(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.IngressAnnotations"], []).map((a: KeyValuePair) => [a.key, a.value])),
            }),
            ...(props.properties["Octopus.Action.KubernetesContainers.Namespace"] && {
                namespace: props.properties["Octopus.Action.KubernetesContainers.Namespace"],
            }),
        },
        spec: {
            rules: JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.IngressRules"], []).map((r: IngressRule) => ({
                host: r.host,
                http: {
                    paths: (_.get(r, "http.paths") || []).map((p: KeyValueOption) => ({
                        path: p.key,
                        backend: {
                            servicePort: getIntOrString(p.value),
                            serviceName: "placeholder",
                        },
                    })),
                },
            })),
        },
    };

    return convertToYAML(ingress);
}

export function importIngress(props: ActionEditProps<KubernetesIngressProperties>, template: string, labelsAndNamespace: boolean) {
    try {
        const yaml = jsyaml(template.split("---\n")[0]);

        if (yaml.kind !== "Ingress") {
            throw new Error("Not the correct type");
        }

        props.setProperties({ ["Octopus.Action.KubernetesContainers.IngressName"]: _.toString(_.get(yaml, "metadata.name")) });
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.IngressAnnotations"]: JSON.stringify(
                _.toPairs((_.get(yaml, "metadata.annotations") as { [key: string]: string }) || {}).map((a: string[]) => ({
                    key: _.toString(a[0]),
                    value: _.toString(a[1]),
                }))
            ),
        });
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.IngressRules"]: JSON.stringify(
                // transform each incoming rule into the JSON format used by the UI
                (_.get(yaml, "spec.rules") ?? []).map((r: IngressRule) => ({
                    host: r.host,
                    http: {
                        // paths flatten some nesting when displayed in the UI
                        paths: (_.get(r, "http.paths") ?? []).map((p: { path: string; backend: { servicePort: string | number } }) => ({
                            key: _.toString(p.path),
                            value: _.toString(_.get(p, "backend.servicePort")),
                        })),
                    },
                }))
            ),
        });

        if (labelsAndNamespace) {
            props.setProperties({
                ["Octopus.Action.KubernetesContainers.DeploymentLabels"]: JSON.stringify(
                    _.assign(
                        _.mapValues(_.get(yaml, "metadata.labels") || {}, v => _.toString(v)),
                        _.mapValues(_.get(yaml, "spec.template.metadata.labels") || {}, v => _.toString(v))
                    )
                ),
            });
            props.setProperties({ ["Octopus.Action.KubernetesContainers.Namespace"]: _.toString(_.get(yaml, "metadata.namespace")) });
        }
    } catch {
        throw { ErrorMessage: "The supplied text was not valid YAML or not a Ingress resource" };
    }
}

export function exportService(props: ActionEditProps<KubernetesServiceProperties>, labels: boolean) {
    const service = {
        apiVersion: "v1",
        kind: "Service",
        metadata: {
            name: props.properties["Octopus.Action.KubernetesContainers.ServiceName"],
            ...(labels &&
                _.keys(JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {})).length !== 0 && {
                    labels: JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {}),
                }),
            ...(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.LoadBalancerAnnotations"], []).length !== 0 && {
                annotations: _.fromPairs(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.LoadBalancerAnnotations"], []).map((a: KeyValuePair) => [a.key, a.value])),
            }),
            ...(props.properties["Octopus.Action.KubernetesContainers.Namespace"] && {
                namespace: props.properties["Octopus.Action.KubernetesContainers.Namespace"],
            }),
        },
        spec: {
            type: props.properties["Octopus.Action.KubernetesContainers.ServiceType"],
            ports: JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.ServicePorts"], []).map((p: ServicePort) => ({
                name: p.name,
                port: getIntOrString(p.port),
                nodePort: getIntOrString(p.nodePort),
                targetPort: getIntOrString(p.targetPort),
                protocol: p.protocol,
            })),
            ...(labels &&
                _.keys(JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.SelectorLabels"], {})).length !== 0 && {
                    selector: JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.SelectorLabels"], {}),
                }),
        },
    };

    return convertToYAML(service);
}

export function importService(props: ActionEditProps<KubernetesServiceProperties>, template: string, labelsAndNamespace: boolean) {
    try {
        const yaml = jsyaml(template.split("---\n")[0]);

        if (yaml.kind !== "Service") {
            throw new Error("Not the correct type");
        }

        props.setProperties({ ["Octopus.Action.KubernetesContainers.ServiceName"]: _.toString(_.get(yaml, "metadata.name")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.ServiceType"]: _.toString(_.get(yaml, "spec.type")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.ServiceClusterIp"]: _.toString(_.get(yaml, "spec.clusterIP")) });
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.LoadBalancerAnnotations"]: JSON.stringify(
                _.toPairs((_.get(yaml, "metadata.annotations") as { [key: string]: string }) || {}).map((a: string[]) => ({
                    key: _.toString(a[0]),
                    value: _.toString(a[1]),
                }))
            ),
        });
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.ServicePorts"]: JSON.stringify(
                (_.get(yaml, "spec.ports") || [])
                    // The property bag saves all values as strings, so change any port numbers to strings
                    .map((p: object) => _.mapValues(p, v => _.toString(v)))
            ),
        });

        if (labelsAndNamespace) {
            props.setProperties({
                ["Octopus.Action.KubernetesContainers.DeploymentLabels"]: JSON.stringify(
                    _.assign(
                        _.mapValues(_.get(yaml, "metadata.labels") || {}, v => _.toString(v)),
                        _.mapValues(_.get(yaml, "spec.template.metadata.labels") || {}, v => _.toString(v))
                    )
                ),
            });
            props.setProperties({ ["Octopus.Action.KubernetesContainers.Namespace"]: _.toString(_.get(yaml, "metadata.namespace")) });
            props.setProperties({
                ["Octopus.Action.KubernetesContainers.SelectorLabels"]: JSON.stringify(_.mapValues(_.get(yaml, "spec.selector") || {}, v => _.toString(v))),
            });
        }
    } catch (e) {
        throw { ErrorMessage: "The supplied text was not valid YAML or not a Service resource" };
    }
}

export function exportDeployment(props: ActionEditProps<KubernetesDeploymentProperties>) {
    const deployment = {
        apiVersion: "apps/v1",
        kind: "Deployment",
        metadata: {
            // The name is a required field, but set a default if a name hasn't been set yet
            name: props.properties["Octopus.Action.KubernetesContainers.DeploymentName"] || "OctopusDeployment",
            ...(_.keys(JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {})).length !== 0 && {
                labels: JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {}),
            }),
            ...(_.keys(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.DeploymentAnnotations"], [])).length !== 0 && {
                annotations: _.fromPairs(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.DeploymentAnnotations"], []).map((a: KeyValueOption) => [a.key, _.toString(a.value)])),
            }),
            ...(props.properties["Octopus.Action.KubernetesContainers.Namespace"] && {
                namespace: props.properties["Octopus.Action.KubernetesContainers.Namespace"],
            }),
        },
        spec: {
            // Use a default label as the selector, as the real selectors used by Octopus are specific to an individual deployment
            selector: {
                matchLabels: {
                    [PLACEHOLDER_LABEL_KEY]: PLACEHOLDER_LABEL_VALUE,
                },
            },
            replicas: getIntOrStringWithDefault(props.properties["Octopus.Action.KubernetesContainers.Replicas"], 1),
            ...(props.properties["Octopus.Action.KubernetesContainers.ProgressDeadlineSeconds"] && {
                progressDeadlineSeconds: getIntOrString(props.properties["Octopus.Action.KubernetesContainers.ProgressDeadlineSeconds"]),
            }),
            strategy: {
                // Blue green is not a Kubernetes deployment strategy, so revert back to the default if Blue Green was selected
                ...(props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"] !== "BlueGreen" && {
                    type: props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"],
                }),
                ...(props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"] === "RollingUpdate" && {
                    rollingUpdate: {
                        maxUnavailable: getIntOrString(props.properties["Octopus.Action.KubernetesContainers.MaxUnavailable"]),
                        maxSurge: getIntOrString(props.properties["Octopus.Action.KubernetesContainers.MaxSurge"]),
                    },
                }),
            },
            template: {
                metadata: {
                    labels: {
                        ...JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {}),
                        [PLACEHOLDER_LABEL_KEY]: PLACEHOLDER_LABEL_VALUE,
                    },
                },
                spec: {
                    ...(props.properties["Octopus.Action.KubernetesContainers.TerminationGracePeriodSeconds"] && {
                        terminationGracePeriodSeconds: getIntOrString(props.properties["Octopus.Action.KubernetesContainers.TerminationGracePeriodSeconds"]),
                    }),
                    ...(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.CombinedVolumes"], []).length !== 0 && {
                        volumes: JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.CombinedVolumes"], []).map((v: CombinedVolumeDetails) => ({
                            name: v.Name,
                            ...(v.Type === "ConfigMap" && {
                                configMap: {
                                    name: v.ReferenceNameType === "LinkedResource" ? "configmapname" : v.ReferenceName,
                                },
                            }),
                            ...(v.Type === "Secret" && {
                                secret: {
                                    secretName: v.ReferenceNameType === "LinkedResource" ? "configmapname" : v.ReferenceName,
                                },
                            }),
                            ...(v.Type === "EmptyDir" && {
                                emptyDir: {
                                    ...(v.EmptyDirMedium && {
                                        medium: v.EmptyDirMedium,
                                    }),
                                },
                            }),
                            ...(v.Type === "HostPath" && {
                                hostPath: {
                                    path: v.HostPathPath,
                                    type: v.HostPathType,
                                },
                            }),
                            ...(v.Type === "PersistentVolumeClaim" && {
                                persistentVolumeClaim: {
                                    claimName: v.ReferenceName,
                                },
                            }),
                            ...(v.Type === "RawYaml" && jsyaml(v.RawYaml)),
                        })),
                    }),
                    ...(props.properties["Octopus.Action.KubernetesContainers.Containers"] && {
                        containers: JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.Containers"], [])
                            .filter((c: ContainerPackageDetails) => c.InitContainer !== "True")
                            .map((c: ContainerPackageDetails) => exportContainer(c, props)),
                    }),
                    ...(props.properties["Octopus.Action.KubernetesContainers.Containers"] && {
                        initContainers: JsonUtils.tryParse(props.properties["Octopus.Action.KubernetesContainers.Containers"], [])
                            .filter((c: ContainerPackageDetails) => c.InitContainer === "True")
                            .map((c: ContainerPackageDetails) => exportContainer(c, props)),
                    }),
                    ...(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.Tolerations"], []).length !== 0 && {
                        tolerations: JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.Tolerations"], []).map((t: TolerationDetails) => ({
                            key: t.Key,
                            operator: t.Operator,
                            value: t.Value,
                            effect: t.Effect,
                        })),
                    }),
                    ...((props.properties["Octopus.Action.KubernetesContainers.PodSecurityFsGroup"] ||
                        props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsGroup"] ||
                        props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsNonRoot"] ||
                        JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.PodSecuritySysctls"], []).length !== 0 ||
                        props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxLevel"] ||
                        props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxRole"] ||
                        props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxType"] ||
                        props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxUser"]) && {
                        securityContext: {
                            ...(props.properties["Octopus.Action.KubernetesContainers.PodSecurityFsGroup"] && {
                                fsGroup: getIntOrString(props.properties["Octopus.Action.KubernetesContainers.PodSecurityFsGroup"]),
                            }),
                            ...(props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsGroup"] && {
                                runAsGroup: getIntOrString(props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsGroup"]),
                            }),
                            ...(props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsNonRoot"] && {
                                runAsNonRoot: getBoolOrString(props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsNonRoot"]),
                            }),
                            ...(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.PodSecuritySysctls"], []).length !== 0 && {
                                sysctls: JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.PodSecuritySysctls"], []).map((s: KeyValueOption) => ({ name: s.key, value: s.value })),
                            }),
                            ...((props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxLevel"] ||
                                props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxRole"] ||
                                props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxType"] ||
                                props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxUser"]) && {
                                seLinuxOptions: {
                                    level: props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxLevel"],
                                    role: props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxRole"],
                                    type: props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxType"],
                                    user: props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxUser"],
                                },
                            }),
                        },
                    }),
                    ...(props.properties["Octopus.Action.KubernetesContainers.PodServiceAccountName"] && {
                        serviceAccountName: props.properties["Octopus.Action.KubernetesContainers.PodServiceAccountName"],
                    }),
                    ...((JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.NodeAffinity"], []).length !== 0 ||
                        JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.PodAffinity"], []).length !== 0 ||
                        JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.PodAntiAffinity"], []).length !== 0) && {
                        affinity: {
                            ...(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.NodeAffinity"], []).length !== 0 && {
                                nodeAffinity: {
                                    ...(getRequiredRules(props.properties["Octopus.Action.KubernetesContainers.NodeAffinity"]).length !== 0 && {
                                        requiredDuringSchedulingIgnoredDuringExecution: {
                                            nodeSelectorTerms: getRequiredRules(props.properties["Octopus.Action.KubernetesContainers.NodeAffinity"]).map((n: NodeAffinityDetails) => ({
                                                matchExpressions: _.concat(
                                                    (n.InMatch ?? []).map((m: KeyValueOption) => ({
                                                        key: m.key,
                                                        operator: m.value,
                                                        values: m.option.split(","),
                                                    })),
                                                    (n.ExistMatch ?? []).map((m: KeyValueOption) => ({
                                                        key: m.key,
                                                        operator: m.value,
                                                    }))
                                                ),
                                            })),
                                        },
                                    }),
                                    ...(getPreferredRules(props.properties["Octopus.Action.KubernetesContainers.NodeAffinity"]).length !== 0 && {
                                        preferredDuringSchedulingIgnoredDuringExecution: getPreferredRules(props.properties["Octopus.Action.KubernetesContainers.NodeAffinity"]).map((n: NodeAffinityDetails) => ({
                                            weight: getIntOrString(n.Weight),
                                            preference: {
                                                matchExpressions: _.concat(
                                                    (n.InMatch ?? []).map((m: KeyValueOption) => ({
                                                        key: m.key,
                                                        operator: m.value,
                                                        values: m.option.split(","),
                                                    })),
                                                    (n.ExistMatch ?? []).map((m: KeyValueOption) => ({
                                                        key: m.key,
                                                        operator: m.value,
                                                    }))
                                                ),
                                            },
                                        })),
                                    }),
                                },
                            }),
                            ...(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.PodAffinity"], []).length !== 0 && {
                                podAffinity: {
                                    ...(getRequiredRules(props.properties["Octopus.Action.KubernetesContainers.PodAffinity"]).length !== 0 && {
                                        requiredDuringSchedulingIgnoredDuringExecution: getRequiredRules(props.properties["Octopus.Action.KubernetesContainers.PodAffinity"]).map((n: PodAffinityDetails) => ({
                                            labelSelector: {
                                                matchExpressions: _.concat(
                                                    (n.InMatch ?? []).map((m: KeyValueOption) => ({
                                                        key: m.key,
                                                        operator: m.value,
                                                        values: m.option.split(","),
                                                    })),
                                                    (n.ExistMatch ?? []).map((m: KeyValueOption) => ({
                                                        key: m.key,
                                                        operator: m.value,
                                                    }))
                                                ),
                                            },
                                            topologyKey: n.TopologyKey,
                                        })),
                                    }),
                                    ...(getPreferredRules(props.properties["Octopus.Action.KubernetesContainers.PodAffinity"]).length !== 0 && {
                                        preferredDuringSchedulingIgnoredDuringExecution: getPreferredRules(props.properties["Octopus.Action.KubernetesContainers.PodAffinity"]).map((n: PodAffinityDetails) => ({
                                            weight: getIntOrString(n.Weight),
                                            podAffinityTerm: {
                                                labelSelector: {
                                                    matchExpressions: _.concat(
                                                        (n.InMatch ?? []).map((m: KeyValueOption) => ({
                                                            key: m.key,
                                                            operator: m.value,
                                                            values: m.option.split(","),
                                                        })),
                                                        (n.ExistMatch ?? []).map((m: KeyValueOption) => ({
                                                            key: m.key,
                                                            operator: m.value,
                                                        }))
                                                    ),
                                                },
                                                topologyKey: n.TopologyKey,
                                            },
                                        })),
                                    }),
                                },
                            }),
                            ...(JsonUtils.tryParseArray(props.properties["Octopus.Action.KubernetesContainers.PodAntiAffinity"], []).length !== 0 && {
                                podAntiAffinity: {
                                    ...(getRequiredRules(props.properties["Octopus.Action.KubernetesContainers.PodAntiAffinity"]).length !== 0 && {
                                        requiredDuringSchedulingIgnoredDuringExecution:
                                            getRequiredRules(props.properties["Octopus.Action.KubernetesContainers.PodAntiAffinity"]).map((n: PodAffinityDetails) => ({
                                                labelSelector: {
                                                    matchExpressions: _.concat(
                                                        (n.InMatch ?? []).map((m: KeyValueOption) => ({
                                                            key: m.key,
                                                            operator: m.value,
                                                            values: m.option.split(","),
                                                        })),
                                                        (n.ExistMatch ?? []).map((m: KeyValueOption) => ({
                                                            key: m.key,
                                                            operator: m.value,
                                                        }))
                                                    ),
                                                },
                                                topologyKey: n.TopologyKey,
                                            })) ?? null,
                                    }),
                                    ...(getPreferredRules(props.properties["Octopus.Action.KubernetesContainers.PodAntiAffinity"]).length !== 0 && {
                                        preferredDuringSchedulingIgnoredDuringExecution:
                                            getPreferredRules(props.properties["Octopus.Action.KubernetesContainers.PodAntiAffinity"]).map((n: PodAffinityDetails) => ({
                                                weight: getIntOrString(n.Weight),
                                                podAffinityTerm: {
                                                    labelSelector: {
                                                        matchExpressions: _.concat(
                                                            (n.InMatch ?? []).map((m: KeyValueOption) => ({
                                                                key: m.key,
                                                                operator: m.value,
                                                                values: m.option.split(","),
                                                            })),
                                                            (n.ExistMatch ?? []).map((m: KeyValueOption) => ({
                                                                key: m.key,
                                                                operator: m.value,
                                                            }))
                                                        ),
                                                    },
                                                    topologyKey: n.TopologyKey,
                                                },
                                            })) ?? null,
                                    }),
                                },
                            }),
                        },
                    }),
                },
            },
        },
    };

    return convertToYAML(deployment);
}

export function importDeployment(props: ActionEditProps<KubernetesDeploymentProperties>, feeds: FeedResource[], template: string) {
    try {
        const yaml = jsyaml(template.split("---\n")[0]);

        if (yaml.kind !== "Deployment") {
            throw new Error("Not the correct type");
        }

        props.setProperties({
            ["Octopus.Action.KubernetesContainers.Tolerations"]: JSON.stringify(
                (_.get(yaml, "spec.template.spec.tolerations") || []).map((t: KubernetesToleration) => ({
                    Key: t.key,
                    Operator: t.operator,
                    Value: t.value,
                    Effect: t.effect,
                }))
            ),
        });

        props.setProperties({
            ["Octopus.Action.KubernetesContainers.NodeAffinity"]: JSON.stringify(
                _.concat(
                    convertKubernetesNodeAffinityRequired(_.get(yaml, "spec.template.spec.affinity.nodeAffinity.requiredDuringSchedulingIgnoredDuringExecution") ?? []),
                    convertKubernetesNodeAffinityPreference(_.get(yaml, "spec.template.spec.affinity.nodeAffinity.preferredDuringSchedulingIgnoredDuringExecution") ?? [])
                )
            ),
        });
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.PodAffinity"]: JSON.stringify(
                _.concat(
                    convertKubernetesPreferredPodAffinity(_.get(yaml, "spec.template.spec.affinity.podAffinity.preferredDuringSchedulingIgnoredDuringExecution") ?? []),
                    convertKubernetesPodAffinity(_.get(yaml, "spec.template.spec.affinity.podAffinity.requiredDuringSchedulingIgnoredDuringExecution") ?? [])
                )
            ),
        });
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.PodAntiAffinity"]: JSON.stringify(
                _.concat(
                    convertKubernetesPreferredPodAffinity(_.get(yaml, "spec.template.spec.affinity.podAntiAffinity.preferredDuringSchedulingIgnoredDuringExecution") ?? []),
                    convertKubernetesPodAffinity(_.get(yaml, "spec.template.spec.affinity.podAntiAffinity.requiredDuringSchedulingIgnoredDuringExecution") ?? [])
                )
            ),
        });

        props.setProperties({ ["Octopus.Action.KubernetesContainers.Namespace"]: _.toString(_.get(yaml, "metadata.namespace")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentName"]: _.toString(_.get(yaml, "metadata.name")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.Replicas"]: _.toString(_.get(yaml, "spec.replicas") ?? "1") });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.ProgressDeadlineSeconds"]: _.toString(_.get(yaml, "spec.progressDeadlineSeconds")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.TerminationGracePeriodSeconds"]: _.toString(_.get(yaml, "spec.template.spec.terminationGracePeriodSeconds")) });
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.PodAnnotations"]: JSON.stringify(
                _.toPairs(_.get(yaml, "spec.template.metadata.annotations") as { [key: string]: string }).map((a: string[]) => ({
                    key: _.toString(a[0]),
                    value: _.toString(a[1]),
                }))
            ),
        });
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.DeploymentAnnotations"]: JSON.stringify(
                _.toPairs(_.get(yaml, "metadata.annotations") as { [key: string]: string }).map((a: string[]) => ({
                    key: _.toString(a[0]),
                    value: _.toString(a[1]),
                }))
            ),
        });
        props.setProperties({
            /*
                Merge the deployment and pod template labels. This form applies one set of labels to all resources, and so merging the two means we are likely to get the desired labels.
                Note that we omit any labels with a key matching the placeholder that was added to the exported yaml. This means an export/import cycle doesn't add new labels.
             */
            ["Octopus.Action.KubernetesContainers.DeploymentLabels"]: JSON.stringify(
                _.assign(
                    _.omit(
                        _.mapValues(_.get(yaml, "metadata.labels") || {}, v => _.toString(v)),
                        [PLACEHOLDER_LABEL_KEY]
                    ),
                    _.omit(
                        _.mapValues(_.get(yaml, "spec.template.metadata.labels") || {}, v => _.toString(v)),
                        [PLACEHOLDER_LABEL_KEY]
                    )
                )
            ),
        });
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.CombinedVolumes"]: JSON.stringify(
                (_.get(yaml, "spec.template.spec.volumes") ?? []).map((v: KubernetesVolume) => ({
                    Name: v.name,
                    ...(v.configMap && {
                        Type: "ConfigMap",
                        Items: (_.get(v, "configMap.items") ?? []).map((i: KubernetesVolumeItem) => ({ key: _.toString(i.key), value: _.toString(i.path) })),
                        ReferenceName: _.toString(_.get(v, "configMap.name")),
                    }),
                    ...(v.secret && {
                        Type: "Secret",
                        Items: (_.get(v, "secret.items") ?? []).map((i: KubernetesVolumeItem) => ({ key: _.toString(i.key), value: _.toString(i.path) })),
                        ReferenceName: _.toString(_.get(v, "secret.secretName")),
                    }),
                    ...(v.emptyDir && {
                        Type: "EmptyDir",
                        EmptyDirMedium: _.toString(_.get(v, "emptyDir.medium")),
                    }),
                    ...(v.hostPath && {
                        Type: "HostPath",
                        HostPathPath: _.toString(_.get(v, "hostPath.path")),
                        HostPathType: _.toString(_.get(v, "hostPath.type")),
                    }),
                    ...(v.persistentVolumeClaim && {
                        Type: "PersistentVolumeClaim",
                        ReferenceName: _.toString(_.get(v, "persistentVolumeClaim.claimName")),
                    }),
                    ...(v.configMap === undefined &&
                        v.secret === undefined &&
                        v.emptyDir === undefined &&
                        v.hostPath === undefined &&
                        v.persistentVolumeClaim === undefined && {
                            Type: "RawYaml",
                            RawYaml: convertToYAML(v),
                        }),
                }))
            ),
        });

        props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecurityFsGroup"]: _.toString(_.get(yaml, "spec.template.spec.securityContext.fsGroup")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecurityRunAsGroup"]: _.toString(_.get(yaml, "spec.template.spec.securityContext.runAsGroup")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecurityRunAsNonRoot"]: _.toString(_.get(yaml, "spec.template.spec.securityContext.runAsNonRoot")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxLevel"]: _.toString(_.get(yaml, "spec.template.spec.securityContext.seLinuxOptions.level")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxRole"]: _.toString(_.get(yaml, "spec.template.spec.securityContext.seLinuxOptions.role")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxType"]: _.toString(_.get(yaml, "spec.template.spec.securityContext.seLinuxOptions.type")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxUser"]: _.toString(_.get(yaml, "spec.template.spec.securityContext.seLinuxOptions.user")) });
        props.setProperties({
            ["Octopus.Action.KubernetesContainers.PodSecuritySysctls"]: JSON.stringify((_.get(yaml, "spec.template.spec.securityContext.sysctls") || []).map((s: KubernetesSysctl) => ({ key: s.name, value: _.toString(s.value) }))),
        });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.PodServiceAccountName"]: _.toString(_.get(yaml, "spec.template.spec.serviceAccountName")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.MaxUnavailable"]: _.toString(_.get(yaml, "spec.strategy.rollingUpdate.maxUnavailable")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.MaxSurge"]: _.toString(_.get(yaml, "spec.strategy.rollingUpdate.maxSurge")) });
        props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentStyle"]: _.toString(_.get(yaml, "spec.strategy.type")) || "Recreate" });

        props.setPackages(
            _.concat(_.get(yaml, "spec.template.spec.containers") || [], _.get(yaml, "spec.template.spec.initContainers") || []).map((container: object) => ({
                AcquisitionLocation: PackageAcquisitionLocation.NotAcquired,
                Name: _.toString(_.get(container, "name")),
                PackageId: sanitizeContainerName(_.get(container, "image")),
                FeedId: _.get(
                    feeds.find(f => f.FeedType === FeedType.Docker || f.FeedType === FeedType.AwsElasticContainerRegistry),
                    "Id"
                )!,
                Properties: {},
                Id: null!,
            }))
        );

        props.setProperties({
            ["Octopus.Action.KubernetesContainers.Containers"]: JSON.stringify(
                _.concat(
                    (_.get(yaml, "spec.template.spec.containers") || []).map((container: object) => importContainer(container, feeds, false)),
                    (_.get(yaml, "spec.template.spec.initContainers") || []).map((container: object) => importContainer(container, feeds, true))
                )
            ),
        });
    } catch (e) {
        throw { ErrorMessage: "The supplied text was not valid YAML or not a Deployment resource" };
    }
}

/**
 * Image names can embed the hostname of the repository, which we do not want when importing as we use the
 * feed as the repo. Here we strip out the image version and any repo information.
 * @param container The container image to sanitize
 */
function sanitizeContainerName(container: string) {
    if (!container) {
        return "";
    }
    const pathElements = container.split(":")[0].split("/");
    return _.takeRight(pathElements, Math.min(pathElements.length, 2)).join("/");
}

/**
 * Attempt to parse the input as an int, or fall back to a string. If the input is empty, return a default value.
 * This is done to try and build YAML with integer properties where possible, but using strings where variable replacement has been used.
 * @param input The string to parse
 * @param defaultNumber The default value to return if the input is empty
 */
function getIntOrStringWithDefault(input: string, defaultNumber: number): string | number {
    return isNaN(parseInt(input, 10) ?? defaultNumber) ? input : parseInt(input ?? defaultNumber, 10);
}

/**
 * Attempt to parse the input as an int, or fall back to a string. This is done to try and build YAML with integer
 * properties where possible, but using strings where variable replacement has been used.
 * @param input The string to parse
 */
function getIntOrString(input: string): string | number {
    return isNaN(parseInt(input, 10)) ? input : parseInt(input, 10);
}

/**
 * Attempt to parse the input as an boolean, or fall back to a string. This is done to try and build YAML with boolean
 * properties where possible, but using strings where variable replacement has been used.
 * @param input The string to parse
 */
function getBoolOrString(input: string | boolean): string | boolean {
    if (!input) {
        return false;
    }
    if (_.isBoolean(input)) {
        return input;
    }
    if (input.toLowerCase() === "true") {
        return true;
    }
    if (input.toLowerCase() === "false") {
        return false;
    }
    return input;
}

/**
 * Convert the supplied object into a YAML string
 * @param input The object to convert
 */
function convertToYAML(input: object) {
    return safeDump(input, { skipInvalid: true, flowLevel: -1, sortKeys: false });
}

function convertKubernetesPreferredPodAffinity(input: KubernetesPreferredPodAffinity[]) {
    return (input ?? []).map((a: KubernetesPreferredPodAffinity) => ({
        Type: "Preferred",
        Weight: _.toString(a.weight),
        ...convertKubernetesPreferredPodAffinityItem(a.podAffinityTerm),
    }));
}

function convertKubernetesPodAffinity(input: KubernetesPodAffinity[]) {
    return (input ?? []).map((a: KubernetesPodAffinity) => ({
        Type: "Required",
        ...convertKubernetesRequiredPodAffinityItem(a),
    }));
}

function convertKubernetesPreferredPodAffinityItem(input: KubernetesPodAffinity) {
    return {
        NamespacesList: input.namespaces,
        TopologyKey: input.topologyKey,
        InMatch: (_.get(input, "labelSelector.matchExpressions") ?? [])
            .filter((m: KubernetesMatchExpression) => m.operator === "In" || m.operator === "NotIn")
            .map((m: KubernetesMatchExpression) => ({
                key: m.key,
                value: m.operator,
                option: (m.values ?? []).join(","),
            })),
        ExistMatch: (_.get(input, "labelSelector.matchExpressions") ?? [])
            .filter((m: KubernetesMatchExpression) => m.operator === "Exists" || m.operator === "DoesNotExist")
            .map((m: KubernetesMatchExpression) => ({
                key: m.key,
                value: m.operator,
            })),
    };
}

function convertKubernetesRequiredPodAffinityItem(input: KubernetesPodAffinity) {
    return {
        NamespacesList: input.namespaces,
        TopologyKey: input.topologyKey,
        InMatch: (_.get(input, "labelSelector.matchExpressions") ?? [])
            .filter((m: KubernetesMatchExpression) => m.operator === "In" || m.operator === "NotIn")
            .map((m: KubernetesMatchExpression) => ({
                key: m.key,
                value: m.operator,
                option: (m.values ?? []).join(","),
            })),
        ExistMatch: (_.get(input, "labelSelector.matchExpressions") ?? [])
            .filter((m: KubernetesMatchExpression) => m.operator === "Exists" || m.operator === "DoesNotExist")
            .map((m: KubernetesMatchExpression) => ({
                key: m.key,
                value: m.operator,
            })),
    };
}

function convertKubernetesNodeAffinityRequired(input: KubernetesNodeAffinityRequired) {
    return (input.nodeSelectorTerms ?? []).map((n: KubernetesMatchExpressions) => ({
        Type: "Required",
        ...convertKubernetesMatchExpression(n.matchExpressions),
    }));
}

function convertKubernetesNodeAffinityPreference(input: KubernetesNodeAffinityPreference[]) {
    return (input || []).map((a: KubernetesNodeAffinityPreference) => ({
        Type: "Preferred",
        Weight: _.toString(a.weight),
        ...convertKubernetesMatchExpression(a.preference.matchExpressions),
    }));
}

function convertKubernetesMatchExpression(input: KubernetesMatchExpression[]) {
    return {
        InMatch: (input || [])
            .filter((m: KubernetesMatchExpression) => m.operator !== "Exists" && m.operator !== "DoesNotExist")
            .map((m: KubernetesMatchExpression) => ({
                key: m.key,
                value: m.operator,
                option: (m.values ?? []).join(","),
            })),
        ExistMatch: (input || [])
            .filter((m: KubernetesMatchExpression) => m.operator === "Exists" || m.operator === "DoesNotExist")
            .map((m: KubernetesMatchExpression) => ({
                key: m.key,
                value: m.operator,
            })),
    };
}

function getPreferredRules(input: string) {
    return JsonUtils.tryParseArray(input, []).filter((n: NodeAffinityDetails | PodAffinityDetails) => n.Type === "Preferred");
}

function getRequiredRules(input: string) {
    return JsonUtils.tryParseArray(input, []).filter((n: NodeAffinityDetails | PodAffinityDetails) => n.Type === "Required");
}

function importContainer(container: object, feeds: FeedResource[], init: boolean) {
    return {
        IsNew: true,
        InitContainer: init ? "True" : "False",
        Ports: (_.get(container, "ports") ?? []).map((p: { name: string; containerPort: string | number; protocol: string }) => ({
            ...(p.name && {
                key: _.toString(p.name),
            }),
            value: _.toString(p.containerPort),
            ...(p.protocol && {
                option: _.toString(p.protocol),
            }),
        })),
        EnvironmentVariables: (_.get(container, "env") ?? [])
            .filter((p: { name: string; value: string }) => p.value)
            .map((p: { name: string; value: string }) => ({
                key: _.toString(p.name),
                value: _.toString(p.value),
            })),
        SecretEnvironmentVariables: (_.get(container, "env") ?? [])
            .filter((p: object) => _.get(p, "valueFrom.secretKeyRef"))
            .map((p: KubernetesSecretEnvVar) => ({
                key: _.toString(p.name),
                value: _.toString(_.get(p, "valueFrom.secretKeyRef.name")),
                option: _.toString(_.get(p, "valueFrom.secretKeyRef.key")),
            })),
        ConfigMapEnvironmentVariables: (_.get(container, "env") ?? [])
            .filter((p: object) => _.get(p, "valueFrom.configMapKeyRef"))
            .map((p: KubernetesConfigmapEnvVar) => ({
                key: _.toString(p.name),
                value: _.toString(_.get(p, "valueFrom.configMapKeyRef.name")),
                option: _.toString(_.get(p, "valueFrom.configMapKeyRef.key")),
            })),
        FieldRefEnvironmentVariables: (_.get(container, "env") ?? [])
            .filter((p: object) => _.get(p, "valueFrom.fieldRef"))
            .map((p: { name: string; valueFrom: { fieldRef: { fieldPath: string } } }) => ({
                key: _.toString(p.name),
                value: _.toString(_.get(p, "valueFrom.fieldRef.fieldPath")),
            })),
        VolumeMounts: (_.get(container, "volumeMounts") ?? []).map((p: { name: string; mountPath: string; subPath: string }) => ({
            key: _.toString(p.name),
            value: _.toString(p.mountPath),
            option: _.toString(p.subPath),
        })),
        AcquisitionLocation: PackageAcquisitionLocation.NotAcquired,
        Name: _.toString(_.get(container, "name")),
        PackageId: sanitizeContainerName(_.get(container, "image")),
        FeedId: _.get(
            feeds.find(f => f.FeedType === FeedType.Docker || f.FeedType === FeedType.AwsElasticContainerRegistry),
            "Id"
        ),
        Properties: {},
        Command: (_.get(container, "command") || []).map((c: string) => _.toString(c)),
        Args: (_.get(container, "args") || []).map((c: string) => _.toString(c)),
        Resources: {
            requests: {
                memory: _.toString(_.get(container, "resources.requests.memory")),
                cpu: _.toString(_.get(container, "resources.requests.cpu")),
                ephemeralStorage: _.toString(_.get(container, "resources.requests.ephemeralStorage")),
            },
            limits: {
                memory: _.toString(_.get(container, "resources.limits.memory")),
                cpu: _.toString(_.get(container, "resources.limits.cpu")),
                ephemeralStorage: _.toString(_.get(container, "resources.limits.ephemeralStorage")),
            },
        },
        LivenessProbe: {
            failureThreshold: _.toString(_.get(container, "livenessProbe.failureThreshold")),
            initialDelaySeconds: _.toString(_.get(container, "livenessProbe.initialDelaySeconds")),
            periodSeconds: _.toString(_.get(container, "livenessProbe.periodSeconds")),
            successThreshold: _.toString(_.get(container, "livenessProbe.successThreshold")),
            timeoutSeconds: _.toString(_.get(container, "livenessProbe.timeoutSeconds")),
            type: _.get(container, "livenessProbe.exec.command") ? "Command" : _.get(container, "livenessProbe.httpGet.port") ? "HttpGet" : _.get(container, "livenessProbe.tcpSocket.port") ? "TcpSocket" : null,
            exec: {
                // we expect string array, but force the type just in case
                command: (_.get(container, "livenessProbe.exec.command") || []).map((c: string) => _.toString(c)),
            },
            httpGet: {
                host: _.toString(_.get(container, "livenessProbe.httpGet.host")),
                path: _.toString(_.get(container, "livenessProbe.httpGet.path")),
                port: _.toString(_.get(container, "livenessProbe.httpGet.port")),
                scheme: _.toString(_.get(container, "livenessProbe.httpGet.scheme")),
                httpHeaders: (_.get(container, "livenessProbe.httpGet.httpHeaders") ?? []).map((h: KubernetesHttpHeaders) => ({
                    key: _.toString(h.name),
                    value: _.toString(h.value),
                })),
            },
            tcpSocket: {
                host: _.toString(_.get(container, "livenessProbe.tcpSocket.host")),
                port: _.toString(_.get(container, "livenessProbe.tcpSocket.port")),
            },
        },
        ReadinessProbe: {
            failureThreshold: _.toString(_.get(container, "readinessProbe.failureThreshold")),
            initialDelaySeconds: _.toString(_.get(container, "readinessProbe.initialDelaySeconds")),
            periodSeconds: _.toString(_.get(container, "readinessProbe.periodSeconds")),
            successThreshold: _.toString(_.get(container, "readinessProbe.successThreshold")),
            timeoutSeconds: _.toString(_.get(container, "readinessProbe.timeoutSeconds")),
            type: _.get(container, "readinessProbe.exec.command") ? "Command" : _.get(container, "readinessProbe.httpGet.port") ? "HttpGet" : _.get(container, "readinessProbe.tcpSocket.port") ? "TcpSocket" : null,
            exec: {
                command: (_.get(container, "readinessProbe.exec.command") || []).map((c: string) => _.toString(c)),
            },
            httpGet: {
                host: _.toString(_.get(container, "readinessProbe.httpGet.host")),
                path: _.toString(_.get(container, "readinessProbe.httpGet.path")),
                port: _.toString(_.toString(_.get(container, "readinessProbe.httpGet.port"))),
                scheme: _.toString(_.get(container, "readinessProbe.httpGet.scheme")),
                httpHeaders: (_.get(container, "readinessProbe.httpGet.httpHeaders") ?? []).map((h: KubernetesHttpHeaders) => ({
                    key: _.toString(h.name),
                    value: _.toString(h.value),
                })),
            },
            tcpSocket: {
                host: _.toString(_.get(container, "readinessProbe.tcpSocket.host")),
                port: _.toString(_.get(container, "readinessProbe.tcpSocket.port")),
            },
        },
        Lifecycle: {
            ...(_.get(container, "lifecycle.preStop.exec.command") && {
                PreStop: {
                    Exec: {
                        command: (_.get(container, "lifecycle.preStop.exec.command") || []).map((c: string) => _.toString(c)),
                    },
                },
            }),
            ...(_.get(container, "lifecycle.postStart.exec.command") && {
                PostStart: {
                    Exec: {
                        command: (_.get(container, "lifecycle.postStart.exec.command") || []).map((c: string) => _.toString(c)),
                    },
                },
            }),
        },
        SecurityContext: {
            allowPrivilegeEscalation: _.toString(_.get(container, "securityContext.allowPrivilegeEscalation")),
            privileged: _.toString(_.get(container, "securityContext.privileged")),
            readOnlyRootFilesystem: _.toString(_.get(container, "securityContext.readOnlyRootFilesystem")),
            runAsGroup: _.toString(_.get(container, "securityContext.runAsGroup")),
            runAsNonRoot: _.toString(_.get(container, "securityContext.runAsNonRoot")),
            runAsUser: _.toString(_.get(container, "securityContext.runAsUser")),
            capabilities: {
                add: (_.get(container, "securityContext.capabilities.add") ?? []).map((v: string) => _.toString(v)),
                drop: (_.get(container, "securityContext.capabilities.drop") ?? []).map((v: string) => _.toString(v)),
            },
            seLinuxOptions: {
                level: _.toString(_.get(container, "securityContext.seLinuxOptions.level")),
                role: _.toString(_.get(container, "securityContext.seLinuxOptions.role")),
                type: _.toString(_.get(container, "securityContext.seLinuxOptions.type")),
                user: _.toString(_.get(container, "securityContext.seLinuxOptions.user")),
            },
        },
    };
}

function exportContainer(c: ContainerDetails, props: ActionEditProps<KubernetesDeploymentProperties>) {
    return {
        name: c.Name,
        image: _.get(
            props.packages.find((p: PackageReference) => p.Name === c.Name),
            "PackageId"
        ),
        ...(objectHasValuesOrNestedObjects(c.Command ?? []) && {
            command: c.Command,
        }),
        ...(objectHasValuesOrNestedObjects(c.Args ?? []) && {
            args: c.Args,
        }),
        ...((c.Ports ?? []).length !== 0 && {
            ports: (c.Ports ?? []).map((p: KeyValueOption) => ({
                name: p.key,
                containerPort: getIntOrString(p.value),
                protocol: p.option,
            })),
        }),
        ...((c.EnvironmentVariables ?? []).length + (c.SecretEnvironmentVariables ?? []).length + (c.EnvironmentVariables ?? []).length + (c.FieldRefEnvironmentVariables ?? []).length !== 0 && {
            env: _.concat(
                (c.SecretEnvironmentVariables ?? []).map((p: KeyValueOption) => ({
                    name: p.key,
                    valueFrom: {
                        secretKeyRef: {
                            name: p.value,
                            key: p.option,
                        },
                    },
                })) as object[],
                (c.ConfigMapEnvironmentVariables ?? []).map((p: KeyValueOption) => ({
                    name: p.key,
                    valueFrom: {
                        configMapKeyRef: {
                            name: p.value,
                            key: p.option,
                        },
                    },
                })) as object[],
                (c.FieldRefEnvironmentVariables ?? []).map((p: KeyValueOption) => ({
                    name: p.key,
                    valueFrom: {
                        fieldRef: {
                            fieldPath: p.value,
                        },
                    },
                })) as object[],
                (c.EnvironmentVariables ?? [])
                    .filter((p: KeyValueOption) => p.value)
                    .map((p: KeyValueOption) => ({
                        name: p.key,
                        value: p.value,
                    })) as object[]
            ),
        }),
        ...(c.VolumeMounts &&
            c.VolumeMounts.length !== 0 && {
                volumeMounts: (c.VolumeMounts ?? []).map((p: KeyValueOption) => ({
                    name: p.key,
                    mountPath: p.value,
                    subPath: p.option,
                })),
            }),
        ...(objectHasValuesOrNestedObjects(c.Resources) && {
            resources: {
                ...(objectHasValuesOrNestedObjects(_.get(c, "Resources.requests")) && {
                    requests: {
                        ...(_.get(c, "Resources.requests.memory") && {
                            memory: _.get(c, "Resources.requests.memory"),
                        }),
                        ...(_.get(c, "Resources.requests.cpu") && {
                            cpu: _.get(c, "Resources.requests.cpu"),
                        }),
                        ...(_.get(c, "Resources.requests.ephemeralStorage") && {
                            ephemeralStorage: _.get(c, "Resources.requests.ephemeralStorage"),
                        }),
                    },
                }),
                ...(objectHasValuesOrNestedObjects(_.get(c, "Resources.limits")) && {
                    limits: {
                        ...(_.get(c, "Resources.limits.memory") && {
                            memory: _.get(c, "Resources.limits.memory"),
                        }),
                        ...(_.get(c, "Resources.limits.cpu") && {
                            cpu: _.get(c, "Resources.limits.cpu"),
                        }),
                        ...(_.get(c, "Resources.limits.ephemeralStorage") && {
                            ephemeralStorage: _.get(c, "Resources.limits.ephemeralStorage"),
                        }),
                    },
                }),
            },
        }),
        ...(_.get(c, "LivenessProbe.type") && {
            livenessProbe: {
                ...(_.get(c, "LivenessProbe.failureThreshold") && {
                    failureThreshold: getIntOrString(_.get(c, "LivenessProbe.failureThreshold")),
                }),
                ...(_.get(c, "LivenessProbe.initialDelaySeconds") && {
                    initialDelaySeconds: getIntOrString(_.get(c, "LivenessProbe.initialDelaySeconds")),
                }),
                ...(_.get(c, "LivenessProbe.initialDelaySeconds") && {
                    periodSeconds: getIntOrString(_.get(c, "LivenessProbe.periodSeconds")),
                }),
                ...(_.get(c, "LivenessProbe.timeoutSeconds") && {
                    timeoutSeconds: getIntOrString(_.get(c, "LivenessProbe.timeoutSeconds")),
                }),
                ...(_.get(c, "LivenessProbe.type") === "Command" && {
                    exec: {
                        command: _.get(c, "LivenessProbe.exec.command"),
                    },
                }),
                ...(_.get(c, "LivenessProbe.type") === "HttpGet" && {
                    httpGet: {
                        host: _.get(c, "LivenessProbe.httpGet.host"),
                        path: _.get(c, "LivenessProbe.httpGet.path"),
                        port: getIntOrString(_.get(c, "LivenessProbe.httpGet.port")),
                        scheme: _.get(c, "LivenessProbe.httpGet.scheme"),
                        httpHeaders: (_.get(c, "LivenessProbe.httpGet.httpHeaders") || []).map((h: KeyValueOption) => ({
                            name: h.key,
                            value: h.value,
                        })),
                    },
                }),
                ...(_.get(c, "LivenessProbe.type") === "TcpSocket" && {
                    httpGet: {
                        host: _.get(c, "LivenessProbe.tcpSocket.host"),
                        port: getIntOrString(_.get(c, "LivenessProbe.tcpSocket.port")),
                    },
                }),
            },
        }),
        ...(_.get(c, "ReadinessProbe.type") && {
            readinessProbe: {
                ...(_.get(c, "ReadinessProbe.failureThreshold") && {
                    failureThreshold: getIntOrString(_.get(c, "ReadinessProbe.failureThreshold")),
                }),
                ...(_.get(c, "ReadinessProbe.initialDelaySeconds") && {
                    initialDelaySeconds: getIntOrString(_.get(c, "ReadinessProbe.initialDelaySeconds")),
                }),
                ...(_.get(c, "ReadinessProbe.periodSeconds") && {
                    periodSeconds: getIntOrString(_.get(c, "ReadinessProbe.periodSeconds")),
                }),
                ...(_.get(c, "ReadinessProbe.successThreshold") && {
                    successThreshold: getIntOrString(_.get(c, "ReadinessProbe.successThreshold")),
                }),
                ...(_.get(c, "ReadinessProbe.timeoutSeconds") && {
                    timeoutSeconds: getIntOrString(_.get(c, "ReadinessProbe.timeoutSeconds")),
                }),
                ...(_.get(c, "ReadinessProbe.type") === "Command" && {
                    exec: {
                        command: _.get(c, "ReadinessProbe.exec.command"),
                    },
                }),
                ...(_.get(c, "ReadinessProbe.type") === "HttpGet" && {
                    httpGet: {
                        host: _.get(c, "ReadinessProbe.httpGet.host"),
                        path: _.get(c, "ReadinessProbe.httpGet.path"),
                        port: getIntOrString(_.get(c, "ReadinessProbe.httpGet.port")),
                        scheme: _.get(c, "ReadinessProbe.httpGet.scheme"),
                        httpHeaders: (_.get(c, "ReadinessProbe.httpGet.httpHeaders") || []).map((h: KeyValueOption) => ({
                            name: h.key,
                            value: h.value,
                        })),
                    },
                }),
                ...(_.get(c, "ReadinessProbe.type") === "TcpSocket" && {
                    tcpSocket: {
                        host: _.get(c, "ReadinessProbe.tcpSocket.host"),
                        port: getIntOrString(_.get(c, "ReadinessProbe.tcpSocket.port")),
                    },
                }),
            },
        }),
        ...(objectHasValuesOrNestedObjects(c.Lifecycle) && {
            lifecycle: {
                ...(_.get(c, "Lifecycle.PreStop") && {
                    preStop: {
                        exec: {
                            command: _.get(c, "Lifecycle.PreStop.Exec.command"),
                        },
                    },
                }),
                ...(_.get(c, "Lifecycle.PostStart") && {
                    postStart: {
                        exec: {
                            command: _.get(c, "Lifecycle.PostStart.Exec.command"),
                        },
                    },
                }),
            },
        }),
        ...(objectHasValuesOrNestedObjects(c.SecurityContext) && {
            securityContext: {
                ...(_.get(c, "SecurityContext.allowPrivilegeEscalation") && {
                    allowPrivilegeEscalation: getBoolOrString(_.get(c, "SecurityContext.allowPrivilegeEscalation")),
                }),
                ...(_.get(c, "SecurityContext.privileged") && {
                    privileged: getBoolOrString(_.get(c, "SecurityContext.privileged")),
                }),
                ...(_.get(c, "SecurityContext.runAsGroup") && {
                    readOnlyRootFilesystem: getBoolOrString(_.get(c, "SecurityContext.readOnlyRootFilesystem")),
                }),
                runAsGroup: getIntOrString(_.get(c, "SecurityContext.runAsGroup")),
                ...(_.get(c, "SecurityContext.runAsNonRoot") && {
                    runAsNonRoot: getBoolOrString(_.get(c, "SecurityContext.runAsNonRoot")),
                }),
                ...(_.get(c, "SecurityContext.runAsUser") && {
                    runAsUser: getIntOrString(_.get(c, "SecurityContext.runAsUser")),
                }),
                ...(objectHasValuesOrNestedObjects(_.get(c, "SecurityContext.capabilities")) && {
                    capabilities: {
                        add: _.get(c, "SecurityContext.capabilities.add"),
                        drop: _.get(c, "SecurityContext.capabilities.drop"),
                    },
                }),
                ...(objectHasValuesOrNestedObjects(_.get(c, "SecurityContext.seLinuxOptions")) && {
                    seLinuxOptions: {
                        level: _.get(c, "SecurityContext.seLinuxOptions.level"),
                        role: _.get(c, "SecurityContext.seLinuxOptions.role"),
                        type: _.get(c, "SecurityContext.seLinuxOptions.type"),
                        user: _.get(c, "SecurityContext.seLinuxOptions.user"),
                    },
                }),
            },
        }),
    };
}

/**
 * Returns true if myObject contains any significant non-object parameters. Any object parameters are recursively tested
 * with this function.
 * @param myObject The object to test
 * @return true if the object to tests contains useful values, and false otherwise
 */
function objectHasValuesOrNestedObjects(myObject: object): boolean {
    return _.values(myObject ?? {}).some((v: any) => {
        return (
            // most properties with a useful value are considered significant
            !_.isUndefined(v) &&
            !_.isNull(v) &&
            !_.isNaN(v) &&
            !_.isSymbol(v) &&
            // Only non empty strings are significant
            (!_.isString(v) || v.length !== 0) &&
            // Only non empty arrays are significant
            (!_.isArray(v) || v.length !== 0) &&
            // Only non empty objects are significant
            (!_.isObject(v) || objectHasValuesOrNestedObjects(v))
        );
    });
}
