import * as React from "react";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { Errors } from "components/DataBaseComponent/Errors";

interface LoadingProps {
    title: string;
    busy: Promise<void>;
    errors: Errors;
    flatStyle?: boolean;
}

const Loading: React.StatelessComponent<LoadingProps> = props => <PaperLayout title={props.title} disableSettingPageTitle={true} busy={props.busy} errors={props.errors} {...(props.flatStyle ? { flatStyle: true } : {})} />;

export default Loading;
