// tslint:disable: no-any

import * as React from "react";
type DoBusyTask = import("./DataBaseComponent").DoBusyTask;

export const useDoBusyTaskEffect = <T extends any>(doBusyTask: DoBusyTask, fetch: () => Promise<T>, dependencies: React.DependencyList) => {
    const refresh = React.useCallback(() => doBusyTask(fetch), dependencies);

    // https://reactjs.org/docs/hooks-reference.html#useeffect
    React.useEffect(() => {
        // It's important not to make useEffect async or to return a promise here, since useEffect can return a cleanup function.
        // tslint:disable-next-line: no-floating-promises
        refresh();
    }, [refresh]);

    return refresh;
};

export default useDoBusyTaskEffect;
