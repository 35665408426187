// tslint:disable: no-any

import ExternalLink from "../../Navigation/ExternalLink/ExternalLink";
import * as React from "react";
import pluginRegistry, { ActionEditProps } from "../../Actions/pluginRegistry";
import { BaseComponent } from "../../BaseComponent/BaseComponent";
import { ExpandableFormSection, Summary } from "../../form";
import { BoundStringCheckbox } from "../../form/Checkbox/StringCheckbox";
import Note from "../../form/Note/Note";
import { VariableLookupText } from "../../form/VariableLookupText";
import { Callout, CalloutType } from "../../Callout";
import ActionProperties from "client/resources/actionProperties";
import { ValueInPropertiesOrErrorsHasChanged } from "utils/ShouldUpdate/ValueInPropertiesHasChanged";

const StringProperties = {
    "Octopus.Action.RedGateDatabase.Enabled": "",
    "Octopus.Action.RedGateDatabase.DatabaseName": "",
    "Octopus.Action.RedGateDatabase.ConnectionStrings": "",
    "Octopus.Action.RedGateDatabase.PostDeployValidationEnabled": "",
    "Octopus.Action.RedGateDatabase.SqlCiPath": "",
    "Octopus.Action.RedGateDatabase.SqlComparePath": "",
    "Octopus.Action.RedGateDatabase.CompareOptions": "",
};

type RedgateDatabaseProperties = { [P in keyof typeof StringProperties]: string };

class RedgateDatabaseEdit extends BaseComponent<ActionEditProps<RedgateDatabaseProperties>, never> {
    shouldComponentUpdate(nextProps: ActionEditProps<RedgateDatabaseProperties>) {
        return ValueInPropertiesOrErrorsHasChanged(StringProperties, nextProps, this.props);
    }

    summary() {
        if (this.props.properties["Octopus.Action.RedGateDatabase.Enabled"] !== "False") {
            return Summary.summary("Database package deployment is enabled");
        } else {
            return Summary.placeholder("Database package deployment is not enabled");
        }
    }

    render() {
        const properties = this.props.properties;
        return (
            <ExpandableFormSection
                errorKey="Octopus.Action.RedGateDatabase.SqlCiPath|Octopus.Action.RedGateDatabase.SqlComparePathOctopus.Action.RedGateDatabase.ConnectionStrings"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Red Gate Database"
                summary={this.summary()}
                help="Configure Red Gate database package deployment."
            >
                <Callout type={CalloutType.Warning} title={"Deprecated"}>
                    SQL Release has been replaced by DLM Automation. Don't worry: You can continue to use this feature until you decide to start using DLM Automation. For more information refer to our{" "}
                    <ExternalLink href="RedGateSQLRelease">documentation</ExternalLink>.
                </Callout>
                <BoundStringCheckbox
                    variableLookup={{
                        localNames: this.props.localNames,
                    }}
                    resetValue={"False"}
                    value={properties["Octopus.Action.RedGateDatabase.Enabled"]}
                    onChange={x => this.props.setProperties({ ["Octopus.Action.RedGateDatabase.Enabled"]: x })}
                    label="Enable Red Gate database package deployment"
                />
                <VariableLookupText
                    localNames={this.props.localNames}
                    value={properties["Octopus.Action.RedGateDatabase.DatabaseName"]}
                    onChange={x => this.props.setProperties({ ["Octopus.Action.RedGateDatabase.DatabaseName"]: x })}
                    error={this.props.getFieldError("Octopus.Action.RedGateDatabase.DatabaseName")}
                    label="Database name"
                />
                <Note>The name of the SQL database to deploy.</Note>
                <VariableLookupText
                    localNames={this.props.localNames}
                    value={properties["Octopus.Action.RedGateDatabase.ConnectionStrings"]}
                    onChange={x => this.props.setProperties({ ["Octopus.Action.RedGateDatabase.ConnectionStrings"]: x })}
                    multiLine={true}
                    error={this.props.getFieldError("Octopus.Action.RedGateDatabase.ConnectionStrings")}
                    label="SQL Servers"
                />
                <Note>
                    A list of SQL Server connection strings for the databases to deploy to. Each connection string should be on a new line.
                    <br />
                    You can use variables to define different connection strings for each environment, and then reference them:
                    <pre>{`#{MyConnectionString}`}</pre>
                </Note>
                <BoundStringCheckbox
                    variableLookup={{
                        localNames: this.props.localNames,
                    }}
                    resetValue={"False"}
                    value={properties["Octopus.Action.RedGateDatabase.PostDeployValidationEnabled"]}
                    onChange={x => this.props.setProperties({ ["Octopus.Action.RedGateDatabase.PostDeployValidationEnabled"]: x })}
                    label="Validate that no other changes to the database have been made outside this deployment"
                />
                <Note>Check that the deployment was successful, and that no changes were made outside of Octopus during the deployment. If validation fails, it stops the deployment after the database upgrade.</Note>
                <VariableLookupText
                    localNames={this.props.localNames}
                    value={properties["Octopus.Action.RedGateDatabase.SqlCiPath"]}
                    onChange={x => this.props.setProperties({ ["Octopus.Action.RedGateDatabase.SqlCiPath"]: x })}
                    error={this.props.getFieldError("Octopus.Action.RedGateDatabase.SqlCiPath")}
                    label="SQLCI.exe path"
                />
                <Note>The path to Red Gate's SQLCI.exe.</Note>
                <VariableLookupText
                    localNames={this.props.localNames}
                    value={properties["Octopus.Action.RedGateDatabase.SqlComparePath"]}
                    onChange={x => this.props.setProperties({ ["Octopus.Action.RedGateDatabase.SqlComparePath"]: x })}
                    error={this.props.getFieldError("Octopus.Action.RedGateDatabase.SqlComparePath")}
                    label="SQLCompare.exe path"
                />
                <Note>The path to the Red Gate's SQLCompare.exe.</Note>
                <VariableLookupText
                    localNames={this.props.localNames}
                    value={properties["Octopus.Action.RedGateDatabase.CompareOptions"]}
                    onChange={x => this.props.setProperties({ ["Octopus.Action.RedGateDatabase.CompareOptions"]: x })}
                    error={this.props.getFieldError("Octopus.Action.RedGateDatabase.CompareOptions")}
                    label="Comparison options"
                />
                <Note>The options to pass to the SQL Compare engine. To use multiple options, separate each option with commas or semicolons.</Note>
            </ExpandableFormSection>
        );
    }
}

pluginRegistry.registerFeatureForAllScopes({
    featureName: "Octopus.Features.RedGateDatabase",
    title: "Red Gate Database Deployment",
    description: "Deploy a database using SQLCI.exe from Red Gate",
    edit: RedgateDatabaseEdit,
    priority: 100,
    enable: (properties: ActionProperties) => {
        properties["Octopus.Action.RedGateDatabase.Enabled"] = "True";
        properties["Octopus.Action.RedGateDatabase.CompareOptions"] = "IgnoreUsers,IgnoreCertificatesAndCryptoKeys,ForceColumnOrder,ThrowOnFileParseFailed";
        properties["Octopus.Action.RedGateDatabase.PostDeployValidationEnabled"] = "True";
        properties["Octopus.Action.RedGateDatabase.SqlCiPath"] = "C:\\Program Files (x86)\\Red Gate\\SQL Automation Pack 1\\sqlCI\\sqlCI.exe";
        properties["Octopus.Action.RedGateDatabase.SqlComparePath"] = "C:\\Program Files (x86)\\Red Gate\\SQL Automation Pack 1\\sqlCI\\SC\\SQLCompare.exe";
    },
    disable: (properties: ActionProperties) => {
        Object.keys(properties)
            .filter(name => {
                return name.indexOf("Octopus.Action.RedGateDatabase.") === 0;
            })
            .forEach(name => {
                delete properties[name];
            });
    },
    validate: (properties: ActionProperties, errors: any) => {
        if (!properties["Octopus.Action.RedGateDatabase.SqlCiPath"]) {
            errors["Octopus.Action.RedGateDatabase.SqlCiPath"] = "Please specify a path to SQLCI.exe.";
        }
        if (!properties["Octopus.Action.RedGateDatabase.SqlComparePath"]) {
            errors["Octopus.Action.RedGateDatabase.SqlComparePath"] = "Please specify a path to SQLCompare.exe.";
        }
        if (!properties["Octopus.Action.RedGateDatabase.ConnectionStrings"]) {
            errors["Octopus.Action.RedGateDatabase.ConnectionStrings"] = "Please specify the connection strings to the databases that you wish to deploy.";
        }
    },
});
