// tslint:disable: no-any

import * as React from "react";
import { Switch } from "react-router-dom";
import ReloadableRoute from "components/ReloadableRoute";
import { withPage } from "components/Page/Page";
import AllVariables from "./AllVariables";
import ProjectVariableTemplates from "./ProjectVariableTemplates";
import CommonVariableTemplates from "./CommonVariableTemplates";
import LibraryVariableSets from "./LibraryVariableSets";
import ProjectVariables from "./ProjectVariables";
import VariablePreview from "areas/projects/components/Variables/VariablePreview";
import pageIds from "pageIds";

export const AllVariablesPage = withPage({ page: pageIds.project().variables.all })(AllVariables);
export const ProjectVariableTemplatesPage = withPage({ page: pageIds.project().variables.projectTemplates })(ProjectVariableTemplates);
export const CommonVariableTemplatesPage = withPage({ page: pageIds.project().variables.commonTemplates })(CommonVariableTemplates);
export const LibraryVariableSetsPage = withPage({ page: pageIds.project().variables.library })(LibraryVariableSets);
export const VariablePreviewPage = withPage({ page: pageIds.project().variables.preview })(VariablePreview);
export const ProjectVariablesPage = withPage({ page: pageIds.project().variables.root })(ProjectVariables);

export default class VariablesRoute extends React.Component<any, any> {
    render() {
        const match = this.props.match;
        return (
            <Switch>
                <ReloadableRoute path={`${match.path}/all`} component={AllVariablesPage} />
                <ReloadableRoute path={`${match.path}/projectvariabletemplates`} component={ProjectVariableTemplatesPage} />
                <ReloadableRoute path={`${match.path}/commonvariabletemplates`} component={CommonVariableTemplatesPage} />
                <ReloadableRoute path={`${match.path}/library`} component={LibraryVariableSetsPage} />
                <ReloadableRoute path={`${match.path}/preview`} component={VariablePreviewPage} />
                <ReloadableRoute path={`${match.path}`} component={ProjectVariablesPage} />
            </Switch>
        );
    }
}
