import * as React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "theme";
import { useCallback, useMemo } from "react";
import { useTabs, RenderTabCallback } from "./useTabs";
import { useOctopusTheme } from "components/Theme";

export interface ControlledTabComponentProps {
    value: string;
    afterTabsElement?: JSX.Element;
    onChange: (value: string) => void;
    renderTab?: RenderTabCallback;
}

const ControlledTabsContainer: React.FC<ControlledTabComponentProps> = props => {
    const octoTheme = useOctopusTheme();

    const useStyles = makeStyles<Theme, ControlledTabComponentProps>(theme => ({
        root: {
            color: theme.palette.text.primary,
        },
        tabsContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        indicator: {
            backgroundColor: octoTheme.tabActiveIndicator,
        },
    }));

    const classes = useStyles(props);

    const rootTabStyle: React.CSSProperties = {
        minWidth: "7.23rem", // Needed for DrawerWrapperLayout.
    };

    const renderTab = useMemo(() => {
        const defaultTabRender: RenderTabCallback = renderProps => <Tab style={rootTabStyle} {...renderProps} key={renderProps.value} />;
        return props.renderTab || defaultTabRender;
    }, [props.renderTab, octoTheme]);

    const { tabs, content, value } = useTabs(props.children, props.value, renderTab);

    const handleTabChange = useCallback(
        (event: React.ChangeEvent<{}>, tabValue: string) => {
            if (props.onChange) {
                props.onChange(tabValue);
            }
        },
        [props.onChange]
    );

    return (
        <React.Fragment>
            <div className={classes.tabsContainer}>
                <Tabs classes={classes} value={value} onChange={handleTabChange} variant="scrollable" scrollButtons="off">
                    {tabs}
                </Tabs>
                {props.afterTabsElement}
            </div>
            {content}
        </React.Fragment>
    );
};

export default ControlledTabsContainer;
