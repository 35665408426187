import React from "react";
import { LargeWidthSnackbar } from "./internal";
import { useWebpackHotModuleProgress } from "./useWebpackHotModuleProgress";

export const DevTools: React.FC = () => {
    const progress = useWebpackHotModuleProgress();

    const text = "[WDS] ".concat(progress.percent.toString(), "% - ").concat(progress.message, ".");
    return (
        <React.Fragment>
            <LargeWidthSnackbar open={progress.percent > 0 && progress.percent < 100} message={text} />
        </React.Fragment>
    );
};

export default DevTools;
