// tslint:disable: no-any

import { useState, useEffect, useMemo } from "react";
import { getQueryFromWindow as getQuery, getUpdatedWindowQuery as getUpdatedQuery, pushHistory, replaceHistory } from "utils/UrlHelper";
import { identity } from "lodash";
import { noOp } from "utils/noOp";

export enum QueryStateMode {
    PushHistory = "PushHistory",
    ReplaceHistory = "ReplaceHistory",
}

const getHistoryUpdateCallback = (mode: QueryStateMode) => {
    switch (mode) {
        case QueryStateMode.PushHistory:
            return pushHistory;
        case QueryStateMode.ReplaceHistory:
            return replaceHistory;
    }

    return pushHistory;
};

export interface QueryParameterProps {
    name: string;
    defaultValue: string;
    render: (renderProps: QueryParameterRenderProps) => React.ReactElement | null;
    onChange?: (value: string) => void;
    mode?: QueryStateMode;
}

export interface QueryParameterRenderProps {
    value: string;
    onChange: (value: string) => void;
}

export const useUrlQueryEffect = (name: string, defaultValue: string, setValue: (value: string) => void, dependencies: ReadonlyArray<any>) => {
    useEffect(() => {
        const valueFromUrl = getQuery(name, identity) || defaultValue;
        if (setValue) {
            setValue(valueFromUrl);
        }
    }, dependencies);
};

const getQueryStateUpdater = (updateHistory: (value: string) => void, query: () => string, ...callbacks: Array<((value: string) => void) | null | undefined>) => {
    return (updatedValue: string) => {
        const current = query();

        if (current !== updatedValue) {
            updateHistory(updatedValue);
        }

        callbacks.forEach(x => (x ? x(updatedValue) : noOp()));
    };
};

export const useUrlQueryState = (name: string, defaultValue: string, mode: QueryStateMode = QueryStateMode.PushHistory, setValue?: (value: string) => void) => {
    const actions = useMemo(
        () => ({
            query: () => getQuery<string>(name, identity),
            updatedQuery: (x: string) => getUpdatedQuery(name, x),
            updateHistory: (val: string) => updateHistory(() => getUpdatedQuery(name, val)),
            replaceHistory: (val: string) => replaceHistory(() => getUpdatedQuery(name, val)),
        }),
        [name]
    );

    const updateHistory = useMemo(() => getHistoryUpdateCallback(mode), [mode]);
    const queryValue = actions.query();
    const [value, setState] = useState<string>(queryValue || defaultValue);

    const updateLocation = getQueryStateUpdater(actions.updateHistory, actions.query, setState, setValue);
    const initLocation = getQueryStateUpdater(actions.replaceHistory, actions.query, setState, setValue);

    useUrlQueryEffect(name, defaultValue, initLocation, [value, queryValue]);

    return { updateLocation, value };
};

export const UrlQueryState: React.FC<QueryParameterProps> = ({ name, render, defaultValue, onChange, mode = QueryStateMode.PushHistory }) => {
    const { updateLocation, value } = useUrlQueryState(name, defaultValue, mode, onChange);
    return render({ value, onChange: updateLocation });
};

export default UrlQueryState;
