// tslint:disable: no-any

import BasicRepository from "./basicRepository";
import Client from "../client";

class ProxyRepository extends BasicRepository<any, any> {
    constructor(client: Client) {
        super("Proxies", client);
    }
}

export default ProxyRepository;
