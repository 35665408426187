// tslint:disable: no-non-null-assertion
import { UserResource } from "client/resources";

export default class SystemUserFactory {
    public static systemUser(): UserResource {
        const systemUser: UserResource = {
            Id: "users-system",
            DisplayName: "System",
            Username: "system",
            IsActive: true,
            IsService: false,
            Links: null!,
            Identities: [],
        };
        return systemUser;
    }
}
