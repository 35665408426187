import * as React from "react";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import Dialog from "components/Dialog/Dialog";
import moment = require("moment");
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import { DatePicker } from "components/form";
const DateTimeFormat = global.Intl.DateTimeFormat;

export interface DateRange {
    startDate: moment.Moment;
    endDate: moment.Moment;
}

interface DatePickerDialogProps {
    dialogOpen: boolean;
    startDate: moment.Moment;
    endDate: moment.Moment;
    onSaveClick(range: { startDate: moment.Moment; endDate: moment.Moment }): Promise<void>;
    onCancelClick?(): void;
}

interface DatePickerDialogState extends DataBaseComponentState {
    dialogOpen: boolean;
    startDate: moment.Moment;
    endDate: moment.Moment;
}

export default class DatePickerDialog extends DataBaseComponent<DatePickerDialogProps, DatePickerDialogState> {
    constructor(props: DatePickerDialogProps) {
        super(props);

        this.state = {
            dialogOpen: this.props.dialogOpen,
            startDate: this.props.startDate.clone(),
            endDate: this.props.endDate.clone(),
        };
    }

    dateFormatter = () => {
        return new DateTimeFormat("en-US", {
            day: "numeric",
            month: "long",
            year: "numeric",
        }).format;
    };

    closeDialog = () => {
        this.setState({ dialogOpen: false });
    };

    executeCallback = async () => {
        const startDate = this.state.startDate;
        const endDate = this.state.endDate;

        if (this.props.onSaveClick) {
            if (startDate <= endDate) {
                await this.props.onSaveClick({
                    startDate: moment(startDate),
                    endDate: moment(endDate),
                });
                this.closeDialog();
                return true;
            } else {
                this.setState({ errors: { message: '"From Date" must start before "To Date".', details: [], fieldErrors: {} } });
                return false;
            }
        }

        return false;
    };

    notifyCancel = () => {
        if (this.props.onCancelClick) {
            this.props.onCancelClick();
        }
    };

    render() {
        return (
            <Dialog open={this.state.dialogOpen} fullScreen={false}>
                <SaveDialogLayout title="Date Range" busy={this.state.busy} errors={this.state.errors} onSaveClick={this.executeCallback} onCancelClick={this.notifyCancel}>
                    <DatePicker
                        label="From Date"
                        value={this.state.startDate.toDate()}
                        onChange={value => {
                            this.setState({ startDate: moment(value) });
                        }}
                        formatDate={this.dateFormatter()}
                    />
                    <DatePicker
                        label="To Date"
                        value={this.state.endDate.toDate()}
                        onChange={value => {
                            this.setState({ endDate: moment(value) });
                        }}
                        formatDate={this.dateFormatter()}
                    />
                </SaveDialogLayout>
            </Dialog>
        );
    }
}
