import * as React from "react";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import { LocationDescriptor } from "history";
import URI from "urijs";
import { MouseEvent } from "react";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import cn from "classnames";
import { withTheme } from "components/Theme";
const styles = require("./style.less");

export interface NavigationButtonProps {
    label: string;
    titleAltText?: string;
    href: NavigationPath | string;
    icon?: JSX.Element;
    external?: boolean;
    disabled?: boolean;
    type?: NavigationButtonType;
    hoverColor?: string;
}

interface NavigationPath {
    path: string;
    search: { [name: string]: string };
}

interface NavigationButtonState {
    navigate: boolean;
}

export enum NavigationButtonType {
    Primary,
    Secondary,
    Ternary,
    CreateRelease, // Edge case unique to our design.
}

export class NavigationButton extends React.Component<NavigationButtonProps, NavigationButtonState> {
    getPath(): LocationDescriptor {
        if (typeof this.props.href === "string") {
            return this.props.href;
        }

        const query = URI.buildQuery(this.props.href.search);
        return {
            pathname: this.props.href.path,
            search: `?${query}`,
        };
    }

    handleOnClick = (e: MouseEvent<{}>): void => {
        e.preventDefault();
        this.setState({ navigate: true });
    };

    render() {
        return withTheme(theme => {
            if (this.state && this.state.navigate) {
                if (this.props.external) {
                    this.setState({ navigate: false });
                    window.open(this.props.href as string);
                } else {
                    return <InternalRedirect to={this.getPath()} push={true} />;
                }
            }

            switch (this.props.type) {
                case NavigationButtonType.Primary:
                case NavigationButtonType.CreateRelease:
                    const labelStylePrimary = {
                        fontSize: "0.8125rem",
                        verticalAlign: "middle",
                    };
                    return (
                        <RaisedButton
                            onClick={this.handleOnClick}
                            label={this.props.label}
                            title={this.props.titleAltText !== null ? this.props.titleAltText : this.props.label}
                            icon={this.props.icon}
                            disabled={this.props.disabled}
                            backgroundColor={this.props.type === NavigationButtonType.Primary ? theme.transparent : theme.createReleaseBackground} // Primary's are the one thing that should stand out on the page.
                            labelColor={theme.primaryButtonText}
                            labelStyle={labelStylePrimary}
                            disabledBackgroundColor={theme.transparent}
                            disabledLabelColor={theme.disabledButtonText}
                            className={this.props.disabled ? styles.disabled : cn(styles.globalActionButton, styles.primaryButton)}
                        />
                    );

                case NavigationButtonType.Ternary:
                    const ternaryLabelStyle = {
                        opacity: 1,
                        fontSize: "0.8125rem",
                        verticalAlign: "middle",
                    };
                    return (
                        <FlatButton
                            onClick={this.handleOnClick}
                            label={this.props.label}
                            title={this.props.titleAltText !== null ? this.props.titleAltText : this.props.label}
                            icon={this.props.icon}
                            disabled={this.props.disabled}
                            backgroundColor={theme.transparent}
                            labelStyle={ternaryLabelStyle}
                            className={cn(styles.ternaryButton, this.props.disabled ? styles.ternaryDisabled : null)}
                            style={{ boxShadow: "none" }}
                        />
                    );

                default:
                    const labelStyle = {
                        color: this.props.disabled ? theme.disabledButtonText : theme.secondaryButtonText,
                        opacity: 1,
                        fontSize: "0.8125rem",
                        verticalAlign: "middle",
                    };
                    return (
                        <RaisedButton
                            onClick={this.handleOnClick}
                            label={this.props.label}
                            title={this.props.titleAltText !== null ? this.props.titleAltText : this.props.label}
                            icon={this.props.icon}
                            backgroundColor={theme.transparent}
                            labelStyle={labelStyle}
                            disabled={this.props.disabled}
                            disabledBackgroundColor={theme.transparent}
                            className={cn(styles.globalActionButton, this.props.disabled ? styles.disabled : styles.secondaryButton)}
                        />
                    );
            }
        });
    }
}

export default NavigationButton;
