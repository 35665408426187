// tslint:disable: no-non-null-assertion
// tslint:disable: no-any

import * as React from "react";
import { History } from "history";
type LocationDescriptor = History.LocationDescriptor;
import Breadcrumb from "components/Breadcrumb";
import PageTitleHelper from "utils/PageTitleHelper";
import InternalNavLink from "../Navigation/InternalNavLink/InternalNavLink";
import { toggleDrawer, isDrawerOpen } from "components/Drawer/reducers";
const styles = require("./style.less");
import MediaQuery from "react-responsive";
import IconButton, { Icon } from "components/IconButton/IconButton";
import { ComponentProps } from "utils/types";

import { connect } from "react-redux";
import { drawerBreakpointWidth } from "components/Drawer/DrawerWrapperLayout";
import { helpSidebarActionClass } from "uiTestClasses";
import configurationSelectors from "areas/configuration/reducers/selectors";

interface GlobalConnectedProps {
    isDrawerOpen?: boolean;
    isHelpSidebarEnabled?: boolean;
}

interface GlobalDispatchProps {
    toggleDrawer?(): void;
}

interface AreaTitlePropsInternal {
    title?: string;
    link?: string;
    breadcrumbTitle?: string; // If specified, this text will display above the title.
    breadcrumbPath?: LocationDescriptor; // If specified, this will link your breadcrumb title.
    busyIndicator?: JSX.Element;
}

type AreaTitleProps = AreaTitlePropsInternal & GlobalConnectedProps & GlobalDispatchProps;

class AreaTitleInternal extends React.Component<AreaTitleProps, never> {
    constructor(props: AreaTitleProps) {
        super(props);
        PageTitleHelper.setPageTitle(props.title, props.breadcrumbTitle);
    }

    renderActions() {
        if (!this.props.isHelpSidebarEnabled) {
            return null;
        }

        return (
            <React.Fragment>
                <MediaQuery minWidth={drawerBreakpointWidth}>
                    <div className={styles.helpAction}>
                        <IconButton toolTipContent={this.props.isDrawerOpen ? "Hide help" : "Show help"} onClick={this.props.toggleDrawer} icon={this.props.isDrawerOpen ? Icon.CloseHelp : Icon.OpenHelp} className={helpSidebarActionClass} />
                    </div>
                </MediaQuery>
            </React.Fragment>
        );
    }

    render() {
        return (
            <div className={styles.titleBar}>
                <div className={styles.rowSpaced}>
                    <div className={styles.title}>
                        <Breadcrumb title={this.props.breadcrumbTitle} path={this.props.breadcrumbPath} isAreaLevelBreadcrumb={true} />
                        <h1>
                            <InternalNavLink className={styles.pageTitleLink} to={this.props.link!}>
                                {this.props.title}
                            </InternalNavLink>
                        </h1>
                    </div>
                    <div className={styles.actions}>
                        {this.props.children}
                        {this.renderActions()}
                    </div>
                </div>
                {this.props.busyIndicator}
            </div>
        );
    }
}

const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    return {
        isDrawerOpen: isDrawerOpen(state),
        isHelpSidebarEnabled: configurationSelectors.createFeatureEnabledSelector(t => t.isHelpSidebarEnabled)(state),
    };
};

const mapGlobalActionDispatchersToProps = (dispatch: any): GlobalDispatchProps => {
    return {
        toggleDrawer: () => dispatch(toggleDrawer()),
    };
};

const AreaTitle = connect(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(AreaTitleInternal);

export default AreaTitle as React.ComponentType<ComponentProps<typeof AreaTitle>>;
