// tslint:disable: no-non-null-assertion
import * as React from "react";
import ActionTemplateSearchResource from "client/resources/actionTemplateSearchResource";
import ActionTemplateCard from "components/ActionTemplates/ActionTemplateCard";
import { NavigationButton, NavigationButtonType } from "components/Button/NavigationButton";
import matchesFilter from "components/ActionTemplates/matchesFilter";
import PageDivider from "components/PageDivider/PageDivider";
import ActionTemplateCardList from "components/ActionTemplates/ActionTemplateCardList";

interface BuiltinActionTemplateListProps {
    templates: ActionTemplateSearchResource[];
    filter?: string;
    onPostSelectionUrlRequested?: (template: { Type: string; Id: string } | string) => string;
}

class BuiltinActionTemplateList extends React.Component<BuiltinActionTemplateListProps, never> {
    render() {
        const filteredResults = this.props.templates.filter(at => matchesFilter(at, this.props.filter!));

        if (filteredResults.length === 0 && this.props.filter) {
            return <p>There are no built-in step templates that match your filter criteria.</p>;
        }

        return (
            <div>
                <PageDivider>Built-in Step Templates</PageDivider>
                <ActionTemplateCardList>
                    {filteredResults.map(template => {
                        return (
                            <ActionTemplateCard
                                key={template.Type + template.Id}
                                template={template}
                                primaryAction={<NavigationButton label="Add" type={NavigationButtonType.Primary} href={this.props.onPostSelectionUrlRequested!(template)} />}
                                primaryRedirect={this.props.onPostSelectionUrlRequested!(template)}
                            />
                        );
                    })}
                </ActionTemplateCardList>
            </div>
        );
    }
}

export default BuiltinActionTemplateList;
