import * as React from "react";
import { CategorizedEndpointRegistration, CommunicationStyle, EndpointRegistrationKey } from "./endpointRegistry";
import AzureCategory from "./AzureCategoryDefinition";
import WindowsCategory from "./WindowsCategoryDefinition";
import NavigationButton, { NavigationButtonType } from "components/Button/NavigationButton";
import routeLinks from "routeLinks";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import { InfoRegistrationCards } from "./RegistrationCards";
import { AzureVm } from "components/Images/Images/MachineSettings/AzureVm";
const styles = require("./styles.less");

const AzureVmEndpointDialogView: React.SFC<{}> = () => (
    <div>
        <h3>Installation Instructions</h3>
        <p>The Octopus Deploy VM Extension can automatically download the latest Tentacle MSI, install it and register the agent with your Octopus Server.</p>
        <p>
            <ExternalLink href="AzureVmExtensionPortal">Installing via the Azure Portal</ExternalLink>
            <br />
            <ExternalLink href="AzureVmExtensionClassicPortal">Installing via the Classic Azure Portal</ExternalLink>
            <br />
            <ExternalLink href="AzureVmExtensionAzureCli">Installing via the Azure CLI</ExternalLink>
            <br />
            <ExternalLink href="AzureVmExtensionArm">Installing via an ARM template</ExternalLink>
            <br />
            <ExternalLink href="AzureVmExtensionPowershell">Installing via Powershell</ExternalLink>
            <br />
            <ExternalLink href="AzureVmExtensionArmDsc">Installing via DSC in an ARM Template</ExternalLink>
            <br />
        </p>
        <h3>Further Resources</h3>
        <p>
            <ExternalLink href="AzureVmExtensionConfigStructure">Tentacle VM Extension Configuration Structure</ExternalLink>
            <br />
            <ExternalLink href="AzureVmExtensionDiagnosingIssues">Diagnosing Tentacle VM Extension Issues</ExternalLink>
            <br />
        </p>
        <h3 style={{ marginTop: "2rem" }}>After Installation</h3>
        <p>The new Azure VM will automatically show up in Deployment Targets list.</p>
    </div>
);

const AzureVmAdditionalActions: React.SFC<{}> = () => (
    <NavigationButton
        key="deployment-targets"
        type={NavigationButtonType.Secondary}
        label="View Deployment Targets"
        href={routeLinks.infrastructure.machines.filtered({ commStyles: [CommunicationStyle.TentacleActive, CommunicationStyle.TentaclePassive] })}
    />
);

export const azureVmRegistration: CategorizedEndpointRegistration = {
    key: EndpointRegistrationKey.AzureVmExtension,
    displayOrder: 100,
    categories: [AzureCategory, WindowsCategory],
    name: "Azure VM",
    renderCard: InfoRegistrationCards.basic(
        () => <AzureVmEndpointDialogView />,
        ({ registration }) => ({
            logo: <AzureVm className={styles.centreThumbnail} height="4.5rem" title={registration.name} />,
            header: registration.name,
            description: "Easily connect an Azure VM to the Octopus Server with a VM Extension.",
        }),
        () => <AzureVmAdditionalActions />
    ),
};
