// tslint:disable: no-any

import * as React from "react";
import { Switch } from "react-router-dom";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import { RunbookTriggers } from "./Triggers";
import EditScheduledTrigger, { EditScheduledTriggerRouteProps } from "../../Triggers/Scheduled/Edit";
import pageIds from "pageIds";
import { withPage } from "components/Page/Page";
import { TriggerActionCategory } from "client/resources/triggerResource";

export const ScheduledTriggerPage = withPage({ page: pageIds.project().runbook.scheduledTrigger })(EditScheduledTrigger);
export const CreateScheduledTriggerPage = withPage({ page: pageIds.project().runbook.scheduledTriggerNew })(EditScheduledTrigger);
export const TriggersPage = withPage({ page: pageIds.project().runbook.triggers })(RunbookTriggers);

export class TriggersRoute extends React.Component<any, any> {
    render() {
        const match = this.props.match;
        return (
            <Switch>
                <ReloadableRoute
                    path={`${match.path}/scheduled/edit/:triggerId`}
                    render={(routeProps: EditScheduledTriggerRouteProps) => <ScheduledTriggerPage create={false} triggerActionCategory={TriggerActionCategory.Runbook} {...routeProps} />}
                />
                <ReloadableRoute
                    path={`${match.path}/scheduled/create/runbooks/:runbookId`}
                    render={(routeProps: EditScheduledTriggerRouteProps) => <CreateScheduledTriggerPage create={true} triggerActionCategory={TriggerActionCategory.Runbook} {...routeProps} />}
                />
                <ReloadableRoute
                    path={`${match.path}/scheduled/create/runbooks`}
                    render={(routeProps: EditScheduledTriggerRouteProps) => <CreateScheduledTriggerPage create={true} triggerActionCategory={TriggerActionCategory.Runbook} {...routeProps} />}
                />
                <ReloadableRoute path={`${match.path}`} component={TriggersPage} />
            </Switch>
        );
    }
}
