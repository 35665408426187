// tslint:disable: no-non-null-assertion
// tslint:disable: no-any

import * as React from "react";
import * as History from "history";
import PaperLayout, { PaperLayoutProps } from "../PaperLayout/PaperLayout";
import Snackbar from "material-ui/Snackbar";
import ExpansionButtons, { toggleExpandos } from "../form/Sections/ExpansionButtons";
import { Prompt, withRouter, RouteComponentProps } from "react-router";
import { isEqual } from "lodash";
import ActionList from "../ActionList";
import { isAllowed, PermissionCheckProps } from "../PermissionCheck/PermissionCheck";
import { default as OverflowMenu, MenuItem } from "components/Menu/OverflowMenu";
const styles = require("./style.less");
import { Errors } from "components/DataBaseComponent/Errors";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import FormComponent from "../FormComponent/FormComponent";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import store from "../../store";
import { createFormPaperLayoutMountedAction } from "./reducers";

interface FormProps {
    model: object | undefined;
    cleanModel?: object;
    expandAllOnMount?: boolean;
    hideExpandAll?: boolean;
    errors?: Errors;
    saveText?: string | void;
    savePermission?: PermissionCheckProps | undefined;
    saveButtonLabel?: string;
    saveButtonBusyLabel?: string;
    overFlowActions?: Array<MenuItem | MenuItem[]>;
    secondaryAction?: React.ReactNode;
    hideSectionControls?: boolean;
    disableDirtyFormChecking?: boolean;
    disableKeyboardFormSubmission?: boolean;
    forceDisableFormSaveButton?: boolean;
    isNewRecord?: boolean;
    primaryNavigationButton?: JSX.Element; // This allows you to override the primary save button that you get by default.
    onSaveClick(): Promise<any> | void;
}

interface FormPaperLayoutState {
    dirty: boolean;
    showSnackbar: boolean;
    redirect: string | null;
}

export type FormPaperLayoutProps = FormProps & PaperLayoutProps;
type RouteProps = RouteComponentProps<any>;

type InternalPaperLayoutProps = FormPaperLayoutProps & RouteProps;

class FormPaperLayout extends BaseComponent<InternalPaperLayoutProps, FormPaperLayoutState> {
    public static defaultProps: Partial<InternalPaperLayoutProps> = {
        expandAllOnMount: false,
        saveText: "Details updated",
    };

    constructor(props: InternalPaperLayoutProps) {
        super(props);
        this.state = {
            dirty: false,
            showSnackbar: false,
            redirect: null,
        };
    }

    handleSnackbarClose = () => {
        this.setState({
            showSnackbar: false,
        });
    };

    handleMenuClick = (link: any) => {
        if (link.onClick) {
            link.onClick();
        } else {
            this.setState({ redirect: link.url });
        }
    };

    componentWillReceiveProps(nextProps: FormProps) {
        if (!nextProps.disableDirtyFormChecking) {
            const dirty = !isEqual(nextProps.model, nextProps.cleanModel);
            this.setState({ dirty });
        }
    }

    componentDidMount() {
        store.dispatch(createFormPaperLayoutMountedAction(this.save));
    }

    componentWillUnmount() {
        store.dispatch(createFormPaperLayoutMountedAction(null!));
    }

    render() {
        const { children, model, errors, ...rest } = this.props;

        const overFlowMenu = this.props.overFlowActions && this.props.overFlowActions.length > 0 && <OverflowMenu menuItems={this.props.overFlowActions} />;

        const disabledDueToPermission = this.isDisableDueToPermission();
        const permissionLabel = this.getPermissionLabel();
        const label = disabledDueToPermission ? `${permissionLabel} permission required` : this.props.saveButtonLabel || "Save";

        const saveAction = <ActionButton type={ActionButtonType.Save} onClick={this.handleSave} label={label} busyLabel={this.props.saveButtonBusyLabel || "Saving"} disabled={disabledDueToPermission || this.shouldBeDisabled()} />;

        const primaryAction = this.props.primaryNavigationButton ? this.props.primaryNavigationButton : saveAction;

        if (this.state.redirect) {
            return <InternalRedirect to={this.state.redirect} push={true} />;
        }

        const sectionControl = this.props.hideSectionControls ? null : <ActionList actions={[this.props.secondaryAction, primaryAction, overFlowMenu].filter(action => !!action)} />;

        return (
            <FormComponent onFormSubmit={this.onCtrlEnterPressed}>
                <PaperLayout {...rest} sectionControl={sectionControl} errors={this.props.errors}>
                    <Prompt when={this.state.dirty && !this.props.disableDirtyFormChecking} message={location => this.getPromptMessage(location)} />
                    {!this.props.hideExpandAll && <ExpansionButtons errors={errors} expandAllOnMount={this.props.expandAllOnMount} />}
                    <div className={styles.formContainer}>{children}</div>
                    {this.props.saveText && <Snackbar open={this.state.showSnackbar} message={this.props.saveText} autoHideDuration={3500} onRequestClose={this.handleSnackbarClose} className={styles.snackContainer} />}
                </PaperLayout>
            </FormComponent>
        );
    }

    private isDisableDueToPermission = () => {
        return !!this.props.savePermission ? !isAllowed(this.props.savePermission) : false;
    };

    private getPermissionLabel(): string {
        if (this.props.savePermission === undefined) {
            return "No";
        }

        if (Array.isArray(this.props.savePermission.permission)) {
            return this.props.savePermission.permission.join(", ");
        }

        return this.props.savePermission.permission;
    }

    private shouldBeDisabled = () => {
        return ((!this.state.dirty || this.props.busy) && !this.props.disableDirtyFormChecking && !this.props.isNewRecord) || this.props.forceDisableFormSaveButton;
    };

    private onCtrlEnterPressed = async () => {
        const disabledDueToPermission = this.isDisableDueToPermission();
        const isSaveDisable = disabledDueToPermission || this.shouldBeDisabled();
        if (!isSaveDisable && !this.props.disableKeyboardFormSubmission) {
            await this.save();
        }
    };

    private handleSave = async (e: any) => {
        e.preventDefault();
        await this.save();
    };

    private save = async (): Promise<boolean> => {
        await this.props.onSaveClick();
        if (!this.props.errors) {
            if (this.props.saveText) {
                this.setState({ showSnackbar: true });
            }
            toggleExpandos(false);
        }
        return !this.props.errors;
    };

    private getPromptMessage(location: History.Location) {
        // If the pathname hasn't changed, return true which will allow the transition.
        // This is so we can ignore filter changes which only modify the query string.
        if (location.pathname === this.props.location.pathname) {
            return true;
        }
        return "If you leave this page, any changes you have made will be lost. Are you sure you wish to leave this page?";
    }
}

export default withRouter(FormPaperLayout);
