// tslint:disable: no-non-null-assertion
import * as React from "react";
import { repository } from "clientInstance";
import { RouteComponentProps } from "react-router";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { ResourceCollection, EventResource, DeploymentTargetResource, EventCategoryResource } from "client/resources";
import EventListing from "components/EventListing/EventListing";

type MachineEventsProps = RouteComponentProps<MachineEventsParams>;

interface MachineEventsParams {
    machineId: string;
}

interface MachineEventsState extends DataBaseComponentState {
    machineId: string;
    eventsResponse: ResourceCollection<EventResource>;
    eventCategories: EventCategoryResource[];
}

export default class MachineEventsLayout extends DataBaseComponent<MachineEventsProps, MachineEventsState> {
    constructor(props: MachineEventsProps) {
        super(props);
        this.state = {
            eventsResponse: null!,
            eventCategories: null!,
            machineId: this.props.match && this.props.match.params ? this.props.match.params.machineId : null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const eventsResponsePromise = repository.Events.list({ regarding: [this.state.machineId] });
            const eventCategoriesPromise = repository.Events.categories({});
            this.setState({
                eventsResponse: await eventsResponsePromise,
                eventCategories: await eventCategoriesPromise,
            });
        });
    }

    render() {
        return (
            <PaperLayout title="Events" busy={this.state.busy} errors={this.state.errors}>
                {this.state.eventsResponse && <EventListing data={this.state.eventsResponse} eventCategories={this.state.eventCategories} regarding={[this.state.machineId]} />}
            </PaperLayout>
        );
    }
}
