// tslint:disable: no-any

import Client from "../client";

class BackupRepository {
    private client: Client;
    constructor(client: Client) {
        this.client = client;
    }
    getConfiguration() {
        return this.client.get(this.client.getLink("BackupConfiguration"));
    }
    updateConfiguration(configuration: any) {
        return this.client.update(configuration.Links["Self"], configuration);
    }
}

export default BackupRepository;
