// tslint:disable: no-any

import BasicRepository from "./basicRepository";
import Client from "../client";
import { CertificateResource } from "../resources/certificateResource";
import { repository } from "clientInstance";

type CertificateRepositoryListArgs = {
    archived?: boolean;
    skip?: number;
    take?: number;
    search?: string;
    tenant?: string;
    firstResult?: string;
    orderBy?: string;
};

class CertificateRepository extends BasicRepository<CertificateResource, CertificateResource, CertificateRepositoryListArgs> {
    constructor(client: Client) {
        super("Certificates", client);
    }

    names(projectId: string, projectEnvironmentsFilter: any) {
        return this.client.get(this.client.getLink("VariableNames"), {
            project: projectId,
            projectEnvironmentsFilter: projectEnvironmentsFilter ? projectEnvironmentsFilter.join(",") : projectEnvironmentsFilter,
        });
    }

    async listForTenant(tenantId: string) {
        // We need all the certs for the drop-down, but we need them filtered by tenant
        // certificates/all is cached, and so does not support filtering by tenant.
        const certificates = (await this.list({ tenant: tenantId, take: repository.takeAll })).Items;
        return certificates;
    }
}

export default CertificateRepository;
