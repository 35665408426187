// tslint:disable: no-non-null-assertion
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { SpaceRouteParams } from "../Navbar/SpacesMenu";
import { repository, session } from "../../clientInstance";
import { SpaceResource } from "../../client/resources";
import store from "store";
import { configurationActions } from "areas/configuration/reducers/configurationArea";
import routeLinks from "routeLinks";
import InternalRedirect from "../Navigation/InternalRedirect";
import { connect, MapStateToProps } from "react-redux";
import { Action, Dispatch } from "redux";
import useLocalStorage from "hooks/useLocalStorage";
import callAll from "utils/callAll";
import Logger from "client/logger";
import BusyIndicator from "components/BusyIndicator";

export interface SpaceNotFoundContext {
    // If you have access to zero space, they we don't need to prompt users to enter a space context,
    // and they can continue to navigate around because they are already in the "system" context
    isAlsoInSystemContext: boolean;
    missingSpaceId: string; // This id of the space which could not be found
}

type SystemContext = "system";

export type SpaceContext = SpaceResource | SystemContext | SpaceNotFoundContext;

export function isSpecificSpaceContext(spaceContext: SpaceContext): spaceContext is SpaceResource {
    return !!(spaceContext as SpaceResource).Id;
}

export function isSpaceNotFound(spaceContext: SpaceContext): spaceContext is SpaceNotFoundContext {
    return spaceContext !== "system" && !isSpecificSpaceContext(spaceContext);
}

interface SpaceLoaderState {
    redirectTo: string | null;
    currentSpaceContext?: SpaceContext;
}

interface GlobalDispatchProps {
    onUserAccessibleSpacesLoaded(spaces: SpaceResource[]): void;
}

interface GlobalConnectedProps {
    spaces: SpaceResource[] | null; // null indicates that the spaces haven't yet been loaded
}

interface ProvidedProps {
    lastKnownSpaceId?: string;
    onChangeSpace?: (id: string) => void;
    render(spaceContext: SpaceContext): React.ReactNode;
}

type PropsExceptReduxProps = ProvidedProps & RouteComponentProps<SpaceRouteParams>;

type SpaceLoaderProps = PropsExceptReduxProps & GlobalDispatchProps & GlobalConnectedProps;

class SpaceLoader extends React.Component<SpaceLoaderProps, SpaceLoaderState> {
    constructor(props: SpaceLoaderProps) {
        super(props);
        this.state = {
            redirectTo: null,
        };
    }

    async componentWillReceiveProps(nextProps: Readonly<SpaceLoaderProps>) {
        const nextId = nextProps.match.params.spaceId || (nextProps.match.isExact && nextProps.match.path === routeLinks.root && nextProps.lastKnownSpaceId);
        const foundSpace = nextProps.spaces && nextProps.spaces.find(x => x.Id === nextId);

        if (nextProps.spaces && nextProps.spaces !== this.props.spaces) {
            await this.switchToSpace(nextProps.spaces, foundSpace ? foundSpace.Id : nextProps.match.params.spaceId, nextProps.location.pathname);
        }
    }

    async componentDidMount() {
        repository.Spaces.subscribeToDataModifications(this.constructor.name, this.loadSpaces);
        return this.loadSpaces();
    }

    componentWillUnmount() {
        repository.Spaces.unsubscribeFromDataModifications(this.constructor.name);
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} />;
        }

        return this.state.currentSpaceContext ? this.props.render(this.state.currentSpaceContext) : <BusyIndicator show={true} />;
    }

    private loadSpaces = async () => {
        try {
            const spaces = await repository.Users.getSpaces(session.currentUser!);
            // This updates redux, which makes this component update as part of componentWillReceiveProps
            this.props.onUserAccessibleSpacesLoaded(spaces);
        } catch (e) {
            Logger.error(e);
        }
    };

    private async switchToSpace(spaces: SpaceResource[], spaceId: string, pathname: string) {
        try {
            if (spaceId) {
                await this.stepIntoSelectedSpaceId(spaces, spaceId);
            } else {
                const automaticallySelectedSpace = spaces.find(s => s.IsDefault) || spaces.find(() => true); // default to the first space returned from the API
                if (automaticallySelectedSpace) {
                    this.redirectToSpace(automaticallySelectedSpace, pathname);
                } else if (this.state.currentSpaceContext !== "system") {
                    await this.stepIntoNoSpaceSelectedContext();
                    this.setState({ currentSpaceContext: "system" });
                }
            }
        } catch (e) {
            Logger.error(e);
        }
    }

    private redirectToSpace(space: SpaceResource, pathname: string) {
        const redirectTo = routeLinks.space(space.Id) + pathname;
        this.setState({ redirectTo });
    }

    private async stepIntoSelectedSpaceId(spaces: SpaceResource[], spaceId: string) {
        const selectedSpace = spaces.find(s => s.Id === spaceId);

        if (selectedSpace) {
            const alreadyInTheSelectedSpace = this.state.currentSpaceContext && isSpecificSpaceContext(this.state.currentSpaceContext) && selectedSpace.Id === this.state.currentSpaceContext.Id;
            if (!alreadyInTheSelectedSpace) {
                await this.stepIntoSpace(selectedSpace);
            }
        } else {
            await this.stepIntoNoSpaceSelectedContext();
            const isAlsoInSystemContext = spaces.length === 0;
            this.setState({ currentSpaceContext: { isAlsoInSystemContext, missingSpaceId: spaceId } });
        }
    }

    private async stepIntoNoSpaceSelectedContext() {
        repository.switchToSystem();
        await this.refreshPermissions();
    }

    private async stepIntoSpace(space: SpaceResource) {
        await repository.switchToSpace(space.Id);

        const refreshPermissions = this.refreshPermissions();

        const status = await repository.Tenants.status();
        store.dispatch(configurationActions.spaceMultiTenancyStatusFetched(status));

        this.props.onChangeSpace!(space.Id);
        if (this.props.match.params.spaceId !== space.Id) {
            this.setState({ redirectTo: space.Id });
        }

        await refreshPermissions;

        this.setState({ currentSpaceContext: space });
    }

    private async refreshPermissions() {
        const permissionSet = await repository.UserPermissions.getAllPermissions(session.currentUser!, true);
        session.refreshPermissions(permissionSet);
    }
}

const mapGlobalActionDispatchersToProps = (dispatch: Dispatch): GlobalDispatchProps => {
    return {
        onUserAccessibleSpacesLoaded: (spaces: SpaceResource[]) => {
            dispatch(configurationActions.userAccessibleSpacesFetched(spaces));
        },
    };
};

const mapGlobalStateToProps: MapStateToProps<GlobalConnectedProps, PropsExceptReduxProps, GlobalState> = state => {
    return {
        spaces: state.configurationArea.spaces ? state.configurationArea.spaces.usersAccessibleSpaces : null,
    };
};

const withPersistedSpace = (Component: React.ComponentType<SpaceLoaderProps>) => {
    const WithPersistedSpace: React.FC<SpaceLoaderProps> = props => {
        const [lastKnownSpaceId, setValue] = useLocalStorage<string>("octo-space", props.lastKnownSpaceId!);
        const handleSpaceChange = React.useMemo(() => callAll(setValue, props.onChangeSpace), [setValue, props.onChangeSpace]);
        return <Component {...props} lastKnownSpaceId={lastKnownSpaceId} onChangeSpace={handleSpaceChange} />;
    };

    return WithPersistedSpace;
};

export default withRouter(connect(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(withPersistedSpace(SpaceLoader)));
