// tslint:disable: no-non-null-assertion
import DataBaseComponent, { DataBaseComponentState } from "components/DataBaseComponent";
import { WorkerPoolResource, StaticWorkerPoolResource, DynamicWorkerPoolResource } from "client/resources";
import { WorkerPoolType } from "client/resources/workerPoolsSupportedTypesResouce";
import { RouteComponentProps } from "react-router";
import { repository } from "clientInstance";
import StaticWorkerPoolEdit from "./StaticWorkerPoolEdit";
import React = require("react");
import InfrastructureLayout from "../../InfrastructureLayout";
import PaperLayout from "components/PaperLayout";
import DynamicWorkerPoolEdit from "./DynamicWorkerPoolEdit";

interface WorkerPoolRouteParams {
    workerPoolId: string;
}

interface WorkerPoolProps extends RouteComponentProps<WorkerPoolRouteParams> {
    create?: boolean;
}

interface WorkerPoolEditState<TWorkerPoolResource extends WorkerPoolResource> extends DataBaseComponentState {
    workerPool: TWorkerPoolResource;
    deleted: boolean;
    newId: string;
    workerPoolType: WorkerPoolType;
}

class WorkerPoolEdit extends DataBaseComponent<WorkerPoolProps, WorkerPoolEditState<WorkerPoolResource>> {
    constructor(props: WorkerPoolProps) {
        super(props);
        this.state = {
            deleted: false,
            newId: null!,
            workerPool: null!,
            workerPoolType: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const workerPoolPromise = repository.WorkerPools.get(this.props.match.params.workerPoolId);
            const workerPool = await workerPoolPromise;
            this.setState({ workerPool });
        });
    }

    render() {
        if (this.state.workerPool) {
            switch (this.state.workerPool.WorkerPoolType) {
                case WorkerPoolType.Static:
                    return <StaticWorkerPoolEdit workerPool={this.state.workerPool as StaticWorkerPoolResource} />;
                case WorkerPoolType.Dynamic:
                    return <DynamicWorkerPoolEdit workerPool={this.state.workerPool as DynamicWorkerPoolResource} />;
            }
        }

        return (
            <InfrastructureLayout {...this.props}>
                <PaperLayout title="Worker Pools" busy={this.state.busy} errors={this.state.errors} />
            </InfrastructureLayout>
        );
    }
}

export { WorkerPoolEditState };
export default WorkerPoolEdit;
