// tslint:disable: no-non-null-assertion
// tslint:disable: no-any

import * as React from "react";
import * as _ from "lodash";
import { ControlType, AccountType, SensitiveValue, SetPrimaryPackageReference, IsPrimaryPackageReference, SetNamedPackageReference, getFeedTypesForActionType, CertificateResource } from "client/resources";
import FormFieldProps from "../form/FormFieldProps";
import CertificateSelect from "../form/CertificateSelect/CertificateSelect";
import { AccountResource } from "../../client/resources/accountResource";
import { BoundAccountSelect } from "../form/AccountSelect/AccountSelect";
import { BoundSensitive } from "components/form/Sensitive/Sensitive";
import { ResetValue } from "../form/BoundField/BoundField";
const styles = require("./style.less");
import { AwsBoundAccountVariableSelect, AzureBoundAccountVariableSelect } from "components/form/AccountSelect/AccountVariableSelect";
import CertificateVariableSelect from "components/form/CertificateSelect/CertificateVariableSelect";
import { ActionTemplateParameterResource } from "../../client/resources/actionTemplateParameterResource";
import selectOptionsToItems from "../form/Select/Options";
import getResetValue from "../form/Sensitive/getResetValue";
import DebounceValue from "components/DebounceValue/DebounceValue";
import Text from "components/form/Text/Text";
import { BoundSelect } from "components/form/Select/Select";
import { BoundStringCheckbox } from "components/form/Checkbox/StringCheckbox";
import { VariableLookupText } from "components/form/VariableLookupText";
import { isEqual } from "lodash";
import Note from "components/form/Note/Note";
import PackageSelector from "components/PackageSelector/PackageSelector";
import { PackageReference } from "client/resources/packageReference";
import FeedResource from "client/resources/feedResource";
import Callout, { CalloutType } from "components/Callout";
import { JsonUtils } from "utils/jsonUtils";
import ExternalLink from "components/Navigation/ExternalLink";
import WorkerPoolVariableSelect from "../form/WorkerPoolSelect/WorkerPoolVariableSelect";
import WorkerPoolSelect from "../form/WorkerPoolSelect/WorkerPoolSelect";

export interface SourceItems {
    tenantId?: string;
    projectId?: string;
    accounts?: {
        type: AccountType | AccountType[];
        items: AccountResource[];
        onRequestRefresh(): Promise<any>;
    };
    certificates?: {
        items(): Promise<CertificateResource[]>;
        onRequestRefresh(): Promise<boolean>;
    };
    stepNames?: string[];
    packages?: {
        items: Array<PackageReference<any>>;
        feeds: FeedResource[];
        onRequestRefresh(): Promise<any>;
        setPackages(packages: PackageReference[], initialise?: boolean): void;
    };
}

interface ActionTemplateParameterInputProps extends FormFieldProps<any> {
    parameter: ActionTemplateParameterResource;
    localNames?: string[];
    sourceItems: SourceItems;
    disabled?: boolean;
    error?: string;
    warning?: string;
    projectId?: string;
    actionType?: string;
    validate?(value: any): string;
    onValidate?(value: string): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}

interface PackageDetails {
    PackageId: string;
    Package: PackageReference<any>;
}

class ActionTemplateParameterInput extends React.Component<ActionTemplateParameterInputProps, never> {
    render() {
        return <div className={styles.container}>{this.getInputControl()}</div>;
    }

    private getInputControl = () => {
        const { warning, sourceItems = {}, doBusyTask, value, localNames, projectId, error, actionType, ...rest } = this.props;

        const resetValue = getResetValueFromParameter(this.props.parameter);
        const type = this.props.parameter.DisplaySettings["Octopus.ControlType"];
        const label = this.props.parameter.Label || this.props.parameter.Name;
        const formProps = { ...rest, label };
        const evaluatedResetValue = typeof resetValue === "function" ? resetValue() : resetValue;
        const fieldValue = value !== undefined ? value : evaluatedResetValue;
        const usingDefaultValue = isEqual(fieldValue, evaluatedResetValue);
        const defaultValueIndicator =
            evaluatedResetValue && usingDefaultValue ? (
                <Note>Using default value.</Note>
            ) : (
                <Note>
                    <a
                        href="#"
                        onClick={e => {
                            e.preventDefault();
                            if (this.props.onChange) {
                                this.props.onChange(evaluatedResetValue);
                            }
                        }}
                    >
                        Reset to default
                    </a>
                </Note>
            );

        switch (type) {
            case ControlType.SingleLineText:
                return (
                    <React.Fragment>
                        <VariableLookupText localNames={localNames} warning={warning} {...formProps} value={fieldValue} />
                        {defaultValueIndicator}
                    </React.Fragment>
                );
            case ControlType.MultiLineText:
                return (
                    <React.Fragment>
                        <VariableLookupText localNames={localNames} multiLine={true} warning={warning} {...formProps} value={fieldValue} />
                        {defaultValueIndicator}
                    </React.Fragment>
                );
            case ControlType.Select: {
                const options = selectOptionsToItems(this.props.parameter.DisplaySettings["Octopus.SelectOptions"]);
                const allowClear = this.props.parameter.AllowClear !== undefined ? this.props.parameter.AllowClear : true;
                return (
                    <React.Fragment>
                        <BoundSelect
                            variableLookup={{
                                localNames,
                            }}
                            resetValue={resetValue as string}
                            items={options}
                            warning={warning}
                            allowClear={allowClear}
                            value={fieldValue}
                            {...formProps}
                        />
                        {defaultValueIndicator}
                    </React.Fragment>
                );
            }
            case ControlType.Checkbox: {
                return (
                    <React.Fragment>
                        <BoundStringCheckbox
                            variableLookup={{
                                localNames,
                            }}
                            resetValue={resetValue as string}
                            warning={warning}
                            {...formProps}
                            value={fieldValue}
                        />
                        {defaultValueIndicator}
                    </React.Fragment>
                );
            }
            case ControlType.Sensitive:
                return (
                    <React.Fragment>
                        <BoundSensitive
                            variableLookup={{
                                localNames,
                            }}
                            value={fieldValue}
                            resetValue={resetValue}
                            warning={warning}
                            {...formProps}
                        />
                        {defaultValueIndicator}
                    </React.Fragment>
                );
            case ControlType.StepName:
                const items = sourceItems.stepNames ? sourceItems.stepNames.map(s => ({ value: s, text: s })) : [];
                return (
                    <React.Fragment>
                        <BoundSelect
                            variableLookup={{
                                localNames,
                            }}
                            resetValue={resetValue as string}
                            items={items}
                            value={fieldValue}
                            warning={warning}
                            {...formProps}
                        />
                        {defaultValueIndicator}
                    </React.Fragment>
                );
            case ControlType.Certificate:
                return (
                    <React.Fragment>
                        {sourceItems.projectId ? (
                            <CertificateVariableSelect value={fieldValue} projectId={sourceItems.projectId} doBusyTask={this.props.doBusyTask} allowClear={true} {...formProps} />
                        ) : (
                            <CertificateSelect
                                value={fieldValue}
                                onRequestRefresh={sourceItems.certificates ? sourceItems.certificates.onRequestRefresh : () => Promise.resolve<boolean>(true)}
                                items={sourceItems.certificates ? sourceItems.certificates.items : () => Promise.resolve<CertificateResource[]>([])}
                                warning={warning}
                                doBusyTask={doBusyTask}
                                tenantId={this.props.sourceItems.tenantId}
                                allowClear={true}
                                {...formProps}
                            />
                        )}
                        {defaultValueIndicator}
                    </React.Fragment>
                );
            case ControlType.WorkerPool:
                return (
                    <React.Fragment>
                        {sourceItems.projectId ? (
                            <WorkerPoolVariableSelect value={fieldValue} projectId={sourceItems.projectId} doBusyTask={this.props.doBusyTask} allowClear={true} {...formProps} />
                        ) : (
                            <WorkerPoolSelect value={fieldValue} warning={warning} doBusyTask={doBusyTask} allowClear={true} {...formProps} />
                        )}
                        {defaultValueIndicator}
                    </React.Fragment>
                );
            case ControlType.AmazonWebServicesAccount:
                return (
                    <React.Fragment>
                        {sourceItems.projectId ? (
                            <AwsBoundAccountVariableSelect value={fieldValue} resetValue={resetValue as string} projectId={sourceItems.projectId} doBusyTask={this.props.doBusyTask} allowClear={true} {...formProps} />
                        ) : (
                            <BoundAccountSelect
                                resetValue={resetValue as string}
                                value={fieldValue}
                                onRequestRefresh={sourceItems.accounts ? sourceItems.accounts.onRequestRefresh : this.emptyPromise}
                                items={sourceItems.accounts ? sourceItems.accounts.items : []}
                                type={[AccountType.AmazonWebServicesAccount]}
                                warning={warning}
                                allowClear={true}
                                {...formProps}
                            />
                        )}
                        {defaultValueIndicator}
                    </React.Fragment>
                );
            case ControlType.AzureAccount:
                return (
                    <React.Fragment>
                        {sourceItems.projectId ? (
                            <AzureBoundAccountVariableSelect value={fieldValue} resetValue={resetValue as string} projectId={sourceItems.projectId} doBusyTask={this.props.doBusyTask} allowClear={true} {...formProps} />
                        ) : (
                            <BoundAccountSelect
                                variableLookup={{
                                    localNames,
                                }}
                                resetValue={resetValue as string}
                                value={fieldValue}
                                onRequestRefresh={sourceItems.accounts ? sourceItems.accounts.onRequestRefresh : this.emptyPromise}
                                items={sourceItems.accounts ? sourceItems.accounts.items : []}
                                type={sourceItems.accounts ? sourceItems.accounts.type : []}
                                warning={warning}
                                allowClear={true}
                                {...formProps}
                            />
                        )}
                        {defaultValueIndicator}
                    </React.Fragment>
                );
            case ControlType.Package:
                const isPackageParameterReferenced = sourceItems.packages!.items.find(p => p.Properties["PackageParameterName"] === this.props.parameter.Name);
                if (isPackageParameterReferenced) {
                    const { PackageId, Package } = this.getPackageDetails(fieldValue, sourceItems.packages!.items);

                    if (Package) {
                        const feedIdValue = Package.FeedId;

                        return (
                            <React.Fragment>
                                <PackageSelector
                                    packageId={PackageId}
                                    feedId={feedIdValue}
                                    onPackageIdChange={packageId => {
                                        IsPrimaryPackageReference(Package)
                                            ? sourceItems.packages!.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, sourceItems.packages!.items))
                                            : sourceItems.packages!.setPackages(SetNamedPackageReference(Package.Name!, { PackageId: packageId }, sourceItems.packages!.items));
                                        this.notifyChange(JSON.stringify({ PackageId: packageId, FeedId: feedIdValue }));
                                    }}
                                    onFeedIdChange={feedId => {
                                        IsPrimaryPackageReference(Package)
                                            ? sourceItems.packages!.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, sourceItems.packages!.items))
                                            : sourceItems.packages!.setPackages(SetNamedPackageReference(Package.Name!, { FeedId: feedId }, sourceItems.packages!.items));
                                        this.notifyChange(JSON.stringify({ PackageId, FeedId: feedId }));
                                    }}
                                    projectId={this.props.projectId!}
                                    feeds={sourceItems.packages!.feeds}
                                    localNames={this.props.localNames!}
                                    feedType={getFeedTypesForActionType(actionType!)}
                                    refreshFeeds={sourceItems.packages!.onRequestRefresh}
                                    {...formProps}
                                />
                                {Package.Name && (
                                    <Note>
                                        The name used to identify this package reference is <code>{Package.Name}</code>. Learn more about{" "}
                                        <ExternalLink href="ScriptStepPackageReferencesFromCustomScripts">Accessing Package References from a Custom Script</ExternalLink>.
                                    </Note>
                                )}
                            </React.Fragment>
                        );
                    }
                }
                return (
                    <Callout type={CalloutType.Warning} title={"This parameter is not used by the step"}>
                        <p>This parameter is not currently used by the step, it might have been replaced by another parameter.</p>
                    </Callout>
                );

            default:
                return <Text warning={warning} {...formProps} value={fieldValue} />;
        }
    };

    private notifyChange = (value: any) => {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };

    private getPackageDetails = (value: string, packages: Array<PackageReference<any>>): PackageDetails => {
        let packageIdValue = value;
        let pkg = _.find(packages, p => p.Properties["PackageParameterName"] === this.props.parameter.Name)!;

        if (!!value && JsonUtils.tryParseJson(value)) {
            const { PackageId, FeedId }: Partial<PackageReference<any>> = JSON.parse(value);
            pkg = { ...pkg, PackageId: PackageId!, FeedId: FeedId! };
            packageIdValue = pkg.PackageId;
        }

        if (!pkg && packages.length > 0) {
            const hasPackageParameters = _.some(packages, p => p.Properties["PackageParameterName"]);
            if (!hasPackageParameters) {
                // If there's no PackageParameterName the action is the old style. Use the first item as the package.
                pkg = packages[0];
                // Pre-populate existing value
                packageIdValue = pkg.PackageId;
            }
        }

        return { PackageId: packageIdValue, Package: pkg! };
    };

    private emptyPromise = () => {
        return Promise.resolve();
    };
}

function getResetValueFromParameter(parameter: ActionTemplateParameterResource): ResetValue<SensitiveValue> {
    return parameter.DisplaySettings && parameter.DisplaySettings["Octopus.ControlType"] === "Sensitive" ? getResetValue(parameter.DefaultValue) : parameter.DefaultValue!;
}

export default DebounceValue(ActionTemplateParameterInput);
export { ActionTemplateParameterInputProps };
