// tslint:disable: no-non-null-assertion
import * as React from "react";
import { DeploymentStepResource, DeploymentActionResource, IProcessResource, isDeploymentProcessResource, isRunbookProcessResource, RunbookProcessResource, processPermission } from "client/resources";
import FilterSearchBox from "components/FilterSearchBox";
import Divider from "components/Divider";
import { DoBusyTask, DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import SpecialVariables from "client/specialVariables";
import routeLinks from "routeLinks";
import DeploymentPartForSidebar from "./DeploymentPartForSidebar";
import pluginRegistry, { ActionScope } from "components/Actions/pluginRegistry";
import getActionLogoUrl from "../getActionLogoUrl";
import FormPaperLayout, { FormPaperLayoutProps } from "components/FormPaperLayout";
import ActionButton, { ActionButtonType } from "components/Button";
import OverflowMenu, { OverflowMenuItems } from "components/Menu/OverflowMenu";
import StepSorter from "./DeploymentPartSorter";
import { RouteComponentProps, withRouter } from "react-router";
import Section from "components/Section";
import DeploymentPartContextMenu from "./DeploymentPartContextMenu";
import { NoResults } from "components/NoResults/NoResults";
import Logger from "client/logger";
import { withProjectContext, WithProjectContextInjectedProps, useProjectContext, ActionContextProvider } from "../../context";
import { ProcessContextProvider, StepsEditorFilter, useProcessContext } from "../../context";
import { Note } from "components/form";
import ActionTemplateSearchResource from "client/resources/actionTemplateSearchResource";
import { AddStepButton } from "../Steps";
import { useRunbookContext } from "../Runbooks/RunbookContext";
import RunNowButton from "../Runbooks/RunNowButton";
import { StepRolling } from "components/Images/Images/DeploymentProcess/StepRolling";

const deploymentPartStyles = require("./DeploymentPart.less");

const styles = require("./DeploymentProcessEditor.less");
const deploymentPartForSidebarStyles = require("./DeploymentPartForSidebar.less");

export interface DeploymentProcessEditorRenderProps {
    step: DeploymentStepResource;
}

interface DeploymentProcessEditorState extends DataBaseComponentState {
    redirectTo: string;
}

export interface DeploymentProcessEditorProps {
    render: (renderProps: DeploymentProcessEditorRenderProps) => React.ReactNode;
    doBusyTask: DoBusyTask;
    isAddStepDisabled: boolean;
    id: string;
    scope: ActionScope;
}

export interface DeploymentProcessEditorMatch {
    projectSlug: string;
    stepId: string;
    filterKeyword: string;
}

type RouteProps = RouteComponentProps<DeploymentProcessEditorMatch>;
type Props = RouteProps & DeploymentProcessEditorProps & WithProjectContextInjectedProps;

const DeploymentProcessLayoutOverflowMenu: React.FC<{ scope: ActionScope }> = props => {
    const context = useProcessContext();
    const projectContext = useProjectContext();

    const overFlowActions = [
        OverflowMenuItems.dialogItem("Reorder Steps", <StepSorter scope={props.scope} title="Reorder Steps" processId={context.state.process && context.state.process.Id} onStepsUpdated={context.actions.onStepsChange} />, {
            permission: processPermission(context.state.process),
            project: projectContext.state.model.Id,
            wildcard: true,
        }),
    ];

    return <OverflowMenu menuItems={overFlowActions} />;
};

const DeploymentsEditorFormPaperLayout: React.FC<FormPaperLayoutProps & { projectSlug: string; steps: IProcessResource }> = ({ projectSlug, steps, ...props }) => {
    const projectLinks = routeLinks.project(projectSlug);
    const { title = "Process Editor", breadcrumbPath = projectLinks.deployments.process.root, breadcrumbTitle = "Deployment Process", ...rest } = props;
    return <FormPaperLayout title={title} primaryNavigationButton={<AddStepButton />} breadcrumbPath={breadcrumbPath} breadcrumbTitle={breadcrumbTitle} {...rest} />;
};

const RunbooksEditorFormPaperLayout: React.FC<FormPaperLayoutProps & { projectSlug: string; steps: RunbookProcessResource }> = ({ projectSlug, steps, ...props }) => {
    const runbooksContext = useRunbookContext();
    const projectLinks = routeLinks.project(projectSlug);

    const {
        title = "Step Editor",
        breadcrumbPath = projectLinks.operations.runbook(steps.RunbookId).runbookProcess.runbookProcess(steps.Id).steps.root,
        breadcrumbTitle = runbooksContext.state.runbook && `${runbooksContext.state.runbook.Name} Steps`,
        ...rest
    } = props;

    const hasSteps = steps && steps.Steps && steps.Steps.length > 0;
    return (
        <FormPaperLayout
            title={title}
            primaryNavigationButton={
                <React.Fragment>
                    <AddStepButton />
                    <RunNowButton isDisabled={!hasSteps} />
                </React.Fragment>
            }
            breadcrumbPath={breadcrumbPath}
            breadcrumbTitle={breadcrumbTitle!}
            {...rest}
        />
    );
};

type ProcessContextFormPaperLayoutProps = FormPaperLayoutProps & RouteComponentProps<{ projectSlug: string }>;
const ProcessContextFormPaperLayout: React.FC<ProcessContextFormPaperLayoutProps> = props => {
    const processContext = useProcessContext();
    const projectSlug = props.match.params.projectSlug;
    const process = processContext.state.process;

    if (isRunbookProcessResource(process)) {
        return <RunbooksEditorFormPaperLayout steps={process} projectSlug={projectSlug} {...props} />;
    } else if (isDeploymentProcessResource(process)) {
        return <DeploymentsEditorFormPaperLayout steps={process} projectSlug={projectSlug} {...props} />;
    }

    return <FormPaperLayout title="Step Editor" {...props} />;
};

const EnhancedProcessContextFormPaperLayout = withRouter(ProcessContextFormPaperLayout);

class DeploymentProcessEditorLayout extends DataBaseComponent<Props, DeploymentProcessEditorState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            redirectTo: null!,
        };
    }

    render() {
        return (
            <ActionContextProvider doBusyTask={this.props.doBusyTask}>
                {({ state: { actionTemplates } }) => (
                    <ProcessContextProvider scope={this.props.scope} id={this.props.id} doBusyTask={this.props.doBusyTask}>
                        {processContext => {
                            const filter = processContext.state.filter;
                            const showExtendedFilterDetails = filter.channel || filter.environment || !filter.includeUnscoped;
                            return (
                                <EnhancedProcessContextFormPaperLayout
                                    model={null!}
                                    cleanModel={null!}
                                    onSaveClick={null!}
                                    busy={this.state.busy}
                                    errors={this.state.errors}
                                    saveButtonLabel={null!}
                                    savePermission={{
                                        permission: processPermission(processContext.state.process),
                                        project: this.props.projectContext.state.model.Id,
                                        wildcard: true,
                                    }}
                                    hideExpandAll={true}
                                >
                                    <Divider fullHeight={true} />
                                    <div className={styles.container}>
                                        {processContext.state.process && processContext.state.process.Steps && processContext.state.process.Steps.length > 0 && (
                                            <div className={styles.sidebar}>
                                                <>
                                                    <Section bodyClassName={styles.headerBody}>
                                                        <div className={styles.headerBodyFilter}>
                                                            <FilterSearchBox
                                                                hintText="Filter by name..."
                                                                value={filter.filterKeyword}
                                                                onChange={val => processContext.actions.onFilterChange(prev => ({ ...prev, filterKeyword: val }))}
                                                                fullWidth={true}
                                                                autoFocus={true}
                                                            />
                                                            <DeploymentProcessLayoutOverflowMenu scope={this.props.scope} />
                                                        </div>
                                                        {showExtendedFilterDetails && (
                                                            <div className={styles.regardingAnyContainer}>
                                                                <Note style={{ fontSize: "0.875rem" }}>
                                                                    {!!filter.environment && (
                                                                        <div>
                                                                            Environment: <strong>{filter.environment.Name}</strong>
                                                                        </div>
                                                                    )}
                                                                    {!!filter.channel && (
                                                                        <div>
                                                                            Channel: <strong>{filter.channel.Name}</strong>
                                                                        </div>
                                                                    )}
                                                                    <div>{filter.includeUnscoped ? "Including unscoped steps" : "Excluding unscoped steps"}</div>
                                                                </Note>
                                                                <div>
                                                                    <ActionButton type={ActionButtonType.Ternary} onClick={val => processContext.actions.onClearFilter()} label="Clear" />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Section>
                                                    <div className={deploymentPartForSidebarStyles.stepList}>
                                                        {processContext.filteredSteps.steps.length > 0 ? (
                                                            processContext.filteredSteps.steps
                                                                .filter(x => x.filtered)
                                                                .map(({ step: filteredStep, index }) => {
                                                                    const step = this.findStepByName(processContext.state.process, filteredStep.Name);
                                                                    if (!step) {
                                                                        Logger.log(`Failed to find step with name ${filteredStep.Name}`);
                                                                        return null;
                                                                    }
                                                                    return step.Actions.length === 1
                                                                        ? this.buildAction(this.props.scope, actionTemplates, filter, step, step.Actions[0], index)
                                                                        : this.buildParentStep(this.props.scope, actionTemplates, filter, step, index);
                                                                })
                                                        ) : (
                                                            <NoResults />
                                                        )}
                                                    </div>
                                                </>
                                            </div>
                                        )}
                                        <div className={styles.content}>{this.props.render({ step: null! })}</div>
                                    </div>
                                </EnhancedProcessContextFormPaperLayout>
                            );
                        }}
                    </ProcessContextProvider>
                )}
            </ActionContextProvider>
        );
    }

    private findStepByName(process: IProcessResource, name: string) {
        // We lookup by .Name due to cloned steps not having an ID initially.
        return process.Steps.find(x => x.Name === name);
    }

    private buildParentStep(scope: ActionScope, actionTemplates: ActionTemplateSearchResource[], filter: StepsEditorFilter, step: DeploymentStepResource, stepIndex: number) {
        const currentActionId = this.props.match.params.stepId;
        const isCurrentAction = currentActionId === step.Id;

        let disableActions = false;
        if (currentActionId && currentActionId !== step.Id) {
            // Is this the parent of a currently selected action?
            if (step.Actions.filter(x => x.Id === currentActionId).length > 0) {
                disableActions = true;
            }
        }
        const showWindowSize = step.Properties[SpecialVariables.Action.MaxParallelism] ? step.Properties[SpecialVariables.Action.MaxParallelism]!.toString().length > 0 : false;
        const parentStepLabel = showWindowSize ? (
            <span>Rolling deployment</span>
        ) : (
            <span>
                Multi-step deployment across
                <br />
                deployment targets
            </span>
        );

        return (
            <div key={step.Id} className={deploymentPartForSidebarStyles.group}>
                <DeploymentPartContextMenu
                    scope={this.props.scope}
                    keywordSearch={filter && filter.filterKeyword}
                    step={step}
                    stepIndex={stepIndex}
                    isParentGroup={true}
                    isCurrentAction={isCurrentAction}
                    render={renderProps => {
                        return <DeploymentPartForSidebar actionType={parentStepLabel} icon={<StepRolling height="1.6rem" className={deploymentPartStyles.stepIcon} />} disableActionsDueToGroupEditing={disableActions} {...renderProps} />;
                    }}
                />
                {step.Actions.map((action, index) => this.buildAction(scope, actionTemplates, filter, step, action, index + 1, stepIndex, currentActionId))}
            </div>
        );
    }

    private buildAction(
        scope: ActionScope,
        actionTemplates: ActionTemplateSearchResource[],
        filter: StepsEditorFilter,
        step: DeploymentStepResource,
        action: DeploymentActionResource,
        actionIndex: number,
        stepIndex?: number,
        currentActionId?: string
    ) {
        const isChildAction = !!stepIndex;
        const isCurrentAction = this.props.match.params.stepId === action.Id;
        let actionTypeName = action.ActionType;
        const actionTemplate = actionTemplates.find(x => x.Type === action.ActionType);
        if (actionTemplate) {
            actionTypeName = actionTemplate.Name;
        }

        let disableActions = false;
        if (isChildAction && currentActionId && currentActionId !== action.Id) {
            // Is the current action the parent step?
            if (step.Id === currentActionId) {
                disableActions = true;
            }
            // Is the current action a child of this parent step?
            if (step.Actions.filter(x => x.Id === currentActionId).length > 0) {
                disableActions = true;
            }
        }

        return (
            <DeploymentPartContextMenu
                scope={this.props.scope}
                key={action.Id}
                step={step}
                action={action}
                stepIndex={stepIndex}
                actionIndex={actionIndex}
                isChildAction={isChildAction}
                isCurrentAction={isCurrentAction}
                keywordSearch={filter && filter.filterKeyword}
                render={renderProps => <DeploymentPartForSidebar key={action.Id} actionType={actionTypeName} logoUrl={getActionLogoUrl(action)} disableActionsDueToGroupEditing={disableActions} {...renderProps} />}
            />
        );
    }
}

const EnhancedDeploymentProcessEditorLayout = withProjectContext(DeploymentProcessEditorLayout);

export default EnhancedDeploymentProcessEditorLayout;
