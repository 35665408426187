// tslint:disable: no-non-null-assertion
import * as React from "react";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { MachineModelHealthStatus, TenantResource, MachineResource, EnvironmentResource, WorkerPoolResource, EnvironmentSummaryResource, Resource, WorkerPoolSummaryResource } from "client/resources";
import { TagIndex } from "components/tenantTagsets";
import { ResourceCollection } from "client/resources";
import { ReactNode } from "react";
import MachineIconHelper from "utils/MachineIconHelper";
import List from "components/List";
import { FormSectionHeading } from "components/form";
import { Section } from "components/Section/Section";
import MachineFilter from "../MachinesLayout/MachineFilter";
import { Dictionary } from "lodash";
import MachineHealthStatusHelper from "utils/MachineHealthStatusHelper";
const styles = require("./style.less");
import routeLinks from "../../../../routeLinks";
import MachineRow from "../MachineRow/MachineRow";
import { NoResults } from "components/NoResults/NoResults";
import IconButton from "components/IconButton";
import { Icon } from "components/IconButton/IconButton";
import { repository } from "clientInstance";

interface SmallCloseButtonProps {
    onClose?: (e: React.MouseEvent<Element, MouseEvent>) => void;
}
export const SmallCloseButton: React.SFC<SmallCloseButtonProps> = props => {
    return <IconButton onClick={(e: React.MouseEvent<Element, MouseEvent>) => props.onClose!(e)} icon={Icon.CancelSmall} style={{ width: "1.25rem", height: "1.25rem" }} />;
};

class MachinesList extends List<MachineResource> {}

export interface BaseMachinesSummaryProps {
    filter: MachineFilter;
    isFiltering: boolean;
    tenants: TenantResource[];
    tagIndex: TagIndex;
}

export interface BaseMachinesSummaryState extends DataBaseComponentState {
    machinesResponse: ResourceCollection<MachineResource>;
    machineHealthStatusFastLookup?: Dictionary<ResourceCollection<MachineResource>>;
    currentPageIndex: number; // This has a custom endpoint, so we manage our own paging implementation in List/onLoadMore.
    expanded: boolean; // Need to know if we're currently expanded so we can choose to reload when the filter changes or not.
    healthStatusFilter: MachineModelHealthStatus;
    isDisabledFilter: boolean;
    redirectToTaskId?: string;
}

abstract class BaseMachinesSummary<Props extends BaseMachinesSummaryProps, State extends BaseMachinesSummaryState> extends DataBaseComponent<Props, State> {
    protected machineListTakeSize = repository.takeDefaultPageSize;
    protected machineIconHelper = new MachineIconHelper();

    protected abstract async loadData(): Promise<Pick<State, keyof State> | void>;

    protected reloadDataAndCurrentPageIndex() {
        this.setState({ currentPageIndex: 0 }, async () => {
            await this.doBusyTask(async () => {
                await this.loadData();
            });
        });
    }

    protected makeMachineResourceCollection(machines: MachineResource[], takeSize: number) {
        // Emulate a paging response so this will work with our List easily.
        const totalResults = machines.length;
        const itemsPerPage = takeSize;
        const numberOfPages = Math.max(1, Math.ceil(totalResults / itemsPerPage));
        const lastPageNumber = numberOfPages - 1;
        const pageItems = machines.slice(0, itemsPerPage);
        const machinesCollection: ResourceCollection<MachineResource> = {
            ItemType: "Machine",
            TotalResults: totalResults,
            ItemsPerPage: itemsPerPage,
            NumberOfPages: numberOfPages,
            LastPageNumber: lastPageNumber,
            Items: pageItems,
            Links: null!,
        };
        return machinesCollection;
    }

    protected renderMachinesList(summary: EnvironmentSummaryResource | WorkerPoolSummaryResource, environments?: EnvironmentResource[], workerPools?: WorkerPoolResource[]) {
        const componentKey = "allMachines";
        return <div key={componentKey}>{this.state.expanded && this.state.machinesResponse && <div>{this.renderMachinesListGroupedByHealthStatus(summary, environments!, workerPools!)}</div>}</div>;
    }

    protected renderMachinesListGroupedByHealthStatus(summary: EnvironmentSummaryResource | WorkerPoolSummaryResource, environments: EnvironmentResource[], workerPools: WorkerPoolResource[]) {
        const allMachines = this.state.machinesResponse.Items;
        if (allMachines.length === 0) {
            return (
                <Section>
                    <NoResults />
                </Section>
            );
        }
        let machinesNeedUpgrading: string[] = [];
        if (summary.MachineIdsForCalamariUpgrade) {
            machinesNeedUpgrading = machinesNeedUpgrading.concat(summary.MachineIdsForCalamariUpgrade);
        }
        if (summary.MachineIdsForTentacleUpgrade) {
            machinesNeedUpgrading = machinesNeedUpgrading.concat(summary.MachineIdsForTentacleUpgrade);
        }

        const machinesHealthyList = this.renderHealthStatusSectionHeading(MachineModelHealthStatus.Healthy, false, allMachines, environments, workerPools, true, machinesNeedUpgrading);
        const machinesUnavailableList = this.renderHealthStatusSectionHeading(MachineModelHealthStatus.Unavailable, false, allMachines, environments, workerPools, true, machinesNeedUpgrading);
        const machinesUnknownList = this.renderHealthStatusSectionHeading(MachineModelHealthStatus.Unknown, true, allMachines, environments, workerPools, true, machinesNeedUpgrading);
        const machinesHasWarningsList = this.renderHealthStatusSectionHeading(MachineModelHealthStatus.HasWarnings, false, allMachines, environments, workerPools, true, machinesNeedUpgrading);
        const machinesUnhealthyList = this.renderHealthStatusSectionHeading(MachineModelHealthStatus.Unhealthy, false, allMachines, environments, workerPools, true, machinesNeedUpgrading);
        const machinesDisabledList = this.renderDisabledSectionHeading(allMachines, environments, workerPools, true);
        return (
            <div>
                {machinesHealthyList}
                {machinesUnavailableList}
                {machinesUnknownList}
                {machinesHasWarningsList}
                {machinesUnhealthyList}
                {machinesDisabledList}
            </div>
        );
    }

    protected getHealthStatusFiltersAsPerPrecedence(): MachineModelHealthStatus[] {
        if (this.props.isFiltering) {
            if (this.props.filter.healthStatuses && this.props.filter.healthStatuses.length > 0) {
                return this.props.filter.healthStatuses;
            }
            return null!;
        }
        return [this.state.healthStatusFilter];
    }

    protected getIsDisabledFilterAsPerPrecedence(): boolean {
        if (this.props.isFiltering) {
            return this.props.filter.isDisabled;
        }
        return this.state.isDisabledFilter;
    }

    protected renderHealthStatusSectionHeading(
        status: MachineModelHealthStatus,
        excludeDisabledMachines: boolean,
        allMachines: MachineResource[],
        environments: EnvironmentResource[],
        workerPools: WorkerPoolResource[],
        showTitle: boolean,
        machineIdsTobeUpgraded: string[]
    ): JSX.Element {
        let machines = allMachines.filter(x => x.HealthStatus === status);
        if (excludeDisabledMachines) {
            machines = machines.filter(x => !x.IsDisabled);
        }

        let machinesTitleIcon = null;
        let machinesTitle = null;
        if (showTitle) {
            machinesTitleIcon = this.machineIconHelper.healthStatusIcons[status];
            machinesTitle = (
                <div className={styles.healthStatusCardTitleContainer} key={status}>
                    <div className={styles.healthStatusIconContainer}>{machinesTitleIcon && <img src={machinesTitleIcon} className={styles.healthStatusIcon} alt="Health status" />}</div>
                    <div className={styles.healthStatusName}>{MachineHealthStatusHelper.getFriendlyName(status)}</div>
                    <div className={styles.healthStatusMachinesCount}>{`(${machines.length}/${allMachines.length})`}</div>
                </div>
            );
        }
        const componentKey = status.toString();
        const machinesList = (
            <React.Fragment>
                {machines.length > 0 && (
                    <div>
                        {showTitle && <FormSectionHeading title={machinesTitle!} />}
                        <MachinesList
                            key={componentKey}
                            initialData={this.state.machineHealthStatusFastLookup![componentKey]}
                            onRow={(machine: MachineResource) => this.renderMachine(machine, environments, workerPools, machineIdsTobeUpgraded.includes(machine.Id))}
                            onRowRedirectUrl={(machine: MachineResource) => {
                                if (machineIdsTobeUpgraded.includes(machine.Id)) {
                                    return routeLinks.infrastructure.machine(machine).connection;
                                }

                                return routeLinks.infrastructure.machine(machine).root;
                            }}
                            onLoadMore={async () => {
                                const newTakeSize = this.getBaseState(state => state.machineHealthStatusFastLookup![componentKey].ItemsPerPage + this.machineListTakeSize);
                                const machineHealthStatusFastLookup = this.getBaseState(state => state.machineHealthStatusFastLookup!);
                                const response = this.getBaseState(state => state.machinesResponse);
                                const filteredMachines = response.Items.filter(x => x.HealthStatus === status);
                                const machinesForHealthStatus = this.makeMachineResourceCollection(filteredMachines, newTakeSize);
                                machineHealthStatusFastLookup[componentKey] = machinesForHealthStatus;
                                this.setState({
                                    machineHealthStatusFastLookup,
                                });
                            }}
                        />
                    </div>
                )}
            </React.Fragment>
        );
        return machinesList;
    }

    protected renderDisabledSectionHeading(allMachines: MachineResource[], environments: EnvironmentResource[], workerPools: WorkerPoolResource[], showTitle: boolean): JSX.Element {
        const machines = allMachines.filter(x => x.IsDisabled);

        let machinesTitleIcon = null;
        let machinesTitle = null;
        if (showTitle) {
            machinesTitleIcon = this.machineIconHelper.healthStatusIcons["Disabled"];
            machinesTitle = (
                <div className={styles.healthStatusCardTitleContainer} key={status}>
                    <div className={styles.healthStatusIconContainer}>{machinesTitleIcon && <img src={machinesTitleIcon} className={styles.healthStatusIcon} alt="Health status" />}</div>
                    <div className={styles.healthStatusName}>Disabled</div>
                    <div className={styles.healthStatusMachinesCount}>({machines.length})</div>
                </div>
            );
        }

        const componentKey = "Disabled";
        const machinesList = (
            <React.Fragment>
                {machines.length > 0 && (
                    <div>
                        {showTitle && <FormSectionHeading title={machinesTitle!} />}
                        <MachinesList
                            key={componentKey}
                            initialData={this.state.machineHealthStatusFastLookup![componentKey]}
                            onRow={(machine: MachineResource) => this.renderMachine(machine, environments, workerPools)}
                            onRowRedirectUrl={(machine: MachineResource) => routeLinks.infrastructure.machine(machine).root}
                            onLoadMore={async () => {
                                const newTakeSize = this.getBaseState(state => state.machineHealthStatusFastLookup![componentKey].ItemsPerPage + this.machineListTakeSize);
                                const machineHealthStatusFastLookup = this.getBaseState(state => state.machineHealthStatusFastLookup!);
                                const response = this.getBaseState(state => state.machinesResponse);
                                const filteredMachines = response.Items.filter(x => x.IsDisabled);
                                const machinesForHealthStatus = this.makeMachineResourceCollection(filteredMachines, newTakeSize);
                                machineHealthStatusFastLookup[componentKey] = machinesForHealthStatus;
                                this.setState({
                                    machineHealthStatusFastLookup,
                                });
                            }}
                        />
                    </div>
                )}
            </React.Fragment>
        );
        return machinesList;
    }

    protected renderMachine = (machine: MachineResource, environments: EnvironmentResource[], workerPools: WorkerPoolResource[], needsUpgrading: boolean = false): ReactNode => {
        return <MachineRow machine={machine} environments={environments} tenants={this.props.tenants} tagIndex={this.props.tagIndex} workerPools={workerPools} needsUpgrading={needsUpgrading} />;
    };

    private getBaseState = <TResult extends {}>(accessor: (state: Readonly<BaseMachinesSummaryState>) => TResult) => {
        return accessor(this.state as BaseMachinesSummaryState);
    };
}

export default BaseMachinesSummary;
