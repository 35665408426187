// tslint:disable: no-non-null-assertion
import Logger from "client/logger";

export default class RequestRaceConditioner {
    private requestTimestamp?: number;

    // To avoid race conditions, this helps check we are only showing the LAST request's response.
    // This was necessary on screens with a side-filter panel, as users can quickly select multiple
    // filters, causing multiple API requests, so this guarantees that "the last request wins".
    public async avoidStaleResponsesForRequest<T>(promise: Promise<T>, completionCallback: (response: T) => void, staleCallback: (response: T) => void = null!) {
        const requestTimestamp = Date.now();
        this.requestTimestamp = requestTimestamp;
        const response = await promise;
        if (this.requestTimestamp === requestTimestamp) {
            completionCallback(response);
        } else {
            Logger.log("Ignoring stale response (this request has been superceded)");
            if (staleCallback) {
                staleCallback(response);
            }
        }
    }
}
