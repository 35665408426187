// tslint:disable: no-non-null-assertion
import * as React from "react";
import List from "components/List";
import { ResourceCollection } from "client/resources";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import { ProxyResource } from "client/resources";
import { repository } from "clientInstance";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { NavigationButton, NavigationButtonType } from "components/Button";
import { RouteComponentProps } from "react-router";
import InfrastructureLayout from "../InfrastructureLayout";
import ListTitle from "components/ListTitle/ListTitle";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import routeLinks from "routeLinks";
import ToolTip from "components/ToolTip";

class ProxyList extends List<ProxyResource> {}

interface ProxyLayoutState extends DataBaseComponentState {
    proxiesResponse: ResourceCollection<ProxyResource>;
}

export default class ProxiesLayout extends DataBaseComponent<RouteComponentProps<void>, ProxyLayoutState> {
    constructor(props: RouteComponentProps<void>) {
        super(props);
        this.state = {
            proxiesResponse: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const proxiesResponse = await repository.Proxies.list();
            this.setState({ proxiesResponse });
        });
    }

    render() {
        const addButton = (
            <PermissionCheck permission={Permission.ProxyCreate}>
                <ToolTip content={"Modify the proxy used by the Octopus Server for web requests"}>
                    <NavigationButton type={NavigationButtonType.Secondary} label="Modify Web Request Proxy" href={routeLinks.configuration.setting("server-proxy")} />
                </ToolTip>
                <NavigationButton type={NavigationButtonType.Primary} label="Add Proxy" href={routeLinks.infrastructure.proxies.create} />
            </PermissionCheck>
        );

        return (
            <InfrastructureLayout {...this.props}>
                <PaperLayout busy={this.state.busy} errors={this.state.errors} title="Machine Proxies" sectionControl={addButton}>
                    {this.state.proxiesResponse && (
                        <ProxyList
                            initialData={this.state.proxiesResponse}
                            onRow={(item: ProxyResource) => this.buildProxyRow(item)}
                            onFilter={this.Filter}
                            filterSearchEnabled={true}
                            apiSearchParams={["partialName"]}
                            match={this.props.match}
                            filterHintText="Filter by name..."
                        />
                    )}
                </PaperLayout>
            </InfrastructureLayout>
        );
    }

    private Filter(filter: string, resource: ProxyResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }

    private buildProxyRow(proxy: ProxyResource) {
        return <ListTitle>{proxy.Name}</ListTitle>;
    }
}
