import "core-js/fn/array/from";
import "core-js/fn/array/fill";
import "core-js/fn/array/find";
import "core-js/fn/array/find-index";
import "core-js/fn/array/includes";

import "core-js/fn/string/starts-with";
import "core-js/fn/string/ends-with";
import "core-js/fn/string/includes";

import "core-js/fn/object/assign";
import "core-js/fn/object/values";
import "core-js/fn/object/entries";

import "core-js/fn/map";
import "core-js/fn/set";
import "url-polyfill";
