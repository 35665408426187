import { combineReducers } from "redux";
import infrastructureArea from "areas/infrastructure/reducers/infrastructureArea";
import libraryArea from "areas/library/reducers/libraryArea";
import unhandledError from "components/UnhandledError/reducers";
import { tenantsArea } from "../areas/tenants/tenantsArea";
import expanders from "components/form/Sections/reducers/expanders";
import dialogs from "components/Dialog/store";
import menus from "components/Menu/store";
import { notificationReducer } from "../components/Navbar/notifications.redux";
import { configurationArea } from "areas/configuration/reducers/configurationArea";
import formPaperLayout from "components/FormPaperLayout/reducers";
import overlayArea from "components/OverlaySteps/reducers/overlayArea";
import onboardingArea from "components/GettingStarted/reducers/onboardingArea";
import pageLayoutArea from "components/PageLayout/reducers/pageLayoutArea";
import drawerArea from "components/Drawer/reducers";

const rootReducer = combineReducers({
    libraryArea,
    infrastructureArea,
    tenantsArea,
    configurationArea,
    expanders,
    dialogs,
    menus,
    notifications: notificationReducer,
    formPaperLayout,
    unhandledError,
    overlayArea,
    onboardingArea,
    pageLayoutArea,
    drawerArea,
});

export type GlobalState = ReturnType<typeof rootReducer> & {
    expanders: { [containerKey: string]: ExpanderContainer | undefined };
};

export interface ExpanderContainer {
    expanderValues: {
        [errorKey: string]: boolean | undefined | null; // Null indicates that the key should use the initial value
    };
    initialState: boolean;
    expandingAll: boolean;
}

export default rootReducer;

export { rootReducer };
