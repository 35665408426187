// tslint:disable: no-non-null-assertion
import * as React from "react";
import { FormFieldProps } from "components/form";
import { RoleChip } from "components/Chips";
import { MultiSelect } from "components/MultiSelect";
import { SelectItem } from "components/VirtualListWithKeyboard/SelectItem";

interface RoleMultiSelectProps extends FormFieldProps<string[]> {
    items: string[];
    canAdd?: boolean;
    openOnFocus?: boolean;
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    hideFloatingLabel?: boolean;
    validate?(value: string[]): string;
}

const RoleTypedMultiSelect = MultiSelect<SelectItem>();

const RoleMultiSelect: React.StatelessComponent<RoleMultiSelectProps> = props => {
    const onAdd = (value: string) => {
        props.onChange!(props.value!.concat(value));
    };

    const chipRenderer = (r: SelectItem, onRequestDelete: (event: object) => void) => {
        return <RoleChip onRequestDelete={onRequestDelete} role={r.Name} />;
    };

    return (
        <RoleTypedMultiSelect
            items={props.items.map(i => ({ Id: i, Name: i }))}
            fieldName="target roles"
            label={props.label}
            renderChip={chipRenderer}
            onNew={props.canAdd ? onAdd : null!}
            value={props.value}
            onChange={props.onChange}
            error={props.error}
            openOnFocus={props.openOnFocus}
            autoFocus={props.autoFocus}
            hideFloatingLabel={props.hideFloatingLabel}
            addNewTemplate={text => `"${text}" (add new role)`}
        />
    );
};

export default RoleMultiSelect;
