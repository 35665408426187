// tslint:disable: no-non-null-assertion
import * as React from "react";
import LazyLoad, { forceCheck } from "react-lazyload";
import { ResourceWithId } from "client/resources";
import { client } from "clientInstance";
import { debounce } from "lodash";
const styles = require("./style.less");
import { LogoEditorSettings } from "components/form/LogoEditor/LogoEditor";
import { uploadLogo } from "client/repositories/logoUpload";
import classNames = require("classnames");

interface LogoProps {
    url: string;
    size?: string;
    isDisabled?: boolean;
    className?: string;
}

class Logo extends React.Component<LogoProps> {
    private static defaultProps: Partial<LogoProps> = {
        size: "3.1rem",
    };

    // This is static because we want it to be debounced so that when we have lots of Logos calling it we are not rechecking it for every single one
    private static recheckVisibility = debounce(() => {
        forceCheck();
    }, 800);

    // We need to force a recheck because LazyLoad only rechecks on scrolling, but because our screens support filtering, that bypasses the scrolling event
    componentDidUpdate() {
        Logo.recheckVisibility();
    }

    render() {
        if (!this.props.url) {
            return <div />;
        }
        const resolvedUrl = this.props.url.startsWith("~") || this.props.url.startsWith("/") ? client.resolve(this.props.url) : this.props.url;
        return (
            <div className={classNames(styles.logo, this.props.className)} style={{ width: this.props.size, height: this.props.size, minWidth: this.props.size }}>
                <LazyLoad once={true} height={this.props.size}>
                    <img
                        src={resolvedUrl}
                        style={{
                            maxWidth: this.props.size,
                            maxHeight: this.props.size,
                        }}
                        className={this.props.isDisabled ? styles.disabled : null}
                        alt="Logo"
                    />
                </LazyLoad>
            </div>
        );
    }
}

async function saveLogo(model: ResourceWithId, logo: LogoEditorSettings) {
    // Important: Don't change the order of the calls
    // We upload the logo first so that when we do the model save we get back a new url for logo
    if (logo.file) {
        return uploadLogo(model, logo.file);
    } else if (logo.reset) {
        return uploadLogo(model, null!);
    }
}

export { saveLogo };
export default Logo;
