// tslint:disable: no-non-null-assertion
import React, { Component, ReactElement, ReactNode } from "react";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import { repository } from "clientInstance";
const styles = require("./LoadMoreWrapper.less");

interface LoadMoreWrapperProps<T> {
    items: T[];
    initialTakeSize?: number;
    renderLoadMore: (children: ReactNode) => ReactElement<ReactNode>;
    renderItem: (item: T, index: number) => ReactElement<ReactNode>;
}

interface LoadMoreWrapperState {
    itemsTakeSize: number;
}

class LoadMoreWrapper<T> extends Component<LoadMoreWrapperProps<T>, LoadMoreWrapperState> {
    // tslint:disable-next-line: no-any
    public static defaultProps: Partial<LoadMoreWrapperProps<any>> = {
        initialTakeSize: repository.takeDefaultPageSize,
    };

    constructor(props: LoadMoreWrapperProps<T>) {
        super(props);
        this.state = { itemsTakeSize: props.initialTakeSize! };
    }

    render() {
        const packagesToShow = this.props.items ? this.props.items.slice(0, this.state.itemsTakeSize) : [];
        return (
            <>
                {packagesToShow.map(this.props.renderItem)}
                {this.renderLoadMoreAction()}
            </>
        );
    }

    renderLoadMoreAction() {
        const numberOfItems = this.props.items && this.props.items.length;
        const showLoadMoreAction = numberOfItems > this.state.itemsTakeSize;

        let loadMoreComponent: ReactElement | null = null;
        if (showLoadMoreAction) {
            loadMoreComponent = this.props.renderLoadMore(
                <div className={styles.loadMoreContainer}>
                    <ActionButton type={ActionButtonType.Secondary} label="Load more" onClick={e => this.onLoadMore()} />
                    <div className={styles.loadMoreSubText}>
                        <a
                            href="#"
                            onClick={e => {
                                e.preventDefault();
                                this.onLoadAll();
                            }}
                        >
                            load all
                        </a>
                    </div>
                </div>
            );
        }
        return loadMoreComponent;
    }

    private onLoadMore = () => {
        this.setState({ itemsTakeSize: this.state.itemsTakeSize + this.props.initialTakeSize! });
    };

    private onLoadAll = () => {
        this.setState({ itemsTakeSize: repository.takeAll });
    };
}

export default LoadMoreWrapper;
