// tslint:disable: no-non-null-assertion
import MissingProcessStepsMessage from "./MissingProcessStepsMessage";
import { DeploymentProcessResource } from "../../../../client/resources/index";
import Onboarding from "./Onboarding";
import { Callout, CalloutType } from "../../../../components/Callout/Callout";
import * as React from "react";
import { repository } from "clientInstance";
import { ProjectLayout, ProjectRouteParams } from "../ProjectLayout";
import ReleasesTable from "./ReleasesTable";
import { NavigationButton, NavigationButtonType } from "components/Button";
import PaperLayout from "components/PaperLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import { RouteComponentProps } from "react-router";
import { ProjectResource, ReleaseResource, ChannelResource, Permission } from "client/resources";
import { ResourceCollection } from "../../../../client/resources/resourceCollection";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import { RecentProjects } from "utils/RecentProjects/RecentProjects";
import { WithProjectContextInjectedProps, withProjectContext } from "areas/projects/context/withProjectContext";

interface ReleasesState extends DataBaseComponentState {
    project: ProjectResource;
    releases: ResourceCollection<ReleaseResource>;
    channels: ChannelResource[];
    hasDeploymentProcess: boolean;
    selectedChannel: string;
    versionFilter: string;
}

type ReleasesProps = RouteComponentProps<ProjectRouteParams> & WithProjectContextInjectedProps;

class ReleasesInternal extends DataBaseComponent<ReleasesProps, ReleasesState> {
    constructor(props: ReleasesProps) {
        super(props);
        this.state = {
            project: null!,
            releases: null!,
            channels: null!,
            hasDeploymentProcess: false,
            selectedChannel: null!,
            versionFilter: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = this.props.projectContext.state.model;
            await RecentProjects.getInstance().UpdateAccessedProjectIntoLocalStorage(project.Id);
            const [releases, channels, deplomentProc] = await Promise.all([repository.Projects.getReleases(project), repository.Projects.getChannels(project), repository.DeploymentProcesses.get(project.DeploymentProcessId)]);
            this.setState({
                project,
                releases,
                channels: channels.Items,
                hasDeploymentProcess: deplomentProc && deplomentProc.Steps.length > 0,
            });
        });
    }

    isFiltering() {
        return !!this.state.selectedChannel || !!this.state.versionFilter;
    }

    renderBody() {
        if (!this.state.hasDeploymentProcess) {
            return <MissingProcessStepsMessage project={this.state.project} />;
        }

        return this.isFiltering() || this.state.releases.Items.length > 0 ? (
            <ReleasesTable
                releases={this.state.releases}
                channels={this.state.channels}
                onChannelFilterChange={async selectedChannel => {
                    this.setState({ selectedChannel }, async () => {
                        await this.refreshReleases();
                    });
                }}
                onVersionFilterChange={async versionFilter => {
                    this.setState({ versionFilter }, async () => {
                        await this.refreshReleases();
                    });
                }}
                {...this.props}
            />
        ) : (
            <Onboarding project={this.state.project} />
        );
    }

    render() {
        return (
            <PaperLayout
                busy={this.state.busy}
                errors={this.state.errors}
                title="Releases"
                sectionControl={
                    this.state.hasDeploymentProcess && (
                        <PermissionCheck permission={Permission.ReleaseCreate} project={this.state.project && this.state.project.Id} tenant="*">
                            <NavigationButton type={NavigationButtonType.Primary} label="Create release" href={`${this.props.match.url}/create`} disabled={this.state.project && this.state.project.IsDisabled} />
                        </PermissionCheck>
                    )
                }
            >
                {this.state.project && this.state.project.IsDisabled && (
                    <Callout type={CalloutType.Warning} title="Warning">
                        This project is currently disabled, so releases cannot be created.
                    </Callout>
                )}
                {this.state.releases && this.renderBody()}
            </PaperLayout>
        );
    }

    private refreshReleases = async () => {
        const selectedChannel = this.state.selectedChannel;
        const searchByVersion = this.state.versionFilter;
        await this.doBusyTask(async () => {
            const releases = await (selectedChannel ? repository.Channels.getReleases(this.state!.channels.find(c => c.Id === selectedChannel)!, { searchByVersion }) : repository.Projects.getReleases(this.state.project, { searchByVersion }));
            this.setState({ releases });
        });
    };
}

const Releases = withProjectContext(ReleasesInternal);
export { Releases };
