// tslint:disable: no-non-null-assertion
import * as React from "react";
import LibraryLayout from "../LibraryLayout/LibraryLayout";
import PaperLayout from "components/PaperLayout";
import ActionTemplateList from "./ActionTemplateList";
import { repository } from "clientInstance";
import { ActionTemplateResource, ResourceCollection } from "client/resources";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
const styles = require("./style.less");
import { ActionButtonType } from "components/Button/ActionButton";
import { NavigationButton, NavigationButtonType } from "components/Button/NavigationButton";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import ImportActionTemplateDialog from "areas/library/components/ActionTemplates/ImportActionTemplateDialog";
import routeLinks from "routeLinks";
import { ThirdPartyIcon, ThirdPartyIconType } from "components/Icon";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";

interface ActionTemplatesState extends DataBaseComponentState {
    templates: ResourceCollection<ActionTemplateResource>;
    isLoaded: boolean;
    importedTemplateId?: string;
}

class ActionTemplates extends DataBaseComponent<{}, ActionTemplatesState> {
    constructor(props: {}) {
        super(props);

        this.state = {
            templates: null!,
            isLoaded: false,
            importedTemplateId: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const templates = await repository.ActionTemplates.list({ take: 250 });
            this.setState({
                templates,
                isLoaded: true,
            });
        });
    }

    render() {
        if (this.state.importedTemplateId) {
            return <InternalRedirect to={routeLinks.library.stepTemplate(this.state.importedTemplateId).root} push={true} />;
        }

        return (
            <LibraryLayout {...this.props}>
                <PaperLayout title="Step Templates" busy={this.state.busy} errors={this.state.errors}>
                    {this.state.isLoaded && (
                        <div className={styles.lists}>
                            <ActionTemplateList
                                templates={this.state.templates}
                                autoFocusOnFilterSearch={true}
                                filter={t => this.isCustomTemplate(t)}
                                title="Custom Step Templates"
                                actions={[
                                    <OpenDialogButton key="import" label="Import" type={ActionButtonType.Secondary}>
                                        <ImportActionTemplateDialog onSaveDone={template => this.setState({ importedTemplateId: template.Id })} />
                                    </OpenDialogButton>,
                                    <NavigationButton key="add" label="Add" type={NavigationButtonType.Primary} href={routeLinks.library.stepTemplates.builtIn} />,
                                ]}
                                onPrimaryActionRequested={template =>
                                    template.ActionType === "Octopus.Script" && (
                                        <NavigationButton label="RUN" type={NavigationButtonType.Ternary} icon={<ThirdPartyIcon iconType={ThirdPartyIconType.PlayArrow} />} href={routeLinks.library.stepTemplate(template).run} />
                                    )
                                }
                            />
                            <ActionTemplateList
                                templates={this.state.templates}
                                autoFocusOnFilterSearch={false}
                                filter={t => !this.isCustomTemplate(t)}
                                actions={[<NavigationButton key="browse" label="Browse Library" type={NavigationButtonType.Primary} href={routeLinks.library.stepTemplates.community} />]}
                                title="Installed Community Step Templates"
                            />
                        </div>
                    )}
                </PaperLayout>
            </LibraryLayout>
        );
    }

    private isCustomTemplate = (template: ActionTemplateResource) => {
        return !template.CommunityActionTemplateId;
    };
}

export default ActionTemplates;
