// tslint:disable: no-non-null-assertion
import * as React from "react";
import AreaTitle from "components/AreaTitle";
import BaseComponent from "components/BaseComponent";
import MachineIconHelper from "utils/MachineIconHelper";
import NavigationSidebarLayout, { Navigation, NavItem } from "components/NavigationSidebarLayout";
import routeLinks from "routeLinks";
import { connect } from "react-redux";
import { MachineResource } from "client/resources";
import { DisabledChip } from "components/Chips";
import { getCurrentMachine } from "../../reducers/selectors";
import { machineActions, CurrentMachineState } from "../../reducers/machines";
import { Permission } from "client/resources/permission";
import { repository } from "clientInstance";
import { RouteComponentProps } from "react-router";
const styles = require("./style.less");
import { withRouter } from "react-router-dom";
import EndpointsHelper from "utils/EndpointsHelper/EndpointsHelper";
import { Dispatch, Action } from "redux";
import { compact } from "lodash";
const { machineFetched, machineCleared } = machineActions;

type MachineLayoutRouteProps = RouteComponentProps<{ machineId: string }>;

interface MachineLayoutDispatchProps {
    onFetchMachine?(machine: MachineResource): void;
    onClearMachine?(): void;
}

interface MachineCommonProps {
    machineId?: string;
    machineName?: string;
    machineType?: string;
    machineIconFilename?: string;
    machineIsDisabled?: boolean;
}

interface MachineLayoutStateProps extends MachineCommonProps {
    title: string;
    rootLink: string;
    extraNavLinks: NavItem[];
    getMachine(id: string): Promise<MachineResource>;
    getMachineLinks(id: string): MachineLinks;
}

type MachineLayoutConnectedProps = MachineLayoutDispatchProps & MachineLayoutStateProps;
type MachineLayoutProps = MachineLayoutConnectedProps & MachineLayoutRouteProps;

interface MachineLinks {
    settings: string;
    connection: string;
    events: string;
}

class MachineLayoutInternal extends BaseComponent<MachineLayoutProps, {}> {
    private machineId: string;

    constructor(props: MachineLayoutProps) {
        super(props);
        this.machineId = this.props.match && this.props.match.params ? this.props.match.params.machineId : null!;
        this.state = {};
    }

    async componentDidMount() {
        if (this.machineId) {
            const machine = await this.props.getMachine(this.machineId);
            this.props.onFetchMachine!(machine);
        }
    }

    render() {
        const machineId = this.machineId;
        const navLinks: Array<NavItem | null> = [];
        if (machineId) {
            const machineLinks = this.props.getMachineLinks(machineId);

            navLinks.push(Navigation.navItem("Settings", machineLinks.settings));
            navLinks.push(Navigation.navItem("Connectivity", machineLinks.connection));
            if (this.props.extraNavLinks) {
                navLinks.push.apply(navLinks, this.props.extraNavLinks);
            }
            navLinks.push(Navigation.navItem("Events", machineLinks.events, undefined, { permission: Permission.EventView, wildcard: true }));
        }

        let machineImage = null;
        if (this.props.machineIconFilename) {
            const machineIcon = MachineIconHelper.machineIconFromFilename(this.props.machineIconFilename);
            machineImage = (
                <div className={styles.machineImage}>
                    <img src={machineIcon} className={styles.machineIcon} alt="Machine" />
                </div>
            );
        }

        return (
            <main id="maincontent">
                <AreaTitle link={this.props.rootLink} title={this.props.title} breadcrumbTitle={"Infrastructure"} breadcrumbPath={routeLinks.infrastructure.root} />
                <NavigationSidebarLayout
                    name={this.props.machineName}
                    resourceType={this.props.machineType}
                    image={machineImage}
                    navLinks={compact(navLinks)}
                    preNavbarComponent={
                        this.props.machineIsDisabled && (
                            <div>
                                <DisabledChip />
                            </div>
                        )
                    }
                    content={this.props.children}
                />
            </main>
        );
    }
}

const mapGlobalStateToProps = (state: GlobalState) => {
    const machine = getCurrentMachine(state);
    return !machine
        ? {}
        : {
              machineId: machine.id,
              machineName: machine.name,
              machineType: machine.machineType,
              machineIconFilename: machine.machineIconFilename,
              machineIsDisabled: machine.isDisabled,
          };
};

const mapGlobalStateToPropsForDeploymentTargets = (state: GlobalState): MachineLayoutStateProps => {
    const machine = getCurrentMachine(state);
    const common = mapGlobalStateToProps(state);
    return {
        ...common,
        title: "Deployment Targets",
        rootLink: routeLinks.infrastructure.machines.root,
        extraNavLinks: [(machine && Navigation.navItem("Deployments", routeLinks.infrastructure.deploymentTarget(machine.id).deployments, undefined, { permission: Permission.TaskView, wildcard: true }))!],
        getMachine: (id: string) => repository.Machines.get(id),
        getMachineLinks: (id: string) => routeLinks.infrastructure.machine(id),
    };
};

const mapGlobalStateToPropsForWorkers = (state: GlobalState): MachineLayoutStateProps => {
    const common = mapGlobalStateToProps(state);
    return {
        ...common,
        title: "Workers",
        extraNavLinks: [],
        rootLink: routeLinks.infrastructure.workerMachines.root,
        getMachine: (id: string) => repository.Workers.get(id),
        getMachineLinks: (id: string) => routeLinks.infrastructure.workerMachine(id),
    };
};

const mapGlobalActionDispatchersToProps = (dispatch: Dispatch<Action>) => {
    return {
        onFetchMachine: (machine: MachineResource) => {
            const filename = MachineIconHelper.machineIconFilename(machine);
            dispatch(
                machineFetched({
                    id: machine.Id,
                    name: machine.Name,
                    machineIconFilename: filename,
                    machineType: EndpointsHelper.getFriendlyName(machine.Endpoint.CommunicationStyle)!,
                    isDisabled: machine.IsDisabled,
                })
            );
        },
        onClearMachine: () => {
            dispatch(machineCleared());
        },
    };
};

export const WorkerMachineLayout = withRouter(connect<MachineLayoutStateProps, MachineLayoutDispatchProps, MachineLayoutRouteProps & MachineCommonProps>(mapGlobalStateToPropsForWorkers, mapGlobalActionDispatchersToProps)(MachineLayoutInternal));

export const MachineLayout = withRouter(connect<MachineLayoutStateProps, MachineLayoutDispatchProps, MachineLayoutRouteProps & MachineCommonProps>(mapGlobalStateToPropsForDeploymentTargets, mapGlobalActionDispatchersToProps)(MachineLayoutInternal));
