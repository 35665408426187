// tslint:disable: no-non-null-assertion
import * as React from "react";
import { RouteComponentProps } from "react-router";
import LibraryLayout from "../LibraryLayout/LibraryLayout";
import PaperLayout from "components/PaperLayout";
import ExternalFeedList from "./ExternalFeedList";
import { NavigationButton, NavigationButtonType } from "components/Button";
import { ExternalFeedResource, FeedType } from "../../../../client/resources/feedResource";
import { repository } from "../../../../clientInstance";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import Onboarding from "./Onboarding";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";

interface FeedsState extends DataBaseComponentState {
    feeds: ExternalFeedResource[];
}

class ExternalFeeds extends DataBaseComponent<RouteComponentProps<void>, FeedsState> {
    constructor(props: RouteComponentProps<void>) {
        super(props);
        this.state = {
            feeds: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const feeds = await repository.Feeds.all();
            this.setState({
                feeds: feeds.filter(f => f.FeedType !== FeedType.BuiltIn && f.FeedType !== FeedType.OctopusProject).map(f => f as ExternalFeedResource),
            });
        });
    }

    render() {
        const addFeedButton = (
            <PermissionCheck permission={[Permission.FeedEdit]}>
                <NavigationButton type={NavigationButtonType.Primary} label="Add feed" href={`${this.props.match.url}/create`} />
            </PermissionCheck>
        );

        return (
            <LibraryLayout {...this.props}>
                <PaperLayout title="External Feeds" sectionControl={addFeedButton} busy={this.state.busy} errors={this.state.errors}>
                    {this.state.feeds != null && (
                        <div>
                            {this.state.feeds.length === 0 && <Onboarding />}
                            {this.state.feeds.length > 0 && (
                                <React.Fragment>
                                    <ExternalFeedList feeds={this.state.feeds} onDelete={this.handleFeedDelete} />
                                </React.Fragment>
                            )}
                        </div>
                    )}
                </PaperLayout>
            </LibraryLayout>
        );
    }

    private handleFeedDelete = async (feed: ExternalFeedResource) => {
        this.setState((prev: FeedsState) => ({
            feeds: prev.feeds.filter(f => f.Id !== feed.Id),
        }));
        return true;
    };
}

export default ExternalFeeds;
