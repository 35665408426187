// tslint:disable: no-any

import BasicRepository from "./basicRepository";
import Client from "../client";
import { ResourceCollection, WorkerMachineResource, MachineConnectionStatus, MachineResource } from "../resources";

export type ListWorkerArgs = {
    skip?: number;
    take?: number;
    partialName?: string;
    roles?: string;
    isDisabled?: boolean;
    healthStatuses?: string;
    commStyles?: string;
    tenantIds?: string;
    tenantTags?: string;
    environmentIds?: string;
    shellNames?: string;
    workerPoolIds?: string;
};

class WorkerRepository extends BasicRepository<WorkerMachineResource, WorkerMachineResource> {
    constructor(client: Client) {
        super("Workers", client);
    }
    list(args?: ListWorkerArgs): Promise<ResourceCollection<WorkerMachineResource>> {
        return this.client.get(this.client.getLink("Workers"), args);
    }
    getConnectionStatus(machine: MachineResource): Promise<MachineConnectionStatus> {
        return this.client.get<MachineConnectionStatus>(machine.Links["Connection"]);
    }
    discover(host: string, port: number, type: any, proxyId: string): Promise<WorkerMachineResource> {
        return this.client.get<WorkerMachineResource>(this.client.getLink("DiscoverWorker"), { host, port, type, proxyId });
    }
}

export default WorkerRepository;
