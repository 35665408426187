// tslint:disable: no-any

import { NamedResource, TenantedDeploymentMode, ActionTemplateParameterResource } from "client/resources";
import { DeploymentActionPackageResource } from "./deploymentActionPackageResource";
import { MetadataTypeCollection } from "./dynamicFormResources";
import { ExtensionSettingsValues } from "./extensionSettingsValues";

interface ProjectResourceLinks {
    Self: string;
    Releases: string;
    Channels: string;
    Triggers: string;
    ScheduledTriggers: string;
    OrderChannels: string;
    Variables: string;
    Progression: string;
    RunbookTaskRunDashboardItemsTemplate: string;
    DeploymentProcess: string;
    Web: string;
    Logo: string;
    Metadata: string;
    Runbooks: string;
    RunbookSnapshots: string;
}

export interface ProjectResource extends ProjectBaseResource {
    VariableSetId: string;
    DeploymentProcessId: string;
    DiscreteChannelRelease: boolean;
    IncludedLibraryVariableSetIds: string[];
    DefaultToSkipIfAlreadyInstalled: boolean;
    TenantedDeploymentMode: TenantedDeploymentMode;
    VersioningStrategy: VersioningStrategy;
    ReleaseCreationStrategy: ReleaseCreationStrategy;
    Templates: ActionTemplateParameterResource[];
    AutoDeployReleaseOverrides: any[];
    LifecycleId: string;
    AutoCreateRelease: boolean;
    ProjectConnectivityPolicy: ProjectConnectivityPolicy;
    DefaultGuidedFailureMode: GuidedFailureMode;
    ClonedFromProjectId: string;
    SpaceId?: string;
    ExtensionSettings: ExtensionSettingsValues[];
    ReleaseNotesTemplate: string;
    DeploymentChangesTemplate: string;
}

export interface ProjectBaseResource extends NamedResource<ProjectResourceLinks> {
    Slug: string;
    ProjectGroupId: string;
    SpaceId?: string;
    Description: string;
    IsDisabled: boolean;
}

export interface ProjectSettingsMetadata {
    ExtensionId: string;
    Metadata: MetadataTypeCollection;
}

export interface VersioningStrategy {
    Template: string;
    DonorPackage?: DeploymentActionPackageResource;
    DonorPackageStepId?: string;
}

export interface ReleaseCreationStrategy {
    ReleaseCreationPackage: DeploymentActionPackageResource;
    ChannelId?: string;
    ReleaseCreationPackageStepId?: string;
}

export interface ProjectConnectivityPolicy {
    SkipMachineBehavior: string;
    TargetRoles: string[];
    AllowDeploymentsToNoTargets: boolean;
    ExcludeUnhealthyTargets: boolean;
}

export enum GuidedFailureMode {
    EnvironmentDefault = "EnvironmentDefault",
    Off = "Off",
    On = "On",
}

export interface RecentlyViewedProjectIds {
    [key: string]: string[];
}

export default ProjectResource;
