import * as React from "react";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import { ActionEditProps } from "components/Actions/pluginRegistry";
import { default as ExpandableFormSection } from "components/form/Sections/ExpandableFormSection";
import Summary from "components/form/Sections/Summary";
import { default as CodeEditor, TextFormat } from "components/CodeEditor/CodeEditor";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import SourceCodeDialog from "components/SourceCodeDialog/sourceCodeDialog";
import Note from "components/form/Note/Note";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";

export interface KubernetesCustomResourceProps {
    "Octopus.Action.KubernetesContainers.CustomResourceYaml": string;
}

export class KubernetesCustomResourceComponent extends DataBaseComponent<ActionEditProps<KubernetesCustomResourceProps>, DataBaseComponentState> {
    constructor(props: ActionEditProps<KubernetesCustomResourceProps>) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <ExpandableFormSection
                errorKey="Octopus.Action.KubernetesContainers.CustomResourceYaml"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Custom resource YAML"
                summary={this.resourceYamlSummary()}
                help={"Define the YAML for additional Kubernetes resources to be managed as part of this deployment."}
            >
                <Note>A new instance of each resource listed in the editor below will be created with each deployment, and old resources will be cleaned up.</Note>
                <Note>
                    Multiple resources can be defined by separating the individual YAML documents with a triple dash i.e. <code>---</code>.
                </Note>
                <Note>
                    Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#custom-resources-yaml">custom resources</ExternalLink>.
                </Note>
                <CodeEditor value={this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYaml"]} language={TextFormat.YAML} allowFullScreen={false} readOnly={true} />
                <div>
                    <OpenDialogButton
                        label={this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYaml"] ? "Edit Resource" : "Add Resource"}
                        renderDialog={({ open, closeDialog }) => (
                            <SourceCodeDialog
                                title="Edit Kubernetes Resource"
                                open={open}
                                close={closeDialog}
                                template={this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYaml"]}
                                doBusyTask={this.props.doBusyTask}
                                autocomplete={[]}
                                saveDone={(template, metadata) => {
                                    this.props.setProperties({ ["Octopus.Action.KubernetesContainers.CustomResourceYaml"]: template });
                                }}
                                language={TextFormat.YAML}
                                metadata="Kubernetes"
                            />
                        )}
                    />
                </div>
            </ExpandableFormSection>
        );
    }

    private resourceYamlSummary() {
        if (this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYaml"]) {
            return Summary.summary("Additional resources have been defined");
        }
        return Summary.default("No additional resources have been defined");
    }
}
