// tslint:disable: no-non-null-assertion
import * as React from "react";
import { OctopusError } from "client/resources";
import Text from "components/form/Text/Text";
import { DataBaseComponentState } from "components/DataBaseComponent";
import { DataBaseComponent } from "components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";

interface ExampleDialogContentProps {
    throwErrors?: boolean;
    title?: string;

    saveDone?(updated: string): void; // Optional if you need to talk back to the launching component on save
}

interface ExampleDialogContentState extends DataBaseComponentState {
    buttonLabel?: string;
    mydata: boolean;
}

export default class ExampleDialogContent extends DataBaseComponent<ExampleDialogContentProps, ExampleDialogContentState> {
    constructor(props: ExampleDialogContentProps) {
        super(props);
        this.state = { mydata: false };
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            await new Promise(resolve => setTimeout(resolve, 1000));
            if (this.props.throwErrors) {
                throw new OctopusError(500, "Load failed for some reason");
            }
            this.setState({ buttonLabel: "Open example save form", mydata: true });
        });
    }

    async save() {
        return this.doBusyTask(async () => {
            await new Promise(resolve => setTimeout(resolve, 1000));
            if (this.props.throwErrors) {
                throw new OctopusError(501, "Save went boom boom");
            }
            this.props.saveDone!(this.state.buttonLabel!);
        });
    }

    render() {
        const body = this.state.mydata && <Text value={this.state.buttonLabel!} label="Button label" hintText="Label for the button that opens this dialog" onChange={buttonLabel => this.setState({ buttonLabel })} />;

        return (
            <SaveDialogLayout title={this.props.title || "Example Dialog"} busy={this.state.busy} errors={this.state.errors} onSaveClick={() => this.save()}>
                {body}
            </SaveDialogLayout>
        );
    }
}
